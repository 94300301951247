import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";


function getAllCourier(){
    return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_courier`)
}

function getOrderDataByCourierID(courierIDObj){
    return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_delivered_orders_by_courier`, courierIDObj)
      .then((res)=>{

        if(res.data.status === '200'){
          return res.data
        }
        else {
          throw new toast.error("Data Not Found!");
        }
      })
    .catch((error)=>{
      if(error.response.data.status === '401'){
        toast.warn(error.response.data.message)
      }
      else{
        toast.warn("Something went wrong, please try again in a while")
      }
      })
  }

  function courierAndOrderAmountSubmissions(orderAndCourierObj){
    return axios.post(`${process.env.REACT_APP_BASE_URL}post_pay_courier`, orderAndCourierObj)
    .then((res)=>{
      if(res.data.status === '200'){
        toast.info("Amounts submitted !")
      }
      else if(res.data.status === '401'){
        toast.error(res.data.data)
      }
      else {
        throw new toast.error("Can't Submit!");
      }
    })
  .catch((error)=>{
    if(error){
      
      toast.warn("Something went wrong, please try again in a while")
    }
    })

  }

  const OrderDataByCourierID = ()=>{
    return useMutation(getOrderDataByCourierID)
}

const CourierAndOrderAmount = ()=>{
  return useMutation(courierAndOrderAmountSubmissions)
}


const CourierSectionEndPoint ={
    getAllCourier,
    OrderDataByCourierID,
    CourierAndOrderAmount
}

export default CourierSectionEndPoint;