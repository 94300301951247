import React, { useState, useEffect } from "react";
import wolfImg from '../Images/wolfIconT.jpeg';
import { Link } from "react-router-dom";

const Sidebar = ({ isVisible, toggleSidebar }) => {
  const [isSubMenuOneOpen, setIsSubMenuOneOpen] = useState(false);

  const [memberSubMenu , setMemberSubMenu] = useState(false)

  const [inventorySubMenu, setInventorySubMenu] = useState(false);
  const [TextileItemsSubmenu, setTextileItemSubSubmenu] = useState(false);
  const [clothingSubMenu, setClothingSubMenu] = useState(false);
  const [vendorSubMenu, setVendorSubMenu] = useState(false);
  const [vendorReceSubMenu, setVendorReceSubMenu] = useState(false);

  const [articleReceivingSubMenu , setArticleReceivingSubMenu] = useState('')
  const [articleSection, setArticleSection] = useState(false);

  const [eventManagementSubMenu, setEventManagemnetSubMenu] = useState(false);
  const [eventSubmMenu, setEventSubMenu] = useState(false);
  const [promocodeSubMenu , setPromoCodeSubMenu] = useState(false);

  const [staffManagementSubMenu , setStaffManagementSubMenu] = useState(false);
  const [staffSectionSubMenu , setStaffSectionSubMenu] = useState(false);
  const [staffPayRollSubMenu , setStaffPayRollSubMenu] = useState(false);

  const [orderSectionSubMenu , setOrderSectionSubMenu] = useState(false);
  const [orderManagementSubMenu , setOrderManagementSubMenu] = useState(false);
  const [manualOrderSubMenu , setManualOrderSubMenu] = useState(false);

  const[disputedOrderSubMenu , setDisputedOrderSubMenu] = useState(false);


  const[bankManagementSubMenu , setBankManagementSubMenu] = useState(false); 
  const[bankSectionSubMenu , setBankSectionSubMenu] = useState(false);

  const[cashAndDepositSubMenu , setCashAndDepositSubMenu] = useState(false);

  const[courierSectionSubMenu , setCourierSectionSubMenu] = useState(false);
  const[expenseSectionSubMenu , setExpenseSectionSubMenu] = useState(false);

  const[statisticsSubMenu , setStatisticsSubmenu] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const SubMenuToggle = (menu) => {
    if (menu === "Layouts") {
      setIsSubMenuOneOpen(!isSubMenuOneOpen);
    } 
    else if(menu === "Member section"){
        setMemberSubMenu(!memberSubMenu)
    }
    else if (menu === "Inventory") {
      setInventorySubMenu(!inventorySubMenu);
    } else if (menu === "Textile Items") {
      setTextileItemSubSubmenu(!TextileItemsSubmenu);
    } else if (menu === "Clothing Items") {
      setClothingSubMenu(!clothingSubMenu);
    } else if (menu === "Vendor Section") {
      setVendorSubMenu(!vendorSubMenu);
    } else if (menu === "Vendor Receiving Section") {
      setVendorReceSubMenu(!vendorReceSubMenu);
    } else if (menu === "Article Section") {
      setArticleSection(!articleSection);
    } 
    
    else if(menu === "Article Receiving Section"){
      setArticleReceivingSubMenu(!articleReceivingSubMenu)
    }
    else if (menu === "Event Management") {
      setEventManagemnetSubMenu(!eventManagementSubMenu);
    } else if (menu === "Event section") {
      setEventSubMenu(!eventSubmMenu);
    } 
    else if(menu === "Promocode Section"){
      setPromoCodeSubMenu(!promocodeSubMenu)
    }
    else if(menu === 'Staff Management'){
      setStaffManagementSubMenu(!staffManagementSubMenu)
    }
    else if(menu === 'Staff section'){
      setStaffSectionSubMenu(!staffSectionSubMenu)
    }
    else if(menu === 'Staff Payrolls'){
      setStaffPayRollSubMenu(!staffPayRollSubMenu)
    }
     else if(menu === 'Order Management'){
      setOrderManagementSubMenu(!orderManagementSubMenu)
     }
     else if(menu === 'Manual Order'){
      setManualOrderSubMenu(!manualOrderSubMenu)

     }
     else if(menu === 'Order Section'){
      setOrderSectionSubMenu(!orderSectionSubMenu)
     }
     else if(menu === 'Disputed Orders'){
      setDisputedOrderSubMenu(!disputedOrderSubMenu)
     }
     else if(menu === 'Bank Management'){
      setBankManagementSubMenu(!bankManagementSubMenu)
     }
     else if(menu === 'Courier Management'){
      setCourierSectionSubMenu(!courierSectionSubMenu)
     }
     else if(menu === 'Statistics'){
      setStatisticsSubmenu(!statisticsSubMenu)
     }
     else if(menu === 'Bank section'){
      setBankSectionSubMenu(!bankSectionSubMenu)
     }

     else if(menu === 'Cash Deposit Sub Menu'){
      setCashAndDepositSubMenu(!cashAndDepositSubMenu)

     }
     else if(menu === 'Expense section'){
      setExpenseSectionSubMenu(!expenseSectionSubMenu)
     }

     
    else {
    }
  }

  return (
  <>
   {isVisible && (
        <aside
          id="layout-menu"
          className={`layout-menu menu-vertical menu bg-menu-theme ${
            isVisible ? "sidebar-open" : false
          }`}
        >
          <div className="app-brand demo mb-4">
          <img className="img-fluid d-block mx-auto" src={wolfImg} alt="" width={120} />
              {/* <span className="app-brand-text  menu-text fw-bolder" style={{fontSize:"22px"}}>
                Avant grade
              </span> */}
            <a href="#" className="app-brand-link">
              {/* <span className="app-brand-logo demo"> */}
                {/* <svg
                  width={25}
                  viewBox="0 0 25 42"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <path
                      d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z"
                      id="path-1"
                    />
                    <path
                      d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z"
                      id="path-3"
                    />
                    <path
                      d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z"
                      id="path-4"
                    />
                    <path
                      d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z"
                      id="path-5"
                    />
                  </defs>
                  <g
                    id="g-app-brand"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Brand-Logo"
                      transform="translate(-27.000000, -15.000000)"
                    >
                      <g id="Icon" transform="translate(27.000000, 15.000000)">
                        <g id="Mask" transform="translate(0.000000, 8.000000)">
                          <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                          </mask>
                          <use fill="#696cff" xlinkHref="#path-1" />
                          <g id="Path-3" mask="url(#mask-2)">
                            <use fill="#696cff" xlinkHref="#path-3" />
                            <use
                              fillOpacity="0.2"
                              fill="#FFFFFF"
                              xlinkHref="#path-3"
                            />
                          </g>
                          <g id="Path-4" mask="url(#mask-2)">
                            <use fill="#696cff" xlinkHref="#path-4" />
                            <use
                              fillOpacity="0.2"
                              fill="#FFFFFF"
                              xlinkHref="#path-4"
                            />
                          </g>
                        </g>
                        <g
                          id="Triangle"
                          transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) "
                        >
                          <use fill="#696cff" xlinkHref="#path-5" />
                          <use
                            fillOpacity="0.2"
                            fill="#FFFFFF"
                            xlinkHref="#path-5"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg> */}
              {/* </span> */}
              
            </a>
            <a href="#" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        </a>
            <a
              href="#x"
              className="layout-menu-toggle menu-link  ms-auto"
              onClick={toggleSidebar}
            >
              <i className="bx bx-chevron-left bx-sm align-middle" />
            </a>
          </div>
          {/* <div className="menu-inner-shadow" /> */}
          <div className="scroll-view scrollbar-secondary">
            <ul className="menu-inner">
              {/* Dashboard */}
              <li className="menu-item active">
                <Link to="/" className="menu-link">
                  <i className="menu-icon tf-icons bx bx-home-circle" />
                  <div data-i18n="Analytics">Dashboard</div>
                </Link>
              </li>

              
              <li className="menu-header small text-uppercase">
                  <span className="menu-header-text">Users</span>
                </li>


                    
                <li className="menu-item">
                  <a
                    href="#b"
                    className={`menu-link menu-toggle ${
                      memberSubMenu ? "open" : ""
                    }`}
                    onClick={() => SubMenuToggle("Member section")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="menu-icon tf-icons bx bxs-user" />
                    <div data-i18n="user">Members Management</div>
                  </a>
                  <ul
                    className={`menu-sub ${
                      memberSubMenu ? "open" : "close"
                    }`}
                  >

                    {/* Expense Management sub-menu */}
                    <li className="menu-item" style={{ marginLeft: "-1em" }}>
                      <ul
                        className={`menu-sub ${memberSubMenu ? "open" : "close"}`}
                        style={{ marginLeft: "-1em" }}
                      >
                              
                        <li className={`menu-item ${selectedItem === 'RegisterMemberForm' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('RegisterMemberForm')}>
                          <Link to="/RegisterMemberForm" className="menu-link">
                            <div data-i18n="Submenu Item 2">Register Members</div>
                          </Link>
                        </li>

                          <li className={`menu-item ${selectedItem === 'MembersSheet' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('MembersSheet')}>
                          <Link to="/MembersSheet" className="menu-link">
                            <div data-i18n="Submenu Item 2">Members Sheet</div>
                          </Link>
                        </li>  


                        <li className={`menu-item ${selectedItem === 'PermissionForm' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('PermissionForm')}>
                          <Link to="/PermissionForm" className="menu-link">
                            <div data-i18n="Submenu Item 2">Permission Form</div>
                          </Link>
                        </li>  

                        <li className={`menu-item ${selectedItem === 'PermissionSheet' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('PermissionSheet')}>
                          <Link to="/PermissionSheet" className="menu-link">
                            <div data-i18n="Submenu Item 2">Permission Sheet</div>
                          </Link>
                        </li>  


                        
                        <li className={`menu-item ${selectedItem === 'GrantPermissionForm' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('GrantPermissionForm')}>
                          <Link to="/GrantPermissionForm" className="menu-link">
                            <div data-i18n="Submenu Item 2">Grant Permission Form</div>
                          </Link>
                        </li>  

                        <li className={`menu-item ${selectedItem === 'GrantPermissionSheet' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('GrantPermissionSheet')}>
                          <Link to="/GrantPermissionSheet" className="menu-link">
                            <div data-i18n="Submenu Item 2">Grant Permission Sheet</div>
                          </Link>
                        </li>  


                        


                      </ul>
                    </li>
                    {/*Expense Management sub-menu */}

                  </ul>
                </li>

              
                <li className="menu-header small text-uppercase">
                  <span className="menu-header-text">Inventory & Event</span>
                </li>

              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    inventorySubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Inventory")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bx-store" />
                  <div data-i18n="store">Inventory Section</div>
                </a>
                <ul
                  className={`menu-sub ${inventorySubMenu ? "open" : "close"}`}
                >

                  {/* Textile Supplies sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        TextileItemsSubmenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Textile Items")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Textile Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${
                        TextileItemsSubmenu ? "open" : "close"
                      }`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className={`menu-item ${selectedItem === 'TextileItemsForm' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('TextileItemsForm')}
                      >
                        <Link to="/TextileItemsForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">
                            Textile Items Form
                          </div>
                        </Link>
                      </li>
                      <li className={`menu-item ${selectedItem === 'TextileItemsSheet' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('TextileItemsSheet')}>
                        <Link to="/TextileItemsSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">
                            Textile Items Sheet
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Textile Supplies sub-menu */}

                  {/* Clothing sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        clothingSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Clothing Items")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Clothing Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${
                        clothingSubMenu ? "open" : "close"
                      }`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/ClothCategoryForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Category Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/ClothCategorySheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Category Sheet</div>
                        </Link>
                      </li>

                      <li className="menu-item">
                        <Link to="/ClothSubCategoryForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">
                            Sub Category Form
                          </div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/ClothSubCategorySheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">
                            Sub Category Sheet
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* Clothing sub-menu */}

                  {/* Vendor sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        vendorSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Vendor Section")}
                      style={{ listStyleType: "none", cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Vendor Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${vendorSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/VendorForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Vendor Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/VendorSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Vendor Sheet</div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Vendor sub-menu */}

                  {/* Vendor receiving sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        vendorReceSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Vendor Receiving Section")}
                      style={{ listStyleType: "none", cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Item Receiving Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${
                        vendorReceSubMenu ? "open" : "close"
                      }`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/ItemReceivingForm" className="menu-link">
                          <div data-i18n="Submenu Item 4">
                            Item Receiving Form
                          </div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/ItemReceivingSheet" className="menu-link">
                          <div data-i18n="Submenu Item 5">
                            Item Receiving Sheet
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Vendor receiving sub-menu */}

                        {/* Article Section sub-menu */}
                        <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        articleReceivingSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Article Receiving Section")}
                      style={{ listStyleType: "none", cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Article Receiving Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${
                        articleReceivingSubMenu ? "open" : "close"
                      }`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/ArticleReceivingForm" className="menu-link">
                          <div data-i18n="Submenu Item 4">Article Receiving Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/ArticleReceivingSheet" className="menu-link">
                          <div data-i18n="Submenu Item 5">Article Receiving Sheet</div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Article Section sub-menu */}

                  {/* Article Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        articleSection ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Article Section")}
                      style={{ listStyleType: "none", cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Article Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${
                        articleSection ? "open" : "close"
                      }`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/AddArticleForm" className="menu-link">
                          <div data-i18n="Submenu Item 4">Article Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/ArticleSheet" className="menu-link">
                          <div data-i18n="Submenu Item 5">Article Sheet</div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* Article Section sub-menu */}
                </ul>
              </li>

              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    eventManagementSubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Event Management")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bx-shape-polygon" />
                  <div data-i18n="store">Event Management</div>
                </a>
                <ul
                  className={`menu-sub ${
                    eventManagementSubMenu ? "open" : "close"
                  }`}
                >
          

                  {/* Event Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        eventSubmMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Event section")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Event Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${eventSubmMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/EventForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Event Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/EventSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Event Sheet</div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* Event Section sub-menu */}


                     {/* PromoCode Section sub-menu */}
                     <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        promocodeSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Promocode Section")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Promo Code Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${promocodeSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/PromoCodeForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Promocode Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/PromoCodeSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Promo Sheet</div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* PromoCode Section sub-menu */}


                </ul>
              </li>

              <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Staff</span>
              </li>

              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    staffManagementSubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Staff Management")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bx-group" />
                  <div data-i18n="store">Staff Management</div>
                </a>
                <ul
                  className={`menu-sub ${
                    staffManagementSubMenu ? "open" : "close"
                  }`}
                >


                  {/* Staff Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        staffSectionSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Staff section")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Staff Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${staffSectionSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/StaffForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Staff Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/StaffSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Staff Sheet</div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* Staff Section sub-menu */}

                   {/* Staff Payrolls sub-menu */}
                   <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        staffPayRollSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Staff Payrolls")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Staff Payrolls</div>
                    </a>
                    <ul
                      className={`menu-sub ${staffPayRollSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/StaffPayRollForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Staff Payroll Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/StaffPayRollSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Staff Payroll Sheet</div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* Staff Payrolls sub-menu */}

                </ul>
              </li>


              
              <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Bank</span>
              </li>

              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    bankManagementSubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Bank Management")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bxs-bank" />
                  <div data-i18n="bank">Bank Management</div>
                </a>
                <ul
                  className={`menu-sub ${
                    bankManagementSubMenu ? "open" : "close"
                  }`}
                >
         

                  {/* Bank Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        bankSectionSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Bank section")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Bank Section</div>
                    </a>

                    <ul
                      className={`menu-sub ${bankSectionSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/BankForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Bank Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/BankSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Bank Sheet</div>
                        </Link>
                      </li>
                    </ul>


                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        cashAndDepositSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Cash Deposit Sub Menu")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Cash & Deposits</div>
                    </a>
                    
                    <ul
                      className={`menu-sub ${cashAndDepositSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      <li className="menu-item">
                        <Link to="/DepositForm" className="menu-link">
                          <div data-i18n="Submenu Item 1">Deposit Form</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/DeductionForm" className="menu-link">
                          <div data-i18n="Submenu Item 2">Deduction Form</div>
                        </Link>
                      </li>
                    </ul>



                  </li>
                  {/* Bank Section sub-menu */}


                </ul>
              </li>


              <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Orders</span>
              </li>

              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    orderManagementSubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Order Management")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bx-package" />
                  <div data-i18n="store">Order Management</div>
                </a>
                <ul
                  className={`menu-sub ${
                    orderManagementSubMenu ? "open" : "close"
                  }`}
                >
         

                  {/* Order Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        staffSectionSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Order Section")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Order Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${orderSectionSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                       <li className={`menu-item ${selectedItem === 'AllOrders' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('AllOrders')}
                      >
                        <Link to="/AllOrders" className="menu-link">
                          <div data-i18n="Submenu Item 1">All Orders</div>
                        </Link>
                      </li>

                      <li className={`menu-item ${selectedItem === 'OrderIntake' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('OrderIntake')}
                      >
                        <Link to="/OrderIntake" className="menu-link">
                          <div data-i18n="Submenu Item 1">Online Orders</div>
                        </Link>
                      </li>

                      
                      <li className={`menu-item ${selectedItem === 'PendingOrders' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('PendingOrders')}>
                        <Link to="/PendingOrders" className="menu-link">
                          <div data-i18n="Submenu Item 2">Pending Orders</div>
                        </Link>
                      </li>

                       <li className={`menu-item ${selectedItem === 'VerifiedOrders' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('VerifiedOrders')}>
                        <Link to="/VerifiedOrders" className="menu-link">
                          <div data-i18n="Submenu Item 2">Verified Orders</div>
                        </Link>
                      </li> 

                      <li className={`menu-item ${selectedItem === 'AssignedOrders' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('AssignedOrders')}>
                        <Link to="/AssignedOrders" className="menu-link">
                          <div data-i18n="Submenu Item 2">Assigned Orders</div>
                        </Link>
                      </li> 

                      <li className={`menu-item ${selectedItem === 'DispatchedOrders' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('DispatchedOrders')}>
                        <Link to="/DispatchedOrders" className="menu-link">
                          <div data-i18n="Submenu Item 2">Dispatched Orders</div>
                        </Link>
                      </li> 
                      
                      

                      
                      <li className={`menu-item ${selectedItem === 'DeliveredOrders' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('DeliveredOrders')}>
                        <Link to="/DeliveredOrders" className="menu-link">
                          <div data-i18n="Submenu Item 2">Delivered Orders</div>
                        </Link>
                      </li> 
                    </ul>
                  </li>
                  {/* Order Section sub-menu */}


                  
                  {/* Order Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <a
                      href="#c"
                      className={`menu-link menu-toggle ${
                        manualOrderSubMenu ? "open" : ""
                      }`}
                      onClick={() => SubMenuToggle("Manual Order")}
                      style={{ cursor: "pointer" }}
                    >
                      <div data-i18n="NewSubMenu">Manual Order Section</div>
                    </a>
                    <ul
                      className={`menu-sub ${manualOrderSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >

                      <li className={`menu-item ${selectedItem === 'ManualOrderForm' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('ManualOrderForm')}>
                        <Link to="/ManualOrderForm" className="menu-link">
                          <div data-i18n="Submenu Item 2">Manual Order Form</div>
                        </Link>
                      </li> 
                      
                    </ul>
                  </li>
                  {/* Order Section sub-menu */}

               
                </ul>
              </li>

        

              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    disputedOrderSubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Disputed Orders")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bx-user-x" />
                  <div data-i18n="store">Disputed Orders</div>
                </a>
                <ul
                  className={`menu-sub ${
                    disputedOrderSubMenu ? "open" : "close"
                  }`}
                >

                  {/* Disputed Order Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <ul
                      className={`menu-sub ${disputedOrderSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                      
                      <li className={`menu-item ${selectedItem === 'DisputedOrders' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('DisputedOrders')}>
                        <Link to="/DisputedOrders" className="menu-link">
                          <div data-i18n="Submenu Item 2">Disputed Orders</div>
                        </Link>
                      </li> 

                    </ul>
                  </li>
                  {/*Disputed Order Section sub-menu */}

                </ul>
              </li>

                <li className="menu-header small text-uppercase">
                  <span className="menu-header-text">Expenses</span>
                </li>


                    
                <li className="menu-item">
                  <a
                    href="#b"
                    className={`menu-link menu-toggle ${
                      expenseSectionSubMenu ? "open" : ""
                    }`}
                    onClick={() => SubMenuToggle("Expense section")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="menu-icon tf-icons bx bxs-wallet-alt" />
                    <div data-i18n="wallet">Expense Management</div>
                  </a>
                  <ul
                    className={`menu-sub ${
                      expenseSectionSubMenu ? "open" : "close"
                    }`}
                  >

                    {/* Expense Management sub-menu */}
                    <li className="menu-item" style={{ marginLeft: "-1em" }}>
                      <ul
                        className={`menu-sub ${expenseSectionSubMenu ? "open" : "close"}`}
                        style={{ marginLeft: "-1em" }}
                      >
                              
                        <li className={`menu-item ${selectedItem === 'ExpenseForm' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('ExpenseForm')}>
                          <Link to="/ExpenseForm" className="menu-link">
                            <div data-i18n="Submenu Item 2">Expense Form</div>
                          </Link>
                        </li>

                          <li className={`menu-item ${selectedItem === 'ExpenseSheet' ? 'active-menu-items' : ''}`}
                        onClick={() => handleItemClick('ExpenseSheet')}>
                          <Link to="/ExpenseSheet" className="menu-link">
                            <div data-i18n="Submenu Item 2">Expense Sheet</div>
                          </Link>
                        </li>  


                      </ul>
                    </li>
                    {/*Expense Management sub-menu */}

                  </ul>
                </li>

            
              <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Courier</span>
              </li>
              
              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    courierSectionSubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Courier Management")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bxs-truck" />
                  <div data-i18n="truck">Courier Management</div>
                </a>
                <ul
                  className={`menu-sub ${
                    courierSectionSubMenu ? "open" : "close"
                  }`}
                >

                  {/* Courier Management sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <ul
                      className={`menu-sub ${courierSectionSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                            
                      <li className={`menu-item ${selectedItem === 'PayCourierSheet' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('PayCourierSheet')}>
                        <Link to="/PayCourierSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Pay Couriers</div>
                        </Link>
                      </li>

                         <li className={`menu-item ${selectedItem === 'PaidCourierSheet' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('PaidCourierSheet')}>
                        <Link to="/PaidCourierSheet" className="menu-link">
                          <div data-i18n="Submenu Item 2">Pay Courier Sheet</div>
                        </Link>
                      </li>  


                    </ul>
                  </li>
                  {/*Courier Management sub-menu */}

                </ul>
              </li>


              <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Statistics</span>
              </li>


                     
              <li className="menu-item">
                <a
                  href="#b"
                  className={`menu-link menu-toggle ${
                    statisticsSubMenu ? "open" : ""
                  }`}
                  onClick={() => SubMenuToggle("Statistics")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="menu-icon tf-icons bx bx-stats" />
                  <div data-i18n="stats">Statistics</div>
                </a>
                <ul
                  className={`menu-sub ${
                    statisticsSubMenu ? "open" : "close"
                  }`}
                >

                  {/* Disputed Order Section sub-menu */}
                  <li className="menu-item" style={{ marginLeft: "-1em" }}>
                    <ul
                      className={`menu-sub ${statisticsSubMenu ? "open" : "close"}`}
                      style={{ marginLeft: "-1em" }}
                    >
                            
                      <li className={`menu-item ${selectedItem === 'FinancePieChart' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('FinancePieChart')}>
                        <Link to="/FinancePieChart" className="menu-link">
                          <div data-i18n="Submenu Item 2">Finance Pie Chart</div>
                        </Link>
                      </li>

                         <li className={`menu-item ${selectedItem === 'ItemsAndArticleGraph' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('ItemsAndArticleGraph')}>
                        <Link to="/ItemsAndArticleGraph" className="menu-link">
                          <div data-i18n="Submenu Item 2">Item & Article Graph</div>
                        </Link>
                      </li>  

                      <li className={`menu-item ${selectedItem === 'PayStaffGraph' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('PayStaffGraph')}>
                        <Link to="/PayStaffGraph" className="menu-link">
                          <div data-i18n="Submenu Item 2">PayStaff Graph</div>
                        </Link>
                      </li>  

                      
                      <li className={`menu-item ${selectedItem === 'ExpenseGraph' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('ExpenseGraph')}>
                        <Link to="/ExpenseGraph" className="menu-link">
                          <div data-i18n="Submenu Item 2">Expense Graph</div>
                        </Link>
                      </li>

                       <li className={`menu-item ${selectedItem === 'DelieveredOrderGraph' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('DelieveredOrderGraph')}>
                        <Link to="/DelieveredOrderGraph" className="menu-link">
                          <div data-i18n="Submenu Item 2">Delievered Order Graph</div>
                        </Link>
                      </li> 

                      
                      <li className={`menu-item ${selectedItem === 'PayCourierGraph' ? 'active-menu-items' : ''}`}
                      onClick={() => handleItemClick('PayCourierGraph')}>
                        <Link to="/PayCourierGraph" className="menu-link">
                          <div data-i18n="Submenu Item 2">Pay-Courier Graph</div>
                        </Link>
                      </li>


                    </ul>
                  </li>
                  {/*Disputed Order Section sub-menu */}

                </ul>
              </li>

              
                
          <div>

          <li className="menu-header small text-uppercase"><span className="menu-header-text">Misc</span></li>
          <li className="menu-item">
              <a href="https://github.com/themeselection/sneat-html-admin-template-free/issues" target="_blank" class="menu-link mb-5" >
                <i className="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support">Support</div>
              </a>
            </li>


  {/* <li className="menu-header small text-uppercase"><span className="menu-header-text">Components</span></li>
  <li className="menu-item">
    <a href="cards-basic.html" className="menu-link">
      <i className="menu-icon tf-icons bx bx-collection" />
      <div data-i18n="Basic">Cards</div>
    </a>
  </li>

  <li className="menu-item">
    <a href="icons-boxicons.html" className="menu-link">
      <i className="menu-icon tf-icons bx bx-crown" />
      <div data-i18n="Boxicons">Boxicons</div>
    </a>
  </li>
  <li className="menu-header small text-uppercase"><span className="menu-header-text">Forms &amp; Tables</span></li>
  <li className="menu-item">
    <a href="javascript:void(0);" className="menu-link menu-toggle">
      <i className="menu-icon tf-icons bx bx-detail" />
      <div data-i18n="Form Elements">Form Elements</div>
    </a>
    <ul className="menu-sub">
      <li className="menu-item">
        <a href="forms-basic-inputs.html" className="menu-link">
          <div data-i18n="Basic Inputs">Basic Inputs</div>
        </a>
      </li>
      <li className="menu-item">
        <a href="forms-input-groups.html" className="menu-link">
          <div data-i18n="Input groups">Input groups</div>
        </a>
      </li>
    </ul>
  </li>
  <li className="menu-item">
    <a href="javascript:void(0);" className="menu-link menu-toggle">
      <i className="menu-icon tf-icons bx bx-detail" />
      <div data-i18n="Form Layouts">Form Layouts</div>
    </a>
    <ul className="menu-sub">
      <li className="menu-item">
        <a href="form-layouts-vertical.html" className="menu-link">
          <div data-i18n="Vertical Form">Vertical Form</div>
        </a>
      </li>
      <li className="menu-item">
        <a href="form-layouts-horizontal.html" className="menu-link">
          <div data-i18n="Horizontal Form">Horizontal Form</div>
        </a>
      </li>
    </ul>
  </li> */}




</div>


            </ul>
          </div>
        </aside>
      )}

  </>
  );
};

export default Sidebar;
