import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState,useEffect} from 'react';
import UsersGlobalData from '../../GlobalCalls/UsersGlobalData';
import UpdateMemberPasswordModal from '../Modals/UpdateMemberPasswordModal';

const MembersSheet = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [userData , setUserData] = useState('');


  const getAllUsers = ()=>{
      axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_users`)
      .then((res)=>{
        setAllUsers(res.data.Users)
      })
      .catch((err)=>{
          return err
      })
  }

  
  function removeMember(index) {
    setAllUsers((prevState) => {
        const mem = [...prevState];
        mem.splice(index, 1);
        return mem;
      });
    }

    function deleteRemoveMember(id,index){
      axios.post(`${process.env.REACT_APP_BASE_URL}delete_user_by_id/${id}`)
      .then((res)=>{
        if(res.data.status === '200'){
          toast.error("Member Removed")
          removeMember(index)
    
          }
      })
      .catch((error)=>{
          if(error){
            toast.warn("Something went wrong! Please try again")
          }
     
    })
    }

  

  useEffect(() => {
    getAllUsers()
    const getocal = async() =>{
      const data = await UsersGlobalData()
      setUserData(data)
    }
    getocal()
  }, [])


  // console.log(userData.role_id)

  function AllMemberSheet({items , index}){
      
    const [showUpdateMemModal, setShowUpdateMemModal] = useState(false);


      return(
          <>
             <tr>
              <td>{allUsers.length - index}</td>
              <td>{items.firstname}</td>
              <td>{items.lastname}</td>
              <td>{items.email}</td>
              <td>{items.phone_number}</td>
              <td>{items.username}</td>
              {
                items.role_id === 1 ?
                <td>Super Admin</td>
                :
                items.role_id === 2 ?
                <td>Assist Admin</td>
                :
                items.role_id === 3 ?
                <td>Admin</td>
                :
                items.role_id === 4 ?
                <td>Manager</td>
                :
                items.role_id === 5 ?
                <td>Staff</td>
                :
                null



              }
              <td>{items.Idate}</td>

            <td>
              <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <i className="bx bx-dots-vertical-rounded" />
                </button>
                <div className="dropdown-menu">
                  <button className="dropdown-item" onClick={()=>{
                    setShowUpdateMemModal(true)
                  }}><i className="bx bx-edit-alt me-1" /> Edit</button>
                 {
                     items.role_id === 5 ? 
                     null
                     :
                     <button className="dropdown-item" onClick={()=>deleteRemoveMember(items.id,index)}><i className="bx bx-trash me-1" /> Delete</button>

                  } 
                </div>
              </div>
            </td>
          </tr>
        {
          showUpdateMemModal === true ?
          <UpdateMemberPasswordModal
          memID = {items.id}
          memName = {items.username}
          showUpdateMemModal={showUpdateMemModal}
          setShowUpdateMemModal = {setShowUpdateMemModal}

          />
          :
          ""
        }

          </>
      )
  }
  return (
    <>
  <div className="scroll-view-component scrollbar-secondary-component">
<div className="content-wrapper">
  <div className="container-xxl flex-grow-1 container-p-y">
    <h4 className="fw-bold"><span className="text-muted fw-light">Member Sheet</span> </h4>
    <div className="card">
      <h5 className="card-header">Members Sheet</h5>
      <div className="table-responsive text-nowrap">
      {allUsers && allUsers.length > 0 ?
        <table className="table">
          <thead>
            <tr className='text-center'>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Username</th>
              <th>Role</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
        
          <tbody className="table-border-bottom-0 table-responsive text-center">
            {
                allUsers?.map((items,index)=>{
                  return(
                      <AllMemberSheet items={items} index={index} />  
                  )
              })
            }
                  
          </tbody>
            
        </table>
                :
                    
                <div className="text-center">
                <h3 className='fw-bold'>No Data Found !</h3>
                </div>

            }
      </div>
    </div>

  </div>

</div>
</div>
    </>
  )
}

export default MembersSheet