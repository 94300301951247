import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import React, { useState , useEffect } from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
// import TextileSectionEndPoint from "../../../Api/TextileSection/TextileSectionEndPoint";
import TextileReceiveSectionEndPoint from "../../../Api/TextileReceiveSection/TextileReceiveSectionEndPoint";
const UpdateTextileReceivingItem = ({
  textileReceiveItemID,
  showItemReceivingModal,
  setShowItemRecevingModal,
  refetchItemReceiving
}) => {
  const { mutate: updateTextileReceivedItem, isLoading } = TextileReceiveSectionEndPoint.useUpdateTextileReceivedItems();

  // Items Hooks;

  const[userData , setUserData] = useState('');

  const [itemReceivingID, setItemReceivingID] = useState("");
  const [itemID, setItemID] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [unitName, setUnitName] = useState("");
  const [receivedFrom, setReceivedFrom] = useState("");
  const [quantity, setQuantity] = useState("");
  const [totalBill, setTotalBill] = useState("");
  const [billPaid, setBillPaid] = useState("");
  const [tax, setTax] = useState("");
  const [gst, setGst] = useState("");
  const [otherChargesName, setOtherChargesName] = useState("");
  const [otherCharges, setOtherCharges] = useState("");
  const [shippingCharges, setShippingCharges] = useState("");

  const[autoFetch , setAutoFetch] = useState(false);

  // loading and conditing hooks:
  const [getTextileItems, setTextileItems] = useState([]);

  // Getting all textile items function:
  useQuery(
    "all_textile_items_for_dropdown",
    TextileReceiveSectionEndPoint.getAllTextileItems,
    {
      onSuccess: (data) => {
        setTextileItems(data.data.items);
      },
      onError: (err) => {
        return err;
      },
    }
  );

  // Getting Specific textile items:
  useQuery(["getTextileItemByID", textileReceiveItemID], (_) =>TextileReceiveSectionEndPoint.getTextileReceiveItemByID(textileReceiveItemID),
    {
      enabled : !autoFetch,
      onSuccess: (data) => {
        setItemReceivingID(data.data.Item.item_receivings_id);
        setItemID(data.data.Item.item_id);
        setItemName(data.data.Item.item_name);
        setItemPrice(data.data.Item.item_price);
        setReceivedFrom(data.data.Item.received_by);
        setUnitName(data.data.Item.unit_name);
        setQuantity(data.data.Item.quantity);
        setTotalBill(data.data.Item.total_bill);
        setBillPaid(data.data.Item.paid);
        setTax(data.data.Item.tax);
        setGst(data.data.Item.gst);
        setOtherChargesName(data.data.Item.other_charges_name);
        setOtherCharges(data.data.Item.other_charges);
        setShippingCharges(data.data.Item.shipping_charges);
        setAutoFetch(true)
      },
      onError: (err) => {
        return err;
      },
    }
  );

  function updateReceiveItems() {
    const receiveItemObj = {
      role_id :userData.role_id,
      user_id:userData.id,
      item_id: itemID,
      item_name: itemName,
      item_price: itemPrice,
      received_by: receivedFrom,
      unit_name: unitName,
      quantity: quantity,
      total_bill: totalBill,
      paid: billPaid,
      tax: tax,
      gst: gst,
      other_charges: otherCharges,
      other_charges_name: otherChargesName,
      shipping_charges: shippingCharges,
    };

    updateTextileReceivedItem(
      { receiveItemObj, itemReceivingID },
      {
        onMutate: () => {},
        onSettled: () => {
          setItemName("");
          setItemID("");
          setItemPrice("");
          setUnitName("");
          setReceivedFrom("");
          setQuantity("");
          setTotalBill("");
          setBillPaid("");
          setTax("");
          setGst("");
          setOtherChargesName("");
          setOtherCharges("");
          setShippingCharges("");
        },
      }
    );
  }

  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
  }, [])
  
  return (
    <>
      <div
        className="modal fade"
        id="textileReceiveUpdateModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel4">
                Update Item Receiving
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname"
                  >
                    Item Name
                  </label>
                  <select
                    id="inputGroupSelect01"
                    className={"form-select"}
                    defaultValue={itemName}
                    onChange={(e) => {
                      const selectedValue = e.target.value; // This will be the item_id
                      const selectedText =
                        e.target.options[e.target.selectedIndex].text;
                      setItemID(selectedValue);
                      setItemName(selectedText);
                    }}
                  >
                    <option selected>Choose...</option>
                    {getTextileItems && getTextileItems.length > 0 ? (
                      getTextileItems?.map((items, index) => {
                        return (
                          <option key={index} value={items.id}>
                            {items.item_name}
                          </option>
                        );
                      })
                    ) : (
                      <option>No Category Found...</option>
                    )}
                  </select>
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Item Price
                  </label>
                  <input
                    type="number"
                    className={"form-control"}
                    id="basic-default-company"
                    placeholder="Enter Item price..."
                    value={itemPrice}
                    onChange={(e) => setItemPrice(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-email">
                    Unit Name
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      type="text"
                      id="basic-default-email"
                      className={"form-control"}
                      placeholder="Enter Unit name..."
                      aria-label="john.doe"
                      aria-describedby="basic-default-email2"
                      value={unitName}
                      onChange={(e) => setUnitName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-phone">
                    Received By
                  </label>
                  <input
                    type="text"
                    id="basic-default-phone"
                    className={"form-control"}
                    placeholder="Item Received from..."
                    value={receivedFrom}
                    onChange={(e) => setReceivedFrom(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Quantity
                  </label>
                  <input
                    type="number"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Quantity..."
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Total Bill
                  </label>
                  <input
                    type="number"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Total Bill..."
                    value={totalBill}
                    onChange={(e) => setTotalBill(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Bill Paid
                  </label>
                  <input
                    type="text"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Bill paid status..."
                    value={billPaid}
                    onChange={(e) => setBillPaid(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Tax
                  </label>
                  <input
                    type="number"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Tax..."
                    value={tax}
                    onChange={(e) => setTax(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Gst
                  </label>
                  <input
                    type="number"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Gst..."
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Other Charges Name
                  </label>
                  <input
                    type="text"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Other charges name..."
                    value={otherChargesName}
                    onChange={(e) => setOtherChargesName(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Other Charges
                  </label>
                  <input
                    type="number"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Other charges..."
                    value={otherCharges}
                    onChange={(e) => setOtherCharges(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Shipping Charges
                  </label>
                  <input
                    type="number"
                    id="basic-default-message"
                    className={"form-control"}
                    placeholder="Enter Shipping charges..."
                    value={shippingCharges}
                    onChange={(e) => setShippingCharges(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {isLoading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={updateReceiveItems}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showItemReceivingModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title  id="contained-modal-title-vcenter">
          Update Item Receiving
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-fullname">
                  Item Name
                </label>
                <select
                  id="inputGroupSelect01"
                  className={"form-select"}
                  defaultValue={itemName}
                  onChange={(e) => {
                    const selectedValue = e.target.value; // This will be the item_id
                    const selectedText =
                      e.target.options[e.target.selectedIndex].text;
                    setItemID(selectedValue);
                    setItemName(selectedText);
                  }}
                >
                  <option selected>Choose...</option>
                  {getTextileItems && getTextileItems.length > 0 ? (
                    getTextileItems?.map((items, index) => {
                      return (
                        <option key={index} value={items.id}>
                          {items.item_name}
                        </option>
                      );
                    })
                  ) : (
                    <option>No Category Found...</option>
                  )}
                </select>
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-company">
                  Item Price
                </label>
                <input
                  type="number"
                  className={"form-control"}
                  id="basic-default-company"
                  placeholder="Enter Item price..."
                  value={itemPrice}
                  onChange={(e) => setItemPrice(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-email">
                  Unit Name
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type="text"
                    id="basic-default-email"
                    className={"form-control"}
                    placeholder="Enter Unit name..."
                    aria-label="john.doe"
                    aria-describedby="basic-default-email2"
                    value={unitName}
                    onChange={(e) => setUnitName(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-phone">
                  Received By
                </label>
                <input
                  type="text"
                  id="basic-default-phone"
                  className={"form-control"}
                  placeholder="Item Received from..."
                  value={receivedFrom}
                  onChange={(e) => setReceivedFrom(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Quantity
                </label>
                <input
                  type="number"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Quantity..."
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Total Bill
                </label>
                <input
                  type="number"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Total Bill..."
                  value={totalBill}
                  onChange={(e) => setTotalBill(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Bill Paid
                </label>
                <input
                  type="text"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Bill paid status..."
                  value={billPaid}
                  onChange={(e) => setBillPaid(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Tax
                </label>
                <input
                  type="number"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Tax..."
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Gst
                </label>
                <input
                  type="number"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Gst..."
                  value={gst}
                  onChange={(e) => setGst(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Other Charges Name
                </label>
                <input
                  type="text"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Other charges name..."
                  value={otherChargesName}
                  onChange={(e) => setOtherChargesName(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Other Charges
                </label>
                <input
                  type="number"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Other charges..."
                  value={otherCharges}
                  onChange={(e) => setOtherCharges(e.target.value)}
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label className="form-label" htmlFor="basic-default-message">
                  Shipping Charges
                </label>
                <input
                  type="number"
                  id="basic-default-message"
                  className={"form-control"}
                  placeholder="Enter Shipping charges..."
                  value={shippingCharges}
                  onChange={(e) => setShippingCharges(e.target.value)}
                />
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
        <button
                type="button"
                className="btn btn-dark"
                onClick={()=> {
                  setShowItemRecevingModal()
                  refetchItemReceiving()
                  }}
              >
                Close
              </button>
              {
                  isLoading ?
                  <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <button type="button" className="btn btn-outline-primary" onClick={()=>updateReceiveItems()}>
                Update
              </button>
              }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateTextileReceivingItem;
