import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState,useEffect} from 'react';
import UpdateExpenseModal from '../Modal/UpdateExpenseModal';

const ExpenseSheet = () => {
  const [allExpense, setAllExpense] = useState([])

  const getAllExpenses = ()=>{
      axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_expences`)
      .then((res)=>{
        setAllExpense(res.data.Expences)
      })
      .catch((err)=>{
          return err
      })
  }

  
  function removeExpenses(index) {
    setAllExpense((prevState) => {
        const expenses = [...prevState];
        expenses.splice(index, 1);
        return expenses;
      });
    }

    function deleteRemoveExpense(id,index){
      axios.post(`${process.env.REACT_APP_BASE_URL}delete_expence_by_id/${id}`)
      .then((res)=>{
        if(res.data.status === '200'){
          toast.error("Expense Removed")
          removeExpenses(index)
    
          }
      })
      .catch((error)=>{
          if(error){
            toast.warn("Something went wrong! Please try again")
          }
     
    })
    }

  useEffect(() => {
    getAllExpenses()
  }, [])

  function AllExpenseDetails({items , index}){
      
    const [showExpenseModal, setShowExpenseModal] = useState(false);


      return(
          <>
             <tr>
              <td>{items.expence_id}</td>
              <td>{items.added_by}</td>
              <td>{items.expence_name}</td>
              <td>{items.expence_price}</td>
              <td>{items.bank_name}</td>
              <td>{items.expence_date}</td>

            <td>
              <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <i className="bx bx-dots-vertical-rounded" />
                </button>
                <div className="dropdown-menu">
                  <button className="dropdown-item" onClick={()=>{
                    setShowExpenseModal(true)
                  }}><i className="bx bx-edit-alt me-1" /> Edit</button>
                  <button className="dropdown-item" onClick={()=>deleteRemoveExpense(items.expence_id,index)}><i className="bx bx-trash me-1" /> Delete</button>
                </div>
              </div>
            </td>
          </tr>
        {
          showExpenseModal === true ?
          <UpdateExpenseModal
          expenseID = {items.expence_id}
          showExpenseModal={showExpenseModal}
          setShowExpenseModal = {setShowExpenseModal}
          getAllExpenses={getAllExpenses}
          />
          :
          ""
        }

          </>
      )
  }
  return (
    <>
<div className="scroll-view-component scrollbar-secondary-component">
<div className="content-wrapper">
  <div className="container-xxl flex-grow-1 container-p-y">
    <h4 className="fw-bold"><span className="text-muted fw-light">Expense Sheet</span> </h4>
    <div className="card">
      <h5 className="card-header">Expense Sheet</h5>
      <div className="table-responsive text-nowrap">
      {allExpense && allExpense.length > 0 ?
        <table className="table">
          <thead>
            <tr className='text-center'>
              <th>#</th>
              <th>Added By</th>
              <th>Expense Name</th>
              <th>Expense Price</th>
              <th>Bank Name</th>
              <th>Expense Date</th>
              <th>Actions</th>
            </tr>
          </thead>
      
          <tbody className="table-border-bottom-0 table-responsive text-center">
            {
                allExpense?.map((items,index)=>{
                  return(
                      <AllExpenseDetails items={items} index={index} />  
                  )
              })
            }
                  
          </tbody>
              
        </table>
              :
              <div className="text-center">
              <h1 className='fw-bold'>No Data Found !</h1>
              </div>

          }
      </div>
    </div>

  </div>

</div>
</div>
    </>
  )
}

export default ExpenseSheet