import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import GraphErr from '../Images/graphErr.svg';

const DelieveredOrderGraph = () => {
  const [financeGraphData, setFinanceGraphData] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  
  const [checkBoxStatus , setCheckBoxStatus] = useState(false)
  const [checkBoxVal , setCheckBoxVal] = useState('')


  const [startDate ,setStartDate] = useState('');
  const [endDate , setEndDate] = useState('');
  const [enableSearchBtn , setEnableSearchBtn] = useState(false);


  const handleCheckInput = (val)=>{
    setSelectedCheckbox(val);
    graphFunc(val)
  }

  function graphFunc(val){

    var formdata = new FormData();
formdata.append("type", val);
formdata.append("start_date", startDate);
formdata.append("end_date", endDate);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://avantgarde.alphanitesofts.net/api/fetch_graphs_data", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status === '200'){
      setFinanceGraphData(result?.data)
    }
  })


  .catch(error => console.log('error', error));

  }

    console.log(financeGraphData)
function clearFilters(){
  setStartDate('');
  setEndDate('');
  setCheckBoxVal('');
  setEnableSearchBtn(false)
}
// Delivered Orders data:
const orderTotal = financeGraphData?.map((items)=> items.total)
const orderSubTotal = financeGraphData?.map((items)=> items.sub_total)


const articlesQty = financeGraphData?.map((items)=> items.order_data.map((item)=> item.qty))
const orderDate = financeGraphData?.map((items)=> items.Idate)

const deliveredOrders = {
  
  series: [
    {
      name: 'Order total amount - Orders',
      data: orderTotal,
      color: '#1de9b6'
    },
    {
      name: 'Order Sub-total - Orders',
      data: orderSubTotal,
      color:'#00b0ff'
    },
    // {
    //   name: 'Articles # - Orders',
    //   data: articleNo.map((articles)=>{
    //     return articles
    //   }),
    //   color:'#696cff'
    // }
    // ,
    {
      name: 'Articles Qty - Orders',
      data: articlesQty,
      color:'#ff3d00'
    }
  ],
  options: {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
    categories: financeGraphData?.map((data, index) => {
      return `${data.Idate} - Order# ${data.id}`;
    }),
    },
    yaxis: {
      title: {
        text: 'Delivered Orders Graph',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return 'Rs.' + value;
        },
      },
    },
  },
}


  return (
    <>
    <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mb-5">
              <div className="card-header">
                <h4 className="fw-bold">
                  <span className="text-muted fw-light">Delivered Graph</span>
                </h4>
              </div>

              <div className="d-flex">
            <div className="form-check ms-5">
                <input
                  className="form-check-input fs-5 border border-primary"
                  type="checkbox"
                  id="inlineCheckbox1"
                  defaultValue="option1"
                  onChange={()=> 
                    {
                    handleCheckInput('delivered_orders')
                    setCheckBoxVal('delivered_orders')

                    setCheckBoxStatus(true)
                }}
                  checked={selectedCheckbox === 'delivered_orders' && checkBoxStatus === true}
                />
                <label
                  className="form-check-label fs-5"
                  htmlFor="inlineCheckbox1"
                >
                  Delivered Orders
                </label>
              </div>
                &nbsp;&nbsp;&nbsp;
             
                {
                checkBoxStatus === true ?
                  <>
                          <div className="col-lg-3">
                  <div className="input-group input-group-sm">
                    <label
                      className="input-group-text border border-primary fw-bold"
                      htmlFor="inputGroupSelect01"
                    >
                      From*
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm border border-primary"
                      id="inputGroupSelect01"
                      value={startDate}
                      onChange={(e)=> setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                &nbsp;&nbsp;
                <div className="col-lg-3">
                  <div className="input-group input-group-sm">
                    <label
                      className="input-group-text border border-primary fw-bold"
                      htmlFor="inputGroupSelect02"
                    >
                      To*
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm border border-primary"
                      id="inputGroupSelect02"
                      value={endDate}
                      onChange={(e)=> {
                        setEndDate(e.target.value)
                        setEnableSearchBtn(true)
                      }}

                    />
                  </div>
                </div>
                &nbsp; &nbsp;
                {
                  enableSearchBtn === true ?
                  <>
                  <button className="btn btn-sm btn-outline-primary" onClick={()=>graphFunc(checkBoxVal)}> 
                  <i className="fa fa-magnifying-glass"/>
                  &nbsp;&nbsp;
                  search
                  </button>
                  &nbsp;&nbsp;
                  <button className="btn btn-sm btn-outline-primary" onClick={()=>clearFilters(checkBoxVal)}> 
                  <i className="fa fa-filter"/>
                  &nbsp;&nbsp;
                  clear filters
                  </button>
                  
                  
                  </>
                  :
                  null
                }
               
                  </>
                  :
                  null
                }
          

              </div>

              <div className="card-body">
              {
             
                selectedCheckbox === "delivered_orders" ?
                <ReactApexChart
                    options={deliveredOrders.options}
                    series={deliveredOrders.series}
                  type="bar"
                  height={300}
                />
                :
                <>

              <img className='img-fluid d-block mx-auto m-5' src={GraphErr} alt="" width={300}/>
             </>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DelieveredOrderGraph