import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState,useRef,useEffect} from "react";
import UsersGlobalData from "../../GlobalCalls/UsersGlobalData";
import EventSectionEndPoint from '../../Api/EventSection/EventSectionEndPoint';

const AddEventForm = () => {
  const {mutate:eventsSubmission , isLoading } = EventSectionEndPoint.useSubmitEvents();
  const fileInputRef = useRef(null);
  const[eventName , setEventName] = useState('');
  const[eventImage , setEventImage] = useState('');
  const[eventDescription , setEventDescription] = useState('');

  const[userData , setUserData] = useState('');
  
  // loading and conditing hooks:
  const[input , setInput] = useState(false);


  function submitEvents(){
      if(eventName === '' || eventImage === ''  || eventDescription === '' ){
        setInput(true)
        toast.warn("Fill the information !")
        
      }
      else{
        var formdata = new FormData();
        formdata.append("event_name", eventName);
        formdata.append("description", eventDescription);
        formdata.append("added_by",userData.username);
        formdata.append("user_id",userData.id);
        formdata.append("role_id",userData.role_id);
        formdata.append("event_image",eventImage, "[PROXY]");
  
        
        eventsSubmission((formdata) , {
          onMutate: () => {
          },
          onSettled: () => {
  
            setInput(false);
  
            setEventName('');
            setEventImage('');
            setEventDescription('');
            setEventImage('');
            fileInputRef.current.value = '';
          },
  
        
        })
   
      }
  
    }

    useEffect(() => {
      const getocal = async() =>{
        const data = await UsersGlobalData()
       setUserData(data)
      }
      getocal()
    }, [])

  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div class="container-xxl flex-grow-1 container-p-y">
            <h4 class="fw-bold py-3 mb-4">
              <span class="text-muted fw-light">Event Form</span> 
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Event Form</h5>
                  {/* <small className="text-muted float-end">Default label</small> */}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Event Name
                        </label>
                        <input
                          type="text"
                          value={eventName}
                          className={eventName === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname"
                          placeholder="Enter Event Name"
                          onChange={(e)=> setEventName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Event Image
                        </label>
                        <input
                          type="file"
                          ref={fileInputRef}
                          id="basic-default-company"
                          className={eventImage === '' && input === true?"form-control border border-danger":"form-control"}
                          onChange={(e)=> setEventImage(e.target.files[0])}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                          Event Description
                        </label>
                        <textarea
                          value={eventDescription}
                          onChange={(e)=> setEventDescription(e.target.value)}
                          id="basic-default-message"
                          className={eventDescription === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Event Description..."
                          defaultValue={""}
                          rows={5}
                        />
                      </div>
                    </div>
                      {
                        isLoading ? 
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                      <button type="submit" className="btn btn-primary" onClick={submitEvents}>
                      Submit
                    </button>
                      }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEventForm;
