import { useQuery } from "react-query";
import React, { useState } from "react";
import StaffPayRollSectionEndPoint from "../../../Api/StaffPayRollSection/StaffPayRollSection";

const UpdateStaffPayRollModal = ({ staffPayID }) => {
  const {mutate:updatePayRollStaff , isLoading } = StaffPayRollSectionEndPoint.useUpdatePayRollStaff();
  // alert(staffPayID)
  const [ID, setStaffID] = useState("");
  const [staffPayRollID , setStaffPayRollID] = useState('');
  const [username , setUsername] = useState('');
  const [staffMemName, setStaffMemName] = useState("");
  const [addedBy , setAddedBy] = useState('');
  const [daysOff , setDaysOff] = useState('');
  const [leaves , setLeaves] = useState('');
  const [realSalary , setRealSalary] = useState('');
  const [calculatedSalary , setCalculatedSalary] = useState('');
  const [amountPaid , setAmountPaid] = useState('');
  const [amountDeducted , setAmountDeducted] = useState('');
  const [amountLeft , setAmountLeft] = useState('');
  const [payOfMonth , setPayOfMonth] = useState('');
  const [isPaid , setIsPaid] = useState('');
  

      // Getting Specific staff member:
      useQuery(["getPayStaffByID", staffPayID], _=> StaffPayRollSectionEndPoint.getPayStaffMemberByID(staffPayID), {
        onSuccess: (data) => {
        setStaffID(data.data.Pay_staff.staff_id)
        setStaffPayRollID(data.data.Pay_staff.pay_staff_id)
        setAddedBy(data.data.Pay_staff.added_by)
        setUsername(data.data.Pay_staff.username)
        setStaffMemName(data.data.Pay_staff.staff_name)
        setDaysOff(data.data.Pay_staff.days_off)
        setLeaves(data.data.Pay_staff.leaves)
        setRealSalary(data.data.Pay_staff.real_salary)
        setCalculatedSalary(data.data.Pay_staff.calculated_salary)
        setAmountPaid(data.data.Pay_staff.amount_paid)
        setAmountDeducted(data.data.Pay_staff.amount_deducted)
        setAmountLeft(data.data.Pay_staff.amount_left)
        setPayOfMonth(data.data.Pay_staff.month_of_pay)
        setIsPaid(data.data.Pay_staff.paid) 
    
        },
        onError: (err) => {
          return err;
        },
        refetchOnWindowFocus: false,
    
      })

      const updateStaff = ()=>{

        var formdata = new FormData();
        formdata.append("staff_name", staffMemName);
        formdata.append("staff_id", ID);
        formdata.append("days_off", daysOff);
        formdata.append("leaves", leaves);
        formdata.append("real_salary", realSalary);
        formdata.append("calculated_salary", calculatedSalary);
        formdata.append("amount_paid", amountPaid);
        formdata.append("amount_deducted", amountDeducted);
        formdata.append("paid", isPaid);
        formdata.append("amount_left", amountLeft);
        formdata.append("month_of_pay", payOfMonth);
        formdata.append("added_by", addedBy);
        formdata.append("role_id", 1);
        formdata.append("user_id", "1");
  
        
        updatePayRollStaff(({formdata, staffPayRollID}) , {
          onMutate: () => {
          },
          onSettled: () => {
            setStaffID('')
            setAddedBy('')
            setUsername('')
            setStaffMemName('')
            setDaysOff('')
            setLeaves('')
            setRealSalary('')
            setCalculatedSalary('')
            setAmountPaid('')
            setAmountDeducted('')
            setAmountLeft('')
            setPayOfMonth('')
            setIsPaid('') 
          },
  
        
        })
    
      }
  return (
    <>
      <div
        className="modal fade"
        id="updatePayStaffModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel2">
                Update Staff
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname"
                  >
                    Staff Member Name
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-fullname00"
                    placeholder="Enter Staff Member Name..."
                    value={staffMemName}
                    onChange={(e)=> setStaffMemName(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname01"
                  >
                    Days Off
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname01"
                    placeholder="Enter Days Off..."
                    value={daysOff}
                    onChange={(e)=> setDaysOff(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-message01"
                  >
                    Leaves Taken
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname05"
                    placeholder="Enter Leaves taken..."
                    value={leaves}
                    onChange={(e)=> setLeaves(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname02"
                  >
                    Actual Salary
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname02"
                    placeholder="Enter Actual Salary..."
                    value={realSalary}
                    onChange={(e)=> setRealSalary(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname03"
                  >
                    Calculated Salary
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname03"
                    placeholder="Enter Calculated Salary..."
                    value={realSalary}
                    onChange={(e)=> setRealSalary(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname033"
                  >
                    Paid Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname033"
                    placeholder="Enter Paid Amount..."
                    value={amountPaid}
                    onChange={(e)=> setAmountPaid(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname034"
                  >
                    Amount Deducted
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname034"
                    placeholder="Enter Amount Deduction..."
                    value={amountDeducted}
                    onChange={(e)=> setAmountDeducted(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Amount Left
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-fullname044"
                    placeholder="Enter Amount Left..."
                    value={amountLeft}
                    onChange={(e)=> setAmountLeft(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Paid
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={isPaid}
                    onChange={(e)=> setIsPaid(e.target.value)}
                  >
                    <option>Choose...</option>
                    <option value="true">Paid</option>
                    <option value="false">Not Paid</option>
                  </select>
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Month of Pay
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="basic-default-fullname044"
                    value={payOfMonth}
                    onChange={(e)=> setPayOfMonth(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* {
                       isLoading ?
                       <div className="spinner-border text-primary" role="status">
                       <span className="visually-hidden">Loading...</span>
                     </div>
                     : */}
              <button type="button" className="btn btn-outline-primary" onClick={updateStaff}>
                Update
              </button>
              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStaffPayRollModal;
