import 'jspdf-autotable';
import { jsPDF } from "jspdf";
import {useQuery}from"react-query";
import Error from '../../Error/Error';
import React,{useState,useEffect} from "react";
import CsvDownloadButton from 'react-json-to-csv';
import DispatchModal from "../Modals/DispatchModal";
import OrderFilterEndPoint from "../../Api/OrderFilters/OrderFilterEndPoint";
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";
import ApproveMultipleOrderModal from "../Modals/MultipleQueryModal/ApproveMultipleOrderModal";
import DisputeMultipleOrderModal from "../Modals/MultipleQueryModal/DisputeMultipleOrderModal";
import AssignedOrderList from "./AssignedOrderList";

import GenerateCSVToWhatsapp from "../../Approvals&Permissions/GenerateCSVToWhatsapp";

const AssignedOrders = () => {
    const { mutate: filterOrders, data:orderFilterData , isLoading:orderSearchLoading } =  OrderFilterEndPoint.useFilterOrders();
    const [getOrdersByStatus, setOrdersByStatus] = useState([]);
    
  const [autoFetch, setAutoFetch] = useState(true);
  const [showMultiSelectApproveModal , setShowMultiSelectApproveModal] = useState(false);
  const [showMultiSelectDisputeModal , setShowMultiSelectDisputeModal] = useState(false);

  const [showMultiCheckButton, setShowMultiCheckButton] = useState(false);
  const [showMultiCheckButtonForCSV , setShowMultiCheckButtonForCSV] = useState(false);


  const [multiSelectApproveOrder, setMultiSelectApproveOrders] = useState([]);
  const [multiSelectDownloadOrders, setMultiSelectDownloadOrders] = useState([]);

  const [multiSelectWhatsappOrders , setMultiSelectWhatsappOrders] = useState([]);
  const [selectedWhatsappCSVContent , setSelectedWhatsappCSVContent] = useState('');

  
  const [enableFilters , setEnableFilters] = useState(false);
  const [enableCVSFilters , setEnableCVSFilters] = useState(false);

  const [btnActive , setBtnActive] = useState("");

  const [showDispatchModal, setShowDispatchModal] = useState(false);
  
  // const [showOTPModal , setShowOTPModal] = useState(false);

  const [selectOrderID , setSelectOrderID] = useState('');

  const[openCSVModal ,setOpenCSVModal] = useState(false);


    // Multi Select approve order status function:
    const handleMultiSelectApproveOrders = (itemId) => {
      // Check if the item is already selected
      const isSelected = multiSelectApproveOrder.includes(itemId);
  
      // If selected, remove it from the array, otherwise add it
      if (isSelected) {
        setMultiSelectApproveOrders((prevSelectedItems) =>
          prevSelectedItems.filter((id) => id !== itemId)
        );
      } else {
        setMultiSelectApproveOrders((prevSelectedItems) => [
          ...prevSelectedItems,
          itemId,
        ]);
      }
    };
  
    // Multi Select download order function:
    const handleMultiSelectDownloadOrders = (items) => {
      // Check if the item is already selected
      const isSelected = multiSelectDownloadOrders.includes(items);

      // const isSelectedForWhatsapp = multiSelectWhatsappOrders.includes(items);

      if (isSelected ) {

        setMultiSelectDownloadOrders((prevSelectedItems) =>
          prevSelectedItems.filter((id) => id !== items)
        );

          setMultiSelectWhatsappOrders((prevSelectedItems) =>
          prevSelectedItems.filter((id) => id !== items)
        );

      } 
      else {
        setMultiSelectDownloadOrders((prevSelectedItems) => [
          ...prevSelectedItems,
          items,
        ])

          setMultiSelectWhatsappOrders((prevSelectedItems) => [
          ...prevSelectedItems,
          items,
        ]);
      }


      // if (isSelectedForWhatsapp) {
      //   setMultiSelectWhatsappOrders((prevSelectedItems) =>
      //     prevSelectedItems.filter((id) => id !== items)
      //   );
      // } 
      // else {
      //   setMultiSelectWhatsappOrders((prevSelectedItems) => [
      //     ...prevSelectedItems,
      //     items,
      //   ]);
      // }
    
    }

    // console.log("Multi Whatsapp order",multiSelectWhatsappOrders)

      // Function to convert JSON to CSV
  // const convertToCSV = (jsonArray) => {
  //   const header = Object.keys(jsonArray[0]).join(',');
  //   const rows = jsonArray.map(obj => Object.values(obj).join(','));
  //   return header + '\n' + rows.join('\n');
  // };

  // function convertToCSV(json) {
  //   const header = Object.keys(json[0]);
  //   const headerString = header.join(',');
  //   const replacer = (key, value) => value ?? '';
  //   const rowItems = json.map((row) =>
  //     header
  //       .map((fieldName) => JSON.stringify(row[fieldName], replacer))
  //       .join(',')
  //   );
  //   const csv = [headerString, ...rowItems].join('\r\n');
  //   return csv;

  // }

  function convertToCSVWhatsapp(){

    const doc = new jsPDF();
    let y = 10;
    // const pageHeight = doc.internal.pageSize.height;

    const pageHeight = doc.internal.pageSize.height;
    const data = multiSelectWhatsappOrders; 


     // Extracting keys for table headers
    //  const keys = Object.keys(data[0]);

     // Extracting values for table rows
    //  const values = data.map(item => Object.values(item));
     // Determine the width for each column
  //   const columnWidths = keys.map(key => ({
  //     cellWidth: '30' 
  // }));
 
    //  // Drawing table
    //  doc.autoTable({
    //      startY: y,
    //      head: [keys],
    //      body: values,
    //      theme: 'grid', // You can change the theme if you prefer
    //      margin: { top: 10 },
    //      styles: {
    //       fontSize: 10, // Adjust font size
    //       overflow: 'linebreak', // Enable word wrap
    //       cellPadding: 2, // Adjust padding
    //       cellWidth: 'auto'
    //   },
    //   columnStyles :  columnWidths,
    //      didDrawPage: function (data) {
           
    //      }
    //  });
 
    //  // Adjusting y-coordinate for the next content
    //  y = doc.autoTable.previous.finalY + 20;



    for (const key in data) {
      if (data.hasOwnProperty(key)) {
          let value = data[key];
          
          // Convert objects to JSON strings for better readability
          if (typeof value === 'object') {
              value = JSON.stringify(value, null, 4); // Pretty print with indentation
          }
          
          // Split long strings into multiple lines
          const lines = doc.splitTextToSize(`${key}: ${value}`, doc.internal.pageSize.width - 20);
          lines.forEach(line => {
              if (y > pageHeight - 20) {
                  doc.addPage();
                  y = 10; // Reset y-coordinate for the new page
              }
              doc.text(10, y, line);
              y += 10; // Move to the next line
          });
          
          // Add spacing between sections
          y += 10;
      }
  }


     const pdfBlob = doc.output('blob');
    // Convert Blob to File
    const pdfFile = new File([pdfBlob], 'data.pdf', { type: 'application/pdf' });

    setSelectedWhatsappCSVContent(pdfFile);


  }

  // function chunkString(str, length) {
  //   const chunks = [];
  //   for (let i = 0; i < str.length; i += length) {
  //     chunks.push(str.slice(i, i + length));
  //   }
  //   return chunks;
  // }

  

    function removeSpecificeOrder(index) {
      setOrdersByStatus((prevState) => {
        const removeOrder = [...prevState];
        removeOrder.splice(index, 1);
        return removeOrder;
      });
    }

    
    const removeMultiOrders = () => {
      setOrdersByStatus((prevState) => {
        const updatedOrders = [...prevState];
    
        // Iterate through selected orders and remove them
        multiSelectApproveOrder.forEach((itemId) => {
          const indexToRemove = updatedOrders.findIndex((order) => order.id === itemId);
    
          if (indexToRemove !== -1) {
            updatedOrders.splice(indexToRemove, 1);
          }
        });
    
        return updatedOrders;
      });
          setMultiSelectApproveOrders([]);
    };
    

  
    const orderStatusObj = {
      status: "assigned",
    };
  
    // Getting all orders by status:
    useQuery(["get_orders_by_status", orderStatusObj],() => OrderIntakeSectionEndPoint.getOrdersByStatus(orderStatusObj),
      {
        onSuccess: (data) => {
          setOrdersByStatus(data.data.data);
        },
        onError: (err) => {
          return err;
        },
      }
    )

      //Hooks for Filters

  const[orderID , setOrderID] = useState('');
  const[index , setIndex] = useState('')
  const[courierID , setCourierID] = useState('');
  const[customerName , setCustomerName] = useState('');
  const[customerPhone , setCustomerPhone] = useState('');
  const[customerEmail , setCustomerEmail] = useState('');
  const[customerPaid , setCustomerPaid] = useState('');
  const[paymentMethod , setPaymentMethod] = useState('');
  const[startDate , setStartDate] = useState('');
  const[endDate , setEndDate] = useState('');

  const[rangeFrom , setRangeFrom] = useState();
  const[rangeTo , setRangeTo] = useState();


  function getFilteredOrder(){
    const toValues = rangeTo.split(',').map(value => parseInt(value.trim(), 10));

    const orderSearch = {
      id:orderID,
      courier_id:courierID,
      name: customerName,
      phone:customerPhone,
      email:customerEmail,
      paid:customerPaid,
      payment_method:paymentMethod,
      start_date:startDate,
      end_date:endDate,
      range:toValues.map(value => parseInt(rangeFrom, 10) + ',' + value).join(','),
      status : "assigned"
    }  
  
      filterOrders(orderSearch ,{
      onMutate: () => {
        
      },
      onSettled: () => {
  
      },
    })
  
  }

  function openModal(id,index){
    setShowDispatchModal((p)=>!p)
    setOrderID(id)
    setIndex(index)
     
  }
  

  useEffect(() => {
    setOrdersByStatus(orderFilterData)
  }, [orderFilterData])




  function selectOrderId(id){
    setSelectOrderID(id)
  }


  

  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold">
              <span className="text-muted fw-light">Assigned Orders</span>
            </h4>

         
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-fill bd-highlight">
          {/* Enable multi-select to select multiple orders at once and change status   */}
               <button
                  type="button"
                  className={btnActive === "Select Orders Button" ?"btn bg-secondary text-white mb-2 me-3":"btn btn-outline-secondary mb-2 me-3"}
                  onClick={() => {
                    setShowMultiCheckButton(!showMultiCheckButton);
                    setShowMultiCheckButtonForCSV(false);
                    setMultiSelectDownloadOrders('');
                    setEnableFilters(false)
                    setBtnActive("Select Orders Button")
                  }}
                >
                  <span className="tf-icons bx bx-bell"></span>&nbsp; Select Orders
                </button>

                {/* Enable multi-select to select multiple orders at once and change status   */}
                
            {/*Enable Multiple filters e.g. search with (order/phone/email) etc  */}
            <button
                  type="button"
                  className={btnActive === "Enable Filters Btn" ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setShowMultiCheckButton(false)
                    setEnableFilters(!enableFilters)
                    setBtnActive("Enable Filters Btn")
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
            </button>
                {/*Enable Multiple filters e.g. search with (order/phone/email) etc  */}

   
              {/* Enable CSV download options(download all order or download specific) */}

              <button
                  type="button"
                  className='btn btn-light text-black border border-2 mb-2 dropdown-toggle hide-arrow'
                  data-bs-toggle="dropdown"
                  aria-expanded="false"          
                >
                  <span className="tf-icons bx bx-file"></span>&nbsp; Download CSV
                </button>

                <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <button
                          type="button"
                          className="dropdown-item btn btn-outline-dark"
                           onClick={() =>{ 
                            setEnableCVSFilters(!enableCVSFilters)
                            setMultiSelectApproveOrders('')
                            setShowMultiCheckButton(false)
                            setEnableFilters(false)
                            setBtnActive("Download CSV Btn")
                           
                          }}

                        >
                         <i className="fa-solid fa-file"/>
                          &nbsp;&nbsp; Download all orders
                        </button>
                      </li>

                      <li>
                        <button
                          type="button"
                          className="dropdown-item btn btn-outline-dark"
                          onClick={()=>{
                            setShowMultiCheckButtonForCSV(!showMultiCheckButtonForCSV)
                            setShowMultiCheckButton(false)
                            setMultiSelectApproveOrders('')
                            setEnableFilters(false)
                            setBtnActive("Download CSV Btn")
                          }}
                        >
                          <i className="fa-solid fa-folder-plus" />
                          &nbsp;&nbsp; Download specific orders
                        </button>
                      </li>
                </ul>
                  {
                    multiSelectDownloadOrders.length >=1 && showMultiCheckButtonForCSV === true? (
                        <>
                          
                          
                            <CsvDownloadButton
                            className="btn btn-outline-danger ms-2 mb-2"
                            filename={`assigned_marked_orders.csv`}
                            separator={";"}
                            data={multiSelectDownloadOrders} 

                            onMouseLeave={()=>{
                              setMultiSelectDownloadOrders('')
                              setShowMultiCheckButtonForCSV(!showMultiCheckButtonForCSV)
                            }}
                           >
                            <i className="fa fa-file-arrow-down fa-1x"></i>&nbsp;&nbsp;
                            Download Marked Record
                          </CsvDownloadButton>

                          &nbsp;&nbsp;

                          <button className="btn btn-outline-warning mb-2" onClick={()=>{
                          convertToCSVWhatsapp()
                          setOpenCSVModal(!openCSVModal)
                        
                        }}>Send Marked Orders</button>



                          
                         

                    
                        </>
                    )
                    : null
                  }
                  {
                    enableCVSFilters ?
                    <>
                      {
                        (
                          <CsvDownloadButton  className="btn btn-outline-primary  ms-2 mb-2" 
                          filename={`assigned_orders.csv`}
                          separator={";"}
                          data={getOrdersByStatus}
                          onMouseLeave={()=>setEnableCVSFilters(!enableCVSFilters)}
                          
                          >
                          <i className="fa fa-file-arrow-down fa-1x"></i>&nbsp;&nbsp;
                           Download All Orders
                        </CsvDownloadButton>
                        )
                      }
                    </>
                    :
                    null
                  }
                {/* Enable CSV download options(download all order or download specific) */}
              </div>
                


              {multiSelectApproveOrder.length >=1 && showMultiCheckButton === true? 
                  (
                      <>
                        <div className="p-2 flex-fill bd-highlight">
                          <button
                            className="btn btn-outline-success dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="tf-icons bx bx-task" /> &nbsp; Submit
                            Multiple Orders
                          </button>

                          <ul className="dropdown-menu dropdown-menu-start">
                            <li>
                              <button
                                type="button"
                                className="dropdown-item btn btn-outline-dark"
                                onClick={()=>setShowMultiSelectApproveModal(true)
                                }
                              >
                                <i className="fa-solid fa-file-circle-plus" />
                                &nbsp;&nbsp; Verify to Dispatched Orders
                              </button>
                            </li>

                            <li>
                              <button
                                type="button"
                                className="dropdown-item btn btn-outline-dark"
                                onClick={()=>{
                                  setShowMultiSelectDisputeModal(true)
                                }}
                              >
                                <i className="fa-solid fa-file-circle-minus" />
                                &nbsp;&nbsp; Dispute Multiple Order
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                  )
                  : 
                 null
              }
            </div>

         
            <div className="row gy-2">
              
            {
                  enableFilters ? 
                    (
                      <div className="row mt-2 mb-2">
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Start Date*</label>
                      <input type="date" value={startDate} className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=>{
                        setStartDate(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>

                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">End Date*</label>
                      <input type="date" value={endDate} className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setEndDate(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>

                   

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput1" className="form-label fw-bold">Order ID*</label>
                      <input type="number" className="form-control form-control-sm border border-primary" id="defaultFormControlInput1" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      onChange={(e)=>{ 
                        setOrderID(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Customer name*</label>
                      <input type="text" className="form-control form-control-sm border border-primary" value={customerName} id="defaultFormControlInput6" placeholder="Search with customer name.." aria-describedby="defaultFormControlHelp"
                        onChange={(e)=>{
                          setCustomerName(e.target.value)
                          setAutoFetch(true)
                        }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput2" className="form-label fw-bold">Phone*</label>
                      <input type="number" className="form-control form-control-sm border border-primary" id="defaultFormControlInput2" placeholder="Search with phone.." aria-describedby="defaultFormControlHelp"
                      onChange={(e)=>{
                        setCustomerPhone(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput3" className="form-label fw-bold">Email*</label>
                      <input type="email" className="form-control form-control-sm border border-primary" id="defaultFormControlInput3" placeholder="Search with email.." aria-describedby="defaultFormControlHelp"
                         onChange={(e)=>{
                          setCustomerEmail(e.target.value)
                          setAutoFetch(true)
                        }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput4" className="form-label fw-bold">Payment Method*</label>
                      <input type="text" className="form-control form-control-sm border border-primary" value={paymentMethod} id="defaultFormControlInput4" placeholder="Search with payment method.." aria-describedby="defaultFormControlHelp"
                        onChange={(e)=>{
                          setPaymentMethod(e.target.value)
                          setAutoFetch(true)
                        }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput5" className="form-label fw-bold">Status(Paid/Not-Paid)*</label>
                        <select id="smallSelect" className="form-select form-select-sm  border border-primary"
                        onChange={(e)=> {
                          setCustomerPaid(e.target.value)
                          setAutoFetch(true)
                        }}
                        >
                          <option value="">Choose...</option>
                          <option value="true">Paid</option>
                          <option value="false">Not Paid</option>
                        </select>
                        </div>

                        <span className="fw-bold mt-2">*Select Range*</span>

                            <div className="col-lg-3 mt-1">
                            <label for="defaultFormControlInput7" className="form-label fw-bold">From*</label>
                            <input type="text" className="form-control form-control-sm border border-primary" value={rangeFrom} id="defaultFormControlInput7" placeholder="Enter range from..." aria-describedby="defaultFormControlHelp"
                            onChange={(e)=>{
                              setRangeFrom(e.target.value)
                              setAutoFetch(true)
                            }}
                            />
                            </div>

                            <div className="col-lg-3 mt-1">
                            <label for="defaultFormControlInput8" className="form-label fw-bold">To*</label>
                            <input type="text" className="form-control form-control-sm border border-primary" value={rangeTo} id="defaultFormControlInput8" placeholder="Enter range to..." aria-describedby="defaultFormControlHelp"
                            onChange={(e)=>{
                              setRangeTo(e.target.value)
                              setAutoFetch(true)
                            }}
                            />
                            </div>


                        <div className="col-lg-3" style={{marginTop:"2em"}}>
                        {
                        orderSearchLoading === true ?
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                          <button className="btn btn-primary btn-sm"
                          onClick={()=>{
                            getFilteredOrder()
                            setAutoFetch(!autoFetch)
                          }}
                          >
                            
                            <i className="fa fa-search"/>&nbsp;&nbsp;
                            Search Query
                          </button>
                        }
                        </div>


                      </div>
                    )
                    :
                    null
                  }

              {getOrdersByStatus && getOrdersByStatus.length > 0 ? (
                    <>
                    {
                      getOrdersByStatus?.map((items,index)=>{
                        return <AssignedOrderList
                        
                        items={items} 
                        index={index} 
                        showMultiCheckButton={showMultiCheckButton}
                        handleMultiSelectApproveOrders={handleMultiSelectApproveOrders}
                        multiSelectApproveOrder={multiSelectApproveOrder}
                        showMultiCheckButtonForCSV={showMultiCheckButtonForCSV}
                        handleMultiSelectDownloadOrders={handleMultiSelectDownloadOrders}
                        multiSelectDownloadOrders={multiSelectDownloadOrders}
                        openModal={openModal}
                        removeSpecificeOrder={removeSpecificeOrder}
                        selectOrderID={selectOrderID}
                        setSelectOrderID={selectOrderId}
                        
                        />
                          
                      
                      })
                    }
    
                    </>
              ) : (
                <Error/>

              )}

      {
        showDispatchModal === true && (
          <>
        {/* Dispatch Modal */}
          <DispatchModal  
          index={index} 
          orderID={orderID}
          orderAction="Dispatched"
          showDispatchModal={showDispatchModal}
          setShowDispatchModal={setShowDispatchModal}
          removeSpecificeOrder={removeSpecificeOrder}
          />
       {/* Dispatch Modal */}
        </>
        )
      }

      {
        openCSVModal === true && (
          <GenerateCSVToWhatsapp
          openCSVModal = {openCSVModal}
          setOpenCSVModal = {setOpenCSVModal}
          selectedWhatsappCSVContent = {selectedWhatsappCSVContent}
          />
        )
      }

       {/* Multiple Order+Courier assign is still in under assesment  */}
      {
              showMultiSelectApproveModal === true ?
              <ApproveMultipleOrderModal
                 orderAction = "dispatched"
                autoFetch= {autoFetch}
                setAutoFetch = {setAutoFetch}
                removeMultiOrders = {removeMultiOrders}
                multiSelectApproveOrder = {multiSelectApproveOrder}
                setMultiSelectApproveOrders ={setMultiSelectApproveOrders}

                
                showMultiCheckButton = {showMultiCheckButton}
                setShowMultiCheckButton = {setShowMultiCheckButton}

                showMultiSelectApproveModal={showMultiSelectApproveModal} 
                setShowMultiSelectApproveModal = {setShowMultiSelectApproveModal}

              />

              :
              null
            }

            {
              showMultiSelectDisputeModal === true ?
              <DisputeMultipleOrderModal
              disputeAction = "Assigned Orders"
              multiSelectApproveOrder = {multiSelectApproveOrder}
              removeMultiOrders = {removeMultiOrders}
              autoFetch= {autoFetch}
              setAutoFetch = {setAutoFetch}
              
              showMultiCheckButton = {showMultiCheckButton}
              setShowMultiCheckButton = {setShowMultiCheckButton}

              setMultiSelectApproveOrders ={setMultiSelectApproveOrders}
              showMultiSelectDisputeModal={showMultiSelectDisputeModal} 
              setShowMultiSelectDisputeModal = {setShowMultiSelectDisputeModal}
            

              />
              :
              null
            }
             {/* Multiple Order+Courier assign is still in under assesment  */}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssignedOrders