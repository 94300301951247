import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState , useEffect} from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
import TextileSectionEndPoint from '../../../Api/TextileSection/TextileSectionEndPoint';


const TextileItemsForm = () => {
  const {mutate:textileItemsSubmission , isLoading } = TextileSectionEndPoint.useSubmitTextileItems();


  // Items Hooks;
  const [itemName , setItemName] = useState('');
  const [unitType , setUnitType] = useState('');
  const [costPerUnit , setCostPerUnit] = useState('');

  const[userData , setUserData] = useState('');


  // loading and conditing hooks:
  const[input , setInput] = useState(false);


  function submitTextileItems(){
    if(itemName === ''  || unitType === '' || costPerUnit === '' ){
      setInput(true)
      toast.warn("Fill the information !")
      
    }
    else{
      const textileItemObj = {
        role_id :userData.role_id,
        user_id:userData.id,
        added_by:userData.username,
        item_name: itemName,
        unit_type: unitType,
        price_per_unit: costPerUnit
      }

      
      textileItemsSubmission((textileItemObj) , {
        onMutate: () => {
        },
        onSettled: () => {

          setInput(false);

          setItemName('');
          setUnitType('');
          setCostPerUnit('');
        },

      
      })
 
    }

  }

  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
   
  }, [])
  

  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light"></span> 
              Textile Item Form
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Items Form</h5>
                    {/* <small className="text-muted float-end">
                      Default label
                    </small> */}
                  </div>
                  <div className="card-body">

                    <div className="row">
               
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-company"
                          >
                            Item name
                          </label>
                          <input
                            type="text"
                            value={itemName}
                            className={itemName === ''&& input === true?"form-control border border-danger":"form-control"}
                            id="basic-default-company"
                            placeholder="Enter Item name..."
                            onChange={(e)=> setItemName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-email"
                        >
                          Unit Type
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            value={unitType}
                            id="basic-default-email"
                            className={unitType === ''&& input === true?"form-control border border-danger":"form-control"}
                            placeholder="Enter Unit Type..."
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            onChange={(e)=> setUnitType(e.target.value)}
                          />

                        </div>

                      </div>
                      </div>
                      <div className="col-lg-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Price per unit
                        </label>
                        <input
                          type="number"
                          value={costPerUnit}
                          id="basic-default-phone"
                          className={costPerUnit === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Enter price per unit..."
                          onChange={(e)=> setCostPerUnit(e.target.value)}
                        />
                      </div>
                      </div>
  
                    </div>

              
                      {
                        isLoading ?
                        <div className="spinner-border text-primary float-end" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                      <button type="submit" className="btn btn-primary float-end" onClick={submitTextileItems}>
                      Submit
                    </button>
                     
                      }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextileItemsForm;
