import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useMutation , useQueryClient} from 'react-query';








function filterOrders(orderSearch){
  const OrderfilterResults =   axios.post(`${process.env.REACT_APP_BASE_URL}search_orders`,orderSearch)
            .then((res)=>{
                if(res.data.status === '200' && res.data.data.length > 0){
                return res.data.data
                }
                else if(res.data.status === '200' && res.data.data.length === 0){
                    toast.warning(`No Orders found!`)
                    return res.data.data;
                }
                else {
                    throw new toast.error("Can't submit!");
                }
            })
            .catch((error)=>{
            if(error){
                toast.warn("Something went wrong, please try again in a while")
            }
            })

    return OrderfilterResults;
 }





const useFilterOrders = ()=>{
    return useMutation(filterOrders)
  }


  const OrderFilterEndPoint ={
    useFilterOrders,
    
}

export default OrderFilterEndPoint;