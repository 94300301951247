import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState,useEffect} from 'react';
import UpdateGrantPermissionModal from './UpdateGrantPermissionModal';

const GrantPermissionSheet = () => {

    const[allGrantedPermissions, setAllGrantedPermissions] = useState([]);

    const getAllGrantedPermission = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_granted_permissions`)
        .then((res)=>{
            setAllGrantedPermissions(res.data.Granted_permissions)
        })
        .catch((err)=>{
            return err
        })
    }

    
    function removeGrantedPermission(index) {
        setAllGrantedPermissions((prevState) => {
          const grantedPermission = [...prevState];
          grantedPermission.splice(index, 1);
          return grantedPermission;
        });
      }
  
      function deleteRemoveGrantedPermission(id,index){
        axios.post(`${process.env.REACT_APP_BASE_URL}delete_granted_permission_by_id/${id}`)
        .then((res)=>{
          if(res.data.status === '200'){
            toast.error("Permission Removed")
            removeGrantedPermission(index)
      
            }
        })
        .catch((error)=>{
            if(error){
              toast.warn("Something went wrong! Please try again")
            }
       
      })
      }
  
    useEffect(() => {
        getAllGrantedPermission()
    }, [])

    function AllGrantedPermissionSheet({items , index}){
        
        const [showGrantPermissionModal, setShowGrantPermissionModal] = useState(false);
    
    
          return(
              <>
                 <tr>
                  <td>{allGrantedPermissions.length - index}</td>
                  <td>{items.added_by}</td>
                  <td>{items.permission_name}</td>
                  <td>{items.username}</td>
                  <td>{items.Idate}</td>
    
                <td>
                  <div className="dropdown">
                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                      <i className="bx bx-dots-vertical-rounded" />
                    </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item" onClick={()=>{
                        setShowGrantPermissionModal(true)
                      }}><i className="bx bx-edit-alt me-1" /> Edit</button>
                      <button className="dropdown-item" onClick={()=>deleteRemoveGrantedPermission(items.granted_permission_id,index)}><i className="bx bx-trash me-1" /> Delete</button>
                    </div>
                  </div>
                </td>
              </tr>
            {
              showGrantPermissionModal === true ?
              <UpdateGrantPermissionModal
                
                grantedPermissionID = {items.granted_permission_id}
                memID = {items.user_id}
                permID = {items.permission_id}
                showGrantPermissionModal = {showGrantPermissionModal}
                setShowGrantPermissionModal = {setShowGrantPermissionModal}
                getAllGrantedPermission={getAllGrantedPermission}
              />
              :
              ""
            }
    
              </>
          )
      }
  return (
    <>
        <div className="scroll-view-component scrollbar-secondary-component">
<div className="content-wrapper">
  <div className="container-xxl flex-grow-1 container-p-y">
    <h4 className="fw-bold"><span className="text-muted fw-light">Granted Permissions</span> </h4>
    <div className="card">
      <h5 className="card-header">Granted Permissions Sheet</h5>
      <div className="table-responsive text-nowrap">
      {allGrantedPermissions && allGrantedPermissions.length > 0 ?
        <table className="table">
          <thead>
            <tr className='text-center'>
              <th>#</th>
              <th>Added By</th>
              <th>Permission Name</th>
              <th>Permission To</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
      
          <tbody className="table-border-bottom-0 table-responsive text-center">
            {
                allGrantedPermissions?.map((items,index)=>{
                  return(
                      <AllGrantedPermissionSheet items={items} index={index} />  
                  )
              })
            }
                  
          </tbody>
              
        </table>
              :
              <div className="text-center">
              <h1 className='fw-bold'>No Data Found !</h1>
              </div>

          }
      </div>
    </div>

  </div>

</div>
    </div>
    </>
  )
}

export default GrantPermissionSheet