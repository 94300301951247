import React, {useState , useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';

const UpdateMemberPasswordModal = ({memID , memName , showUpdateMemModal, setShowUpdateMemModal }) => {

  const[updateMemPassword , setMemPassword] = useState('')
  return (
    <>
    <Modal
        show={showUpdateMemModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>Update Password of {memName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <div className="col-lg-4 mb-3">
            <label className="form-label" htmlFor="basic-default-fullname">Expense Name</label>
            <input type="text" className={"form-control"} id="basic-default-fullname" placeholder="Enter expense name"
            onChange={(e)=> {setMemPassword(e.target.value)}}
            />
          </div>

        </Modal.Body>
        <Modal.Footer>
      <button className='btn btn-outline-secondary' onClick={()=>setShowUpdateMemModal(!showUpdateMemModal)}>Close</button>
       
      <button type="submit" className="btn btn-primary"
          // onClick={submitExpense}
          >Submit</button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default UpdateMemberPasswordModal