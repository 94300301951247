import axios from 'axios';
import {useQuery} from "react-query";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import React,{useState, useEffect} from 'react';

import CourierSectionEndPoint from '../../Api/CourierSection/CourierSectionEndPoint';


const UpdatePayCourierModal = ({payCourierID,getAllPayCourier,showUpdatePayCourierModal,setUpdatePayCourierModal}) => {
  
  const { mutate: getOrderDataByCourier, data:courierData } = CourierSectionEndPoint.OrderDataByCourierID();

    const [loading , setLoading] = useState(false);
    const [allCourier , setAllCourier] = useState([]);
    const [autoFetch, setAutoFetch] = useState(false);

    const [orderIDs , setOrderID] = useState([]);
    const [orderData , setOrderData] = useState([]);

    const [multiSelectApproveOrderIDs, setMultiSelectApproveOrderIDs] = useState([]);


    const [selectedCourier, setSelectedCourier] = useState('');

    const [allBanks, setAllBanks] = useState([]);
    const [selectedBank , setSelectedBank] = useState('');

    const [totalReceiveable , setTotalReceiveable] = useState('');
    const [totalPayable , setTotalPayable] = useState('');
    const [paidStripe , setPaidStripe] = useState('');
    const [paidBank , setPaidBank] = useState('');
    const [nonCodAmount , setNonCodAmount] = useState('');





  // Getting all couriers:
  useQuery("all_Courier",  CourierSectionEndPoint.getAllCourier,
  {
    enabled:!autoFetch,
    onSuccess: (data) => {
      setAllCourier(data.data.Couriers);
      setAutoFetch(true)
    },
    onError: (err) => {
      return err;
    },
  }
)

function getAllBanks(){
  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
  .then((res)=>{
      setAllBanks(res.data.Banks)
  })
  .catch((err)=>{
      return err
  })
}

function fetchPayCourierByID(){
  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_pay_courier_by_id/${payCourierID}`)
  .then((res)=>{
    setOrderData(res.data.data.orders)
    setOrderID(res.data.data.order_id)
    setMultiSelectApproveOrderIDs(res.data.data.order_id);
    setSelectedCourier(res.data.data.courier_id)
    setSelectedBank(res.data.data.pay_via)
    setTotalReceiveable(res.data.data.total_receiveable)
    setTotalPayable(res.data.data.total_payable)
    setPaidStripe(res.data.data.paid_via_stripe)
    setPaidBank(res.data.data.paid_via_bank)
    setNonCodAmount(res.data.data.non_cod)

  })
  .catch((err)=>{
    return err
  })
}


const handleSelectCourier = (e) => {
  setSelectedCourier(e.target.value)
  gettingOrderDataByCourierID(e.target.value)

}

const handleSelectBank= (e) =>{
  setSelectedBank(e.target.value)
}

const handleMultiSelectApproveOrders = (itemId) => {
  const isSelected = multiSelectApproveOrderIDs.includes(itemId);


  if (isSelected) {
    setMultiSelectApproveOrderIDs((prevSelectedItems) =>
      prevSelectedItems.filter((id) => id !== itemId)
    );
  } else {
    setMultiSelectApproveOrderIDs((prevSelectedItems) => [
      ...prevSelectedItems,
      itemId,
    ]);
  }

}


function gettingOrderDataByCourierID(courierID){
  const courierIDObj = {
    courier_id :Number(courierID)
 
  };

  getOrderDataByCourier((courierIDObj), {
    onMutate: () => {

    },
    onSettled: () => {
    },
  });
}


function updatePayCourier(){
  setLoading(true)
  const updatePayCourierObj = {

    courier_id:selectedCourier,
    pay_via:selectedBank,
    total_receiveable:totalReceiveable,
    total_payable:totalPayable,
    paid_via_stripe:paidStripe,
    paid_via_bank:paidBank,
    non_cod:nonCodAmount,
    order_id:multiSelectApproveOrderIDs
  }

   axios.post(`${process.env.REACT_APP_BASE_URL}update_pay_courier/${payCourierID}`, updatePayCourierObj)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Pay Courier Updated !");
      setLoading(false)
      getAllPayCourier()

    }
    else {
      setLoading(false);
      throw new toast.error("Can't Submit!");

    }
  })
.catch((error)=>{
  if(error){
    setLoading(false)
    toast.warn("Something went wrong, please try again in a while")
  }
  })
}





useEffect(() => {
  fetchPayCourierByID();
  getAllBanks();
}, [])



  return (
    <>
       <Modal
        show={showUpdatePayCourierModal}
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

<Modal.Header>
          <Modal.Title>
          Update Pay Couriers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">

                <div className="col-lg-12">

                <label className="form-label fw-bold" htmlFor="basic-default-orders">
                  Orders*
                  </label>
                  {
              orderData && orderData.length > 0 ?
    <div className="table-responsive text-nowrap table-bordered table-sm">
        <table className="table table-sm">
          <thead >
            <tr className='text-center'>
              <th>Check</th>
              <th>Order#</th>
              <th>Article's</th>
              <th>Payment Method</th>
              <th>Sub Total</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0  text-center">
                  {
              orderData?.map((item , index)=>{
                return(
                  <tr className='text-center' key={index}>
              <td>
              <input
                className="form-check-input border border-primary"
                type="checkbox"
                id={`defaultCheck${item.id}`}
                value={multiSelectApproveOrderIDs}
                onChange={() =>{ 
                  handleMultiSelectApproveOrders(item.id)
                }}
                checked={multiSelectApproveOrderIDs.includes(item.id)}
                
              />
        </td>

                  <td>{item.id}</td>
                  <td>
                    {item.order_data?.map((order)=>{
                      return(
                        <ul className="d-flex align-self-center" style={{listStyle:"none"}}>
                          <li>#{order.article_id}</li>&nbsp;&nbsp;
                          <li>{order.title}</li>
                        </ul>
                      )
                  })
                  }</td>

              <td>{item.payment_method}</td>

                  <td>
                    {item.sub_total}
                  </td>

                  <td>{item.total}</td>
                  <td>
                    <div className="dropdown">
                      <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                        <i className="bx bx-dots-vertical-rounded" />
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#e"><i className="bx bx-trash me-1" /> Delete</a>
                      </div>
                    </div>
                  </td>
                </tr>
                )
              })
        
            }
        
          </tbody>
        </table>
      </div>
      :
      <div className='text-center'>No Data Found</div>
    }

                </div>

        <div className="col-lg-4">
                <label htmlFor="emailBasic" className="form-label fw-bold">Select Courier</label>
                <select className="form-select border border-primary" aria-label="Default select example"
                value={selectedCourier}
                onChange={handleSelectCourier}
                >
                <option value={0}>Choose....</option>
                {
                    allCourier && allCourier.length > 0 ?
                        allCourier?.map((courier, index)=>{
                            return(
                                <option key={index} value={courier.id}>{courier.courier_name}</option>
                            )
                        })
                    :
                    "No Courier Found!"
                }

        
                </select>
                </div>

                
              <div className="col-lg-4 mb-3">
                <label htmlFor="emailBasic" className="form-label fw-bold">Select Bank</label>
                <select className="form-select border border-primary" aria-label="Default select example"
                value={selectedBank}
                onChange={handleSelectBank}
                >
                <option value={0}>Choose....</option>
                {
                    allBanks && allBanks.length > 0 ?
                    allBanks?.map((bank, index)=>{
                            return(
                                <option key={index} value={bank?.bank_id}>{bank.bank_name}</option>
                            )
                        })
                    :
                    "No Bank Found!"
                }
          
                </select>
                </div>


                <div className="col-lg-4 mb-3">
                  <label className="form-label fw-bold" htmlFor="basic-default-company">
                    Payables
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-company011"
                    placeholder="Payables..."
                    defaultValue={totalPayable}
                    onChange={(e) => setTotalPayable(e.target.value)}
                  />
                </div>

    

                <div className="col-lg-4 mb-3">
                  <label className="form-label fw-bold" htmlFor="basic-default-company">
                  Receivables
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-company011"
                    placeholder="Receivables..."
                    defaultValue={totalReceiveable}
                    onChange={(e) => setTotalReceiveable(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label fw-bold" htmlFor="basic-default-company">
                  Paid via Stripe
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-company012"
                    placeholder="Paid via Stripe..."
                    defaultValue={paidStripe}
                    onChange={(e) => setPaidStripe(e.target.value)}
                  />
                </div>

                
                <div className="col-lg-4 mb-3">
                  <label className="form-label fw-bold" htmlFor="basic-default-company">
                  Paid via Bank
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-company012"
                    placeholder="Paid via Bank..."
                    defaultValue={paidBank}
                    onChange={(e) => setPaidBank(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label fw-bold" htmlFor="basic-default-company">
                  Non-COD Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-company012"
                    placeholder="Non-COD amount..."
                    defaultValue={nonCodAmount}
                    onChange={(e) => setNonCodAmount(e.target.value)}
                  />
                </div>


                
                <div className="col-lg-12 mt-4">

                <label className="form-label fw-bold " htmlFor="basic-default-orders">
                  Orders By Selecting Courier*
                  </label>
                  {
               courierData && courierData.data && courierData.data.length > 0 ? 
    <div className="table-responsive text-nowrap table-bordered table-sm">

        <table className="table table-sm">
          <thead >
            <tr className='text-center'>
              <th>Check</th>
              <th>Order#</th>
              <th>Article's</th>
              <th>Payment Method</th>
              <th>Sub Total</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0  text-center">
           {
               courierData.data?.map((item , index)=>{
                return(
                  <tr className='text-center' key={index}>
              <td>
              <input
                className="form-check-input border border-primary"
                type="checkbox"
                id={`defaultCheck${item.id}`}
                onChange={() =>{ 
                  handleMultiSelectApproveOrders(item.id)
                }}
                checked={multiSelectApproveOrderIDs.includes(item.id)}
                
              />
        </td>

                  <td>{item.id}</td>
                  <td className='text-center'>
                    {item.order_data?.map((order)=>{
                      return(
                        <ul className="d-flex justify-content-center" style={{listStyle:"none"}}>
                          <li># {order.article_id}</li>&nbsp;&nbsp;
                          <li>{order.title}</li>
                        </ul>
                      )
                  })
                  }</td>

              <td>{item.payment_method}</td>

                  <td>
                    {item.sub_total}
                  </td>

                  <td>{item.total}</td>
                  <td>
                    <div className="dropdown">
                      <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                        <i className="bx bx-dots-vertical-rounded" />
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#d"><i className="bx bx-trash me-1" /> Delete</a>
                      </div>
                    </div>
                  </td>
                </tr>
                )
              })
         
        
            }
          </tbody>
        </table>
    
        
      </div>
      :
      <div className='text-center'>No Data Found</div>
    }

                </div>


            </div>

        </Modal.Body>
        <Modal.Footer>
        <button
                  type="submit"
                  className="btn btn-dark"
                  onClick={() =>{
                    setUpdatePayCourierModal(!showUpdatePayCourierModal)
                  }}
                >
                  Close
                </button>
          {loading === true ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>
                &nbsp;
                    <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={updatePayCourier}
                >
                  Submit
                </button>
                
                </>
             )} 

        </Modal.Footer>
      </Modal>

    </>
  )
}

export default UpdatePayCourierModal