import Error from '../../Error/Error';
import { useQuery } from "react-query";
import React,{useState,useEffect } from "react";
import CsvDownloadButton from 'react-json-to-csv';
import ApproveOrderModal from '../Modals/ApproveOrderModal';
import DisputeOrderModal from "../Modals/DisputeOrderModal";
import OrderFilterEndPoint from "../../Api/OrderFilters/OrderFilterEndPoint";
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";
import ApproveMultipleOrderModal from '../Modals/MultipleQueryModal/ApproveMultipleOrderModal';
import DisputeMultipleOrderModal from "../Modals/MultipleQueryModal/DisputeMultipleOrderModal";
import AddBankOrderModal from '../Modals/AddBankOrderModal';

const PendingOrders = () => {
    const { mutate: filterOrders, data:orderFilterData , isLoading:orderSearchLoading } =  OrderFilterEndPoint.useFilterOrders();
  
    const [getOrdersByStatus, setOrdersByStatus] = useState([]);

    const [autoFetch, setAutoFetch] = useState(true);
    const [showMultiCheckButton, setShowMultiCheckButton] = useState(false);
    const [showMultiCheckButtonForCSV , setShowMultiCheckButtonForCSV] = useState(false);


    const [showMultiSelectApproveModal , setShowMultiSelectApproveModal] = useState(false);
    const [showMultiSelectDisputeModal , setShowMultiSelectDisputeModal] = useState(false);
  
    const [multiSelectApproveOrder, setMultiSelectApproveOrders] = useState([]);
    const [multiSelectDownloadOrders, setMultiSelectDownloadOrders] = useState([])

    
    const [enableFilters , setEnableFilters] = useState(false);
    const [enableCVSFilters , setEnableCVSFilters] = useState(false);

    const [showUpdateBankModal , setUpdateBankModal] = useState(false);





    
    const [btnActive , setBtnActive] = useState("");

    // Multi Select approve order status function:
    const handleMultiSelectApproveOrders = (itemId) => {
      // Check if the item is already selected
      const isSelected = multiSelectApproveOrder.includes(itemId);
  
      // If selected, remove it from the array, otherwise add it
      if (isSelected) {
        setMultiSelectApproveOrders((prevSelectedItems) =>
          prevSelectedItems.filter((id) => id !== itemId),
          
        );
      } else {
        setMultiSelectApproveOrders((prevSelectedItems) => [
          ...prevSelectedItems,
          itemId,
        ]);
      }
    };

    
// Multi Select download order function:
  const handleMultiSelectDownloadOrders = (items) => {
    // Check if the item is already selected
    const isSelected = multiSelectDownloadOrders.includes(items);

    // If selected, remove it from the array, otherwise add it
    if (isSelected) {
      setMultiSelectDownloadOrders((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== items)
      );
    } 
    else {
      setMultiSelectDownloadOrders((prevSelectedItems) => [
        ...prevSelectedItems,
        items,
      ]);
    }
  }

    
    function removeSpecificeOrder(index) {
      setOrdersByStatus((prevState) => {
        const removeOrder = [...prevState];
        removeOrder.splice(index, 1);
        return removeOrder;
      });


    }

    



    const removeMultiOrders = () => {
      setOrdersByStatus((prevState) => {
        const updatedOrders = [...prevState];
    
        // Iterate through selected orders and remove them
        multiSelectApproveOrder.forEach((itemId) => {
          const indexToRemove = updatedOrders.findIndex((order) => order.id === itemId);
    
          if (indexToRemove !== -1) {
            updatedOrders.splice(indexToRemove, 1);
          }
        });
    
        return updatedOrders;
      });
    
      // Clear the selected orders array after removing them
      setMultiSelectApproveOrders([]);
    };
    
    const orderStatusObj = {
        status: "pending",
      };
    
      // Getting all orders by status:
      useQuery(["get_orders_by_status", orderStatusObj],() => OrderIntakeSectionEndPoint.getOrdersByStatus(orderStatusObj),
        {
          enabled: autoFetch,
          onSuccess: (data) => {
            setOrdersByStatus(data.data.data);
          },
          onError: (err) => {
            return err;
          },
        }
      )

      
  //Hooks for Filters

  const[orderID , setOrderID] = useState('');
  const[customerName , setCustomerName] = useState('');
  const[courierID , setCourierID] = useState('');
  const[customerPhone , setCustomerPhone] = useState('');
  const[customerEmail , setCustomerEmail] = useState('');
  const[customerPaid , setCustomerPaid] = useState('');
  const[paymentMethod , setPaymentMethod] = useState('');
  const[startDate , setStartDate] = useState('');
  const[endDate , setEndDate] = useState('');

  const[rangeFrom , setRangeFrom] = useState();
  const[rangeTo , setRangeTo] = useState();

  function getFilteredOrder(){
    // const toValues = rangeTo.split(',').map(value => parseInt(value.trim(), 10));

    const orderSearch = {
      id:orderID,
      courier_id:courierID,
      name: customerName,
      phone:customerPhone,
      email:customerEmail,
      paid:customerPaid,
      payment_method:paymentMethod,
      start_date:startDate,
      end_date:endDate,
      // range:"toValues.map(value => parseInt(rangeFrom, 10) + ',' + value).join(',')",
      status : "pending"
    }  
  
      filterOrders(orderSearch ,{
      onMutate: () => {
        
      },
      onSettled: () => {
  
      },
    })
  
  }
  
  
  useEffect(() => {
    setOrdersByStatus(orderFilterData)
  }, [orderFilterData])

    
    function PendingOrders({ items, index}){
      const [showAproveModal, setShowApproveModal] = useState(false);
      const [showDisputeModal , setShowDisputeModal] = useState(false);

      
    return (
      <>
        {/* Pending order section */}
        <div className="col-lg-8 d-flex align-items-center">

          {showMultiCheckButton ? (
            <div className="form-check">
              <input
                className="form-check-input border border-primary fs-5"
                type="checkbox"
                id={`defaultCheck${items.id}`}
                onChange={() =>{ 
                  handleMultiSelectApproveOrders(items.id)
                }}
                checked={multiSelectApproveOrder.includes(items.id)}
              />
            </div>
          ) : null}

            {showMultiCheckButtonForCSV ? (
            <div className="form-check">
              <input
                className="form-check-input border border-info fs-5"
                type="checkbox"
                id={`defaultCheck${items.id}`}
                onChange={() =>{ 
                  handleMultiSelectDownloadOrders(items)
                }}
                checked={multiSelectDownloadOrders.includes(items)}
                
              />
            </div>
          ) : null}


          <div
            className="card accordion-item"
            style={{ border: "1px solid #696cff" }}
            key={index}
          >
            <h4 className="" id={"heading" + index}>
              <span
                className="badge bg-label-dark border border-dark float-end mt-1 me-1"
                style={{ fontSize: "8px" }}
              >
                {items.status}
              </span>
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target={"#accordion" + index}
                aria-expanded="false"
                aria-controls={"accordion" + index}
              >
                       <div className="text-black ms-1">
                                  <i
                                    className="fa-solid fa-image me-3"
                                    style={{ fontSize: "3em" }}
                                  />
                                </div>
                {/* {items.order_data && items.order_data.length > 0
                  ? items.order_data.map((order) => {
                      return (
                        order.image &&
                        order.image.length > 0 && (
                          <img
                            key={order.image[0]}
                            className="img-fluid me-3 card-img-left"
                            src={`${process.env.REACT_APP_IMAGE_URL}${order.image[0]}`}
                            alt="Card_image"
                            width={50}
                          />
                        )
                      );
                    })
                  : "No Data Found!"} */}

                <div className="row gy-3" style={{ fontSize: "13px" }}>
                  <div className="col-lg-5">
                    <div className="row lh-base">
                      <span className="fw-bold">Order# {items.id}</span>
                      <span>COD: {items.cod === "true" ? "Yes" : "No"}</span>
                      <span>Payement Method: {items.payment_method}</span>
                      <span>
                        Paid: {items.paid === "true" ? "Paid" : "Not Paid"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-2 d-flex align-items-center">
                    <div className="text-black order-intake-qty">
                      <div className="d-flex text-dark">
                        <span>Qty:</span>&nbsp;&nbsp;
                        {items.order_data &&
                          items.order_data.length > 0 &&
                          items.order_data.reduce(
                            (total, order) => total + Number(order.qty),
                            0
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="row mt-2 lh-base">
                      <span className="text-black">
                        Contact: {items.contact_data.phone}
                      </span>
                      <span className="text-black">
                        Courier assigned: {items.courier_id === null ?"Not yet":items.courier_id}
                      </span>
                      <span className="text-black">CN: {items.cn === null ? "Not assigned":items.cn }
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            </h4>
            <div
              id={"accordion" + index}
              className="accordion-collapse collapse"
              aria-labelledby={"heading" + index}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="nav-align-top ">
                  <ul className="nav nav-pills  nav-fill" role="tablist">
                    <li className="nav-item">
                      <button
                        type="button"
                        className="nav-link active btn-sm"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target={
                          "#navs-pills-justified-home-" + index + 1
                        }
                        aria-controls={"navs-pills-justified-home-" + index + 1}
                        aria-selected="true"
                      >
                        <i className="tf-icons bx bx-box" /> Order
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className="nav-link btn-sm"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target={
                          "#navs-pills-justified-profile-" + index + 1
                        }
                        aria-controls={
                          "navs-pills-justified-profile-" + index + 1
                        }
                        aria-selected="false"
                      >
                        <i className="tf-icons bx bx-trip" /> Shipping
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className="nav-link btn-sm"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target={
                          "#navs-pills-justified-messages-" + index + 1
                        }
                        aria-controls={
                          "navs-pills-justified-messages-" + index + 1
                        }
                        aria-selected="false"
                      >
                        <i className="tf-icons bx bx-message-square" />
                        Billing
                      </button>
                    </li>
                  </ul>

                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary btn-icon rounded-pill dropdown-toggle hide-arrow"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bx bx-dots-horizontal-rounded " />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">

                      <li>
                        <button
                          type="button"
                          className="dropdown-item btn btn-outline-dark"
                          onClick={() => setShowApproveModal(true)}
                        >
                          <i className="fa-solid fa-file-circle-plus" />
                          &nbsp;&nbsp;Move to Verify Orders
                        </button>
                      </li>
                      {
                        items.payment_method === "bank" ?
                        <li>
                        <button
                          type="button"
                          className="dropdown-item btn btn-outline-dark"
                          onClick={() => {
                            setOrderID(items.id)
                            setUpdateBankModal(true)}}
                        >
                          <i className="fa-solid fa-bank" />
                          &nbsp;&nbsp;Update Bank Details
                        </button>
                      </li>
                      :
                      ""

                      }
               



                      <li>
                        <button
                          type="button"
                          className="dropdown-item btn btn-outline-dark"
                          onClick={()=>{
                            setShowDisputeModal(true)
                          }}
                        >
                          <i className="fa-solid fa-file-circle-minus" />
                          &nbsp;&nbsp; Dispute Orders
                        </button>
                      </li>

                    </ul>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id={"navs-pills-justified-home-" + index + 1}
                      role={"tabpanel" + index + 1}
                    >
                      {items.order_data && items.order_data.length > 0
                        ? items.order_data.map((order, index) => {
                            return (
                              <div className="row mt-1" key={index}>
                                <div className="col-lg-12 border border-1 shadow-sm rounded ">
                                  <h6 className="fw-bold">
                                    #{order.article_id}
                                  </h6>

                                  <div className="row">
                                    <div
                                      className="col-lg-5 d-flex"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {order.image && order.image !== null ? (
                                        <img
                                          key={order.image[0]}
                                          className="img-fluid card-img-left me-3"
                                          src={`${process.env.REACT_APP_IMAGE_URL}${order.image[0]}`}
                                          alt="Card_image"
                                          width={50}
                                        />
                                      ) : (
                                        <div className="text-black ms-1">
                                          <i
                                            className="fa-solid fa-image me-3"
                                            style={{ fontSize: "3em" }}
                                          />
                                        </div>
                                      )}
                                      <div className="text-black fw-bold mt-3">
                                        Qty: &nbsp;
                                        <span class="badge rounded-pill bg-label-warning">
                                          {order.qty}
                                        </span>
                                      </div>
                                      &nbsp;&nbsp;
                                      {order.on_sale === "true" ? (
                                        <div className="text-black fw-bold mt-3">
                                          Sale:&nbsp;
                                          <span className="badge bg-label-info">
                                            Yes
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="text-black fw-bold mt-3">
                                          Sale:&nbsp;
                                          <span class="badge bg-label-danger">
                                            No
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className="col-lg-7"
                                      style={{ fontSize: "13px" }}
                                    >
                                      <span className="text-black fw-bold">
                                        Actual Price:
                                      </span>
                                      &nbsp;&nbsp;
                                      <span className="text-black me-2">
                                        <span>{order.actual_single_price}</span>
                                      </span>
                                      <span className="text-black fw-bold ">
                                        Single Price:
                                      </span>
                                      &nbsp;&nbsp;
                                      <span className="text-black me-2">
                                        <span>{order.single_price}</span>
                                      </span>
                                      <div>
                                        <span className="text-black fw-bold">
                                          Total Price:
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="text-black">
                                          {order.total_price}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="ms-1">
                                    <span
                                      className="badge bg-label-dark mb-3"
                                      style={{ fontSize: "10px" }}
                                    >
                                      {order.size}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : "No Data Found!"}
                    </div>

                    <div
                      className="tab-pane fade"
                      id={"navs-pills-justified-profile-" + index + 1}
                      role={"tabpanel" + index + 1}
                    >
                      <div className="row mt-1">
                        <div
                          className="col-lg-12 border border-1 shadow-sm rounded d-block mx-auto p-3"
                          style={{ fontSize: "13px" }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">Postcode</p>
                            <span className="text-black">
                              <i className="fa-solid fa-signs-post"></i>&nbsp;
                              {items.shipping_data.postcode}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">State</p>
                            <span className="text-black">
                              <i className="fa-solid fa-landmark"></i>&nbsp;
                              {items.shipping_data.state}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">City</p>
                            <span className="text-black">
                              <i class="fa-solid fa-city"></i>&nbsp;
                              {items.shipping_data.city}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">Phone</p>
                            <span className="text-black">
                              <i className="fa-solid fa-phone"></i>&nbsp;
                              {items.shipping_data.phone}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id={"navs-pills-justified-messages-" + index + 1}
                      role={"tabpanel" + index + 1}
                    >
                      <div className="row mt-1">
                        <div
                          className="col-lg-12 border border-1 shadow-sm rounded d-block mx-auto p-3"
                          style={{ fontSize: "13px" }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">City</p>
                            <span className="text-black">
                              <i class="fa-solid fa-city"></i>&nbsp;
                              {items.billing_data.city}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">Phone</p>
                            <span className="text-black">
                              <i className="fa-solid fa-phone"></i>{" "}
                              {items.billing_data.phone}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">Address</p>
                            <span className="text-black">
                              <i className="fa-solid fa-house"></i>{" "}
                              {items.billing_data.address}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">Email</p>
                            <span className="text-black">
                              <i className="fa-solid fa-envelope"></i>{" "}
                              {items.contact_data.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* New order section */}

        {/* Billing total section */}
        <div className="col-lg-4">
          <div
            className="card accordion-item "
            style={{ border: "1px solid #696cff" }}
          >
            <h2 className="accordion-header " id="headingThree">
              <span
                className="badge bg-label-dark border border-dark float-end me-1"
                style={{ fontSize: "8px", marginTop: "2px" }}
              >
                {items.status}
              </span>

              <button
                type="button"
                className="accordion-button collapsed accordion-button-billing-total "
                data-bs-toggle="collapse"
                data-bs-target="#accordionThree"
                aria-expanded="false"
                aria-controls="accordionThree"
              >
                <div className="row " style={{ fontSize: "13px" }}>
                  <div className="d-flex text-black">
                    <span>Promo code:&nbsp;</span>
                    <span>{items.promo_code}</span>
                  </div>

                  <div className="col-lg-12">
                    <div className="row">
                      <span className="text-black">
                        GST %:&nbsp; {items.gst}
                      </span>

                      <span className="text-black">
                        Shippment:&nbsp; {items.shipment_charges}
                      </span>

                      <span className="text-black">
                        Sub Total:&nbsp; {items.sub_total}
                      </span>
                      <span className="text-black">
                        Total:&nbsp;{items.total}
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="accordionThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body"></div>
            </div>
          </div>
        </div>
        {/* Billing total section */}
        {showAproveModal && (
          <ApproveOrderModal
            orderAction="verified"
            orderID={items.id}
            index={index}
            removeSpecificeOrder={removeSpecificeOrder}
            showAproveModal={showAproveModal}
            setShowApproveModal={setShowApproveModal}
          />
        )}



     

        {/* Dispute Order Modal */}
        {
          showDisputeModal && (
            <DisputeOrderModal
            disputeAction="Pending orders"
            orderID={items.id}
            index={index}
            removeSpecificeOrder={removeSpecificeOrder}
            showDisputeModal = {showDisputeModal}
            setShowDisputeModal = {setShowDisputeModal}
          />
          )
        }
    
        {/* Dispute Order Modal */}
      </>
    );

    }


  return (
    <>  
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold">
              <span className="text-muted fw-light">Pending Orders</span>
            </h4>

            <div className="d-flex bd-highlight">
              <div className="p-2 flex-fill bd-highlight">
                {/* Enable multi-select to select multiple orders at once and change status   */}
                <button
                  type="button"
                  className={btnActive === "Select Orders Button" ?"btn bg-secondary text-white mb-2 me-3":"btn btn-outline-secondary mb-2 me-3"}
                  onClick={() => {
                    setShowMultiCheckButton(!showMultiCheckButton);
                    setShowMultiCheckButtonForCSV(false);
                    setMultiSelectDownloadOrders('');
                    setEnableFilters(false)
                    setBtnActive("Select Orders Button")
                  }}
                >
                  <span className="tf-icons bx bx-bell"></span>&nbsp; Select Orders
                </button>

                {/* Enable multi-select to select multiple orders at once and change status   */}
                
              {/*Enable Multiple filters e.g. search with (order/phone/email) etc  */}
              <button
                  type="button"
                  className={btnActive === "Enable Filters Btn" ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setShowMultiCheckButton(false)
                    setEnableFilters(!enableFilters)
                    setBtnActive("Enable Filters Btn")
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>
                {/*Enable Multiple filters e.g. search with (order/phone/email) etc  */}

        
              {/* Enable CSV download options(download all order or download specific) */}

              <button
                  type="button"
                  // className={btnActive === "Download CSV Btn"? "btn btn-light text-black border border-2 mb-2 dropdown-toggle hide-arrow":"btn btn-outline-light text-black border border-2 mb-2 dropdown-toggle hide-arrow"}
                  className='btn btn-light text-black border border-2 mb-2 dropdown-toggle hide-arrow'
                  data-bs-toggle="dropdown"
                  aria-expanded="false"          
                >
                  <span className="tf-icons bx bx-file"></span>&nbsp; Download CSV
                </button>

                <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <button
                          type="button"
                          className="dropdown-item btn btn-outline-dark"
                           onClick={() =>{ 
                            setEnableCVSFilters(!enableCVSFilters)
                            setMultiSelectApproveOrders('')
                            setShowMultiCheckButton(false)
                            setEnableFilters(false)
                            setBtnActive("Download CSV Btn")
                           
                          }}

                        >
                         <i className="fa-solid fa-file"/>
                          &nbsp;&nbsp; Download all orders
                        </button>
                      </li>

                      <li>
                        <button
                          type="button"
                          className="dropdown-item btn btn-outline-dark"
                          onClick={()=>{
                            setShowMultiCheckButtonForCSV(!showMultiCheckButtonForCSV)
                            setShowMultiCheckButton(false)
                            setMultiSelectApproveOrders('')
                            setEnableFilters(false)
                            setBtnActive("Download CSV Btn")
                          }}
                        >
                          <i className="fa-solid fa-folder-plus" />
                          &nbsp;&nbsp; Download specific orders
                        </button>
                      </li>
                </ul>
                  {
                    multiSelectDownloadOrders.length >=1 && showMultiCheckButtonForCSV === true? (
                        <>
                          
                          
                            <CsvDownloadButton
                            className="btn btn-outline-danger ms-2 mb-2"
                            filename={`marked_pending_orders.csv`}
                            separator={";"}
                            data={multiSelectDownloadOrders} 
                            onMouseLeave={()=>{
                              setMultiSelectDownloadOrders('')
                              setShowMultiCheckButtonForCSV(!showMultiCheckButtonForCSV)
                            }}
                           >
                            <i className="fa fa-file-arrow-down fa-1x"></i>&nbsp;&nbsp;
                            Download Marked Record
                          </CsvDownloadButton>
                          
                         

                    
                        </>
                    )
                    : null
                  }
                  {
                    enableCVSFilters ?
                    <>
                      {
                        (
                          <CsvDownloadButton  className="btn btn-outline-primary  ms-2 mb-2" 
                          filename={`pending_orders.csv`}
                          separator={";"}
                          data={getOrdersByStatus}
                          onMouseLeave={()=>setEnableCVSFilters(!enableCVSFilters)}

                          >
                          <i className="fa fa-file-arrow-down fa-1x"></i>&nbsp;&nbsp;
                           Download All Orders
                        </CsvDownloadButton>
                        )
                      }
                    </>
                    :
                    null
                  }
                {/* Enable CSV download options(download all order or download specific) */}
              </div>
                


              {multiSelectApproveOrder.length >=1 && showMultiCheckButton === true? 
                  
                  (
                      <>
                        <div className="p-2 flex-fill bd-highlight">
                          <button
                            className="btn btn-outline-success dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="tf-icons bx bx-task" /> &nbsp; Submit
                            Multiple Orders
                          </button>

                          <ul className="dropdown-menu dropdown-menu-start">
                            <li>
                              <button
                                type="button"
                                className="dropdown-item btn btn-outline-dark"
                                onClick={()=>setShowMultiSelectApproveModal(true)
                                }
                              >
                                <i className="fa-solid fa-file-circle-plus" />
                                &nbsp;&nbsp; Verify to Verified Orders
                              </button>
                            </li>

                            <li>
                              <button
                                type="button"
                                className="dropdown-item btn btn-outline-dark"
                                onClick={()=>{
                                  setShowMultiSelectDisputeModal(true)
                                }}
                              >
                                <i className="fa-solid fa-file-circle-minus" />
                                &nbsp;&nbsp; Dispute Multiple Order
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                  )
                  : 
                 null
              }
            </div>
            <div className="row gy-2">
                {
                   enableFilters ? 
                    (
                      <div className="row mt-2 mb-2">

                        
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Start Date*</label>
                      <input type="date" value={startDate} className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=>{
                        setStartDate(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>

                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">End Date*</label>
                      <input type="date" value={endDate} className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setEndDate(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>
            

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput1" className="form-label fw-bold">Order ID*</label>
                      <input type="number" className="form-control form-control-sm border border-primary" id="defaultFormControlInput1" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      onChange={(e)=>{ 
                        setOrderID(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>
                        

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Customer name*</label>
                      <input type="text" className="form-control form-control-sm border border-primary" value={customerName} id="defaultFormControlInput6" placeholder="Search with customer name.." aria-describedby="defaultFormControlHelp"
                        onChange={(e)=>{
                          setCustomerName(e.target.value)
                          setAutoFetch(true)
                        }}
                      />
                        </div>


                        <div className="col-lg-3">
                        <label for="defaultFormControlInput2" className="form-label fw-bold">Phone*</label>
                      <input type="number" className="form-control form-control-sm border border-primary" id="defaultFormControlInput2" placeholder="Search with phone.." aria-describedby="defaultFormControlHelp"
                      onChange={(e)=>{
                        setCustomerPhone(e.target.value)
                        setAutoFetch(true)
                      }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput3" className="form-label fw-bold">Email*</label>
                      <input type="email" className="form-control form-control-sm border border-primary" id="defaultFormControlInput3" placeholder="Search with email.." aria-describedby="defaultFormControlHelp"
                         onChange={(e)=>{
                          setCustomerEmail(e.target.value)
                          setAutoFetch(true)
                        }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput4" className="form-label fw-bold">Payment Method*</label>
                      <input type="text" className="form-control form-control-sm border border-primary" value={paymentMethod} id="defaultFormControlInput4" placeholder="Search with payment method.." aria-describedby="defaultFormControlHelp"
                        onChange={(e)=>{
                          setPaymentMethod(e.target.value)
                          setAutoFetch(true)
                        }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput5" className="form-label fw-bold">Status(Paid/Not-Paid)*</label>
                        <select id="smallSelect" className="form-select form-select-sm  border border-primary"
                        onChange={(e)=> {
                          setCustomerPaid(e.target.value)
                          setAutoFetch(true)
                        }}
                        >
                          <option value="">Choose...</option>
                          <option value="true">Yes</option>
                          <option value="false">Not yet</option>
                        </select>
                        </div>

                        <span className="fw-bold mt-2">*Select Range*</span>

                            <div className="col-lg-3 mt-1">
                            <label for="defaultFormControlInput7" className="form-label fw-bold">From*</label>
                            <input type="text" className="form-control form-control-sm border border-primary" value={rangeFrom} id="defaultFormControlInput7" placeholder="Enter range from..." aria-describedby="defaultFormControlHelp"
                            onChange={(e)=>{
                              setRangeFrom(e.target.value)
                              setAutoFetch(true)
                            }}
                            />
                            </div>

                            <div className="col-lg-3 mt-1">
                            <label for="defaultFormControlInput8" className="form-label fw-bold">To*</label>
                            <input type="text" className="form-control form-control-sm border border-primary" value={rangeTo} id="defaultFormControlInput8" placeholder="Enter range to..." aria-describedby="defaultFormControlHelp"
                            onChange={(e)=>{
                              setRangeTo(e.target.value)
                              setAutoFetch(true)
                            }}
                            />
                            </div>


                        <div className="col-lg-3" style={{marginTop:"2em"}}>
                        {
                        orderSearchLoading === true ?
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                          <button className="btn btn-primary btn-sm"
                          onClick={()=>{
                            getFilteredOrder()
                            setAutoFetch(!autoFetch)
                          }}
                          >
                            
                            <i className="fa fa-search"/>&nbsp;&nbsp;
                            Search Query
                          </button>
                        }
                        </div>


                      </div>
                    )
                    :
                    null
                    
                  }

              {getOrdersByStatus && getOrdersByStatus.length > 0 ? (
                <>
                {
                  getOrdersByStatus?.map((items,index)=>{
                    return <PendingOrders items={items} index={index} />
                  })
                }

                </>
              ) : (
                <Error/>
              )}
            </div>

            {
  showUpdateBankModal === true && (
    <AddBankOrderModal
    orderID={orderID}
    showUpdateBankModal={showUpdateBankModal} 
    setUpdateBankModal = {setUpdateBankModal}
    />
  )
}

            {
          showMultiSelectApproveModal === true ?
          <ApproveMultipleOrderModal
            orderAction="verified"
            removeMultiOrders = {removeMultiOrders}

            autoFetch= {autoFetch}
            setAutoFetch = {setAutoFetch}
            
            multiSelectApproveOrder = {multiSelectApproveOrder}
            setMultiSelectApproveOrders ={setMultiSelectApproveOrders}

            
            showMultiCheckButton = {showMultiCheckButton}
            setShowMultiCheckButton = {setShowMultiCheckButton}

            showMultiSelectApproveModal={showMultiSelectApproveModal} 
            setShowMultiSelectApproveModal = {setShowMultiSelectApproveModal}

          />

          :
          null
        }

        {
          showMultiSelectDisputeModal === true ?
          <DisputeMultipleOrderModal
          orderAction="verified"
          disputeAction="Pending orders"
          removeMultiOrders = {removeMultiOrders}
          
          removeSpecificeOrder = {removeSpecificeOrder}

          multiSelectApproveOrder = {multiSelectApproveOrder}

          autoFetch= {autoFetch}
          setAutoFetch = {setAutoFetch}
          
          showMultiCheckButton = {showMultiCheckButton}
          setShowMultiCheckButton = {setShowMultiCheckButton}

          setMultiSelectApproveOrders ={setMultiSelectApproveOrders}
          showMultiSelectDisputeModal={showMultiSelectDisputeModal} 
          setShowMultiSelectDisputeModal = {setShowMultiSelectDisputeModal}

          />
          :
          null
        }

          </div>
        </div>
      </div>


    </>
  )
}

export default PendingOrders