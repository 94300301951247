import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import GraphErr from '../Images/graphErr.svg';

const ItemsAndArticleGraph = () => {

  const [financeGraphData, setFinanceGraphData] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  
  const [checkBoxStatus , setCheckBoxStatus] = useState(false)
  const [checkBoxVal , setCheckBoxVal] = useState('')


  const [startDate ,setStartDate] = useState('');
  const [endDate , setEndDate] = useState('');
  const [enableSearchBtn , setEnableSearchBtn] = useState(false);


  const handleCheckInput = (val)=>{
    setSelectedCheckbox(val);
    graphFunc(val)
  }


  function graphFunc(val){

    var formdata = new FormData();
formdata.append("type", val);
formdata.append("start_date", startDate);
formdata.append("end_date", endDate);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://avantgarde.alphanitesofts.net/api/fetch_graphs_data", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status === '200'){
      setFinanceGraphData(result?.data)
    }
  })


  .catch(error => console.log('error', error));

  }

  
function clearFilters(){
  setStartDate('');
  setEndDate('');
  setCheckBoxVal('');
  setEnableSearchBtn(false)
}

// Item Receiving data:
const itemName = financeGraphData?.map((items)=> items.item_name)
const itemDate = financeGraphData?.map((items)=> items.Idate)
const totalBillOfItem = financeGraphData?.map((items)=> items.total_bill)
const amountOfSingleItem = financeGraphData?.map((items)=> items.item_price)
const quantityOFItems = financeGraphData?.map((items)=> items.quantity)

// Article Receiving data:
const articleDate = financeGraphData?.map((items)=> items.Idate)
const totalBillOfArticles = financeGraphData?.map((items)=> items.total_bill)
const subTotalBillOfArticles = financeGraphData?.map((items)=> items.sub_total)
const paidAmountOFArticles = financeGraphData?.map((items)=> items.paid_amount)


const item_receiving = {
  series: [
    {
      name: `Total Bill - Item Receiving`,
      data: totalBillOfItem,
      color: '#ffc107'
    },
    {
      name: `Single amount of - Item Receiving`,
      data:amountOfSingleItem,
      color: "#7367f0"
    },
    {
      name: 'Quantity - Item Receiving',
      data: quantityOFItems,
      color:"#64ffda"
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 300,
    },
  
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: financeGraphData?.map((data, index) => {
        return `${data.Idate} - ${data.item_name}`;
      }),
    },
    yaxis: {
      title: {
        text: `Items Costing`,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return 'Rs.' + val;
        },
      },
    },
  },
}


const articleReceivingData = {

  series: [
    {
      name: 'Total Bill - Article Receiving',
      data: totalBillOfArticles,
      color: '#00bcd4'
    },
    {
      name: 'Sub-Total Bill - Article Receiving',
      data: subTotalBillOfArticles,
      color:'#e53935'
    },
    {
      name: 'Paid amount - Article Receiving',
      data: paidAmountOFArticles,
      color:'#009688'
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories:articleDate
    },
    yaxis: {
      title: {
        text: 'Aricles Costing',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return 'Rs.' + val;
        },
      },
    },
  },
}







  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mb-5">
              <div className="card-header">
                <h4 className="fw-bold">
                  <span className="text-muted fw-light">Finance Graphs</span>
                </h4>
              </div>

              <div className="d-flex">
                <div className="form-check ms-5 me-5">
                  <input
                    className="form-check-input fs-6 border border-primary"
                    type="checkbox"
                    id="inlineCheckbox1"
                    defaultValue="option1"
                    onChange={()=> {
                    handleCheckInput('item_receiving')
                    setCheckBoxVal('item_receiving')
                    setCheckBoxStatus(true)
                  }}
                    checked={selectedCheckbox === 'item_receiving' && checkBoxStatus === true}
                  />
                  <label
                    className="form-check-label fs-6"
                    htmlFor="inlineCheckbox1"
                  >
                    Item Receiving
                  </label>
                </div>

       
             
                {
                checkBoxStatus === true ?
                  <>
                          <div className="col-lg-3">
                  <div className="input-group input-group-sm">
                    <label
                      className="input-group-text border border-primary fw-bold"
                      htmlFor="inputGroupSelect01"
                    >
                      From*
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm border border-primary"
                      id="inputGroupSelect01"
                      value={startDate}
                      onChange={(e)=> setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                &nbsp;&nbsp;
                <div className="col-lg-3">
                  <div className="input-group input-group-sm">
                    <label
                      className="input-group-text border border-primary fw-bold"
                      htmlFor="inputGroupSelect02"
                    >
                      To*
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm border border-primary"
                      id="inputGroupSelect02"
                      value={endDate}
                      onChange={(e)=> {
                        setEndDate(e.target.value)
                        setEnableSearchBtn(true)
                      }}

                    />
                  </div>
                </div>
                &nbsp; &nbsp;
                {
                  enableSearchBtn === true ?
                  <>
                  <button className="btn btn-sm btn-outline-primary" onClick={()=>graphFunc(checkBoxVal)}> 
                  <i className="fa fa-magnifying-glass"/>
                  &nbsp;&nbsp;
                  search
                  </button>
                  &nbsp;&nbsp;
                  <button className="btn btn-sm btn-outline-primary" onClick={()=>clearFilters(checkBoxVal)}> 
                  <i className="fa fa-filter"/>
                  &nbsp;&nbsp;
                  clear filters
                  </button>
                  
                  
                  </>
                  :
                  null
                }
               
                  </>
                  :
                  null
                }
          

              </div>

              <div className="form-check ms-5 mt-2">
                <input
                  className="form-check-input fs-6 border border-primary"
                  type="checkbox"
                  id="inlineCheckbox1"
                  defaultValue="option1"
                  onChange={()=> 
                    {
                    handleCheckInput('article_receiving')
                    setCheckBoxVal('article_receiving')

                    setCheckBoxStatus(true)
                }}
                  checked={selectedCheckbox === 'article_receiving' && checkBoxStatus === true}
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="inlineCheckbox1"
                >
                  Article Receiving
                </label>
              </div>

              {/* <div className="form-check ms-5 mt-2">
                <input
                  className="form-check-input fs-6 border border-primary"
                  type="checkbox"
                  id="inlineCheckbox1"
                  defaultValue="option1"
                  onChange={()=> 
                    {
                    handleCheckInput('pay_staff')
                    setCheckBoxVal('pay_staff')

                    setCheckBoxStatus(true)
                }}
                  checked={selectedCheckbox === 'pay_staff' && checkBoxStatus === true}
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="inlineCheckbox1"
                >
                  Pay Staff
                </label>
              </div> */}




              <div className="card-body">
              {
                selectedCheckbox === "item_receiving" ?
                  <ReactApexChart
                    options={item_receiving.options}
                    series={item_receiving.series}
                  type="bar"
                  height={300}
                />
                :
                selectedCheckbox === "article_receiving" ?
                <ReactApexChart
                    options={articleReceivingData.options}
                    series={articleReceivingData.series}
                  type="bar"
                  height={300}
                />
                // :
                // selectedCheckbox === "pay_staff" ?
                // <ReactApexChart
                //     options={payStaff.options}
                //     series={payStaff.series}
                //   type="bar"
                //   height={300}
                // />
                :
                <>

              <img className='img-fluid d-block mx-auto m-5' src={GraphErr} alt="" width={300}/>
             </>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemsAndArticleGraph;
