import {useQuery} from 'react-query';
import Modal from 'react-bootstrap/Modal';
import React , {useState, useEffect} from 'react';
import UsersGlobalData from '../../../GlobalCalls/UsersGlobalData';
import ClothingCategorySectionEndPoint from '../../../Api/ClothingSection/ClothingCategorySectionEndPoint';
import ClothingSubCategorySectionEndPoint from '../../../Api/ClothingSection/ClothingSubCategoryEndPoint';

const UpdateSubClothCategory = ({subCategoryID , showClothSubCategoryModal , setShowClothSubCategoryModal , refetchSubCategory}) => {
  const {mutate:updateSubCategorySubmission , isLoading } = ClothingSubCategorySectionEndPoint.useUpdateSubCategory();

  const[allCategories , setAllCategories] = useState([]);

  
  const [subCateID , setSubCateID] = useState('')
  const [categoryName , setCategoryName] = useState('');
  const [subCategoryName , setSubCategoryName] = useState('');
  const [userName , setUsername] = useState('');
  const [addedBy ,setAddedBy] = useState('');

  const[userData , setUserData] = useState('');


  useQuery("all_categories", ClothingCategorySectionEndPoint.getAllCategories, {
    onSuccess: (data) => {
      setAllCategories(data.data.Categorys);
    },
    onError: (err) => {
      return err;
    },
  })


  // Getting Specific textile items:
  useQuery(["getSubCategoryByID", subCategoryID], _=> ClothingSubCategorySectionEndPoint.getSubCategoryByID(subCategoryID), {
    onSuccess: (data) => {
      setSubCateID(data.data.Sub_category.sub_category_id)
      setCategoryName(data.data.Sub_category.cat_name)
      setSubCategoryName(data.data.Sub_category.sub_cat_name)
      setUsername(data.data.Sub_category.username)
    },
    onError: (err) => {
      return err;
    },

  })

  function updateSubCategory(){
    const subCategoryObj = {
      role_id :userData.role_id,
      user_id:userData.id,
      added_by:userData.username,
      cat_name: categoryName,
      sub_cat_name:subCategoryName,
    }

    
    updateSubCategorySubmission(({subCategoryObj,subCateID}) , {
      onMutate: () => {
      },
      onSettled: () => {
        setCategoryName('');
        setSubCategoryName('');
      }

    
    })

  
}

useEffect(() => {
  const getocal = async() =>{
    const data = await UsersGlobalData()
   setUserData(data)
  }
  getocal()
}, [])


  return (
    <>

<Modal size={"lg"} show={showClothSubCategoryModal}>
        <Modal.Header >
          <Modal.Title>Update Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
                     
                     <div className="col-lg-6">
                         <div className="mb-3">
                           <label
                            className="form-label"
                             htmlFor="inputGroupSelect01"
                           >
                             Category Name
                           </label>
                           <select
                             className="form-select"
                             id="inputGroupSelect01"
                             value={categoryName}
                             onChange={(e)=>setCategoryName(e.target.value)}
                           >
                             <option>Choose...</option>
                             {
                              allCategories && allCategories.length > 0 ?
                              allCategories?.map((items,index)=>{
                                return(
                                  <option key={index} value={items.cat_name}>{items.cat_name}</option>

                                ) 
                              })
                              :
                              <option >No Category Found...</option>
                            }
                           </select>
                         </div>
                       </div>
 
                     <div className="col-lg-6">
                         <div className="mb-3">
                           <label
                             className="form-label"
                             htmlFor="basic-default-company"
                           >
                            Sub Category name
                           </label>
                           <input
                            value={subCategoryName}
                             type="text"
                             className="form-control"
                             id="basic-default-company"
                             placeholder="Enter Sub Category name..."
                             onChange={(e)=> setSubCategoryName(e.target.value)}
                             
                           />
                         </div>
                       </div>
 
             
   
                     </div>
 
        </Modal.Body>
        <Modal.Footer>
        <button
                type="button"
                className="btn btn-dark"
                onClick={()=> {
                  setShowClothSubCategoryModal()
                  refetchSubCategory()
                  }}
              >
                Close
              </button>
              {
                  isLoading ?
                  <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <button type="button" className="btn btn-outline-primary" onClick={()=> updateSubCategory()}>
                Update
              </button>
              }
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default UpdateSubClothCategory