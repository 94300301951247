import React from 'react';
import Modal from 'react-bootstrap/Modal';
import orderBox from '../../../Images/orderDisputeSign.gif';
import MultipleOrderSubmissionSection from '../../../Api/OrderSection/MultipleOrderSubmitSection';

const DisputeMultipleOrderModal = ({
    disputeAction,orderID,index,removeSpecificeOrder ,
    showMultiSelectDisputeModal,setShowMultiSelectDisputeModal, 
    multiSelectApproveOrder , setMultiSelectApproveOrders,removeMultiOrders,
    autoFetch , setAutoFetch , showMultiCheckButton, setShowMultiCheckButton,
    
}) => {
  const { mutate: disputedOrdersByStatus, isLoading } =  MultipleOrderSubmissionSection.useDisputedOrderStatus();


    function updateOrderStatus() {


  
        disputedOrdersByStatus(({disputeAction,multiSelectApproveOrder,index,removeSpecificeOrder, removeMultiOrders}), {
          onMutate: () => {
    
          },
          onSettled: () => {
            
        setAutoFetch(!autoFetch)
        setShowMultiCheckButton(!showMultiCheckButton);
        setMultiSelectApproveOrders('');
          },
        })
    }
  return (
    <>
    <Modal
        show={showMultiSelectDisputeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>
          <i className="fa-solid fa-box fs-3"/> &nbsp; Order#
        {orderID}     
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center text-black fs-4">
        Want to dispute these orders?  <img className="img-fluid fs-5"src={orderBox} alt="orderBox" width={40}/>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-dark"  onClick={()=> setShowMultiSelectDisputeModal(!showMultiSelectDisputeModal)}>
            Close
          </button>
        <button className="btn btn-outline-primary" onClick={updateOrderStatus}>
         {isLoading ? "...":"Okay ?"} 
        </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default DisputeMultipleOrderModal