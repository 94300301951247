import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";


function approveMultipleOrdersByStatus({multiSelectApproveOrder,orderAction,index,removeSpecificeOrder, removeMultiOrders}){
  const multiOrderObj ={
    order_ids : multiSelectApproveOrder,
    status : orderAction
  }
    
    let resultForMultipleOrders = axios.post(`${process.env.REACT_APP_BASE_URL}update_multiple_order_status`,multiOrderObj)
       .then((res)=>{
         if(res.data.status === '200'){
             toast.info(`Orders moved to ${orderAction} orders!`)
            removeMultiOrders()
           }
           else {
               throw new toast.error("Can't submit!");
           }
       })
     .catch((error)=>{

       if(error){
         toast.warn("Something went wrong, please try again in a while")
       }
       })
 
       
 
 
   return resultForMultipleOrders;
 }
 
 
 function disputedOrdersByStatus({disputeAction ,multiSelectApproveOrder,index,removeSpecificeOrder,removeMultiOrders}){
    const multiOrderObj ={
        order_ids : multiSelectApproveOrder,
        status : "disputed"
    }
 let resultForDisputedOrders = axios.post(`${process.env.REACT_APP_BASE_URL}update_multiple_order_status`,multiOrderObj)
   .then((res)=>{
     if(res.data.status === '200'){
         toast.error(`Order disputed from ${disputeAction}!`)
        //  removeSpecificeOrder(index)
        removeMultiOrders()
       }
       else {
           throw new toast.error("Can't submit!");
       }
   })
 .catch((error)=>{
   if(error){
     toast.warn("Something went wrong, please try again in a while")
   }
   })
 
 return resultForDisputedOrders;
 
 }
 
 
 const useApproveMultipleOrderStatus = ()=>{
   return useMutation(approveMultipleOrdersByStatus)
 }
 
 const useDisputedOrderStatus = ()=>{
   return useMutation(disputedOrdersByStatus)
 }
 
 
 
 
   const MultipleOrderSubmissionSection ={
     useApproveMultipleOrderStatus,
     useDisputedOrderStatus,
 }
 
 export default MultipleOrderSubmissionSection;