import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";


function getAllTextileItems(){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}get_item_name_and_id`)
  
}

function getAllTextileReceiveItems(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_item_receivings?page=${pages}`)
 }

 function getTextileReceiveItemByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_item_receivings_by_id/${ID}`,)
 }
 
function textileReceiveItemsSubmission(receiveItemObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_item_receivings`, receiveItemObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Received Items Submit!")
        return res.data
      }
      else {
        throw new toast.error("Can't item submit");
      }
    })
  .catch((error)=>{
    if(error){
      toast.warn("Something went wrong, please try again in a while")
    }
    })
}

function updateTextileReceivedItem({receiveItemObj, itemReceivingID}){
  
   axios.post(`${process.env.REACT_APP_BASE_URL}update_item_receiving_by_id/${itemReceivingID}`, receiveItemObj)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Item Updated!")
  }
  else {
    throw new toast.error("Can't submit items!");
  }
  })
  .catch((error)=>{
    if(error)
    {
      toast.warn("Something went wrong, please try again in a while")
    }
    
 
})

}

function deleteTextileReceiveItem({id,removeTextileReceiveItemFromSheet,index}){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_item_receiving_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("Item Removed")
      removeTextileReceiveItemFromSheet(index)

      }
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}



const useSubmitTextileReceiveItems = ()=>{
    return useMutation(textileReceiveItemsSubmission)
}

const useUpdateTextileReceivedItems = ()=>{
  // const queryClient = useQueryClient()
  return useMutation(updateTextileReceivedItem , {
  //   onSuccess: ()=>{
  //  queryClient.invalidateQueries({ queryKey: ['all_textile_items'] })
  //   }
  })
}

const useDeleteTextileReceiveItem = ()=>{
  return useMutation( deleteTextileReceiveItem)
}
const TextileReceiveSectionEndPoint ={
    getAllTextileItems,
    useSubmitTextileReceiveItems,
    useUpdateTextileReceivedItems,
    useDeleteTextileReceiveItem,
    getAllTextileReceiveItems,
    getTextileReceiveItemByID
}

export default TextileReceiveSectionEndPoint;