import { useQuery } from "react-query";
import ReadMoreReact from "read-more-react";
import React, { useState, useEffect } from "react";
import UpdateEventModal from "../Modals/UpdateEventModal";
import EventSectionEndPoint from "../../Api/EventSection/EventSectionEndPoint";

const EventSheet = () => {
  const { mutate: deleteEvent } = EventSectionEndPoint.useDeleteEvent();
  const { mutate: liveEvent, isLoading } = EventSectionEndPoint.useLiveEvent();
  const { mutate: UnLiveEvent } = EventSectionEndPoint.useUnLiveEvent();

  

  const [allEvents, setAllEvents] = useState([]);
  const [eventID, setEventID] = useState("");
  const [eventStatus, setEventStatus] = useState("");

  const [progress, setProgress] = useState(0);

  // Hook for pagination:
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState("");

  const[username , setUsername] = useState('');
  const[eventName , setEventName] = useState('');
  const[status , setStatus] = useState('');
  const[date , setDate] = useState('');

  const [filterButton , setFilterButton] = useState(false);


  const [filteredCount, setFilteredCount] = useState(0);

  const resultsPerPage = 20;
  // function for getting page next:
  const handleNextPage = () => {
    setPages((prevPage) => prevPage + 1);
  };
  // function for getting previous page:
  const handlePrevPage = () => {
    setPages((prevPage) => Math.max(prevPage - 1, 1));
  };

  const totalResults = count || 0;
  const startResult = (pages - 1) * resultsPerPage + 1;
  const endResult = Math.min(pages * resultsPerPage, totalResults);

  // Getting all events function:
  useQuery(["all_events", pages],(_) => EventSectionEndPoint.getAllEvents(pages),
    {
      onSuccess: (data) => {
        setAllEvents(data.data.Events);
        setCount(data.data.TotalCount);
      },
      onError: (err) => {
        return err;
      },
    }
  );

  const submitLiveEvent = () => {
    var formdata = new FormData();
    formdata.append("event_live", "true");
    setProgress(100);
    liveEvent(
      { formdata, eventID },
      {
        onMutate: () => {},
        onSettled: () => {},
      }
    );
  };

  const submitUnLiveEvent = () => {
    var formdata = new FormData();
    formdata.append("event_live", "false");
    setProgress(100);
    UnLiveEvent(
      { formdata, eventID },
      {
        onMutate: () => {},
        onSettled: () => {},
      }
    );
  };

  function removeEventFromSheet(index) {
    setAllEvents((prevState) => {
      const events = [...prevState];
      events.splice(index, 1);
      return events;
    });
  }

  function delEvent(id, index) {
    deleteEvent({ id, removeEventFromSheet, index });
  }

  function AllEvents({ items, index }) {
    return (
      <tr key={index} className="text-center">
        <td>{allEvents.length - index}</td>
        <td>{items.username}</td>
        <td>{items.added_by}</td>
        <td>{items.event_name}</td>
        <td>
          <img
            data-bs-toggle="tooltip"
            data-popup="tooltip-custom"
            data-bs-placement="top"
            className="avatar avatar-lg"
            src={`${process.env.REACT_APP_IMAGE_URL}${items.event_image}`}
            alt=""
            width={100}
            style={{ borderRadius: "10%" }}
          />
        </td>
        <td>
          <ReadMoreReact text={items.description} min={5} ideal={20} max={30} />
        </td>

        <td>
          {items.event_live === "false" ? (
            <span className="badge bg-label-danger me-1">Not Live</span>
          ) : (
            <span className="badge bg-label-primary me-1">Live</span>
          )}
        </td>

        <td>{items.Idate}</td>
        <td>
          <div className="dropdown">
            <button
              type="button"
              className="btn p-0 dropdown-toggle hide-arrow"
              data-bs-toggle="dropdown"
            >
              <i className="bx bx-dots-vertical-rounded" />
            </button>
            <div className="dropdown-menu">
              <button
                type="button"
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#updateEventModal"
                onClick={() => setEventID(items.event_id)}
              >
                <i className="bx bx-edit-alt me-1" /> Edit
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  delEvent(items.event_id, index);
                }}
              >
                <i className="bx bx-trash me-1" /> Delete
              </button>
            </div>
          </div>
        </td>
        <td>
          {items.event_live === "false" ? (
            <button
              className="btn btn-outline-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#eventLiveModal"
              onClick={() => {
                setEventID(items.event_id);
                setEventStatus(items.event_live);
                setProgress(0)

              }}
            >
              <i className="fa-solid fa-tower-broadcast"></i>
              &nbsp;&nbsp; Live Event
            </button>
          ) : (
            <button
              className="btn btn-outline-warning btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#eventLiveModal"
              onClick={() => {
                setEventID(items.event_id);
                setEventStatus(items.event_live);
                setProgress(0)
              }}
            >
              <i className="fa-solid fa-tower-broadcast"></i>
              &nbsp;&nbsp; Already Live
            </button>
          )}
        </td>
      </tr>
    );
  }

  const filterEvents =  allEvents.length > 0 && 
  username !== "" && eventName === "" && status === "" && date === "" 
  ? allEvents.filter((items) => items.username === username)
  :
  username === "" && eventName !== "" && date === "" && status === ""
  ? allEvents.filter((items) => items.event_name === eventName)
  :
  username === "" && eventName === "" && date === "" && status !== ""
  ? allEvents.filter((items) => items.event_live === status)
  :
  username === "" && eventName === "" && date !== "" && status === ""
  ? allEvents.filter((items) => items.Idate === date)
  :

  (username !== "" && eventName !== "") && date === "" && status === ""
  ? allEvents.filter((items) => (items.username === username && items.event_name === eventName))
  :
  (username !== "" && status !== "") && eventName === "" && date === ""
  ? allEvents.filter((items) => (items.username === username && items.event_live === status))
  :
  (username !== "" && date !== "") && eventName === "" && status === ""
  ? allEvents.filter((items) => (items.username === username && items.Idate === date))
  :
  (eventName !== "" && status !== "") && date === "" && username === ""
  ? allEvents.filter((items) => (items.event_name === eventName && items.event_live === status))
  :
  (eventName !== "" && date !== "") && username === "" && status === ""
  ? allEvents.filter((items) => (items.event_name === eventName && items.Idate === date))
  :
  (status !== "" && date !== "") && eventName === "" && username === ""
  ? allEvents.filter((items) => (items.event_live === status && items.Idate === date))

  : allEvents;

  useEffect(() => {
    setFilteredCount(filterEvents.length);
  }, [filterEvents])
  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        {/* Content wrapper */}
        <div className="content-wrapper">
          {/* Content */}
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">Event Sheet</span>
            </h4>

            <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Username*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Username..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setUsername(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Event Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Event name..."
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setEventName(e.target.value)}
                          />
                      </div>
                    
                      <div className="col-lg-3">
                        <label
                          className="form-label fw-bold"
                        >
                          Event (Status)*
                        </label>
                        <select
                            id="inputGroupSelect01"
                            className = {"form-select form-select-sm border border-primary"}
                            onChange={(e)=>setStatus(e.target.value) }
                          >
                            <option value="">Choose...</option>
                            <option value="false">Not Live</option>
                            <option value="true">Live</option>

                          </select>


                      </div>
                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }
            {/* Basic Bootstrap Table */}
            <div className="card">
              <h5 className="card-header">Event Sheet</h5>
              <div className="table-responsive text-nowrap">
                {filterEvents && filterEvents.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>Username</th>
                        <th>Added By</th>
                        <th>Event Name</th>
                        <th>Image</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Actions</th>
                        <th>Event Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 ">
                      {filterEvents?.map((items, index) => {
                        return <AllEvents items={items} index={index} />;
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
                )}

                <div className="p-3">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handlePrevPage}
                    disabled={pages === 1}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handleNextPage}
                    disabled={totalResults <= endResult}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                  <p>
                    Showing {startResult} - {endResult} of {filteredCount}{" "}
                    results - total :&nbsp;&nbsp;{count}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* / Content */}
        </div>
        {/* Content wrapper */}


{
  eventStatus === "false"?
  <>
    {/* Event Live Modal */}
  <div className="col-lg-4 col-md-6">
    <div className="mt-3">
      {/* Modal */}
      <div
        className="modal fade"
        id="eventLiveModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="modalCenterTitle">
                Do you want to confirm the publication of this event?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-dark"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="buy-now">
                {isLoading ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <button
                    class="btn btn-danger btn-buy-now"
                    onClick={submitLiveEvent}
                  >
                    <i className="fa-solid fa-bell"></i>&nbsp;&nbsp; Live
                    Event
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Event Live Modal */}
  </>

  :

  <>
        {/* Event Un-Live Modal */}
        <div className="col-lg-4 col-md-6">
          <div className="mt-3">
            {/* Modal */}
            <div
              className="modal fade"
              id="eventLiveModal"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title fw-bold" id="modalCenterTitle">
                      Do you want to confirm the publication of this event?
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated bg-dark"
                        role="progressbar"
                        style={{ width: `${progress}%` }}
                        aria-valuenow={progress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="buy-now">
                      {isLoading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button
                          class="btn btn-danger btn-buy-now"
                          onClick={submitUnLiveEvent}
                        >
                          <i className="fa-solid fa-bell-slash"></i>&nbsp;&nbsp; Un-Live
                          Event
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Event Un-Live Modal */}
        </>
}
      


        <UpdateEventModal eventID={eventID} />
      </div>
    </>
  );
};

export default EventSheet;
