import {useQuery} from 'react-query';
import React,{useState , useEffect} from "react";
import UpdateTextileReceivingItem from "../Modals/UpdateTextileReceivingItem";
import TextileReceiveSectionEndPoint from "../../../Api/TextileReceiveSection/TextileReceiveSectionEndPoint";

const ItemReceivingSheet = () => {
  const {mutate:deleteTextileReceiveItem } = TextileReceiveSectionEndPoint.useDeleteTextileReceiveItem();
  const[getTextileReceiveItems , setTextileReceiveItems] = useState([]);
  const[textileReceiveItemID , setTextileReceiveItemID] = useState('');

  const[showItemReceivingModal , setShowItemRecevingModal] = useState(false);

  const[itemName , setItemName] = useState('');
  const[unitName , setUnitName] = useState('');
  const[billStatus , setBillStatus] = useState('');
  const[date , setDate] = useState('');

  const [filterButton , setFilterButton] = useState(false);




      // Hook for pagination:
      const[pages , setPages] = useState(1);
      const[count , setCount] = useState('');
    
      const [filteredCount, setFilteredCount] = useState(0);
    
    
    
      const resultsPerPage = 20;
    // function for getting page next:
      const handleNextPage = () => {
        setPages((prevPage) => prevPage + 1);
      };
    // function for getting previous page:
      const handlePrevPage = () => {
        setPages((prevPage) => Math.max(prevPage - 1, 1));
      };
  
      const totalResults = count|| 0;
      const startResult = (pages - 1) * resultsPerPage + 1;
      const endResult = Math.min(pages * resultsPerPage, totalResults);

    // Getting all textile items function:
  const {refetch} =   useQuery(["all_textile_receive_items", pages], _=>TextileReceiveSectionEndPoint.getAllTextileReceiveItems(pages), {
      onSuccess: (data) => {
        setTextileReceiveItems(data.data.Items);
        setCount(data.data.TotalCount);
      },
      onError: (err) => {
        return err;
      },
    })


    function removeTextileReceiveItemFromSheet(index) {
      setTextileReceiveItems((prevState) => {
        const textileReceiveItems = [...prevState];
        textileReceiveItems.splice(index, 1);
        return textileReceiveItems;
      });
    }

    function delTextileReceiveItem(id,index){
      deleteTextileReceiveItem({id,removeTextileReceiveItemFromSheet, index})
    }

    function openModal(id){
      setShowItemRecevingModal((p)=>!p)
      setTextileReceiveItemID(id)
       
    }


    function TextileReceiveItems({items , index}){
      return (
        <tr key={index}className="text-center">
          <td>{getTextileReceiveItems.length - index}</td>
          <td>{items.username}</td>
          <td>{items.item_name}</td>
          <td>{items.item_price}</td>
          <td>{items.unit_name}</td>
          <td>{items.quantity}</td>
          <td>{items.total_bill}</td>
          <td>{items.paid}</td>
          <td>{items.gst}</td>
          <td>{items.other_charges_name}</td>
          <td>{items.other_charges}</td>
          <td>{items.shipping_charges}</td>
          <td>{items.Idate}</td>
    <td>
    <div className="dropdown">
      <button
        type="button"
        className="btn p-0 dropdown-toggle hide-arrow"
        data-bs-toggle="dropdown"
      >
        <i className="bx bx-dots-vertical-rounded" />
      </button>
      <div className="dropdown-menu">
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>openModal(items.item_receivings_id)}
        >
          <i className="bx bx-edit-alt me-1" /> Edit
        </button>
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>{
            delTextileReceiveItem(items.item_receivings_id,index)
          }}
        >
          <i className="bx bx-trash me-1" /> Delete
        </button>
      </div>
    </div>
  </td>
</tr>

      )

    }

    const filterTextileItemReceiving =  getTextileReceiveItems.length > 0 && 
    unitName !== "" && itemName === "" && billStatus === "" && date === "" 
    ? getTextileReceiveItems.filter((items) => items.unit_name === unitName)
    :
    unitName === "" && itemName !== "" && date === "" && billStatus === ""
    ? getTextileReceiveItems.filter((items) => items.item_name === itemName)
    :
    unitName === "" && itemName === "" && date === "" && billStatus !== ""
    ? getTextileReceiveItems.filter((items) => items.bill_paid === billStatus)
    :
    unitName === "" && itemName === "" && date !== "" && billStatus === ""
    ? getTextileReceiveItems.filter((items) => items.Idate === date)
    :

    (unitName !== "" && itemName !== "") && date === "" && billStatus === ""
    ? getTextileReceiveItems.filter((items) => (items.unit_name === unitName && items.item_name === itemName))
    :
    (unitName !== "" && billStatus !== "") && itemName === "" && date === ""
    ? getTextileReceiveItems.filter((items) => (items.unit_name === unitName && items.paid_status === billStatus))
    :
    (unitName !== "" && date !== "") && itemName === "" && billStatus === ""
    ? getTextileReceiveItems.filter((items) => (items.unit_name === unitName && items.Idate === date))

    :
    (itemName !== "" && billStatus !== "") && date === "" && unitName === ""
    ? getTextileReceiveItems.filter((items) => (items.item_name === itemName && items.paid_status === billStatus))
    :
    (itemName !== "" && date !== "") && unitName === "" && billStatus === ""
    ? getTextileReceiveItems.filter((items) => (items.item_name === itemName && items.Idate === date))
    :
    (billStatus !== "" && date !== "") && itemName === "" && unitName === ""
    ? getTextileReceiveItems.filter((items) => (items.paid_status === billStatus && items.Idate === date))

    : getTextileReceiveItems;


    useEffect(() => {
      setFilteredCount(filterTextileItemReceiving.length);
    }, [filterTextileItemReceiving])
  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper mb-5">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">Textile Receive Item Sheet </span>
            </h4>

            <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Item Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Item name..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setItemName(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Unit Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Unit name..."
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setUnitName(e.target.value) }
                          />
                      </div>
                    
                      <div className="col-lg-3">
                        <label
                          className="form-label fw-bold"
                        >
                          Paid(Status)*
                        </label>
                        <select
                            id="inputGroupSelect01"
                            className = {"form-select form-select-sm border border-primary"}
                            value={billStatus}
                            onChange={(e)=>setBillStatus(e.target.value) }
                          >
                            <option value="">Choose...</option>
                            <option value="Paid">Paid</option>
                            <option value="Not Paid">Not Paid</option>
                            <option value="Outstanding">Outstanding</option>

                          </select>


                      </div>
                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }

            <div className="card">
              <h5 className="card-header">Receive Item Sheet</h5>
              <div className="table-responsive text-nowrap">
                { filterTextileItemReceiving && filterTextileItemReceiving.length > 0 ?
                      <table className="table">
                      <thead>
                        <tr className="text-center">
                          <th>#</th>
                          <th>Username</th>
                          <th>Item Name</th>
                          <th>Item Price</th>
                          <th>Unit Name</th>
                          <th>Quantity</th>
                          <th>Total Bill</th>
                          <th>Paid</th>
                          <th>Gst</th>
                          <th>Other Charges Name</th>
                          <th>Other Charges</th>
                          <th>Shipping Charges</th>
                          <th>Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                          {
                            filterTextileItemReceiving?.map((items , index)=>{
                                return(
                                  <TextileReceiveItems items={items} index={index}/>
                                )
                            })
                          }
                      

                      </tbody>
                    </table>
                    :

                    <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
              }
                
                <div className="p-3">
              <button className="btn btn-outline-primary btn-sm" onClick={handlePrevPage} disabled={pages === 1}>
                <i className="fa-solid fa-arrow-left"></i>
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-outline-primary btn-sm" onClick={handleNextPage} disabled={totalResults <= endResult}>
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
                <p >Showing {startResult} - {endResult} of {filteredCount} results  -  total :&nbsp;&nbsp;{count}</p>
              </div>

              </div>
            </div>
          </div>

          {/*Update Textile Items Modal */}
          {
           showItemReceivingModal === true && (
            <UpdateTextileReceivingItem 
            textileReceiveItemID = {textileReceiveItemID}
            showItemReceivingModal = {showItemReceivingModal}
            setShowItemRecevingModal = {setShowItemRecevingModal}
            refetchItemReceiving = {refetch}
            />
           ) 
          }
     
          {/* *Update Textile Items Modal */}
        </div>
      </div>

    </>
  )
}

export default ItemReceivingSheet