import {useQuery} from 'react-query';
import React, {useState , useRef} from 'react';
import StaffSectionEndPoint from '../../Api/StaffSection/StaffSection';

const UpdateStaffModal = ({staffID}) => {
  const {mutate:updateStaffMember , isLoading } = StaffSectionEndPoint.useUpdateStaffMember();
  const fileInputRef = useRef(null);

  const[ID , setStaffID] = useState('')
  const[staffMemName ,setStaffMemName] = useState('');
  const[staffPhone , setStaffPhone] = useState('');
  const[staffNIC , setStaffNIC] = useState('');
  const[staffEmail , setStaffEmail] = useState('');
  const[staffSalary , setStaffSalary] = useState('');
  const[staffImage , setStaffImage] = useState('');
  const[staffDaysPerWeek , setStaffDaysWeek] = useState('');

    // Getting Specific staff member:
      useQuery(["getStaffByID", staffID], _=> StaffSectionEndPoint.getStaffByID(staffID), {
        enabled : staffID !== null || staffID !== '' || staffID !== undefined ,
        onSuccess: (data) => {
          setStaffID(data.data.Staff.staff_id)
          setStaffMemName(data.data.Staff.staff_name)
          setStaffSalary(data.data.Staff.staff_salary)
          setStaffPhone(data.data.Staff.staff_phone)
          setStaffEmail(data.data.Staff.staff_email)
          setStaffDaysWeek(data.data.Staff.days_per_week)
          setStaffImage(data.data.Staff.staff_image)
          setStaffNIC(data.data.Staff.cnic)
        },
        onError: (err) => {
          return err;
        },
        refetchOnWindowFocus: false,
    
      })
  

    const updateStaff = ()=>{

      var formdata = new FormData();
      formdata.append("staff_name",staffMemName);
      formdata.append("staff_salary", staffSalary);
      formdata.append("staff_phone", staffPhone);
      formdata.append("staff_email", staffEmail);
      formdata.append("days_per_week", staffDaysPerWeek);
      formdata.append("staff_image",staffImage);
      formdata.append("cnic", staffNIC);

      
      updateStaffMember(({formdata, ID}) , {
        onMutate: () => {
        },
        onSettled: () => {
          setStaffMemName('');
          setStaffPhone('');
          setStaffNIC('');
          setStaffEmail('');
          setStaffSalary('');
          setStaffDaysWeek('');

          fileInputRef.current.value = '';
        },

      
      })
  
    }
  return (
    <>
      <div
        className="modal fade"
        id="updateStaffModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel2">
                Update Staff
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname"
                  >
                    Staff Member Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-fullname"
                    placeholder="Enter Member Name"
                    value={staffMemName}
                    onChange={(e)=> setStaffMemName(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname01"
                  >
                    Staff Member Phone
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname01"
                    placeholder="Enter Staff Name"
                    value={staffPhone}
                    onChange={(e)=> setStaffPhone(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-message01"
                  >
                    Staff Member CNIC
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname05"
                    placeholder="Enter Member CNIC"
                    value={staffNIC}
                    onChange={(e)=> setStaffNIC(e.target.value)}
                  />
                </div>

                <div className="col-lg-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname02"
                  >
                    Staff Member Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="basic-default-fullname02"
                    placeholder="Enter Member Email"
                    value={staffEmail}
                    onChange={(e)=> setStaffEmail(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label
                    className="form-label"
                    htmlFor="basic-default-fullname03"
                  >
                    Staff Member Salary
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-fullname03"
                    placeholder="Enter Salary"
                    value={staffSalary}
                    onChange={(e)=> setStaffSalary(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Member Image
                  </label>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="form-control"
                    id="basic-default-company"
                    onChange={(e)=> setStaffImage(e.target.files[0])}
                  />
                    <span className='text-warning' onClick={()=>{window.open(`${process.env.REACT_APP_IMAGE_URL}${staffImage}`)}} target="_blank" style={{cursor:"pointer"}}>View Image</span>
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-message">
                    Days Per Week
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-fullname04"
                    placeholder="Enter Days Per Week"
                    value={staffDaysPerWeek}
                    onChange={(e)=> setStaffDaysWeek(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* {
                       isLoading ?
                       <div className="spinner-border text-primary" role="status">
                       <span className="visually-hidden">Loading...</span>
                     </div>
                     : */}
              <button type="button" className="btn btn-outline-primary" onClick={updateStaff}>
                Update
              </button>
              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStaffModal;
