import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import React, { useState , useEffect } from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
import TextileSectionEndPoint from "../../../Api/TextileSection/TextileSectionEndPoint";

const UpdateTextileItems = ({ textileItemID , showUpdateTextileModal ,setShowTextileModal , refetchTextileItem}) => {
  const { mutate: updateTextileItemsSubmission, isLoading } = TextileSectionEndPoint.useUpdateTextileItems();

  // Update TextileItems Hooks;
  const [itemID, setItemID] = useState("");
  const [itemName, setItemName] = useState("");
  const [unitType, setUnitType] = useState("");
  const [costPerUnit, setCostPerUnit] = useState("");

  const [autoFetch , setAutoFetch] = useState(false);

  const[userData , setUserData] = useState('');



  // Getting Specific textile items:
  useQuery(["getTextileItemByID", textileItemID],(_) => TextileSectionEndPoint.getTextileItemByID(textileItemID),
    {
      enabled: !autoFetch,
      onSuccess: (data) => {
        setItemID(data.data.Item.item_id);
        setItemName(data.data.Item.item_name);
        setUnitType(data.data.Item.unit_type);
        setCostPerUnit(data.data.Item.price_per_unit);
        setAutoFetch(true)
      },
      onError: (err) => {
        return err;
      },
    }
  );


  function updateTextileItems() {
    const textileItemObj = {
      role_id :userData.role_id,
      user_id:userData.id,
      added_by:userData.username,
      item_name: itemName,
      unit_type: unitType,
      price_per_unit: costPerUnit,
    };

    updateTextileItemsSubmission({ textileItemObj, itemID},
      {
        onMutate: () => {

        },
        onSettled: () => {
          setItemName("");
          setUnitType("");
          setCostPerUnit("");
        },
      }
    );

  }

  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
   
  }, [])
  
  return (
    <>
      <Modal show={showUpdateTextileModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Update Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label fw-bold" htmlFor="basic-default-company">
                  Item name*
                </label>
                <input
                  type="text"
                  value={itemName}
                  className="form-control"
                  id="basic-default-company"
                  placeholder="Enter Item name..."
                  onChange={(e) => setItemName(e.target.value)}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label fw-bold" htmlFor="basic-default-email">
                  Unit Type*
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type="text"
                    value={unitType}
                    id="basic-default-email"
                    className="form-control"
                    placeholder="Enter Unit Type..."
                    aria-label="john.doe"
                    aria-describedby="basic-default-email2"
                    onChange={(e) => setUnitType(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label fw-bold" htmlFor="basic-default-phone">
                  Price per unit*
                </label>
                <input
                  type="number"
                  value={costPerUnit}
                  id="basic-default-phone"
                  className="form-control phone-mask"
                  placeholder="Enter price per unit..."
                  onChange={(e) => setCostPerUnit(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-dark" onClick={()=> {
            setShowTextileModal()
            refetchTextileItem()
            }}>
            Close
          </button>

          {isLoading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => updateTextileItems()}
            >
              Update
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateTextileItems;
