import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useMutation , useQueryClient} from 'react-query';


function getAllEvents(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_events?page=${pages}`)
  
}

 function getAllEventsByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_event_by_id/${ID}`,)
 }

 
function eventsSubmission(EventObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_event`, EventObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Event Submit!")
          return res.data;
        }
        else {
            throw new toast.error("Can't submit!");
        }
    })
  .catch((error)=>{
    if(error){
      toast.warn("Something went wrong, please try again in a while")
    }
    })
}

function updateEvents({formdata ,eveID }){
  
   return axios.post(`${process.env.REACT_APP_BASE_URL}update_event_by_id/${eveID}`, formdata)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Event Updated!")
  }
  else {
    throw new toast.error("Can't submit!");
  }
  })
  .catch((error)=>{
    if(error)
    {
      toast.warn("Something went wrong, please try again in a while")
    }
    
 
})

}

function liveEvent({formdata ,eventID }){
  return axios.post(`${process.env.REACT_APP_BASE_URL}update_event_by_id/${eventID}`, formdata)
 .then((res)=>{
   if(res.data.status === '200'){
     toast.info("Event Live!")
     return res.data
 }

 else {
   throw new toast.error("Can't submit!");
 }
 })
 .catch((error)=>{
   if(error)
   {
     toast.warn("Something went wrong, please try again in a while")
   }
   

})

}
function UnLiveEvent({formdata ,eventID }){
  return axios.post(`${process.env.REACT_APP_BASE_URL}update_event_by_id/${eventID}`, formdata)
 .then((res)=>{
   if(res.data.status === '200'){
     toast.info("Event Un-Live!")
     return res.data
 }

 else {
   throw new toast.error("Can't submit!");
 }
 })
 .catch((error)=>{
   if(error)
   {
     toast.warn("Something went wrong, please try again in a while")
   }
   

})

}
function deleteEvent({id,removeEventFromSheet,index}){

    axios.post(`${process.env.REACT_APP_BASE_URL}delete_event_by_id/${id}`)
    .then((res)=>{
        if(res.data.status === '200'){
            toast.error("Event Removed")
            removeEventFromSheet(index)
            
      }
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}



const useSubmitEvents = ()=>{
    return useMutation(eventsSubmission)
}

const useUpdateEvent = ()=>{
  return useMutation(updateEvents)
}

const useDeleteEvent = ()=>{
  return useMutation(deleteEvent)
}

const useLiveEvent = ()=>{
  return useMutation(liveEvent)
}

const useUnLiveEvent = ()=>{
  return useMutation(UnLiveEvent)
}
const EventSectionEndPoint ={
    getAllEvents,
    useSubmitEvents,
    useUpdateEvent,
    useLiveEvent,
    useUnLiveEvent,
    useDeleteEvent,
    getAllEventsByID
}

export default EventSectionEndPoint;