import {useQuery} from 'react-query';
import Modal from 'react-bootstrap/Modal';
import React, {useState , useEffect} from "react";
import UsersGlobalData from '../../../GlobalCalls/UsersGlobalData.jsx';
import ClothingCategorySectionEndPoint from '../../../Api/ClothingSection/ClothingCategorySectionEndPoint.js';


const UpdateClothCategory = ({categoryID , showClothCategoryModal, setShowClothCategoryModal , refetchCategory}) => {
  const {mutate:updateCategorySubmission , isLoading } = ClothingCategorySectionEndPoint.useUpdateCategory();

  const[cateID , setCateID] = useState('');
  const[cateName , setCateName] = useState('');
  const[username ,setUsername] = useState('');
  const[categoryStatus , setCategoryStatus] = useState('');
  const[autoFetch , setAutoFetch] = useState(false);

  const[userData , setUserData] = useState('');

      
  // Getting Specific textile items:
  useQuery(["getCategoryByID", categoryID], _=> ClothingCategorySectionEndPoint.getCategoryByID(categoryID), 
  {
    enabled:!autoFetch, 
    onSuccess: (data) => {
      setCateID(data.data.Category.category_id)
      setCateName(data.data.Category.cat_name)
      setUsername(data.data.Category.username)
      setCategoryStatus(data.data.Category.cat_active)
      setAutoFetch(true)

    },
    onError: (err) => {
      return err;
    },

  })


  function updateCategory(){
    const categoryObj = {
      role_id :userData.role_id,
      user_id:userData.id,
      added_by:userData.username,
      cat_name: cateName,
      cat_active:categoryStatus,

    }

    
    updateCategorySubmission(({categoryObj,cateID}) , {
      onMutate: () => {
      },
      onSettled: () => {
        setCateName('');
        setCategoryStatus('');
      }

    
    })

  
}

useEffect(() => {
  const getocal = async() =>{
    const data = await UsersGlobalData()
   setUserData(data)
  }
  getocal()
}, [])


  return (
    <>
      <Modal show={showClothCategoryModal}
      size='lg'
      >
        <Modal.Header >
          <Modal.Title>Update Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-company"
                    >
                      Category name
                    </label>
                    <input
                      type="text"
                      value={cateName}
                      onChange={(e)=> setCateName(e.target.value)}
                      className="form-control"
                      id="basic-default-company"
                      placeholder="Enter Item name..."
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="inputGroupSelect01">
                      Category Status
                    </label>
                    <select className="form-select" id="inputGroupSelect01"
                    value={categoryStatus}
                    onChange={(e)=>setCategoryStatus(e.target.value)}
                    >
                      <option >Choose...</option>
                      <option value="true">ACTIVE</option>
                      <option value="false">IN-ACTIVE</option>
                    </select>
                  </div>
                </div>
              </div>
        </Modal.Body>
        <Modal.Footer>
        <button
                type="button"
                className="btn btn-dark"
                onClick={()=> {
                  setShowClothCategoryModal()
                  refetchCategory()
                  }}
              >
                Close
              </button>
              {
                  isLoading ?
                  <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <button type="button" className="btn btn-outline-primary" onClick={()=>updateCategory()}>
                Update
              </button>
              }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateClothCategory;
