import React from 'react';
import { CSS } from "@dnd-kit/utilities";
import {useSortable} from "@dnd-kit/sortable";
import { AiOutlineCloseCircle } from "react-icons/ai";

const SortableArticleImages = ({ file, index, handleImageDelete }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: file.id });
    const style = {
      transition,
      transform: CSS.Transform.toString(transform),
      width: "10em",
      height: "10em",
      borderRadius: "1.5em",
    };
    return (
      <div key={index} ref={setNodeRef} {...attributes} {...listeners}
     
      >
        <div
          className="float-end me-3"
          style={{ cursor: "pointer" }}
          onMouseDown={() => {
            handleImageDelete(file.id)}}
        >
          <AiOutlineCloseCircle size={25} color="red" />
        </div>
        <img className="img-fluid" src={file.url} alt="..." style={style} />
      </div>
    );
}

export default SortableArticleImages