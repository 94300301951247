import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import GraphErr from "../Images/graphErr.svg";

const PayCourierGraph = () => {
  const [financeGraphData, setFinanceGraphData] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [checkBoxVal, setCheckBoxVal] = useState("");

  const [getSpecificCourier, setSpecificCourier] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [enableSearchBtn, setEnableSearchBtn] = useState(false);

  const handleCheckbox = (val) => {
    setSelectedCheckbox(val);
    graphFunc(val);
  };

  const handleSelectCourier = (e) => {
    setSpecificCourier(e);
  };

  function graphFunc(val) {
    var formdata = new FormData();
    formdata.append("type", val);
    formdata.append("start_date", startDate);
    formdata.append("end_date", endDate);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://avantgarde.alphanitesofts.net/api/fetch_graphs_data",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "200") {
          setFinanceGraphData(result?.data);
        }
      })

      .catch((error) => console.log("error", error));
  }

  function clearFilters() {
    setStartDate("");
    setEndDate("");
    setCheckBoxVal("");
    setEnableSearchBtn(false);
  }

  // PayCourier Graph data:

  const totalReceivable = financeGraphData?.map(
    (items) => items.total_receiveable
  );
  const totalPayable = financeGraphData?.map((items) => items.total_payable);

  const nonCodAmounts = financeGraphData?.map((items) => items.non_cod);

  // individualCouriers Graph data:

  const individualCouriers = financeGraphData?.filter(
    (item) => item.courier_name === getSpecificCourier
  );

  const courierName = individualCouriers?.map((item) => item.courier_name);
  const courierTotalReceivable = individualCouriers?.map(
    (item) => item.total_receiveable
  );
  const courierTotalPayable = individualCouriers?.map(
    (item) => item.total_payable
  );

  const courierNonCOD = individualCouriers?.map((item) => item.non_cod);
  const courierDate = individualCouriers?.map((item) => item.Idate);

  const PayCourier = {
    series: [
      {
        name: "Total Receivable - Pay Courier",
        data: totalReceivable,
        color: "#1de9b6",
      },
      {
        name: "Total Payable - Pay Courier",
        data: totalPayable,
        color: "#00b0ff",
      },
      {
        name: "Bank+Stripe amounts - Pay Courier",
        data: nonCodAmounts,
        color: "#696cff",
      },
      // {
      //   name: 'Remaining amount - Pay Staff',
      //   data: amountLeftSalary,
      //   color:'#ff3d00'
      // }
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "65%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: financeGraphData?.map((data, index) => {
          return `${data.Idate} - ${data.courier_name}`;
        }),
      },
      yaxis: {
        title: {
          text: "Staff Graph",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return "Rs." + value;
          },
        },
      },
    },
  };

  const total =
    Number(courierTotalReceivable) +
    Number(courierTotalPayable) +
    Number(courierNonCOD);

  const endAngleReceivable = (totalReceivable / 5000) * 270 - 135;
  const endAnglePayable = (totalPayable / 5000) * 270 + endAngleReceivable;
  const endAngleNonCOD = (courierNonCOD / 5000) * 270 + endAnglePayable;
  const CallCourier = {
    series: [courierTotalReceivable, courierTotalPayable, courierNonCOD],
    options: {
      chart: {
        height: 400,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 0,
            size: "60%",
          },
          dataLabels: {
            name: {
              fontSize: "9px",
            },

            value: {
              fontSize: "16px",
              formatter: function (val) {
                return val; // Return the value without the percentage sign
              },
            },
            total: {
              show: true,
              label: "Total",
              formatter: function () {
                return total;
              },
            },
          },
        },
      },
      labels: [
        `Total Receivable - ${courierName}`,
        `Total Payable - ${courierName}`,
        `Non-COD amounts - ${courierName} `,
      ],
    },
  };

  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mb-5">
              <div className="card-header">
                <h4 className="fw-bold">
                  <span className="text-muted fw-light">Pay-Courier Graph</span>
                </h4>
              </div>

              <div className="d-flex">
                <div className="form-check ms-5">
                  <input
                    className="form-check-input fs-5 border border-primary"
                    type="checkbox"
                    id="inlineCheckbox1"
                    defaultValue="option1"
                    onChange={() => {
                      handleCheckbox("pay_courier");
                      setCheckBoxVal("pay_courier");

                      setCheckBoxStatus(true);
                    }}
                    checked={
                      selectedCheckbox === "pay_courier" &&
                      checkBoxStatus === true
                    }
                  />
                  <label
                    className="form-check-label fs-5"
                    htmlFor="inlineCheckbox1"
                  >
                    Pay Courier
                  </label>
                </div>
                &nbsp;&nbsp;&nbsp;
                {checkBoxStatus === true ? (
                  <>
                    <div className="col-lg-3">
                      <div className="input-group input-group-sm">
                        <label
                          className="input-group-text border border-primary fw-bold"
                          htmlFor="inputGroupSelect01"
                        >
                          From*
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-sm border border-primary"
                          id="inputGroupSelect01"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                    &nbsp;&nbsp;
                    <div className="col-lg-3">
                      <div className="input-group input-group-sm">
                        <label
                          className="input-group-text border border-primary fw-bold"
                          htmlFor="inputGroupSelect02"
                        >
                          To*
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-sm border border-primary"
                          id="inputGroupSelect02"
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                            setEnableSearchBtn(true);
                          }}
                        />
                      </div>
                    </div>
                    &nbsp; &nbsp;
                    {enableSearchBtn === true ? (
                      <>
                        <button
                          className="btn btn-sm btn-outline-primary"
                          onClick={() => graphFunc(checkBoxVal)}
                        >
                          <i className="fa fa-magnifying-glass" />
                          &nbsp;&nbsp; search
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn btn-sm btn-outline-primary"
                          onClick={() => clearFilters(checkBoxVal)}
                        >
                          <i className="fa fa-filter" />
                          &nbsp;&nbsp; clear filters
                        </button>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>

              <>
                <div className="col-lg-3 mt-3" style={{ marginLeft: "12em" }}>
                  <div className="input-group input-group-sm mb-3 ">
                    <label
                      className="input-group-text border border-primary"
                      for="inputGroupSelect01"
                    >
                      Options
                    </label>
                    <select
                      className="form-select form-select-sm border border-primary"
                      id="inputGroupSelect01"
                      onChange={(e) => handleSelectCourier(e.target.value)}
                    >
                      <option value="pay_courier">
                        {" "}
                        -------- Choose------------
                      </option>
                      <option value="Call courier">Call courier</option>
                      <option value="Leopard courier">Leopard courier</option>
                      <option value="Rider courier">Rider courier</option>
                    </select>
                  </div>
                </div>
              </>
              {individualCouriers && individualCouriers.length > 0 ? (
                <div className="card-body">
                  <ReactApexChart
                    options={CallCourier.options}
                    series={CallCourier.series}
                    type="radialBar"
                    height={350}
                  />
                </div>
              ) : (
                <div className="card-body">
                  {selectedCheckbox === "pay_courier" ? (
                    <ReactApexChart
                      options={PayCourier.options}
                      series={PayCourier.series}
                      type="bar"
                      height={300}
                    />
                  ) : (
                    <>
                      <img
                        className="img-fluid d-block mx-auto m-5"
                        src={GraphErr}
                        alt=""
                        width={300}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayCourierGraph;
