import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState, useEffect} from 'react';

const RegisterMemberForm = () => {
    const[role , setRole] = useState('');
    const[email , setEmail] = useState('');
    const[userName , setUsername] = useState('');
    const[firstName , setFirstName] = useState('');
    const[lastName , setLastName] = useState('');
    const[password , setPassword] = useState('');
    const[repassword , setRePassword] = useState('');
    const[phone , setPhone] = useState('');
    const[input , setInput] = useState(false);
    const[loading , setLoading] = useState(false);

    const submitRegMembers =  ()=>{
          setLoading(true)
          if(role && userName && email && password === repassword && phone){
              const regUserObj ={
                  username:userName,
                  password:password,
                  password_confirmation:password,
                  email:email,
                  phone_number:phone,
                  role_id:role,
                  firstname:firstName,
                  lastname:lastName,

                }
                axios.post(`${process.env.REACT_APP_BASE_URL}register`, regUserObj)
                .then((res)=>{
                setLoading(false)
                toast.info("Member Registered!")
                    setRole('')
                    setEmail('')
                    setUsername('')
                    setPassword('')
                    setRePassword('')
                    setPhone('')
                    setFirstName('')
                    setLastName('')
       
            })
            .catch((error)=>{
              if(error.response.data.status === "401"){
                setLoading(false)
                toast.warn(error.response.data.message)
              }
              else{
                setLoading(false)
                toast.warn("Something went wrong! Please try again later..")
    
              }
             
             
          })
    
              setInput(false);
        }
        else if(password !== repassword){
            toast.warn("Password doesn't match")
            setLoading(false)
             setInput(true)
    
        }
        else{
          
          toast.warn("Fill the information !")
          setLoading(false)
          setInput(true)
        }
    
    
      }



  return (
    <>
        <div className="scroll-view-component scrollbar-secondary-component">

    <div className="content-wrapper">
<div class="container-xxl flex-grow-1 container-p-y">
<h4 class="fw-bold mb-4"><span class="text-muted fw-light">Registered Members</span></h4>

<div className="row">

<div className="col-lg-12">
          <div className="card">
              <div className="card-body">

              <div className="row">

                    <div className="col-lg-4 col-sm-12">
                    <div className="form-group mb-4">
                        <label htmlFor="exampleInputEmail1">Role*</label>
                        <select  className={role === ''&& input === true?"form-select border border-danger":"form-select"}
                        
                              onChange={(e)=>setRole(e.target.value)}
                              value={role}
                              >
                            <option value="none">None</option>
                            <option value="1">Super Admin</option>
                            <option value="2">Assist Admin</option>
                            <option value="3">Admin</option>
                            <option value="4">Manager</option>
                            <option value="5">Staff</option>

                          </select>
                    </div>
                    </div>

        
                    
                    <div className="col-lg-4 col-sm-12">
                    <div className="form-group mb-4">
                        <label htmlFor="exampleInputEmail3">First name*</label>
                        <input type="text" name="firstName" value={firstName} className={firstName === ''&& input === true?"form-control border border-danger":"form-control"} id="exampleInputEmail3"  onChange={(e)=>setFirstName(e.target.value)} placeholder="Enter First Name"  />
                    </div>
                    </div>


                <div className="col-lg-4 col-sm-12">
                    <div className="form-group mb-4">
                        <label htmlFor="exampleInputEmail4">Last name*</label>
                        <input type="text" name="lastName" value={lastName} className={lastName === ''&& input === true?"form-control border border-danger":"form-control"} id="exampleInputEmail4"  onChange={(e)=>setLastName(e.target.value)} placeholder="Enter Last Name"   />
                    </div>
                    </div>
                    
                <div className="col-lg-4 col-sm-12">
                    <div className="form-group mb-4">
                        <label htmlFor="exampleInputEmail5">Email*</label>
                        <input type="email" name="Income" value={email} className={email === ''&& input === true?"form-control border border-danger":"form-control"} id="exampleInputEmail5"  onChange={(e)=>setEmail(e.target.value)} placeholder="Enter Email"  />
                    </div>
                    </div>

                    <div className="col-lg-4 col-sm-12">
                    <div className="form-group mb-4">
                  <label htmlFor="exampleInputPassword6">Username*</label>
                  <input type="text" name="Username" value={userName} className={userName === ''&& input === true?"form-control border border-danger":"form-control"} id="exampleInputPassword6"  onChange={(e)=>setUsername(e.target.value)} placeholder="Enter Username"  />
                </div>
                    </div>

             

                  
                <div className="col-lg-4 col-sm-12">
                    <div className="form-group mb-4">
                  <label htmlFor="exampleInputPassword7">Phone*</label>
                  <input type="number" name="Phone" value={phone} className={phone === ''&& input === true?"form-control border border-danger":"form-control"} id="exampleInputPassword7"  onChange={(e)=>setPhone(e.target.value)} placeholder="Enter Phone" />
                </div>
                </div>


                <div className="col-lg-4 col-sm-12">
                    <div className="form-group ">
                  <label htmlFor="exampleInputPassword8">Password*</label>
                  <input type="password" name="Price" value={password} className={password === ''&& input === true?"form-control border border-danger":"form-control"} id="exampleInputPassword8"  onChange={(e)=>setPassword(e.target.value)} placeholder="Enter Password"  />
                </div>
                </div>

                <div className="col-lg-4 col-sm-12">
                    <div className="form-group ">
                  <label htmlFor="exampleInputPassword9">Re-Password*</label>
                  <input type="password" name="Quantity" value={repassword} className={repassword === ''&& input === true?"form-control border border-danger":"form-control"} id="exampleInputPassword9"  onChange={(e)=>setRePassword(e.target.value)} placeholder="Enter Re-Password"  />
                </div>
                </div>

                
            

                </div>

                {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
       
          <button type="submit" className="btn btn-primary float-end mt-3"
          onClick={submitRegMembers}>Submit</button>
    }

              </div>

  

          </div>
        </div>

</div>
</div>
</div>
</div>
    </>
  )
}

export default RegisterMemberForm