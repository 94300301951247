import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";

function getAllCategories(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_categorys?page=${pages}`)
 }

 function getCategoryByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_category_by_id/${ID}`,)
 }
 
function categoriesSubmission(categoryObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_category`, categoryObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Category Submit!")
      }
      else {
        throw new toast.error("Can't submit!");
      }
    })
  .catch((error)=>{
        if(error){
            toast.warn("Something went wrong, please try again in a while")
        }
    
    })
}

function updateCategorySubmission({categoryObj,cateID}){
  
  axios.post(`${process.env.REACT_APP_BASE_URL}update_category_by_id/${cateID}`, categoryObj)
  .then((res)=>{
    if(res.data.status === '200'){
        toast.info("Category Update!")
    }
    else {
      throw new toast.error("Can't submit!");
    }
  })
.catch((error)=>{
      if(error){
          toast.warn("Something went wrong, please try again in a while")
      }
  
  })

}

function deleteCategory({id,removeCategoryFromSheet,index}){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_category_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("Category Removed!")
      removeCategoryFromSheet(index)
      }
     
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}




const useCategorySubmission = ()=>{
    return useMutation(categoriesSubmission)
}

const useUpdateCategory = ()=>{
  return useMutation(updateCategorySubmission)
}

const useDeleteCategory = ()=>{
  return useMutation (deleteCategory)
}
const ClothingCategorySectionEndPoint ={
    useCategorySubmission,
    useUpdateCategory,
    useDeleteCategory,
    getAllCategories,
    getCategoryByID
}

export default ClothingCategorySectionEndPoint;