import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";

function getAllVendors(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_vendors?page=${pages}`)
 }

 function getVendorByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_vendors_by_id/${ID}`,)
 }
 
function vendorSubmission(vendorObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_vendor`, vendorObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Vendors Submit!")
        return res.data
      }
      else {
        throw new toast.error("Can't submit!");
      }
    })
  .catch((error)=>{
    if(error){
      toast.warn("Something went wrong, please try again in a while")
    }
  
    })
}

function updateVendorSubmission({vendorObj,vendID}){
  
  axios.post(`${process.env.REACT_APP_BASE_URL}update_vendor_by_id/${vendID}`, vendorObj)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Vendor Updated!")
  }
  else {
    throw new toast.error("Can't submit!");
  }
  })
  .catch((error)=>{
    if(error){
        toast.warn("Something went wrong, please try again in a while")

    }

})

}

function deleteVendor({id,removeVendorsFromSheet,index}){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_vendor_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("Vendor Removed")
      removeVendorsFromSheet(index)
      }
     
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}



const useSubmitVendors = ()=>{
    return useMutation(vendorSubmission)
}

const useUpdateVendor = ()=>{
  return useMutation(updateVendorSubmission)
}

const useDeleteVendor = ()=>{
  return useMutation(deleteVendor)
}
const VendorSectionEndPoint ={
    useSubmitVendors,
    useUpdateVendor,
    useDeleteVendor,
    getAllVendors,
    getVendorByID
}

export default VendorSectionEndPoint;