import axios from "axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import Modal from 'react-bootstrap/Modal';
import React , {useState , useEffect} from 'react';
import CourierSectionEndPoint from '../../Api/CourierSection/CourierSectionEndPoint';


const UpdateOrderModal = ({orderID ,status , getOrders ,showUpdateOrderModal , setModalUpdateOrderModal}) => {
    
  const [autoFetch, setAutoFetch] = useState(false);

    const [getBanks , setGetBanks] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [bankID , setBankID] = useState();
    const [selectBankName , setSelectBankName] = useState('');

    
    const [allCourier , setAllCourier] = useState([])
    const [courierID , setCourierID] = useState('');
    const [courierName , setCourierName] = useState('');
    const [selectedCourier, setSelectedCourier] = useState('');

  
    const [fetchAllArticles, setFetchAllArticles] = useState([]);
    const [selectedArticleData, setSelectedArticleData] = useState("");

  
    const [paymentMethod , setPaymentMethod] = useState('');
    const [address , setAddress] = useState();
    const [billPaidStatus , setBillPaidStatus] = useState('');
    const [shipmentCharges , setShipmentCharges] = useState('');
    const [subTotalAmount , setSubTotalAmount] = useState('');
    const [totalAmount , setTotalAmount] = useState('');


    const [existingArticles , setExistingArticles] = useState([]);
  
  
  
  
  
    const [input, setInput] = useState(false);
    const [Loading, setLoading] = useState(false);
  
    // Shippment Data Hooks:
  
    const [phoneShip , setPhoneShip] = useState(+92);
    const [addressShip , setAddressShip] = useState('');
    const [cityShip , setCityShip] = useState('');
    const [postcodeShip , setPostcodeShip] = useState('');
    const [stateShip , setStateShip] = useState('');
  
    // Contact Data Hooks:
  
    const [phoneCon , setPhoneCon] = useState(+92);
    const [nameCon , setNameCon] = useState('');
    const [emailCon , setConEmail] = useState('');

    const options = [
        { label: "Small", value: "Small" },
        { label: "Medium", value: "Medium" },
        { label: "Large", value: "Large" },
      ];



      function fetchOrderByID(){
        axios
        .post(`${process.env.REACT_APP_BASE_URL}fetch_order_by_id/${orderID}`)
        .then((res) => {
          
          setAddress(res.data.data.shipping_address)
          setExistingArticles(res.data.data.order_data);
          setShipmentCharges(res.data.data.shipment_charges)
          setSubTotalAmount(res.data.data.sub_total)
          setTotalAmount(res.data.data.total)
          setBillPaidStatus(res.data.data.paid)
          setPaymentMethod(res.data.data.payment_method)

          setPhoneShip(res.data.data.shipping_data.phone)
          setAddressShip(res.data.data.shipping_data.address)
          setCityShip(res.data.data.shipping_data.city)
          setPostcodeShip(res.data.data.shipping_data.postcode)
          setStateShip(res.data.data.shipping_data.state)

          setPhoneCon(res.data.data.contact_data.phone)
          setNameCon(res.data.data.contact_data.name)
          setConEmail(res.data.data.contact_data.email)
          

        })
        .catch((err) => {
          return err;
        });
      }


// Getting all articles:

function getAllArticles() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}get_all_articles`)
      .then((res) => {
        setFetchAllArticles(res.data.data);
      })
      .catch((err) => {
        return err;
      });
  }


// Getting All Banks:

function getAllBanks(){
  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
  .then((res)=>{
    setGetBanks(res.data.Banks)
  })
  .catch((err)=>{
    return err
  })

}

      // Getting all couriers:
      useQuery("all_Courier",  CourierSectionEndPoint.getAllCourier,
      {
        enabled:!autoFetch,
        onSuccess: (data) => {
          setAllCourier(data.data.Couriers);
          setAutoFetch(true)
        },
        onError: (err) => {
          return err;
        },
      }
    );
  
  
    const handleSelectCourier = (e) => {
      const selectedValue = JSON.parse(e.target.value);
      setCourierID(selectedValue.id)
      setCourierName(selectedValue.cName)
      setSelectedCourier(e.target.value);
    }


    
const [articleInputs, setArticleInputs] = useState([
    { articleId: "", size: "", qty: "" },
  ]);
  
    const addArticleData = (articleInputs) => {
      const { articleId, size, qty } = articleInputs;

      const filteredPID = selectedArticleData?.sub_datas.find(
        (item) => item.size === articleInputs.size
      );
      if (
        filteredPID === undefined ||
        filteredPID === null ||
        filteredPID === ""
      ) {
        toast.error("No size found against this article!");
      } else {
        if (articleId && size && qty) {
          toast.info("Article Added!")
          setOrderData([
            ...orderData,
            {
              article_id: articleInputs.articleId,
              size: articleInputs.size,
              qty: articleInputs.qty,
              is_scanned: 0,
              pre_post: selectedArticleData.pre_post,
              p_id: filteredPID?.p_id,
              single_price: selectedArticleData.price,
              actual_single_price: selectedArticleData.price,
              total_price: selectedArticleData.price * articleInputs.qty,
              on_sale: selectedArticleData.on_sale,
            },
          ])
   
        }
      }
    }
  
    
  
  
    const addArticleInputs = () => {
      setArticleInputs([...articleInputs, { articleId: "", size: "", qty: "" }]);
    };
  
    const deleteInputs = (index) => {
      const newInputFields = [...articleInputs];
      const newOrderData = [...orderData];
      newInputFields.splice(index, 1);
      newOrderData.splice(index, 1);
  
      setArticleInputs(newInputFields);
      setOrderData(newOrderData);
  
    };
  
    const updateArticleInput = (index, field, value) => {
      if (field === "articleId") {
        const data = JSON.parse(value);
  
        const updatedInputs = [...articleInputs];
        updatedInputs[index][field] = data.item.id;
        setArticleInputs(updatedInputs);
        setSelectedArticleData(data.item);
      } else {
        const updatedInputs = [...articleInputs];
        updatedInputs[index][field] = value;
        setArticleInputs(updatedInputs);
      }
    };
  




  

    function updateOrderSubmit(){

        setLoading(true)


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");    

        let updateOrder = JSON.stringify({

          total:totalAmount, 
          sub_total: subTotalAmount,

          user_id: 1,
          role_id: 1,
          added_by: "admin",
          gst: 0,
          shipment_charges: shipmentCharges,
          manual: "false",
          delivery_method: paymentMethod,
          shipping_address: address,
          promo_code: "NULL",
          paid: billPaidStatus,
          paid_in: Number(bankID) ?Number(bankID) :"NULL" ,
          status: status,
          cod: paymentMethod,
          
          courier_id:courierID ?courierID :"NULL" ,
          payment_method: paymentMethod,
          cn:"NULL",
          dc_courier: shipmentCharges,
          ledger_description: `update order section - ${bankID}`,
          vat: 0,
          order_data: orderData.length > 0 ?  existingArticles : orderData,
          billing_data: {
            phone: phoneShip,
            address: addressShip,
            city: cityShip,
            postcode: postcodeShip,
            state: stateShip
          },
          contact_data: {
            phone: phoneCon,
            name: nameCon,
            email: emailCon
          },
          shipping_data: {
            phone: phoneShip,
            address: addressShip,
            city: cityShip,
            postcode: postcodeShip,
            state: stateShip
          }
          
        
        })

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: updateOrder,
          redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}update_order_by_id/${orderID}`, requestOptions)
        .then(response => response.json())
            .then((res)=>{
              if(res.status === '200'){              
            toast.info("Order Updated!")
            setInput(false)
            setLoading(false)
        }
        else if(res.status === '401'){
          toast.error(res.message);
          setInput(false)
          setLoading(false)
        }
         else {
           setLoading(false)
          throw new toast.error("Can't submit items!");

        }
      })
        .catch((error)=>{
          if(error)
          {
            setLoading(false)
            toast.warn("Something went wrong, please try again in a while")
          }
       
      })

   
    }


    
  useEffect(() => {
    // let totalPriceSum = orderData.reduce((sum, currentVal) => sum + currentVal.total_price, 0);
    // setSubTotalAmount(totalPriceSum);

    // const grandTotal = (totalPriceSum+ +shipmentCharges);
    // setTotalAmount(grandTotal)



    getAllArticles();
    getAllBanks();
    fetchOrderByID();
  }, []);
  return (
    <>
        <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showUpdateOrderModal}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         Update Order #{orderID}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row">
        {
            existingArticles.map((item,index) =>{
                return(
                    <>
                    <div className="col-lg-4 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-articles"
                              >
                                 Articles# {item.article_id}
                              </label>
                    <input className="form-control" type="text" value={item.title} aria-label="readonly input example" disabled/>
                    </div>


                    <div className="col-lg-4 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-articles-Qty"
                              >
                                {`Qty of ${item.title}`}
                              </label>
                    <input className="form-control" type="text" value={item.qty} aria-label="readonly input example" disabled/>
                    </div>

                    <div className="col-lg-4 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-articles-Sizes"
                              >
                                {`Sizes of ${item.title}`}
                              </label>
                    <input className="form-control" type="text" value={item.size} aria-label="readonly input example" disabled/>
                    </div>
                    
                    
                    </>
                )
       
            })
        }
                    {articleInputs.map((input, index) => (
                      <>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-message"
                          >
                            Select Articles
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) =>
                              updateArticleInput(
                                index,
                                "articleId",
                                e.target.value
                              )
                            }
                          >
                            <option>Choose...</option>
                            {fetchAllArticles && fetchAllArticles.length > 0 ? (
                              fetchAllArticles.map((item) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={JSON.stringify({ item })}
                                  >
                                    {item.title}
                                  </option>
                                );
                              })
                            ) : (
                              <option value="nothing">No Article Found!</option>
                            )}
                          </select>
                        </div>

                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="inputGroupSelect011"
                          >
                            Size
                          </label>
                          <select
                            id="inputGroupSelect011"
                            className="form-select"
                            onChange={(e) =>
                              updateArticleInput(index, "size", e.target.value)
                            }
                          >
                            <option>Choose...</option>
                            {options && options.length > 0 ? (
                              options.map((items, index) => {
                                return (
                                  <option key={index} value={items.value}>
                                    {items.label}
                                  </option>
                                );
                              })
                            ) : (
                              <option value="nothing">No Size Found!</option>
                            )}
                          </select>
                        </div>

                        <div className="col-lg-3 mb-3">
                          <label className="form-label">Qty:</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Qty..."

                            onChange={(e) =>
                              updateArticleInput(index, "qty", e.target.value)
                            }
                          />
                        </div>

                        <div
                          className="col-lg-3"
                          style={{ marginTop: "2.3em" }}
                        >
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => addArticleData(input)}
                          >
                            <i className="fa fa-plus" />
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm btn-info"
                            onClick={addArticleInputs}
                          >
                            <i className="fa fa-list" />
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteInputs(index)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                      </>
                    ))}

         

                    <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-message"
                      >
                        Payment Method
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={paymentMethod}
                        onChange={(e)=> setPaymentMethod(e.target.value)}
                      >
                        <option value="">Choose </option>
                        <option value="cod">COD</option>
                        <option value="bank">Bank</option>
                        <option value="stripe">Stripe</option>
                        <option value="Cash-In-Hand">Cash-in-Hand</option>
                      </select>
                    </div>

                          {
                            paymentMethod === "bank"?
                            <div className="col-lg-4 mb-3">
                            <label
                                className="form-label fw-bold"
                                htmlFor="basic-default-message"
                              >
                               Select Bank
                              </label>
      
                              <select className={"form-select"} aria-label="Default select example"
                              value={bankID}
                              onChange={(e)=>
                                {
                                const selectedValue = e.target.value; 
                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                setBankID(parseInt(selectedValue))
                                setSelectBankName(selectedText)
                                }
                              }
                              >
                                <option value="">Bank select menu</option>
                                {
                                  getBanks && getBanks.length > 0 ?
      
                                  getBanks?.map((item , id)=>{
                                    return(
                                      <option key={id} value={item.bank_id}>{item.bank_name}</option>
                                    )
                                  })
      
                                  :
      
                                  <option value="">No Bank Found !</option>
                                }
                                
                              </select>
                              </div>
                              :
                              paymentMethod === "cod"?
                              <div className="col-lg-4 mb-3">
                              <label htmlFor="emailBasic" className="form-label">Select Courier</label>
                              <select className="form-select" aria-label="Default select example"
                              value={selectedCourier}
                              onChange={handleSelectCourier}
                              >
                              <option   value="">Choose....</option>
                              {
                                  allCourier && allCourier.length > 0 ?
                                  
                                  
                                      allCourier?.map((courier, index)=>{
                                          return(

                                              <option key={index} value={JSON.stringify({id: courier.courier_id, cName: courier.courier_name}) }>{courier.courier_name}</option>
                                          )
                                      })
                                  :
                                  "No Courier Found!"
                              }

                              </select>
                              </div>

                              :
                              paymentMethod === "Cash-In-Hand"?
                              <div className="col-lg-4 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-cash"
                              >
                                Amount
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="basic-default-cash"
                                placeholder="Enter amount..."
                              />
                            </div>
                            :
                              null
                          }


  

                          
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Bill Paid (Status)   
                        </label>
                          <select
                            id="inputGroupSelect01"
                            className = {billPaidStatus === ''&& input === true?"form-select border border-danger":"form-select"}
                            value={billPaidStatus}
                            onChange={(e)=>setBillPaidStatus(e.target.value) }
                          >
                            <option value="">Choose...</option>
                            <option value="true">Paid</option>
                            <option value="false">Not Paid</option>

                          </select>
                      </div>


                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-shipping_address"
                    >
                     Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="basic-default-shipping_address"
                      placeholder="Enter address..."
                      value = {address}
                      onChange={(e)=> setAddress(e.target.value)}
                    />
                  </div>


                  

                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-shipment_charges"
                    >
                      Shipment Charges
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-default-shipment_charges"
                      placeholder="Enter shipment charges..."
                      value={shipmentCharges}
                      onChange={(e)=> setShipmentCharges(e.target.value)}
                    />
                  </div>
                        

                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-sub_total"
                    >
                      Sub Total
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-default-sub_total"
                      placeholder="Sub-Total amount..."
                      value={subTotalAmount}
                      onChange={(e)=>setSubTotalAmount(e.target.value)}

                    />
                  </div>


                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-total-amount"
                    >
                      Total Amount
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-default-total-amount"
                      placeholder="Total amount...."
                      value={totalAmount}
                      onChange={(e)=> setTotalAmount(e.target.value)}
                    />
                  </div>


      </div>
      </Modal.Body>
      <div className="col-xl">
                {/* <div className="card mb-4"> */}
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Shipping Info</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-fullname"
                          >
                            Phone
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="basic-icon-default-fullname"
                            placeholder="Enter phone..."
                            aria-label="John Doe"
                            aria-describedby="basic-icon-default-fullname2"
                            value={phoneShip}
                            onChange={(e)=> setPhoneShip(e.target.value)}
                          />
                      </div>
                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-company"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            id="basic-icon-default-company"
                            className="form-control"
                            placeholder="Enter city..."
                            aria-label="ACME Inc."
                            aria-describedby="basic-icon-default-company2"
                            value={cityShip}
                            onChange={(e)=> setCityShip(e.target.value)}
                          />
                      </div>

                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-email"
                          >
                            Postcode
                          </label>
                          <input
                            type="text"
                            id="basic-icon-default-email"
                            className="form-control"
                            placeholder="Enter postcode..."
                            aria-label="john.doe"
                            aria-describedby="basic-icon-default-email2"
                            value={postcodeShip}
                            onChange={(e)=> setPostcodeShip(e.target.value)}
                          />
                      </div>

                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-phone"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            id="basic-icon-default-phone"
                            className="form-control phone-mask"
                            placeholder="Enter state..."
                            aria-label="658 799 8941"
                            aria-describedby="basic-icon-default-phone2"
                            value={stateShip}
                            onChange={(e)=> setStateShip(e.target.value)}
                          />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-icon-default-message"
                      >
                        Address
                      </label>
                      <div className="input-group input-group-merge">
                        <textarea
                          id="basic-icon-default-message"
                          className="form-control"
                          placeholder="Enter address..."
                          aria-label="Hi, Do you have a moment to talk Joe?"
                          aria-describedby="basic-icon-default-message2"
                          rows={4}
                          value={addressShip}
                          onChange={(e)=> setAddressShip(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                {/* </div> */}
              </div>

              <div className="col-xl">
                {/* <div className="card mb-4"> */}
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Contact Info</h5>
                  </div>
                  <div className="card-body">
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-fullname"
                    >
                      Full Name
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="basic-icon-default-fullname"
                      placeholder="Enter full name..."
                      aria-label="John Doe"
                      aria-describedby="basic-icon-default-fullname2"
                      value={nameCon}
                      onChange={(e)=> setNameCon(e.target.value)}
                    />

                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-phone"
                    >
                      Phone No
                    </label>

                    <input
                      type="number"
                      id="basic-icon-default-phone"
                      className="form-control phone-mask"
                      placeholder="Enter phone no..."
                      aria-label="658 799 8941"
                      aria-describedby="basic-icon-default-phone2"
                      value={phoneCon}
                      onChange={(e)=> setPhoneCon(e.target.value)}
                    />
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-email"
                    >
                      Email
                    </label>

                    <input
                      type="email"
                      id="basic-icon-default-email"
                      className="form-control"
                      placeholder="Enter email..."
                      aria-label="john.doe"
                      aria-describedby="basic-icon-default-email2"
                      value={emailCon}
                      onChange={(e)=> setConEmail(e.target.value)}
                    />

                  </div>
                {/* </div> */}
              </div>
      <Modal.Footer>
      <button className="btn btn-outline-dark mt-5"  onClick={()=>{ setModalUpdateOrderModal(!showUpdateOrderModal)
    getOrders()
    }}>
            Close
          </button>

          {
                        Loading === true ?
                        <div className="spinner-border text-primary float-end mt-5" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>               
                      :
                    <button
                      type="submit"
                      className="btn btn-primary float-end mt-5"
                      onClick={updateOrderSubmit}
                    >
                      Submit
                    </button> 
        }

      </Modal.Footer>
    </Modal>

    </>
  )
}

export default UpdateOrderModal