import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";

const ManualOrderForm = () => { 

  const [getBanks , setGetBanks] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [bankID , setBankID] = useState('');
  const [selectBankName , setSelectBankName] = useState('');

  const [fetchAllArticles, setFetchAllArticles] = useState([]);
  const [selectedArticleData, setSelectedArticleData] = useState("");

  const [paymentMethod , setPaymentMethod] = useState('');
  const [address , setAddress] = useState('');
  const [billPaidStatus , setBillPaidStatus] = useState('');
  const [shipmentCharges , setShipmentCharges] = useState(0);
  const [subTotalAmount , setSubTotalAmount] = useState('');
  const [totalAmount , setTotalAmount] = useState(subTotalAmount);





  const [input, setInput] = useState(false);
  const [Loading, setLoading] = useState(false);

  // Shippment Data Hooks:

  const [phoneShip , setPhoneShip] = useState('');
  const [addressShip , setAddressShip] = useState('');
  const [cityShip , setCityShip] = useState('');
  const [postcodeShip , setPostcodeShip] = useState('');
  const [stateShip , setStateShip] = useState('');

  // Contact Data Hooks:

  const [phoneCon , setPhoneCon] = useState('');
  const [nameCon , setNameCon] = useState('');
  const [emailCon , setConEmail] = useState('');


  const options = [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ];

  // Getting all articles:

  function getAllArticles() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}get_all_articles`)
      .then((res) => {
        setFetchAllArticles(res.data.data);
      })
      .catch((err) => {
        return err;
      });
  }

  // Getting All Banks:

function getAllBanks(){
  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
  .then((res)=>{
    setGetBanks(res.data.Banks)
  })
  .catch((err)=>{
    return err
  })

}

// Order Data Article Hooks:

const [articleInputs, setArticleInputs] = useState([{ articleId: "", size: "", qty: "" }]);

  const addArticleData = (articleInputs) => {
    const { articleId, size, qty } = articleInputs;
    const filteredPID = selectedArticleData?.sub_datas?.find((item) => item.size === articleInputs.size);
    if (
      filteredPID === undefined ||
      filteredPID === null ||
      filteredPID === ""
    ) {
      toast.error("No size found against this article!");
    } 

    
    else {
      if (articleId && size && qty){

        const existingArticleIndex = orderData.findIndex((item) => item.article_id === articleId && item.p_id === filteredPID?.p_id);

        if (existingArticleIndex !== -1) {
          // Article already exists, update quantity and total price
          const updatedOrderData = [...orderData];
          const existingArticle = updatedOrderData[existingArticleIndex];
          existingArticle.qty = Number(existingArticle.qty)+ +Number(qty);
          existingArticle.total_price += selectedArticleData.price * qty;
  
          toast.info("Quantity updated for existing article!");
          setOrderData(updatedOrderData);
        }

        else{

          toast.info("Article Added!")
          setOrderData([
            ...orderData,
            {
              article_id: articleInputs.articleId,
              size: articleInputs.size,
              qty: Number(articleInputs.qty),
              is_scanned: 0,
              pre_post: selectedArticleData.pre_post,
              p_id: filteredPID?.p_id,
              single_price: selectedArticleData.price,
              actual_single_price: selectedArticleData.price,
              total_price: selectedArticleData.price * articleInputs.qty,
              on_sale: selectedArticleData.on_sale,
            },
          ])
          
        }
  
 
      }
    }
  }


// const addArticleData = () => {
//   const { articleId, size, qty } = articleInputs;
//   const filteredPID = selectedArticleData?.sub_datas.find((item) => item.size === articleInputs.size);
//   console.log("getting size ",filteredPID)

//   if (filteredPID === undefined || filteredPID === null || filteredPID === "") {
//     toast.error("No size found against this article!");
//   } else {
//     if (articleId && size && qty) {
//       const existingArticleIndex = orderData.findIndex(
//         (item) => item.article_id === articleId && item.p_id === filteredPID?.p_id
//       );

//       if (existingArticleIndex !== -1) {
//         // Article already exists, update quantity and total price
//         const updatedOrderData = [...orderData];
//         const existingArticle = updatedOrderData[existingArticleIndex];
//         existingArticle.qty += qty;
//         existingArticle.total_price += selectedArticleData.price * qty;

//         toast.info("Quantity updated for existing article!");
//         setOrderData(updatedOrderData);
//       } else {
//         // New article, add to orderData
//         toast.info("Article Added!");
//         setOrderData([
//           ...orderData,
//           {
//             article_id: articleId,
//             size: size,
//             qty: qty,
//             is_scanned: 0,
//             pre_post: selectedArticleData.pre_post,
//             p_id: filteredPID?.p_id,
//             single_price: selectedArticleData.price,
//             actual_single_price: selectedArticleData.price,
//             total_price: selectedArticleData.price * qty,
//             on_sale: selectedArticleData.on_sale,
//           },
//         ]);
//       }
//     }
//   }
// };





  


  const addArticleInputs = () => {
    setArticleInputs([...articleInputs, { articleId: "", size: "", qty: "" }]);
  };

  const deleteInputs = (index) => {
    const newInputFields = [...articleInputs];
    const newOrderData = [...orderData];
    newInputFields.splice(index, 1);
    newOrderData.splice(index, 1);

    setArticleInputs(newInputFields);
    setOrderData(newOrderData);

  };

  const updateArticleInput = (index, field, value) => {
    if (field === "articleId") {
      const data = JSON.parse(value);

      const updatedInputs = [...articleInputs];
      updatedInputs[index][field] = data.item.id;
      setArticleInputs(updatedInputs);
      setSelectedArticleData(data.item);
    } else {
      const updatedInputs = [...articleInputs];
      updatedInputs[index][field] = value;
      setArticleInputs(updatedInputs);
    }
  };




  function submitManualOrder() {
    setLoading(true)

    let manualOrder = {
      total: totalAmount,
      sub_total: subTotalAmount,
      user_id: 1,
      role_id: 1,
      added_by: "admin",
      gst: 0,
      shipment_charges: shipmentCharges,
      manual: "true",
      delivery_method: paymentMethod,
      shipping_address: address,
      promo_code: "",
      paid: billPaidStatus,
      status: "pending",
      cod: "false",
      payment_method: paymentMethod,
      ledger_description: `manual order section - ${articleInputs.articleId}`,
      vat: 0,
      order_data: orderData,
      billing_data: {
        phone: phoneShip,
        address: addressShip,
        city: cityShip,
        postcode: postcodeShip,
        state: stateShip
      },
      contact_data: {
        phone: phoneCon,
        name: nameCon,
        email: emailCon
      },
      shipping_data: {
        phone: phoneShip,
        address: addressShip,
        city: cityShip,
        postcode: postcodeShip,
        state: stateShip
      }
    
    }

    axios.post(`${process.env.REACT_APP_BASE_URL}post_order`, manualOrder)
    .then((res)=>{
      if(res.data.status === '200'){  
        toast.info("Order Submit!")
        setInput(false)
        setLoading(false)
    }
    
    else {
      throw new toast.error("Can't submit items!");
    }
  })
  .catch((error)=>{
    console.log(error)
    if(error)
      {
        setLoading(false)
        toast.warn("Something went wrong, please try again in a while")
      }
   
  })

  setOrderData([])
  setSelectedArticleData('')
  setPaymentMethod('')
  setAddress('')
  setBillPaidStatus('')
  setShipmentCharges('')
  setSubTotalAmount('')
  setTotalAmount('')

  setPhoneShip('')
  setAddressShip('')
  setCityShip('')
  setPostcodeShip('')
  setStateShip('')

  setPhoneCon('')
  setNameCon('')
  setConEmail('')



  }

  useEffect(() => {
    let totalPriceSum = orderData.reduce((sum, currentVal) => sum + currentVal.total_price, 0);
    setSubTotalAmount(totalPriceSum);

    const grandTotal = (totalPriceSum+ +shipmentCharges);
    setTotalAmount(grandTotal)



    getAllArticles();
    getAllBanks();
  }, [orderData, shipmentCharges]);



  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div class="container-xxl flex-grow-1 container-p-y">
            <h4 class="fw-bold">
              <span class="text-muted fw-light">Order Form</span>
            </h4>
            <div className="row">
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Order Form</h4>
                  <small className="text-muted float-end">Default label</small>
                </div>
                <div className="card-body">
                  <div className="row">
                    {articleInputs.map((input, index) => (
                      <>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-message"
                          >
                            Select Articles
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) =>
                              updateArticleInput(
                                index,
                                "articleId",
                                e.target.value
                              )
                            }
                            // value={input.articleId}
                          >
                            <option>Choose...</option>
                            {fetchAllArticles && fetchAllArticles.length > 0 ? (
                              fetchAllArticles.map((item) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={JSON.stringify({ item })}
                                  >
                                    {item.title}
                                  </option>
                                );
                              })
                            ) : (
                              <option value="nothing">No Article Found!</option>
                            )}
                          </select>
                        </div>

                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="inputGroupSelect011"
                          >
                            Size
                          </label>
                          <select
                            id="inputGroupSelect011"
                            className="form-select"
                            onChange={(e) =>
                              updateArticleInput(index, "size", e.target.value)
                            }
                            // value={input.size}
                          >
                            <option>Choose...</option>
                            {options && options.length > 0 ? (
                              options.map((items, index) => {
                                return (
                                  <option key={index} value={items.value}>
                                    {items.label}
                                  </option>
                                );
                              })
                            ) : (
                              <option value="nothing">No Size Found!</option>
                            )}
                          </select>
                        </div>

                        <div className="col-lg-3 mb-3">
                          <label className="form-label">Qty:</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Qty..."

                            onChange={(e) =>
                              updateArticleInput(index, "qty", e.target.value)
                            }
                          />
                        </div>

                        <div
                          className="col-lg-3"
                          style={{ marginTop: "2.3em" }}
                        >
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => addArticleData(input)}
                          >
                            <i className="fa fa-plus" />
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm btn-info"
                            onClick={addArticleInputs}
                          >
                            <i className="fa fa-list" />
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteInputs(index)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                      </>
                    ))}

                    <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-message"
                      >
                        Payment Method
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e)=> setPaymentMethod(e.target.value)}
                      >
                        <option value="">Choose </option>
                        <option value="bank">Bank</option>
                        <option value="Cash-In-Hand">Cash-in-Hand</option>
                      </select>
                    </div>

                          {
                            paymentMethod === "Bank"?
                            <div className="col-lg-4 mb-3">
                            <label
                                className="form-label fw-bold"
                                htmlFor="basic-default-message"
                              >
                               Select Bank
                              </label>
      
                              <select className={"form-select"} aria-label="Default select example"
                              value={bankID}
                              onChange={(e)=>
                                {
                                const selectedValue = e.target.value; 
                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                setBankID(selectedValue)
                                setSelectBankName(selectedText)
                                }
                              }
                              >
                                <option value="">Bank select menu</option>
                                {
                                  getBanks && getBanks.length > 0 ?
      
                                  getBanks?.map((item , id)=>{
                                    return(
                                      <option key={id} value={item.bank_id}>{item.bank_name}</option>
                                    )
                                  })
      
                                  :
      
                                  <option value="">No Bank Found !</option>
                                }
                                
                              </select>
                              </div>
                              :
                              paymentMethod === "Cash-In-Hand"?
                              <div className="col-lg-4 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-cash"
                              >
                                Amount
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="basic-default-cash"
                                placeholder="Enter amount..."
                              />
                            </div>
                            :
                              null
                          }

                          
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Bill Paid (Status)   
                        </label>
                          <select
                            id="inputGroupSelect01"
                            className = {billPaidStatus === ''&& input === true?"form-select border border-danger":"form-select"}
                            value={billPaidStatus}
                            onChange={(e)=>setBillPaidStatus(e.target.value) }
                          >
                            <option value="">Choose...</option>
                            <option value="true">Paid</option>
                            <option value="false">Not Paid</option>

                          </select>
                      </div>


                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-shipping_address"
                    >
                      Enter Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="basic-default-shipping_address"
                      placeholder="Enter address..."
                      value = {address}
                      onChange={(e)=> setAddress(e.target.value)}
                    />
                  </div>


                  

                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-shipment_charges"
                    >
                      Shipment Charges
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-default-shipment_charges"
                      placeholder="Enter shipment charges..."
                      value={shipmentCharges}
                      onChange={(e)=> setShipmentCharges(e.target.value)}
                    />
                  </div>
                        

                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-sub_total"
                    >
                      Sub Total
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-default-sub_total"
                      placeholder="Sub-Total amount..."
                      value={subTotalAmount}
                      onChange={(e)=>setSubTotalAmount(e.target.value)}

                    />
                  </div>


                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-total-amount"
                    >
                      Total Amount
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-default-total-amount"
                      placeholder="Total amount...."
                      value={subTotalAmount+ +shipmentCharges}
                      onChange={(e)=> setTotalAmount(e.target.value)}
                    />
                  </div>


                  </div>

        
                </div>
              </div>

              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Shipping Info</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-fullname"
                          >
                            Phone
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="basic-icon-default-fullname"
                            placeholder="Enter phone..."
                            aria-label="John Doe"
                            aria-describedby="basic-icon-default-fullname2"
                            value={phoneShip}
                            onChange={(e)=> setPhoneShip(e.target.value)}
                          />
                      </div>
                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-company"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            id="basic-icon-default-company"
                            className="form-control"
                            placeholder="Enter city..."
                            aria-label="ACME Inc."
                            aria-describedby="basic-icon-default-company2"
                            value={cityShip}
                            onChange={(e)=> setCityShip(e.target.value)}
                          />
                      </div>

                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-email"
                          >
                            Postcode
                          </label>
                          <input
                            type="text"
                            id="basic-icon-default-email"
                            className="form-control"
                            placeholder="Enter postcode..."
                            aria-label="john.doe"
                            aria-describedby="basic-icon-default-email2"
                            value={postcodeShip}
                            onChange={(e)=> setPostcodeShip(e.target.value)}
                          />
                      </div>

                      <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="basic-icon-default-phone"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            id="basic-icon-default-phone"
                            className="form-control phone-mask"
                            placeholder="Enter state..."
                            aria-label="658 799 8941"
                            aria-describedby="basic-icon-default-phone2"
                            value={stateShip}
                            onChange={(e)=> setStateShip(e.target.value)}
                          />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-icon-default-message"
                      >
                        Address
                      </label>
                      <div className="input-group input-group-merge">
                        <textarea
                          id="basic-icon-default-message"
                          className="form-control"
                          placeholder="Enter address..."
                          aria-label="Hi, Do you have a moment to talk Joe?"
                          aria-describedby="basic-icon-default-message2"
                          rows={4}
                          value={addressShip}
                          onChange={(e)=> setAddressShip(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Contact Info</h5>
                  </div>
                  <div className="card-body">
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-fullname"
                    >
                      Full Name
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="basic-icon-default-fullname"
                      placeholder="Enter full name..."
                      aria-label="John Doe"
                      aria-describedby="basic-icon-default-fullname2"
                      value={nameCon}
                      onChange={(e)=> setNameCon(e.target.value)}
                    />

                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-phone"
                    >
                      Phone No
                    </label>

                    <input
                      type="number"
                      id="basic-icon-default-phone"
                      className="form-control phone-mask"
                      placeholder="Enter phone no..."
                      aria-label="658 799 8941"
                      aria-describedby="basic-icon-default-phone2"
                      value={phoneCon}
                      onChange={(e)=> setPhoneCon(e.target.value)}
                    />
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-email"
                    >
                      Email
                    </label>

                    <input
                      type="email"
                      id="basic-icon-default-email"
                      className="form-control"
                      placeholder="Enter email..."
                      aria-label="john.doe"
                      aria-describedby="basic-icon-default-email2"
                      value={emailCon}
                      onChange={(e)=> setConEmail(e.target.value)}
                    />
              {
                        Loading === true ?
                        <div className="spinner-border text-primary float-end" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                    <button
                      type="submit"
                      className="btn btn-primary float-end mt-5"
                      onClick={submitManualOrder}
                    >
                      Submit
                    </button>
}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualOrderForm;
