import {useQuery} from 'react-query';
import ReadMoreReact from "read-more-react";
import React,{useState,useEffect} from "react";
import colorScheme from '../../../Styles/Styles';
import UpdateArticleModal from "../Modals/UpdateArticleModal";
import ArticleSectionEndPoint from '../../../Api/ArticleSection/ArticleSectionEndpoint';


const ArticleSheet = () => {
  const {mutate : deleteArticleItem} = ArticleSectionEndPoint.useDeleteArticleItem();

  const [allArticles , setAllArticles] = useState([]);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [artId, setArtId] = useState(0);


  // Hook for pagination:
  const[pages , setPages] = useState(1);
  const[count , setCount] = useState('');
  

  const [articleTitle , setArticleTitle] = useState('');
  const [articlePrice , setArticlePrice] = useState('');
  const [articleOnSale , setArticleOnSale] = useState('');
  const [date , setDate] = useState('');

  const [filterButton , setFilterButton] = useState(false);

  const [filteredCount, setFilteredCount] = useState(0);
  
  
  
    const resultsPerPage = 20;
  // function for getting page next:
    const handleNextPage = () => {
      setPages((prevPage) => prevPage + 1);
    };
  // function for getting previous page:
    const handlePrevPage = () => {
      setPages((prevPage) => Math.max(prevPage - 1, 1));
    };

    const totalResults = count|| 0;
    const startResult = (pages - 1) * resultsPerPage + 1;
    const endResult = Math.min(pages * resultsPerPage, totalResults);

      // Getting all articles items function:
   const { refetch } =  useQuery(["all_articles", pages], _=>ArticleSectionEndPoint.getAllArticles(pages), 
      {
        // enabled:autoFetch,
        onSuccess: (data) => {
            setAllArticles(data.data.data);
            setCount(data.data.total_count);
        },
        onError: (err) => {
          return err;
        },
      })



      function removeArticleFromSheet(index) {
        setAllArticles((prevState) => {
          const articles = [...prevState];
          articles.splice(index, 1);
          return articles;
        });
      }
  
      function delArticleItem(id,index){
        deleteArticleItem({id,removeArticleFromSheet,index})
      }
  
  
     function openModal(id){
        setShowArticleModal((p)=>!p)
        setArtId(id)
         
      }
      function AllArticles({items , index}){

        return (
          <>
          <tr key={index}className="text-center">
            <td>{allArticles.length - index}</td>
            <td>{items.title}</td>
       
            <td>{items.price}</td>
                <td>
            {items?.image && items.image.length > 0 ? (
                items.image.map((item) => (
                <>
                <img
                data-bs-toggle="tooltip"
                data-popup="tooltip-custom"
                data-bs-placement="top"
                className="avatar avatar-lg m-1"
                src={`${process.env.REACT_APP_IMAGE_URL}${item}`}
                alt="article_images"
                width={10}
                style={{ borderRadius: "10%" }}
                />
                </>
            ))
            )
            : (
              <div className='text-center'>
                "No image found"
              </div>


            )}
                </td>
               
        
            <td>{items.article_prefix}</td>
            <td>{items.article_post_fix}</td>
            <td>{items.color}</td>
            <td>
            <ReadMoreReact text= {items?.instruction === null   ? "No instructions": items.instruction} min={5} ideal={20} max={30}/>
             
              
            </td>
            <td>{items?.material ===  null ? "No material": items.material}</td>
            <td>{items?.on_sale === null ? "Not yet": items.on_sale ==="true"?"On Sale":"Not on Sale"}</td>
            <td>{items?.is_live === "false" ? <span className="text-danger">Not Published</span>:<span className="text-success">Published</span>}</td>
            <td>
            {items?.sub_datas === null || items?.sub_datas === undefined || items.sub_datas.length === 0 ? (
                  "No data found"
                ) : (
                  <ul style={{listStyle:"none"}}>
                    {items.sub_datas.map((subData, index) => (
                      <li key={index}>
                       <span className="fw-bold">Quantity:</span>  {subData.quantity}, &nbsp;&nbsp;
                        <span className="fw-bold">Size:</span> {subData.size}
                      </li>
                    ))}
                  </ul>
                )}
            </td>
            <td>{items.cat_name}</td>
            <td>{items.sub_cat_name}</td>
            <td>{items.Idate}</td>
      <td>
      <div className="dropdown">
        <button
          type="button"
          className="btn p-0 dropdown-toggle hide-arrow"
          data-bs-toggle="dropdown"
        >
          <i className="bx bx-dots-vertical-rounded" />
        </button>
        <div className="dropdown-menu">
        

        <button 
         type="button"
         style={{ backgroundColor: colorScheme.bg_edit_btn , color:colorScheme.color_edit_btn , boxShadow:colorScheme.box_shadow_edit_btn  }}
         className="dropdown-item"
         onClick={()=>  openModal(items.id)}

         >

        <i className="bx bx-edit-alt me-1" /> Edit
        </button>

          <button
            type="button"
            className="dropdown-item"
            onClick={()=>{
              delArticleItem(items.id,index)
            }}
          >
            <i className="bx bx-trash me-1" /> Delete
          </button>
        </div>
      </div>
    </td>
          </tr>
          </>
  ) 
  }

  const filterArticleSheetData =  allArticles.length > 0 && 
  articleTitle !== "" && articlePrice === "" && articleOnSale === "" && date === "" 
  ? allArticles.filter((items) => items.title === articleTitle)
  :
  articleTitle === "" && articlePrice !== "" && date === "" && articleOnSale === ""
  ? allArticles.filter((items) => items.price === articlePrice)
  :
  articleTitle === "" && articlePrice === "" && date === "" && articleOnSale !== ""
  ? allArticles.filter((items) => items.on_sale === articleOnSale)
  :
  articleTitle === "" && articlePrice === "" && date !== "" && articleOnSale === ""
  ? allArticles.filter((items) => items.Idate === date)
  :

  (articleTitle !== "" && articlePrice !== "") && date === "" && articleOnSale === ""
  ? allArticles.filter((items) => (items.title === articleTitle && items.price === articlePrice))
  :
  (articleTitle !== "" && articleOnSale !== "") && articlePrice === "" && date === ""
  ? allArticles.filter((items) => (items.title === articleTitle && items.on_sale === articleOnSale))
  :
  (articleTitle !== "" && date !== "") && articlePrice === "" && articleOnSale === ""
  ? allArticles.filter((items) => (items.title === articleTitle && items.Idate === date))

  :
  (articlePrice !== "" && articleOnSale !== "") && date === "" && articleTitle === ""
  ? allArticles.filter((items) => (items.price === articlePrice && items.on_sale === articleOnSale))
  :
  (articlePrice !== "" && date !== "") && articleOnSale === "" && articleTitle === ""
  ? allArticles.filter((items) => (items.price === articlePrice && items.Idate === date))
  :
  (articleOnSale !== "" && date !== "") && articlePrice === "" && articleTitle === ""
  ? allArticles.filter((items) => (items.on_sale === articleOnSale && items.Idate === date))

  : allArticles;

  


  useEffect(() => {
    setFilteredCount(filterArticleSheetData.length);
  }, [filterArticleSheetData])

  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper mb-5">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold">
              <span className="text-muted fw-light">Article Sheet </span> 
            </h4>

            
            <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Article Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Article name..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setArticleTitle(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Article Price*
                          </label>
                          <input
                            type="number"
                            placeholder="Search with Article price..."
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setArticlePrice(e.target.value) }
                          />
                      </div>

                      <div className="col-lg-3 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Article On Sale/Not
                  </label>
                  <select
                    id="inputGroupSelect01"
                    className="form-select form-select-sm border border-primary"
                    value={articleOnSale}
                    onChange={(e) => setArticleOnSale(e.target.value)}
                  >
                    <option value="">Choose...</option>
                    <option value="true">On Sale</option>
                    <option value="false">Not On Sale</option>
                  </select>
                </div>
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }
            <div className="card">
              <h5 className="card-header">Article Sheet</h5>
              <div className="table-responsive  text-nowrap">
                { filterArticleSheetData && filterArticleSheetData.length > 0 ?
                      <table className="table">
                      <thead>
                        <tr className="text-center">
                          <th>#</th>
                          <th>Title</th>
                          {/* <th>description</th> */}
                          <th>price</th>
                          <th>Image</th>
                          <th>Article Prefix</th>
                          <th>Article Postfix</th>
                          <th>Color</th>
                          <th>Instruction</th>
                          <th>Material</th>
                          <th>On Sale</th>
                          <th>Article Published</th>
                          <th>Article Size & Quantity</th>
                          <th>Article Category</th>
                          <th>Article Sub_Category</th>
                          <th>Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                          {
                            filterArticleSheetData?.map((items , index)=>{
                                return(
                                  <AllArticles items={items} index={index}/>
                                )
                            })
                          }
                      

                      </tbody>
                    </table>
                    :

                    <div className="text-center">
                    <h2>No Record Found</h2>
                  </div> 
               } 
                
                <div className="p-3">
              <button className="btn btn-outline-primary btn-sm" onClick={handlePrevPage} disabled={pages === 1}>
                <i className="fa-solid fa-arrow-left"></i>
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-outline-primary btn-sm" onClick={handleNextPage} disabled={totalResults <= endResult}>
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
                <p >Showing {startResult} - {count} of {filteredCount} results  -  total :&nbsp;&nbsp;{count}</p>
              </div>

              </div>
            </div>
          </div>

          {/*Update Article Items Modal */}
          {/* *Update Article Items Modal */}
        </div>
      </div>
      {
    showArticleModal === true &&
    <UpdateArticleModal 
    articleID = {artId} 
    showArticleModal={showArticleModal} 
    setShowArticleModal={openModal} 
    refetch = {refetch}

    />

   }
    </>
  )
}

export default ArticleSheet