import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";

function getAllStaffMembers(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_staffs?page=${pages}`)
 }

 function getStaffByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_staff_by_id/${ID}`,)
 }

 
function staffSubmission(formdata){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_staff`, formdata)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Staff Submit!")
          return res.data
      }
      else {
        throw new toast.error("Can't submit!");
      }
    })
  .catch((error)=>{
    if(error){
      toast.warn("Something went wrong, please try again in a while")
    }
   
    })
}

function updateStaffMember({formdata, ID}){
  
   axios.post(`${process.env.REACT_APP_BASE_URL}update_staff_by_id/${ID}`, formdata)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Staff Updated!")
  }
  else {
    throw new toast.error("Can't submit!");
  }
  })
  .catch((error)=>{
    if(error)
    {
      toast.warn("Something went wrong, please try again in a while")
    }
    
 
})

}

function deleteStaffMember({ id, removeStaffFromSheet, index }){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_staff_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("Staff Removed")
      removeStaffFromSheet(index)

      }
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}



const useStaffSubmit = ()=>{
    return useMutation(staffSubmission)
}

const useUpdateStaffMember = ()=>{
  // const queryClient = useQueryClient()
  return useMutation(updateStaffMember , {
  //   onSuccess: ()=>{
  //  queryClient.invalidateQueries({ queryKey: ['all_textile_items'] })
  //   }
  })
}

const useDeleteStaffMember = ()=>{
  return useMutation(deleteStaffMember)
}
const StaffSectionEndPoint ={
    useStaffSubmit,
    useUpdateStaffMember,
    useDeleteStaffMember,
    getAllStaffMembers,
    getStaffByID
}

export default StaffSectionEndPoint;