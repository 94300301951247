import axios from 'axios';
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { useState , useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";

const UpdateBankDetails = ({bankID,showBankDetailsModal , setShowBankDetailsModal}) => {
    const [bankName , setBankName] = useState('');
    const [bankTitle , setBankTitle] = useState('');
    const [accountNumber , setAccountNumber] = useState('');
    const [loading , setLoading] = useState(false);


    function fetchSpecificBankDetails(){
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_banks_by_id/${bankID}`)
        .then((res)=>{
            setBankName(res.data.Bank.bank_name)
            setBankTitle(res.data.Bank.account_title)
            setAccountNumber(res.data.Bank.account_no)
        })

    }

    useEffect(() => {
        fetchSpecificBankDetails()
    }, [])
    

    function submitBankAcc(){
        setLoading(true)
        const bankAccObj = {
            role_id :1,
            user_id:1,
            added_by:"admin",
            bank_name : bankName,
            account_title : bankTitle,
            account_no: accountNumber,

        }
        axios.post(`${process.env.REACT_APP_BASE_URL}update_bank_by_id/${bankID}`, bankAccObj)
        .then((res)=>{
          if(res.data.status === '200'){
              toast.info("Bank Details Updated!")
              setBankName('');
              setBankTitle('')
              setAccountNumber('');
              setLoading(false)
            
          }
          else {
            setLoading(false)
            throw new toast.error("Can't submit items!");

          }
        })
      .catch((error)=>{
        if(error.response.data.status === '401'){
            setLoading(false)
          toast.warn(error.response.data.message)
        }
        else{
            setLoading(false)
          toast.warn("Something went wrong, please try again in a while")
        }
        })

    
    }
  return (
    <>
     <Modal
        size="lg"
        show={showBankDetailsModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
           Update Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
            <div className="col-lg-6">
            <div className="mb-3">
            <label className="form-label" htmlFor="basic-icon-default-fullname">Bank Name</label>
            <div className="input-group input-group-merge">
              <span id="basic-icon-default-fullname2" className="input-group-text"><i className="bx bx-rename" /></span>
              <input type="text" className="form-control" value={bankName} id="basic-icon-default-fullname" placeholder="Enetr bank name..." aria-label="John Doe" aria-describedby="basic-icon-default-fullname2" onChange={(e)=>setBankName(e.target.value)}/>
            </div>
          </div>
            </div>
            <div className="col-lg-6">
            <label className="form-label" htmlFor="basic-icon-default-company">Bank Title</label>
            <div className="input-group input-group-merge">
              <span id="basic-icon-default-company2" className="input-group-text"><i className="bx bx-captions" /></span>
              <input type="text" id="basic-icon-default-company" value={bankTitle} className="form-control" placeholder="Enter bank account title..." aria-label="ACME Inc." aria-describedby="basic-icon-default-company2" onChange={(e)=>setBankTitle(e.target.value)}/>
            </div>
            </div>
        </div>
    
       
          <div className="mb-3">
            <label className="form-label" htmlFor="basic-icon-default-email">Bank Account Number</label>
            <div className="input-group input-group-merge">
              <span className="input-group-text"><i className="bx bxs-user-account" /></span>
              <input type="text" id="basic-icon-default-email" value={accountNumber} className="form-control" placeholder="Enter bank account number..." aria-label="john.doe" aria-describedby="basic-icon-default-email2" onChange={(e)=>setAccountNumber(e.target.value)}/>
              <span id="basic-icon-default-email2" className="input-group-text">e.g XYZ202912NIL</span>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button  className='btn btn-outline-dark' onClick={()=> setShowBankDetailsModal(!showBankDetailsModal)}>
            Close
          </button>
          {
                  loading === true ?
                  <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
          <button type="submit" className="btn btn-primary" onClick={submitBankAcc}>Submit</button>
        }
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateBankDetails