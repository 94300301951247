import { useQuery } from "react-query";
import React,{useState,useEffect} from 'react';
import UpdatePromoCodeModal from '../Modals/UpdatePromoCodeModal'
import PromocodeSection from "../../../Api/PromocodeSection/PromocodeSection";

const PromoCodeSheet = () => {
  const { mutate: deletePromos } = PromocodeSection.useDeletePromocode();
    

  const [promocodes , setAllPromoCodes] = useState([]);
  const [promoCodeID , setPromoCodeID] = useState('');
  const [showPromoModal , setShowPromoModal]= useState(false)
    // Hook for pagination:
    const [pages, setPages] = useState(1);
    const [count, setCount] = useState("");

    const[Promocode , setPromocode] = useState('');
    const[discount , setDiscount] = useState('');
    const[status , setPromoStatus] = useState('');
    const[date , setDate] = useState('');

  const [filterButton , setFilterButton] = useState(false);
  
    const [filteredCount, setFilteredCount] = useState(0);
  
    const resultsPerPage = 20;
    // function for getting page next:
    const handleNextPage = () => {
      setPages((prevPage) => prevPage + 1);
    };
    // function for getting previous page:
    const handlePrevPage = () => {
      setPages((prevPage) => Math.max(prevPage - 1, 1));
    };
  
    const totalResults = count || 0;
    const startResult = (pages - 1) * resultsPerPage + 1;
    const endResult = Math.min(pages * resultsPerPage, totalResults);

      // Getting all events function:
 const {refetch} =  useQuery(["all_promos", pages],(_) => PromocodeSection.getAllPromoCodes(pages),
  {
    onSuccess: (data) => {
      setAllPromoCodes(data.data.Promo_codes);
      setCount(data.data.TotalCount);
    },
    onError: (err) => {
      return err;
    },
  }
)

function removePromosFromSheet(index) {
  setAllPromoCodes((prevState) => {
    const promos = [...prevState];
    promos.splice(index, 1);
    return promos;
  });
}

function delPromos(id, index) {
  deletePromos({ id, removePromosFromSheet, index });
}


function openModal(id){
  setShowPromoModal((p)=>!p)
  setPromoCodeID(id)
   
}



function AllPromoCodes({ items, index }) {
  return (
    <tr key={index} className="text-center">
      <td>{promocodes.length - index}</td>
      <td>{items.username}</td>
      <td>{items.added_by}</td>
      <td>{items.code}</td>
      <td>{items.promo_for}</td>
      <td>{items.discount}</td>
      <td>{items.valid_till}</td>

      <td>
        {items.live === "false" ? (
          <span className="badge bg-label-danger me-1">Not Live</span>
        ) : (
          <span className="badge bg-label-primary me-1">Live</span>
        )}
      </td>

      <td>{items.Idate}</td>
      <td>
        <div className="dropdown">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
          >
            <i className="bx bx-dots-vertical-rounded" />
          </button>
          <div className="dropdown-menu">
            <button
              type="button"
              className="dropdown-item"
              onClick={() => openModal(items.promo_code_id)}
            >
              <i className="bx bx-edit-alt me-1" /> Edit
            </button>
            <button
              type="button"
              className="dropdown-item"
              onClick={() => {
                delPromos(items.promo_code_id, index);
              }}
            >
              <i className="bx bx-trash me-1" /> Delete
            </button>
          </div>
        </div>
      </td>

    </tr>
  );
}

const filtersPromocode =  promocodes.length > 0 && 
Promocode !== "" && discount === "" && status === "" && date === "" 
? promocodes.filter((items) => items.code === Promocode)
:
Promocode === "" && discount !== "" && date === "" && status === ""
? promocodes.filter((items) => items.discount === discount)
:
Promocode === "" && discount === "" && date === "" && status !== ""
? promocodes.filter((items) => items.live === status)
:
Promocode === "" && discount === "" && date !== "" && status === ""
? promocodes.filter((items) => items.Idate === date)
:

(Promocode !== "" && discount !== "") && date === "" && status === ""
? promocodes.filter((items) => (items.code === Promocode && items.discount === discount))
:
(Promocode !== "" && status !== "") && discount === "" && date === ""
? promocodes.filter((items) => (items.code === Promocode && items.live === status))
:
(Promocode !== "" && date !== "") && discount === "" && status === ""
? promocodes.filter((items) => (items.code === Promocode && items.Idate === date))
:
(discount !== "" && status !== "") && date === "" && Promocode === ""
? promocodes.filter((items) => (items.discount === discount && items.live === status))
:
(discount !== "" && date !== "") && Promocode === "" && status === ""
? promocodes.filter((items) => (items.discount === discount && items.Idate === date))
:
(status !== "" && date !== "") && discount === "" && Promocode === ""
? promocodes.filter((items) => (items.live === status && items.Idate === date))

: promocodes;

useEffect(() => {
  setFilteredCount(filtersPromocode.length);
}, [filtersPromocode])


  return (
    <>
    
    <div className="scroll-view-component scrollbar-secondary-component"> 
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">Promo-code Sheet</span>
          </h4>

          
          <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Promocode*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Promocode..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setPromocode(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Discount*
                          </label>
                          <input
                            type="number"
                            placeholder="Search with discount..."
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setDiscount(e.target.value)}
                          />
                      </div>
                    
                      <div className="col-lg-3">
                        <label
                          className="form-label fw-bold"
                        >
                          Promocode (Status)*
                        </label>
                        <select
                            id="inputGroupSelect01"
                            className = {"form-select form-select-sm border border-primary"}
                            onChange={(e)=>setPromoStatus(e.target.value) }
                          >
                            <option value="">Choose...</option>
                            <option value="false">Not Live</option>
                            <option value="true">Live</option>

                          </select>


                      </div>
                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }
          <div className="card">
            <h5 className="card-header">Promo Code Sheet</h5>
            <div className="table-responsive text-nowrap">
            {filtersPromocode && filtersPromocode.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>Username</th>
                        <th>Added By</th>
                        <th>Promocode</th>
                        <th>Promocode For</th>
                        <th>Discount %</th>
                        <th>Valid Till</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 ">
                      {filtersPromocode?.map((items, index) => {
                        return <AllPromoCodes key={index} items={items} index={index} />;
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
                )}

            <div className="p-3">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handlePrevPage}
                    disabled={pages === 1}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handleNextPage}
                    disabled={totalResults <= endResult}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                  <p>
                    Showing {startResult} - {endResult} of {filteredCount}{" "}
                    results - total :&nbsp;&nbsp;{count}
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>

  {
  showPromoModal === true && (

    <UpdatePromoCodeModal 
    promoCodeID={promoCodeID}
    showPromoModal = {showPromoModal}
    setShowPromoModal = {setShowPromoModal}
    refetchPromos = {refetch}
    />

  )
}



      </div>

    </>
  )
}

export default PromoCodeSheet