import {useQuery} from 'react-query';
import React,{useState , useEffect} from 'react';
import UpdateSubClothCategory from '../Modals/UpdateSubClothCategory';
import ClothingSubCategorySectionEndPoint from '../../../Api/ClothingSection/ClothingSubCategoryEndPoint';

const ClothSubCategorySheet = () => {
  const {mutate:deleteSubCategory  } = ClothingSubCategorySectionEndPoint.useDeleteSubCategory();

  const[allSubCategories , setAllSubCategories] = useState([]);
  const[subCategoryID , setSubCategoryID] = useState('');
  const[showClothSubCategoryModal , setShowClothSubCategoryModal] = useState(false);

  const[cateName , setCateName] = useState('');
  const[subCateName , setSubCateName] = useState('')
  const[cateStatus , setCatStatus] = useState('');
  const[date , setDate] = useState('');

  const [filterButton , setFilterButton] = useState(false);



   // Hook for pagination:
   const[pages , setPages] = useState(1);
   const[count , setCount] = useState('');
 
   const [filteredCount, setFilteredCount] = useState(0);
 
 
 
   const resultsPerPage = 20;
 // function for getting page next:
   const handleNextPage = () => {
     setPages((prevPage) => prevPage + 1);
   };
 // function for getting previous page:
   const handlePrevPage = () => {
     setPages((prevPage) => Math.max(prevPage - 1, 1));
   };

   const totalResults = count|| 0;
   const startResult = (pages - 1) * resultsPerPage + 1;
   const endResult = Math.min(pages * resultsPerPage, totalResults);

    // Getting all category function:
   const { refetch } =  useQuery(["all_sub_categories", pages], _=> ClothingSubCategorySectionEndPoint.getAllSubCategories(pages), {
      onSuccess: (data) => {
        setAllSubCategories(data.data.Sub_categorys);
        setCount(data.data.TotalCount);

      },
      onError: (err) => {
        return err;
      },
    })

    function removeSubCategoryFromSheet(index) {
      setAllSubCategories((prevState) => {
        const subCategory = [...prevState];
        subCategory.splice(index, 1);
        return subCategory;
      });
    }

    function delSubCategory(id ,index){
      deleteSubCategory({id,removeSubCategoryFromSheet,index})
    }

    
    function openModal(id){
      setShowClothSubCategoryModal((p)=>!p)
      setSubCategoryID(id)
       
    }


    function AllSubCategories({items , index}){
      return (
        <tr key={index}className="text-center">
          <td>{allSubCategories.length - index}</td>
          <td>{items.who_added}</td>
          <td>{items.username}</td>
          <td>{items.cat_name}</td>
          <td>{items.sub_cat_name}</td>
          {
            items.cat_active === "true"?
            <td className="text-info">ACTIVE</td>
            :
            <td className="text-danger">IN-ACTIVE</td>
          }
          <td>{items.Idate}</td>
    <td>
    <div className="dropdown">
      <button
        type="button"
        className="btn p-0 dropdown-toggle hide-arrow"
        data-bs-toggle="dropdown"
      >
        <i className="bx bx-dots-vertical-rounded" />
      </button>
      <div className="dropdown-menu">
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>openModal(items.sub_category_id)}
        >
          <i className="bx bx-edit-alt me-1" /> Edit
        </button>
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>{
            delSubCategory(items.sub_category_id ,index)
          }}
        >
          <i className="bx bx-trash me-1" /> Delete
        </button>
      </div>
    </div>
  </td>
</tr>

      )

    }

    const filterAllSubCategoriesData =  allSubCategories.length > 0 && 
    cateName !== "" && subCateName === "" && cateStatus === "" && date === "" 
    ? allSubCategories.filter((items) => items.cat_name === cateName)
    :
    cateName === "" && subCateName !== ""  && cateStatus === ""  && date === "" 
    ? allSubCategories.filter((items) => items.sub_cat_name === subCateName)
    :
    cateName === "" && cateStatus !== "" && subCateName === "" && date === "" 
    ? allSubCategories.filter((items) => items.cat_active === cateStatus)
    :
    cateName === "" && cateStatus === "" && subCateName === ""  && date !== "" 
    ? allSubCategories.filter((items) => items.Idate === date)
    :
    (cateName !== "" && cateStatus !== "") && date === "" 
    ? allSubCategories.filter((items) => (items.cat_name === cateName && items.cat_active === cateStatus))
    :
    (cateName !== "" && subCateName !== "" ) && cateStatus && date === "" 
    ? allSubCategories.filter((items) => (items.cat_name === cateName && items.sub_cat_name === subCateName))
    :
    (cateName !== "" && date !== "") && subCateName ===" " && cateStatus === "" 
    ? allSubCategories.filter((items) => (items.cat_name === cateName && items.Idate === date))

    :
    (subCateName !== "" && cateStatus !== "") && cateName ===" " && date === "" 
    ? allSubCategories.filter((items) => (items.sub_cat_name === subCateName && items.cat_active === cateStatus))

    :
    (subCateName !== "" && date !== "") && cateName ===" " && cateStatus === "" 
    ? allSubCategories.filter((items) => (items.sub_cat_name === subCateName && items.Idate === date))
    :
    (cateStatus !== "" && date !== "") && cateName ===" " && cateStatus === "" 
    ? allSubCategories.filter((items) => (items.cat_active === cateStatus && items.Idate === date))

    : allSubCategories

    useEffect(() => {
      setFilteredCount(filterAllSubCategoriesData.length);
    }, [filterAllSubCategoriesData])

  return (
    <>
       <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">Sub-Category Sheet </span> 
            </h4>

            <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                <div className="row">
                {
                          filterButton ? 
                    (
                      <div className="row mt-2 mb-2">

                        
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Category Name*</label>
                      <input type="text"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with category name..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=>{
                        setCateName(e.target.value)
                      }}
                      />
                        </div>

                        <div className="col-lg-3">
                        <label for="defaultFormControlInput7" className="form-label fw-bold">Sub Category Name*</label>
                      <input type="text"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput7" placeholder="Search with category name..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=>{
                        setSubCateName(e.target.value)
                      }}
                      />
                        </div>

                        <div className="col-lg-3">
                  <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="inputGroupSelect01">
                      Category Status*
                    </label>
                    <select className="form-select form-select-sm border border-primary" id="inputGroupSelect01"
                    value={cateStatus}
                    onChange={(e)=>setCatStatus(e.target.value)}
                    >
                      <option value="">Choose...</option>
                      <option value="true">ACTIVE</option>
                      <option value="false">IN-ACTIVE</option>
                    </select>
                  </div>
                </div>

                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput99" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput99" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }

                </div>
            <div className="card">
              <h5 className="card-header">Sub-Category Sheet</h5>
              <div className="table-responsive text-nowrap">
                {
                  allSubCategories && allSubCategories.length > 0 ?
                  <table className="table">
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>Added By</th>
                      <th>Username</th>
                      <th>Category Name</th>
                      <th>Sub Category Name</th>
                      <th>Category Status</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                  {
                        filterAllSubCategoriesData?.map((items , index)=>{
                          return(
                            <AllSubCategories items={items} index={index}/> 
                          )
                        })
                      }
                  </tbody>
                  </table>


                  :
                  <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
                }

          <div className="p-3">
              <button className="btn btn-outline-primary btn-sm" onClick={handlePrevPage} disabled={pages === 1}>
                <i className="fa-solid fa-arrow-left"></i>
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-outline-primary btn-sm" onClick={handleNextPage} disabled={totalResults <= endResult}>
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
                <p >Showing {startResult} - {endResult} of {filteredCount} results  -  total :&nbsp;&nbsp;{count}</p>
              </div>
            
              </div>
            </div>
          </div>

          {/*Update Sub Category Modal */}
          {
            showClothSubCategoryModal === true && (

              <UpdateSubClothCategory 
              subCategoryID ={subCategoryID}
              showClothSubCategoryModal= {showClothSubCategoryModal}
              setShowClothSubCategoryModal ={setShowClothSubCategoryModal}
              refetchSubCategory = {refetch}
              
              />
            )
          }
          {/* *Update Sub Category Modal */}
        </div>
      </div>
    </>
  )
}

export default ClothSubCategorySheet