import {useQuery} from 'react-query';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState , useEffect} from 'react';
import UsersGlobalData from '../../../GlobalCalls/UsersGlobalData';
import ClothingCategorySectionEndPoint from '../../../Api/ClothingSection/ClothingCategorySectionEndPoint';
import ClothingSubCategorySectionEndPoint from '../../../Api/ClothingSection/ClothingSubCategoryEndPoint';



const ClothSubCategoryForm = () => {
  const {mutate:subCategoriesSubmission , isLoading } = ClothingSubCategorySectionEndPoint.useSubCategorySubmission();

  const[allCategories , setAllCategories] = useState([]);

  const [categoryName , setCategoryName] = useState('');
  const [subCategoryName , setSubCategoryName] = useState('');
  const [categoryID , setCategoryID] = useState('');

  const[input , setInput] = useState(false);
  const[userData , setUserData] = useState('');



    // Getting all category function:
    useQuery("all_categories", ClothingCategorySectionEndPoint.getAllCategories, {
      onSuccess: (data) => {
        setAllCategories(data.data.Categorys);
      },
      onError: (err) => {
        return err;
      },
    })




    function submitSubCategory(){
      if(categoryName === '' || subCategoryName === ''){
        setInput(true)
        toast.warn("Fill the information !")
     
        
      }
      else{
        const subCategoryObj = {
          role_id :userData.role_id,
          user_id:userData.id,
          who_added:userData.username,
          cat_name: categoryName,
          sub_cat_name: subCategoryName,
          cat_id:categoryID
        }
  
        
        subCategoriesSubmission((subCategoryObj) , {
          onMutate: () => {
          },
          onSettled: () => {
  
            setInput(false);
            setCategoryName('');
            setSubCategoryName('');
          },
  
        
        })
   
      }
  
    }

    useEffect(() => {
      const getocal = async() =>{
        const data = await UsersGlobalData()
       setUserData(data)
      }
      getocal()
    }, [])




  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light"></span> 
              Sub Category Form
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Sub Category Form</h5>
                    {/* <small className="text-muted float-end">
                      Default label
                    </small> */}
                  </div>
                  <div className="card-body">

                    <div className="row">
                     
                    <div className="col-lg-6">
                        <div className="mb-3">
                          <label
                           className="form-label"
                            htmlFor="inputGroupSelect01"
                          >
                            Category Name
                          </label>
                          <select
                              className={categoryName === ''&& input === true?"form-select border border-danger":"form-select"}
                            id="inputGroupSelect01"
                            value={categoryID}
                            onChange={(e)=>
                              {
                              const selectedValue = e.target.value; 
                              const selectedText = e.target.options[e.target.selectedIndex].text;
                              setCategoryID(selectedValue)
                             setCategoryName(selectedText)
                              }
                            }
                          >
                            <option selected>Choose...</option>
                            {
                              allCategories && allCategories.length > 0 ?
                              allCategories?.map((items,index)=>{
                                return(
                                  <option key={index} value={items.category_id}>{items.cat_name}</option>

                                ) 
                              })
                              :
                              <option >No Category Found...</option>
                            }
                          </select>
                        </div>
                      </div>

                    <div className="col-lg-6">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-company"
                          >
                           Sub Category name
                          </label>
                          <input
                            type="text"
                            value={subCategoryName}
                            onChange={(e)=>setSubCategoryName(e.target.value)}
                            className={subCategoryName === ''&& input === true?"form-control border border-danger":"form-control"}
                            id="basic-default-company"
                            placeholder="Enter Sub Category name..."
                          />
                        </div>
                      </div>

            
  
                    </div>
                    {
                       isLoading ?
                       <div className="spinner-border text-primary float-end" role="status">
                       <span className="visually-hidden">Loading...</span>
                     </div>
                     :
                    <button type="submit" className="btn btn-primary float-end" onClick={submitSubCategory}>
                      Submit
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ClothSubCategoryForm