import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";


function getAllOrders(){
    return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_order`)
}

function getOrdersByStatus(orderStatusObj){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_orders_by_status`,orderStatusObj)
}
  
function approveOrdersByStatus({orderStatusObj,orderAction ,orderID,index,removeSpecificeOrder}){
  
   const courierEndPoint = `${process.env.REACT_APP_BASE_URL}post_assign_courier` ;
   const orderEndPoint = `${process.env.REACT_APP_BASE_URL}update_status_by_id/${orderID}`;
   
   let  resultForApproveOrders = axios.post(orderAction === "Assigned Courier"? courierEndPoint : orderEndPoint ,orderStatusObj)
      .then((res)=>{
        if(res.data.status === '200'){
            toast.info(`Order ${orderAction}!`)
            removeSpecificeOrder(index)
          }
          else {
              throw new toast.error("Can't submit!");
          }
      })
    .catch((error)=>{
      if(error){
        toast.warn("Something went wrong, please try again in a while")
      }
      })

      


  return resultForApproveOrders;
}

function approveBankwithOrder({bankIDObj, orderID,index,removeSpecificeOrder}){
  let resultForApproveBankwithOrder = axios.post(`${process.env.REACT_APP_BASE_URL}updatePaidIn/${orderID}`,bankIDObj)
  .then((res)=>{
    if(res.data.status === '200'){
        toast.info(`Bank added to this order!`)
        // removeSpecificeOrder(index)
      }
      else {
          throw new toast.error("Can't submit!");
      }
  })
.catch((error)=>{
  if(error){
    toast.warn("Something went wrong, please try again in a while")
  }
  })

return resultForApproveBankwithOrder;

}


function disputedOrdersByStatus({disputeAction , orderStatusObj, orderID,index,removeSpecificeOrder}){

let resultForDisputedOrders = axios.post(`${process.env.REACT_APP_BASE_URL}update_status_by_id/${orderID}`,orderStatusObj)
  .then((res)=>{

    if(res.data.status === '200'){
        toast.error(`Order disputed from ${disputeAction}!`)
        removeSpecificeOrder(index)
      }
      else {
          throw new toast.error("Can't submit!");
      }
  })
.catch((error)=>{
  if(error){
    toast.warn("Something went wrong, please try again in a while")
  }
  })

return resultForDisputedOrders;

}


const useApproveOrderStatus = ()=>{
  return useMutation(approveOrdersByStatus)
}

const useDisputedOrderStatus = ()=>{
  return useMutation(disputedOrdersByStatus)
}

const useApproveBankWithOrder = ()=>{
  return useMutation(approveBankwithOrder)
}


  const OrderIntakeSectionEndPoint ={
    getAllOrders,
    getOrdersByStatus,
    useApproveOrderStatus,
    useDisputedOrderStatus,
    useApproveBankWithOrder
}

export default OrderIntakeSectionEndPoint;