import React,{useState} from 'react';
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";
import CourierSectionEndPoint from '../../Api/CourierSection/CourierSectionEndPoint';

const AssignCourierModal = ({orderID,orderData,orderAction,index,removeSpecificeOrder, 
  showAssignCourierModal , setShowAssignCourierModal}) => {
  const { mutate: approveOrdersByStatus, isLoading } =  OrderIntakeSectionEndPoint.useApproveOrderStatus();

    const [allCourier , setAllCourier] = useState([])
    const [courierID , setCourierID] = useState('');
    const [courierName , setCourierName] = useState('');
    const [packageWeight , setPackageWeight] = useState('');
    const [amountCollection , setAmountCollection] = useState('');
    const [courierDescrip , setCourierDescrip] = useState('')

    const [selectedCourier, setSelectedCourier] = useState('');
    const [autoFetch, setAutoFetch] = useState(false);

    const [loading , setLoading] = useState(false);


      // Getting all couriers:
  useQuery("all_Courier",  CourierSectionEndPoint.getAllCourier,
    {
      enabled:!autoFetch,
      onSuccess: (data) => {
        setAllCourier(data.data.Couriers);
        setAutoFetch(true)
      },
      onError: (err) => {
        return err;
      },
    }
  );


  const handleSelectCourier = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    setCourierID(selectedValue.id)
    setCourierName(selectedValue.cName)
    setSelectedCourier(e.target.value);
  }

  async function assignCourier(){
    if(courierName === "Call courier"){
      setLoading(true)

      const saveBookingUrl = "http://cod.callcourier.com.pk/api/CallCourier/SaveBooking";
      const loginId = "test-0001";
      const consigneeName = orderData.contact_data.name;
      const consigneeRefNo = orderID;
      const consigneeCellNo = "03004344328";
      const address = orderData.billing_data.address;
      const origin = orderData.billing_data.city;
      const destCityId = 18;
      const serviceTypeId =  7;
      const pcs = "01";
      const weight = packageWeight;
      const description = courierDescrip;
      const selOrigin = "Domestic";
      const codAmount = amountCollection;
      const specialHandling = false;
      const myBoxId = "1 My Box ID";
      const holiday = false;
      const remarks = courierDescrip;
      const shipperName = "AVANT GARDE";
      const shipperCellNo = "+923217593759";
      const shipperArea =  "1";
      const shipperCity = "1";
      const shipperAddress = "82 A1, PIA Housing Society, Lahore, Pakistan";
      const shipperLandLineNo = "+923217593759";
      const shipperEmail = "info@avantgardeoriginal.com";

      
      try {
        const response = await fetch(`${saveBookingUrl}?loginId=${loginId}&ConsigneeName=${consigneeName}&ConsigneeRefNo=${consigneeRefNo}&ConsigneeCellNo=${consigneeCellNo}&Address=${address}&Origin=${origin}&DestCityId=${destCityId}&ServiceTypeId=${serviceTypeId}&Pcs=${pcs}&Weight=${weight}&Description=${description}&SelOrigin=${selOrigin}&CodAmount=${codAmount}&SpecialHandling=${specialHandling}&MyBoxId=${myBoxId}&Holiday=${holiday}&remarks=${remarks}&ShipperName=${shipperName}&ShipperCellNo=${shipperCellNo}&ShipperArea=${shipperArea}&ShipperCity=${shipperCity}&ShipperAddress=${shipperAddress}&ShipperLandLineNo=${shipperLandLineNo}&ShipperEmail=${shipperEmail}`);
    
        if (!response.ok) {
          setLoading(false)
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();

          if(data.Response === "true"){
          setLoading(false)
            updateOrderStatus(data.CNNO)
            setPackageWeight('');
            setAmountCollection('');
          } 
      } 
      catch (error) {
        return error
      }
    }
    else if(courierName === "Leopard courier")
    {


      // const lepordCourierObj = JSON.stringify({
      //   api_key: "9AB795FFDDC27C8D29E9E9035A7EA170",
      //   api_password: "VERYFASTAVANT123",
        
      //   booked_packet_weight: packageWeight,
      //   booked_packet_vol_weight_w: 0,
      //   booked_packet_vol_weight_h: 0,
      //   booked_packet_vol_weight_l: 0,
      //   booked_packet_no_piece: "01",
      //   booked_packet_collect_amount:amountCollection,
      //   booked_packet_order_id: orderData.id,
  
      //   origin_city: "LAHORE",
      //   destination_city: orderData.billing_data.city,
  
      //   shipment_id: "",
      //   shipment_name_eng: "AVANT GARDE",
      //   shipment_email: "info@avantgardeoriginal.com",
      //   shipment_phone: +923217593759,
      //   shipment_address: "82 A1, PIA Housing Society, Lahore, Pakistan",
  
      //   consignment_name_eng:  orderData.contact_data.name,
      //   consignment_email: orderData.contact_data.email,
      //   consignment_phone: orderData.contact_data.phone,
      //   consignment_phone_two: "",
      //   consignment_phone_three: "",
      //   consignment_address:orderData.shipping_data.address,
      //   special_instructions:courierDescrip ,
  
      //   shipment_type: orderData.payment_method,
      //   custom_data: "",
      //   return_address: "82 A1, PIA Housing Society, Lahore, Pakistan",
      //   return_city: "Lahore",
      // })

      // var requestOptionsLepord = {
      //   method: 'POST',
      //   body: lepordCourierObj,
      //   redirect: 'follow'
      // };
 
      // fetch(`http://new.leopardscod.com/webservice/bookPacketTest/format/json/ `, requestOptionsLepord)
      //   .then(response => response.json())
      //   .then(result => {
      //     console.log(result)
      //     if(result.status === 1){
      //       console.log(result.status)
      //       updateOrderStatus(result.track_number)
      //     }
      //   })
      //   .catch(error => console.log('error', error))

      // const apiUrl = 'http://new.leopardscod.com/webservice/bookPacket/format/json/';
      // const lepordCourierObj ={
      //   api_key: "9AB795FFDDC27C8D29E9E9035A7EA170",
      //   api_password: "VERYFASTAVANT123",
        
      //   booked_packet_weight: packageWeight,
      //   booked_packet_vol_weight_w: 0,
      //   booked_packet_vol_weight_h: 0,
      //   booked_packet_vol_weight_l: 0,
      //   booked_packet_no_piece: "01",
      //   booked_packet_collect_amount:amountCollection,
      //   booked_packet_order_id: orderID,
  
      //   origin_city: "789",
      //   destination_city: orderData.billing_data.city,
  
      //   shipment_id: "",
      //   shipment_name_eng: "AVANT GARDE",
      //   shipment_email: "info@avantgardeoriginal.com",
      //   shipment_phone: +923217593759,
      //   shipment_address: "82 A1, PIA Housing Society, Lahore, Pakistan",
  
      //   consignment_name_eng:  orderData.contact_data.name,
      //   consignment_email: orderData.contact_data.email,
      //   consignment_phone: orderData.contact_data.phone,
      //   consignment_phone_two: "",
      //   consignment_phone_three: "",
      //   consignment_address:orderData.shipping_data.address,
      //   special_instructions:courierDescrip ,
  
      //   shipment_type: orderData.payment_method,
      //   custom_data: "",
      //   return_address: "82 A1, PIA Housing Society, Lahore, Pakistan",
      //   return_city: 0,
      //     is_vpc: 0,
      //   }

      // fetch(apiUrl, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(lepordCourierObj),
      // })
      //   .then(response => response.json())
      //   .then(result => {
      //     console.log(result);
      //     if (result.status === 1) {
      //       updateOrderStatus(result.track_number);
      //     }
      //   })
      //   .catch(error => console.error('Error:', error));

      setLoading(true)
      const apiUrl = 'http://new.leopardscod.com/webservice/bookPacket/format/json/';
      const corsProxyUrl = 'https://corsproxy.io/?'; 
var raw = {
        api_key: "9AB795FFDDC27C8D29E9E9035A7EA170",
        api_password: "VERYFASTAVANT123",
        
        booked_packet_weight: packageWeight,
        booked_packet_vol_weight_w: 0,
        booked_packet_vol_weight_h: 0,
        booked_packet_vol_weight_l: 0,
        booked_packet_no_piece: "01",
        booked_packet_collect_amount:amountCollection,
        booked_packet_order_id: orderData.id,
  
        origin_city: 789,
        destination_city: 789,
  
        shipment_id: "",
        shipment_name_eng: "AVANT GARDE",
        shipment_email: "info@avantgardeoriginal.com",
        shipment_phone: +923217593759,
        shipment_address: "82 A1, PIA Housing Society, Lahore, Pakistan",
  
        consignment_name_eng:  "orderData.contact_data.name",
        consignment_email: orderData.contact_data.email,
        consignment_phone: orderData.contact_data.phone,
        consignment_phone_two: "",
        consignment_phone_three: "",
        consignment_address:orderData.shipping_data.address,
        special_instructions:courierDescrip ,
  
        shipment_type: "",
        custom_data: "",
        return_address: "82 A1, PIA Housing Society, Lahore, Pakistan",
        return_city: 789,
        is_vpc: 0
};

var requestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body:JSON.stringify(raw),
  redirect: 'follow'
};

fetch(corsProxyUrl+apiUrl, requestOptions)
.then(response => {
  if (!response.ok) {
    setLoading(false)
    throw new Error(`HTTP error! Status: ${response.status}`);
    
  }
  return response.json();
})
.then(result => {
  if (result.status === 1) {
    setLoading(false)
    updateOrderStatus(result.track_number);
  }
  else{
    console.log(result)
  }
})
.catch(error =>{
  setLoading(false)
  console.log(error)
  if(error){
    toast.warn("Something went wrong, please try again in a while")
  }
  });

    }
    else if(courierName === "Rider"){

    //   const riderCourierObj = JSON.stringify({
    //   loginId: 4817,
    //   ConsigneeName:orderData.contact_data.name,
    //   ConsigneeRefNo: orderID,
    //   ConsigneeCellNo: orderData.contact_data.phone,
    //   Address: orderData.shipping_data.address,

    //   OriginCityId: 2,
    //   OriginCityName: "LHE",
    //   DestCityId: orderData.shipping_data.postcode,
    //   DestCityName: orderData.shipping_data.city,
    //   DestAreaId: orderData.shipping_data.postcode,

    //   ServiceTypeId: 1,
    //   DeliveryTypeId: "COD",

    //   Pcs: "01",

    //   Weight: packageWeight,
    //   Description: "",
    //   CodAmount:amountCollection,
    //   remarks:courierDescrip,
    //   ShipperAddress: "82 A1, PIA Housing Society, Lahore, Pakistan",
    //   vendorName: "AVANT GARDE",
    //   vendorAddress: "82 A1, PIA Housing Society, Lahore, Pakistan",
    //   vendorPhone: +923217593759,
    //   paymentModeId: 2,
    //   apikey: "VpSJISmo3CXVOfDYqcxMzawkTBZUWPGe7Mrzl8PtfUF1AChvIBflrbsV7H5g7GGo",

    // })

    // var requestOptionsRiderCourier = {
    //   method: 'POST',
    //   body: riderCourierObj,
    //   redirect: 'follow'
    // };
    
    // fetch(`${process.env.REACT_APP_Rider_URL}SaveBooking`, requestOptionsRiderCourier)
    //   .then(response => response.json())
    //   .then(result => {
    //     console.log(result)
    //     if(result.statuscode === 200){
    //       updateOrderStatus(result.CNUM)
    //     }
    //   })
    //   .catch(error => console.log('error', error));

const apiUrl = 'http://api.withrider.com/rider/v2/SaveBooking';
const corsProxyUrl = 'https://cors-anywhere.herokuapp.com/'; 


// Assuming the following query parameters
const queryParams = {
  loginId: 392,
  ConsigneeName: 'SAK',
  ConsigneeRefNo: 'TEST ORD SAK 005 - COMAPI V2',
  ConsigneeCellNo: '03015050775',
  Address: 'House No. 555, Street 2',
  OriginCityId: 1,
  OriginCityName: 'KHI',
  DestCityId: 1,
  DestCityName: 'LHE',
  DestAreaId: 5,
  ServiceTypeId: 1,
  DeliveryTypeId: 2,
  Pcs: 1,
  Weight: 5.5,
  Description: 'Handle with care.....',
  CodAmount: 1400.14,
  remarks: 'NA',
  ShipperAddress: 'JJ Warehouse',
  vendorName: 'NEW VENDOR TEST',
  vendorAddress: 'Vendor Address',
  vendorPhone: '0303030303',
  paymentModeId: 2,
  apikey: 'kSvCVG44D$GU5nYX9N M2OKBkHrUp35NEUR1aa@fKHRaw8oevVirUtkoOrl!E1ObM',
}

var requestOptionRider = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body:JSON.stringify(queryParams),
  redirect: 'follow'
};

fetch(corsProxyUrl + apiUrl, requestOptionRider)
.then(response => {
  if (!response.ok) {
    console.log(response)
    throw new Error(`HTTP error! Status: ${response.status}`);
    
  }
  return response.json();
})
.then(result => {
  console.log(result);
  if (result.statuscode === 200) {
    updateOrderStatus(result.CNUM);
  }
  else{
    console.log(result)
  }
})
.catch(error => {
  if(error){
    toast.warn("Something went wrong, please try again in a while")
  }
}
  
  );

}
 

  

  }

    function updateOrderStatus(CN) {
      const orderStatusObj = {
        user_id:1,
        role_id:1,
        courier_id:parseInt(courierID),
        order_id:orderID,
        cn:CN,
        added_by:"admin",
        status: "assigned"
      };
  
      approveOrdersByStatus(({orderStatusObj,orderAction,index,removeSpecificeOrder}), {
        onMutate: () => {
  
        },
        onSettled: () => {
          setSelectedCourier('');
          setCourierDescrip('');
        },
      });
  }
  
  return (
    <>

      <Modal
        show={showAssignCourierModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>
          <i className="fa-solid fa-box fs-3"/> &nbsp; Order#
        {orderID}     
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black fs-4">
        <div className="row">


                <div className="col-lg-6">
                <label htmlFor="dobBasic1" className="form-label">Package Weight('Grams'e.g.'2000')</label>
                <input type="text" id="dobBasic1" value={packageWeight} className="form-control" placeholder="Enter package weight..."
                onChange={(e)=> setPackageWeight(e.target.value)}/>
                </div>

                <div className="col-lg-6">
                <label htmlFor="dobBasic2" className="form-label">Amount Collection</label>
                <input type="number" id="dobBasic2" value={amountCollection} className="form-control" placeholder="Enter amount collection..."
                onChange={(e)=> setAmountCollection(e.target.value)}/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="emailBasic" className="form-label">Select Courier</label>
                <select className="form-select" aria-label="Default select example"
                value={selectedCourier}
                onChange={handleSelectCourier}
                >
                <option   value="">Choose....</option>
                {
                    allCourier && allCourier.length > 0 ?
                    
                    
                        allCourier?.map((courier, index)=>{
                            return(

                                <option key={index} value={JSON.stringify({id: courier.courier_id, cName: courier.courier_name}) }>{courier.courier_name}</option>
                            )
                        })
                    :
                    "No Courier Found!"
                }
          
                </select>
                </div>
             
                <div className="col-lg-12">
                <label htmlFor="dobBasic" className="form-label">Special Instructions/Rider Note &nbsp;(Optional)</label>
                <textarea type="text" id="dobBasic" value={courierDescrip} className="form-control" placeholder="Enter description..." rows={4}
                onChange={(e)=> setCourierDescrip(e.target.value)}/>
                 </div> 
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-dark"  onClick={()=> setShowAssignCourierModal(!showAssignCourierModal)}>
            Close
          </button>
          {
            loading === true ?
            <>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
            </>

          :
          <button className="btn btn-outline-primary" onClick={assignCourier}>
         Submit
        </button>
          }

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AssignCourierModal