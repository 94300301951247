import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useMutation , useQueryClient} from 'react-query';

function getAllTextileItems(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_items?page=${pages}`)
 }



 function getTextileItemByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_item_by_id/${ID}`,)
 }
 
function textileItemsSubmission(textileItemObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_item`, textileItemObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Items Submit!")
        return res.data
      }
      else {
        throw new toast.error("Can't submit items!");
      }
    })
  .catch((error)=>{
    if(error.response.data.status === '401'){
      toast.warn(error.response.data.message)
    }
    else{
      toast.warn("Something went wrong, please try again in a while")
    }
    })
}

function updateTextileItem({textileItemObj,itemID}){
  axios.post(`${process.env.REACT_APP_BASE_URL}update_item_by_id/${itemID}`, textileItemObj)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Item Updated!")
  }
  else {
    throw new toast.error("Can't submit items!");
  }

  })
  .catch((error)=>{

    if(error.response.data.status === '401'){
      toast.warn(error.response.data.message)
    }
    else{
      toast.warn("Something went wrong, please try again in a while")
    }
 
})


}

function deleteTextileItem({id,removeTextileItemFromSheet,index}){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_item_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("Item Removed")
      removeTextileItemFromSheet(index)
      }
     
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}



const useSubmitTextileItems = ()=>{
    return useMutation(textileItemsSubmission)
}

const useUpdateTextileItems = ()=>{
  // const queryClient = useQueryClient()
  return useMutation(updateTextileItem)
}

const useDeleteTextileItem = ()=>{
  return useMutation( deleteTextileItem)
}
const TextileSectionEndPoint ={
    useSubmitTextileItems,
    useUpdateTextileItems,
    useDeleteTextileItem,
    getAllTextileItems,
    getTextileItemByID
}

export default TextileSectionEndPoint;