import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";

function allFinanceAmounts(){
    return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_total`)
   }

   
   const StatisticsSectionEndPoint ={

    allFinanceAmounts,
}

export default StatisticsSectionEndPoint;