import axios from 'axios'
import { toast } from "react-toastify";
import React,{useState,useEffect} from "react";
import "react-toastify/dist/ReactToastify.css";
import DisputeOrderModal from "../Modals/DisputeOrderModal";
import GenerateOTPApprovalModal from '../../Approvals&Permissions/GenerateOTPApprovalModal';
 
function AssignedOrderList({
    items, 
    index,
    showMultiCheckButton,
    handleMultiSelectApproveOrders,
    multiSelectApproveOrder,
    showMultiCheckButtonForCSV,
    handleMultiSelectDownloadOrders,
    multiSelectDownloadOrders,
    openModal,
    removeSpecificeOrder,
    selectOrderID,
    setSelectOrderID

}){
    const [showDisputeModal , setShowDisputeModal] = useState(false);
    const [showOTPModal , setShowOTPModal] = useState(false);
    

    

      const [scannedBarcodes, setScannedBarcodes] = useState([]);
      const [prePostVal , setPrepostVal] = useState('');
      const [currentBarcode, setCurrentBarcode] = useState('');
      const [checkScanStatus , setCheckScanStatus] = useState(false);
    
      useEffect(() => {

        if(items.id === selectOrderID){
        const handleBarcodeScan = (event) => {
          // Ignore Enter key
          if (event.key === 'Enter') {
            // Check if there's a barcode to add
            if (currentBarcode) {
              setScannedBarcodes((prevBarcodes) => [...prevBarcodes, currentBarcode]);
              setCurrentBarcode(''); // Reset current barcode
              ScanArtcile(currentBarcode)


            }
          } else if (!event.ctrlKey && !event.altKey) {
            // Ignore control and alt keys
            setCurrentBarcode((prevBarcode) => prevBarcode + event.key);
          }
    
          // Prevent the default behavior of the key press
          event.preventDefault();
        };
    
        // Add event listener when the component mounts
        document.addEventListener("keydown", handleBarcodeScan);
    
        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener("keydown", handleBarcodeScan);
        };
      }
      else{
        // console.log("kuch bhi ")
      }

      }, [currentBarcode]);

function ScanArtcile(currentBarcode){
   const scannedOrderObj = {
      order_id :items.id,
      p_id:currentBarcode
    }

    axios.post(`${process.env.REACT_APP_BASE_URL}scan_order`, scannedOrderObj)
    .then((res)=>{
      if(res.data.status === '200'){
        toast.info(`Article Scanned!`)
      }
      else if(res.data.status === '401'){
        toast.error(res.data.message)

      }
    })
    .catch((error)=>{
      if(error.response.data.status === '401'){
        toast.error(error.response.data.message)

      }
      else{
        
        toast.warn("Something went wrong, please try again in a while");
      }
    })
  }
      

function Articles({order,index}){

  
  return (
    <div className="row mt-1" key={index}>
      <div className="col-lg-12 border border-1 shadow-sm rounded ">
        <h6 className="fw-bold">
          #{order.article_id}
        </h6>

        <div className="row">
          <div
            className="col-lg-5 d-flex"
            style={{ fontSize: "13px" }}
          >
            {order.image && order.image !== null ? (
              <img
                key={order.image[0]}
                className="img-fluid card-img-left me-3"
                src={`${process.env.REACT_APP_IMAGE_URL}${order.image[0]}`}
                alt="Card_image"
                width={50}
              />
            ) : (
              <div className="text-black ms-1">
                <i
                  className="fa-solid fa-image me-3"
                  style={{ fontSize: "3em" }}
                />
              </div>
            )}
            <div className="text-black fw-bold mt-3">
              Qty: &nbsp;
              <span class="badge rounded-pill bg-label-warning">
                {order.qty}
              </span>
            </div>
            &nbsp;&nbsp;
            {order.on_sale === "true" ? (
              <div className="text-black fw-bold mt-3">
                Sale:&nbsp;
                <span className="badge bg-label-info">
                  Yes
                </span>
              </div>
            ) : (
              <div className="text-black fw-bold mt-3">
                Sale:&nbsp;
                <span class="badge bg-label-danger">
                  No
                </span>
              </div>
            )}
          </div>

          <div
            className="col-lg-7"
            style={{ fontSize: "13px" }}
          >
            <span className="text-black fw-bold">
              Actual Price:
            </span>
            &nbsp;&nbsp;
            <span className="text-black me-2">
              <span>{order.actual_single_price}</span>
            </span>
            <span className="text-black fw-bold ">
              Single Price:
            </span>
            &nbsp;&nbsp;
            <span className="text-black me-2">
              <span>{order.single_price}</span>
            </span>
            <div>
              <span className="text-black fw-bold">
                Total Price:
              </span>
              &nbsp;&nbsp;
              <span className="text-black">
                {order.total_price}
              </span>
            </div>
            <div>
              <span className="text-black fw-bold">
                Scanned:
              </span>
              &nbsp;&nbsp;
              <span className="text-black">
                {/* {scannedBarcodes.includes((order.article_id))?"true":"false" } */}
                {order.is_scanned !== 0?<span className="text-success">{order.is_scanned}/{order.qty}</span>: <span className="text-danger">Not Scanned</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="ms-1">
          <span
            className="badge bg-label-dark mb-3"
            style={{ fontSize: "10px" }}
          >
            {order.size}
          </span>
        </div>
      </div>
    </div>
  );
}



      return(
        <>
        {/* Assigned order section */}
          <div className="col-lg-8 d-flex align-items-center">
            {showMultiCheckButton ? (
              <div className="form-check">
                <input
                  className="form-check-input border border-primary fs-5"
                  type="checkbox"
                  id={`defaultCheck${items.id}`}
                  onChange={() =>{ 
                    handleMultiSelectApproveOrders(items.id)
                  }}
                  checked={multiSelectApproveOrder.includes(items.id)}
                />
              </div>
            ) : null}
  
  
            
            {showMultiCheckButtonForCSV ? (
              <div className="form-check">
                <input
                  className="form-check-input border border-info fs-5"
                  type="checkbox"
                  id={`defaultCheck${items.id}`}
                  onChange={() =>{ 
                    handleMultiSelectDownloadOrders(items)
                  }}
                  checked={multiSelectDownloadOrders.includes(items)}
                  
                />
              </div>
            ) : null}

              <div className="card accordion-item" style={{ border: "1px solid #696cff" }}  key={index}>
              <h4 className="" id={"heading" + index}>
                <span
                  className="badge bg-label-primary border border-primary float-end mt-1 me-1"
                  style={{ fontSize: "8px" }}
                >
                  {items.status}
                </span>
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target={"#accordion" + index}
                  aria-expanded="false"
                  aria-controls={"accordion" + index}
                >
                         <div className="text-black ms-1">
                                  <i
                                    className="fa-solid fa-image me-3"
                                    style={{ fontSize: "3em" }}
                                  />
                                </div>
                  {/* {items.order_data && items.order_data.length > 0
                    ? items.order_data.map((order) => {
                        return (
                          order.image &&
                          order.image.length > 0 && (
                            <img
                              key={order.image[0]}
                              className="img-fluid me-3 card-img-left"
                              src={`${process.env.REACT_APP_IMAGE_URL}${order.image[0]}`}
                              alt="Card_image"
                              width={50}
                            />
                          )
                        );
                      })
                    : "No Data Found!"} */}

                  <div className="row gy-3" style={{ fontSize: "12px" }}>
                    <div className="col-lg-5">
                      <div className="row lh-base">
                        <span className="fw-bold">Order# {items.id}</span>
                        <span>COD: {items.cod === "true" ? "Yes" : "No"}</span>
                        <span>Payement Method: {items.payment_method}</span>
                        <span>
                        Paid: {items.paid === "true" ? "Paid" : "Not Paid"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-2 d-flex align-items-center">
                      <div className="text-black order-intake-qty">
                        <div className="d-flex text-dark">
                          <span>Qty:</span>&nbsp;&nbsp;
                          {items.order_data &&
                            items.order_data.length > 0 &&
                            items.order_data.reduce(
                              (total, order) => total + Number(order.qty),
                              0
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5" >
                      <div className="row mt-2 lh-base" style={{fontSize:"12px"}}>
                        <span className="text-black">
                          Contact: {items.contact_data.phone}
                        </span>
                        <span className="text-black">
                        Courier assigned: {items.courier_id === null ?"Not yet":items.courier_name}
                      </span>
                      <span className="text-black">CN: {items.cn === null ? "Not assigned":items.cn }
                      </span>
                      </div>
                    </div>
                  </div>
                </button>
              </h4>
              <div id={"accordion" + index}
                className="accordion-collapse collapse"
                aria-labelledby={"heading" + index}
                data-bs-parent={`#accordion`+index}
              >
                <div className="accordion-body">
                  <div className="nav-align-top ">
                    <ul className="nav nav-pills  nav-fill" role="tablist">
                      <li className="nav-item">
                        <button
                          type="button"
                          className="nav-link active btn-sm"
                          role="tab"
                          data-bs-toggle="tab"
                          data-bs-target={
                            "#navs-pills-justified-home-" + index + 1
                          }
                          aria-controls={"navs-pills-justified-home-" + index + 1}
                          aria-selected="true"
                        >
                          <i className="tf-icons bx bx-box" /> Order
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          type="button"
                          className="nav-link btn-sm"
                          role="tab"
                          data-bs-toggle="tab"
                          data-bs-target={
                            "#navs-pills-justified-profile-" + index + 1
                          }
                          aria-controls={
                            "navs-pills-justified-profile-" + index + 1
                          }
                          aria-selected="false"
                        >
                          <i className="tf-icons bx bx-trip" /> Shipping
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          type="button"
                          className="nav-link btn-sm"
                          role="tab"
                          data-bs-toggle="tab"
                          data-bs-target={
                            "#navs-pills-justified-messages-" + index + 1
                          }
                          aria-controls={
                            "navs-pills-justified-messages-" + index + 1
                          }
                          aria-selected="false"
                        >
                          <i className="tf-icons bx bx-message-square" />
                          Billing
                        </button>
                      </li>
                    </ul>

                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary btn-icon rounded-pill dropdown-toggle hide-arrow"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bx bx-dots-horizontal-rounded " />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        
                 
                            {items.order_data?.every(item => item.is_scanned === item.qty) ? (
                              <button
                                type="button"
                                className="dropdown-item btn btn-outline-dark"
                                onClick={() => openModal(items.id)}
                              >
                                <i className="fa-solid fa-file-circle-plus" />
                                &nbsp;&nbsp;Move to Dispatch
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="dropdown-item btn btn-outline-dark"
                                onClick={() => setShowOTPModal(!showOTPModal)}
                              >
                                <i className="fa-solid fa-user" />
                                &nbsp;&nbsp;Ask Approval
                              </button>
                            )}


                        <li>
                          <button
                            type="button"
                            className="dropdown-item btn btn-outline-dark"
                            onClick={()=>{
                              setShowDisputeModal(true)
                            }}
                          >
                            <i className="fa-solid fa-file-circle-minus" />
                            &nbsp;&nbsp; Dispute Orders
                          </button>
                        </li>

                      </ul>
                    </div>

            <div className="tab-content">
              <div className="form-check">
              <label className="form-check-label fw-bold" htmlFor={`defaultCheck${items.id}`}> "Select Order" </label>
              <input className="form-check-input border border-primary" type="checkbox"  id={`defaultCheck${items.id}`}

              onClick={() =>{ 

                setSelectOrderID(items.id === selectOrderID ? "":items.id)
                setPrepostVal(items.order_data.map((item)=> item.pre_pos))
              
              }}
              checked = {items.id === selectOrderID ? true:false}
              />
            </div>

                      <div
                        className="tab-pane fade show active"
                        id={"navs-pills-justified-home-" + index + 1}
                        role={"tabpanel" + index + 1}
                      >
                        {items.order_data && items.order_data.length > 0
                          ? items.order_data.map((order, index) => {
                            return(

                              <Articles order={order} index={index}/>
                              )
                            })
                          : "No Data Found!"}
                      </div>

                      <div
                        className="tab-pane fade"
                        id={"navs-pills-justified-profile-" + index + 1}
                        role={"tabpanel" + index + 1}
                      >
                        <div className="row mt-1">
                          <div
                            className="col-lg-12 border border-1 shadow-sm rounded d-block mx-auto p-3"
                            style={{ fontSize: "13px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">Postcode</p>
                              <span className="text-black">
                                <i className="fa-solid fa-signs-post"></i>&nbsp;
                                {items.shipping_data.postcode}
                              </span>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">State</p>
                              <span className="text-black">
                                <i className="fa-solid fa-landmark"></i>&nbsp;
                                {items.shipping_data.state}
                              </span>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">City</p>
                              <span className="text-black">
                                <i class="fa-solid fa-city"></i>&nbsp;
                                {items.shipping_data.city}
                              </span>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">Phone</p>
                              <span className="text-black">
                                <i className="fa-solid fa-phone"></i>&nbsp;
                                {items.shipping_data.phone}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"navs-pills-justified-messages-" + index + 1}
                        role={"tabpanel" + index + 1}
                      >
                        <div className="row mt-1">
                          <div
                            className="col-lg-12 border border-1 shadow-sm rounded d-block mx-auto p-3"
                            style={{ fontSize: "13px" }}
                          >
                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">City</p>
                              <span className="text-black">
                                <i class="fa-solid fa-city"></i>&nbsp;
                                {items.billing_data.city}
                              </span>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">Phone</p>
                              <span className="text-black">
                                <i className="fa-solid fa-phone"></i>{" "}
                                {items.billing_data.phone}
                              </span>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">Address</p>
                              <span className="text-black">
                                <i className="fa-solid fa-house"></i>{" "}
                                {items.billing_data.address}
                              </span>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">Email</p>
                              <span className="text-black">
                                <i className="fa-solid fa-envelope"></i>{" "}
                                {items.contact_data.email}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        {/* Assigned order section */}

        {/* Billing total section */}
        <div className="col-lg-4">
          <div
            className="card accordion-item "
            style={{ border: "1px solid #696cff" }}
          >
            <h2 className="accordion-header " id="headingThree">
              <span
                className="badge bg-label-primary border border-primary float-end me-1"
                style={{ fontSize: "8px", marginTop: "2px" }}
              >
                {items.status}
              </span>
  
              <button
                type="button"
                className="accordion-button collapsed accordion-button-billing-total "
                data-bs-toggle="collapse"
                data-bs-target="#accordionThree"
                aria-expanded="false"
                aria-controls="accordionThree"
              >
                <div className="row " style={{ fontSize: "13px" }}>
                  <div className="d-flex text-black">
                    <span>Promo code:&nbsp;</span>
                    <span>{items.promo_code}</span>
                  </div>
  
                  <div className="col-lg-12">
                    <div className="row">
                      <span className="text-black">
                        GST %:&nbsp; {items.gst}
                      </span>
  
                      <span className="text-black">
                        Shippment:&nbsp; {items.shipment_charges}
                      </span>
  
                      <span className="text-black">
                        Sub Total:&nbsp; {items.sub_total}
                      </span>
                      <span className="text-black">
                        Total:&nbsp;{items.total}
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="accordionThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body"></div>
            </div>
          </div>
        </div>
        {/* Billing total section */}
  
{
  showOTPModal && (
    <>
    <GenerateOTPApprovalModal
    assignedOrder= "Assigned Orders"
    assignedOrderMessage = "OTP for Assigned Orders - move orders to dispatch"
    showOTPModal = {showOTPModal}
    setShowOTPModal = {setShowOTPModal}
    orderID={items.id} 
    onSuccess={openModal}
    index={index}
    
    />
    </>
  )
}


  {
      showDisputeModal && (
        <>
          <DisputeOrderModal 
          disputeAction="Assigned orders" 
          showDisputeModal = {showDisputeModal} 
          setShowDisputeModal = {setShowDisputeModal}
          orderID={items.id} 
          index={index}  
          removeSpecificeOrder={removeSpecificeOrder}/>
        </>
      ) 

      
  }


  </>
      )
  
  
    }
  export default AssignedOrderList