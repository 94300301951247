import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState,useEffect} from 'react';
import UpdatePermissionModal from './UpdatePermissionModal';

const PermissionSheet = () => {

    const [allPermissions, setAllPermissions] = useState([]);

    const getAllPermission = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_permissions`)
        .then((res)=>{
            setAllPermissions(res.data.Permissions)
        })
        .catch((err)=>{
            return err
        })
    }

    
    function removePermission(index) {
        setAllPermissions((prevState) => {
          const permission = [...prevState];
          permission.splice(index, 1);
          return permission;
        });
      }
  
      function deleteRemovePermission(id,index){
        axios.post(`${process.env.REACT_APP_BASE_URL}delete_permission_by_id/${id}`)
        .then((res)=>{
          if(res.data.status === '200'){
            toast.error("Permission Removed")
            removePermission(index)
      
            }
        })
        .catch((error)=>{
            if(error){
              toast.warn("Something went wrong! Please try again")
            }
       
      })
      }
  
    useEffect(() => {
        getAllPermission()
    }, [])
  
    function AllPermissionSheet({items , index}){
        
      const [showPermissionModal, setShowPermissionModal] = useState(false);
  
  
        return(
            <>
               <tr>
                <td>{allPermissions.length - index}</td>
                <td>{items.added_by}</td>
                <td>{items.permission_name}</td>
                <td>{items.Idate}</td>
  
              <td>
                <div className="dropdown">
                  <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded" />
                  </button>
                  <div className="dropdown-menu">
                    <button className="dropdown-item" onClick={()=>{
                      setShowPermissionModal(true)
                    }}><i className="bx bx-edit-alt me-1" /> Edit</button>
                    <button className="dropdown-item" onClick={()=>deleteRemovePermission(items.permission_id,index)}><i className="bx bx-trash me-1" /> Delete</button>
                  </div>
                </div>
              </td>
            </tr>
          {
            showPermissionModal === true ?
            <UpdatePermissionModal
            permissionID = {items.permission_id}
            permissionTo = {items.permission_name}
            showPermissionModal={showPermissionModal}
            setShowPermissionModal = {setShowPermissionModal}
            getAllPermission={getAllPermission}
            />
            :
            ""
          }
  
            </>
        )
    }
  return (
    <>
    <div className="scroll-view-component scrollbar-secondary-component">
<div className="content-wrapper">
  <div className="container-xxl flex-grow-1 container-p-y">
    <h4 className="fw-bold"><span className="text-muted fw-light">Permissions</span> </h4>
    <div className="card">
      <h5 className="card-header">Permissions Sheet</h5>
      <div className="table-responsive text-nowrap">
      {allPermissions && allPermissions.length > 0 ?
        <table className="table">
          <thead>
            <tr className='text-center'>
              <th>#</th>
              <th>Added By</th>
              <th>Permission Name</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
      
          <tbody className="table-border-bottom-0 table-responsive text-center">
            {
                allPermissions?.map((items,index)=>{
                  return(
                      <AllPermissionSheet items={items} index={index} />  
                  )
              })
            }
                  
          </tbody>
              
        </table>
              :
              <div className="text-center">
              <h1 className='fw-bold'>No Data Found !</h1>
              </div>

          }
      </div>
    </div>

  </div>

</div>
    </div>
    </>
  )
}

export default PermissionSheet