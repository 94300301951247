import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

const AddArticleForm = () => {

    const [articlePrefix , setArticlePrefix] = useState('');
    const [articlePostfix , setArticlePostfix] = useState('');
    // const [subPrepost , setSubPrepost] = useState('');

    const [articleColor , setArticleColor] = useState('');
    const [articlePrice , setArticlePrice] = useState('');
    const [articleTitle , setArticleTitle] = useState('');

    const [selected, setSelected] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoryID , setSubCategoryID] = useState('');
    
    const [inputFields, setInputFields] = useState([]);
    const [prepostInputs, setPrepostInputs] = useState([]);

    const[input , setInput] = useState(false);
    const[Loading , setLoading] = useState(false);

  const options = [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ]


  // Handling Sizes input with respective qunatity input fields:

  const handleSelectChange = (selected) => {
    setSelected(selected);
  
    // Preserve existing quantity and prepost inputs when updating selected sizes
    const updatedInputFields = selected.map((value) => {
      const existingField = inputFields.find((field) => field.value === value);
      const existingPrepost = existingField ? prepostInputs[inputFields.indexOf(existingField)] : '';
  
      return {
        value,
        inputValue: existingField ? existingField.inputValue : '',
        prepostInput: existingPrepost,
      };
    });
  
    setInputFields(updatedInputFields);
    // setPrepostInputs(updatedInputFields.map((field) => field.prepostInput));
  };

  const handleInputChange = (value, inputValue) => {
    const updatedInputFields = inputFields.map((field) =>
      field.value === value ? { ...field, inputValue } : field
    );
    setInputFields(updatedInputFields);
  };

  const handlePrepostInputChange = (index, value) => {
    const updatedPrepostInputs = [...prepostInputs];
    updatedPrepostInputs[index] = value;
    setPrepostInputs(updatedPrepostInputs);
  };

  const selectedSizeValue = selected.map((item) => item.value);
  const selectedSizeQunatity = inputFields.map((item) => item.inputValue);


  const sizeAndQuantity = selectedSizeValue.map((size , index) =>({
    quantity: parseInt(selectedSizeQunatity[index],10) || 0,
    size,
    in_progress_stock:0,
    p_id : articlePrefix+articlePostfix+prepostInputs[index] || ''
  }))

  // Getting Categories and Sub Categories
  function getCategories() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}get_categorys_name_and_id`)
      .then((res) => {
        setCategories(res.data.Categorys);
      })
      .catch((error) => {
        return error;
      });
  }

  function getSubCategories(e) {
    axios.post(
        `${process.env.REACT_APP_BASE_URL}fetch_sub_categorys_by_cid/${e.target.value}`
      )
      .then((res) => {
        setSubCategories(res.data.sub_categories);
      })
      .catch((error) => {
        return error;
      });
  }

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setPrepostInputs(Array(inputFields.length).fill(''));
  }, [inputFields.length]);
  

  function submitArticle() {
    setLoading(true)
    if(articleTitle === '' || articlePrice === '' || articlePostfix === '' ||  subCategoryID ==='' ||  prepostInputs === '' ||
    articlePostfix === '' || selected ==='' || selected.length === 0  || inputFields === ''){
      setLoading(false)
      setInput(true)
      toast.warn("Fill the information !")
    }

    else{
      const articleObj ={
        article_prefix: articlePrefix,
        article_post_fix:articlePostfix,
        color:articleColor,
        price:parseFloat(articlePrice),
        title:articleTitle,
        pre_post:articlePrefix+articlePostfix,
        sub_category:subCategoryID,
        sub_datas : sizeAndQuantity
    }



    axios.post(`${process.env.REACT_APP_BASE_URL}post_article`,articleObj)
    .then((res)=>{
      if(res.data.status === '200'){  
        toast.info("Article Submit!")
        setInput(false)
        setLoading(false)
    }
    
    else {
      throw new toast.error("Can't submit items!");
    }
  })
  .catch((error)=>{
    if(error)
      {
        setLoading(false)
        toast.warn("Something went wrong, please try again in a while")
      }
   
  })



  setInput(false)
  setArticlePrefix('');
  setArticlePostfix('');
  setPrepostInputs([]);
  setArticleColor('');
  setArticlePrice('');
  setArticleTitle('');
  setSelected([]);
  setSubCategoryID('');
  setCategories('');
  setSubCategories([]);
  setInputFields([]);


}
 
  }
  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold"><span className="text-muted fw-light">Article Form</span> </h4>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Add Article</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">

                    
                    <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company1"
                      >
                        Article Title
                      </label>
                      <input
                        type="text"
                        className={articleTitle === ''&& input === true?"form-control border border-danger":"form-control"}
                        id="basic-default-company0"
                        placeholder="Enter Article title..."
                        value={articleTitle}
                        onChange={(e)=> setArticleTitle(e.target.value)}
                      />
                        </div>
                                
                     <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company2"
                      >
                        Article Prefix
                      </label>
                      <input
                        type="text"
                        className={articlePrefix === ''&& input === true?"form-control border border-danger":"form-control"}
                        id="basic-default-company1"
                        placeholder="Enter Prefix..."
                        value={articlePrefix}
                        onChange={(e)=> setArticlePrefix(e.target.value)}
                      />
                        </div>

                        <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company3"
                      >
                        Article Postfix
                      </label>
                      <input
                        type="text"
                        className={articlePostfix === ''&& input === true?"form-control border border-danger":"form-control"}
                        id="basic-default-company2"
                        placeholder="Enter Postfix..."
                        value={articlePostfix}
                        onChange={(e)=> setArticlePostfix(e.target.value)}
                      />
                        </div>

                        {/* <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company44"
                      >
                        Article Sub Pre_post
                      </label>
                      <input
                        type="text"
                        className={subPrepost === '' &&  input === true?"form-control border border-danger":"form-control"}
                        id="basic-default-company44"
                        placeholder="Enter Sub Prepost..."
                        value={subPrepost}
                        onChange={(e)=> setSubPrepost(e.target.value)}
                      />
                        </div> */}

                        
                        <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company4"
                      >
                        Color
                      </label>
                      <input
                        type="text"
                        className={articleColor === ''&& input === true?"form-control border border-danger":"form-control"}
                        id="basic-default-company3"
                        placeholder="Enter Article color..."
                        value={articleColor}
                        onChange={(e)=> setArticleColor(e.target.value)}
                      />
                        </div>

                        <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company5"
                      >
                        Price
                      </label>
                      <input
                        type="number"
                        className={articlePrice === ''&& input === true?"form-control border border-danger":"form-control"}
                        id="basic-default-company4"
                        placeholder="Enter Article price..."
                        value={articlePrice}
                        onChange={(e)=> setArticlePrice(e.target.value)}
                      />
                        </div>

                        

                        <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="inputGroupSelect011"
                        >
                          Categories
                        </label>
                        <select
                          id="inputGroupSelect011"
                          className="form-select"
                          onChange={(e) => getSubCategories(e)}
                        >
                          <option>Choose...</option>
                          {categories && categories.length > 0 ? (
                            categories.map((items, index) => {
                              return (
                                <option key={index} value={items.id}>
                                  {items.cat_name}
                                </option>
                              );
                            })
                          ) : (
                            <option value="nothing">No Category Found</option>
                          )}
                        </select>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="inputGroupSelect022"
                        >
                          Sub Categories
                        </label>
                        <select
                          id="inputGroupSelect022"
                          className = {subCategoryID === ''&& input === true?"form-select border border-danger":"form-select"}
                          value={subCategoryID}
                          onChange={(e) => setSubCategoryID(e.target.value)}
                        >
                          <option>Choose...</option>
                          {subCategories && subCategories.length > 0 ? (
                            subCategories.map((items, index) => {
                              return (
                                <option key={index} value={items.id}>
                                  {items.sub_cat_name}
                                </option>
                              );
                            })
                          ) : (
                            <option value="nothing">No Sub-Category Found</option>
                          )}
                        </select>
                      </div>
              
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname33"
                          >
                            Select Sizes
                          </label>
                          <MultiSelect
                            className={selected.length === 0 && input ? "border border-danger" : ""}
                            options={options}
                            value={selected}
                            onChange={handleSelectChange}
                            labelledBy="Select"
                          />
                        </div>
                      </div>
                      {inputFields.map((field, index) => (

                          <>

                        <div key={index} className="col-lg-4 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-company77"
                          >
                            {`Qunatity for ${field.value.label}`}
                          </label>
                          <input
                            type="number"
                            className={field.inputValue === ''&& input === true?"form-control border border-danger":"form-control"}
                            placeholder="Enter Quantity"
                            value={field.inputValue}
                            onChange={(e) =>
                              handleInputChange(field.value, e.target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <label className="form-label" htmlFor={`prepost-${index}`}>
                              Article Pre_post
                            </label>
                            <input
                              type="text"
                              className={(prepostInputs[index] === '' && input) ? "form-control border border-danger" : "form-control"}
                              id={`prepost-${index}`}
                              placeholder="Enter Sub Prepost..."
                              value={prepostInputs[index]}
                              onChange={(e) => handlePrepostInputChange(index, e.target.value)}
                            />
                        </div>
                          </>
                      ))}
                  

                    </div>
                    {
                        Loading === true ?
                        <div className="spinner-border text-primary float-end" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                    <button
                      type="submit"
                      className="btn btn-primary mt-3 float-end"
                      onClick={submitArticle}
                    >
                      Submit
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddArticleForm;
