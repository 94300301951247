import QRCode from 'qrcode.react';
import React, { Component }  from 'react';
import InvoiceLogo from '../Images/Wide_Black.png';
export default class Invoice extends Component {
  render() {
    

    const {items , ref} = this.props;

    return (
      <div ref={ref} className={'invoice-display'}>
      <div className="row">
    <div className="col-lg-12">
      <div className="">
        <div className="card-body">

        

        <div className="invoice-title">

<div className="d-flex bd-highlight ">
  <div className="me-auto bd-highlight">
  <img className='img-fluid' src={InvoiceLogo} alt="" width={300}/>
  </div>
  <div className="p-2 bd-highlight">
  <h4 className="justify-content-end font-size-15" style={{color:"#000"}}>Invoice #{items.id} <span className="badge bg-success font-size-12 ms-2">Paid</span></h4>
  <div className='float-end'>
  <QRCode value={JSON.stringify(items.id)} size={70} />
  </div>
  </div>
</div>

          
            <div className="avant-garde info" style={{color:"#000"}}>
              <p className="fw-bolder mb-1">AVANT GARDE</p>
              <p className='fw-bold mb-1'>First Floor, 82 A1, PIA Hosuing Society, Lahore, Pakistan</p>
              <p className="fw-normal mb-1">Email: info@avantgardeoriginal.com</p>
              <p className="fw-normal mb-1">Phone: 0092-3217593759</p>
            </div>
          </div>

          <hr className="my-2"/>

          <div className="row" >
            <div className="col-sm-6 " style={{color:"#000"}}>
                <h5 className="font-size-16 fw-bold mb-3 mt-4"  style={{color:"#000"}}>Invoice To:</h5>
                <h5 className="font-size-15 mb-2"  style={{color:"#000"}}>{items.contact_data.name}</h5>
                <p className="mb-1">{items.billing_data.address}</p>
                <p className="mb-1">{items.contact_data.email}</p>
                <p>{items.contact_data.phone}</p>
            </div>
            {/* end col */}
            <div className="col-sm-6"  style={{color:"#000"}}>
              <div className="float-end">
              <h5 className="font-size-16 fw-bold mb-3 mt-4"  style={{color:"#000"}}>Shipping To:</h5>
                <h5 className="font-size-15 mb-2"  style={{color:"#000"}}>{items.contact_data.name}</h5>
                <p className="mb-1">{items.billing_data.address}</p>
                <p className="mb-1">{items.contact_data.email}</p>
                <p>{items.contact_data.phone}</p>
           
              </div>
            </div>
            {/* end col */}
          </div>

          <hr className="my-2"/>

          {/* end row */}
          <div className="">
            {/* <h5 className="font-size-15">Order Summary</h5> */}
            <div className="table-responsive">
              <table className="table align-middle table-nowrap table-centered table-striped mb-0">
                <thead  style={{backgroundColor:"#000000"}}>
                  <tr className='text-center'>
                    <th style={{color:"white"}}>Product</th>
                    <th style={{color:"white"}}>SKU</th>
                    <th style={{color:"white"}}>Price</th>
                    <th style={{color:"white"}}>Qty</th>
                    <th className="text-end" style={{width: 120 , color:"white"}}>Total</th>
                  </tr>
                </thead>
                <tbody>


            {
              items.order_data?.map((order)=>{

                return(
                  <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                    {/* <img className='img-fluid rounded-3 me-4' src={artImage} alt="" width={50}/>
                     */}
                        {order.image && order.image !== null ? (
                                        <img
                                          key={order.image[0]}
                                          className="card-img-left me-3"
                                          src={`${process.env.REACT_APP_IMAGE_URL}${order.image[0]}`}
                                          alt="Card_image"
                                          width={50}
                                        />
                                      ) : (
                                        <div className="text-black ms-1">
                                          <i
                                            className="fa-solid fa-image me-3"
                                            style={{ fontSize: "3em" }}
                                          />
                                        </div>
                                      )}
                      <div className='row'>
                      <h5 className="text-truncate font-size-14 mb-1 fs-6"  style={{color:"#000000"}}>{order.title}</h5>
                      <p className="text-truncate mb-0" style={{fontSize:"12px"}}>Size: {order.size}</p>
                      </div>
                    </div>
                  </td>
                  <td style={{color:"#000000" , fontSize:"12px"}}>
                 {order.p_id}
                  </td>
                  <td className='text-center' style={{fontSize:"13px" , color:"#000000" }}>{order.single_price}</td>
                  <td className='text-center' style={{color:"#000000" }}>{order.qty}</td>
                  <td className="text-end" style={{color:"#000000" }}>{order.total_price}</td>
                </tr>
                )

              })
            }
                     {/* <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                      <img className='img-fluid rounded-3 me-4' src={artImage} alt="" width={50}/>
                        <div className='row'>
                        <h5 className="text-truncate font-size-14 mb-1 fs-6"  style={{color:"#000000"}}>Stainless Steel S010</h5>
                        <p className="text-truncate mb-0" style={{fontSize:"12px"}}>Size: Medium</p>
                        </div>
                      </div>
                    </td>
                    <td style={{color:"#000000" , fontSize:"12px"}}>
                    SS002-00M-99 99
                    </td>
                    <td style={{fontSize:"13px" , color:"#000000" }}>245.50</td>
                    <td style={{color:"#000000" }}>2</td>
                    <td className="text-end" style={{color:"#000000" }}>491.00</td>
                  </tr>

                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                      <img className='img-fluid rounded-3 me-4' src={artImage} alt="" width={50}/>
                        <div className='row'>
                        <h5 className="text-truncate font-size-14 mb-1 fs-6"  style={{color:"#000000"}}>Stainless Steel S010</h5>
                        <p className="text-truncate mb-0" style={{fontSize:"12px"}}>Size: Medium</p>
                        </div>
                      </div>
                    </td>
                    <td style={{color:"#000000" , fontSize:"12px"}}>
                    SS002-00M-99 99
                    </td>
                    <td style={{fontSize:"13px" , color:"#000000" }}>245.50</td>
                    <td style={{color:"#000000" }}>2</td>
                    <td className="text-end" style={{color:"#000000" }}>491.00</td>
                  </tr> */}

                  <tr>
                    <th scope="row" colSpan={4} className="text-end"  style={{color:"#000000" }}>Sub Total</th>
                    <td className="text-end"  style={{color:"#000000" }}>Rs. {items.sub_total}</td>
                  </tr>
                  {/* end tr */}
          
                  {/* end tr */}
                  <tr>
                    <th scope="row" colSpan={4} className="border-0 text-end"  style={{color:"#000000" }}>
                      Shipping Charge :</th>
                    <td className="border-0 text-end"  style={{color:"#000000" }}>Rs. {items.shipment_charges}</td>
                  </tr>
                  {/* end tr */}
                  <tr>
                    <th scope="row" colSpan={4} className="border-0 text-end"  style={{color:"#000000" }}>
                      Tax</th>
                    <td className="border-0 text-end"  style={{color:"#000000" }}>Rs. {items.gst}</td>
                  </tr>
                  {/* end tr */}
                  <tr>
                    <th scope="row" colSpan={4} className="border-0 text-end"  style={{color:"#000000" }}>Total</th>
                    <td className="border-0 text-end"><h6 className="m-0 fw-semibold"  style={{color:"#000000" }}>Rs. {items.total}</h6></td>
                  </tr>
                  {/* end tr */}
                </tbody>{/* end tbody */}
              </table>{/* end table */}
            </div>{/* end table responsive */}
  
          </div>

          <p className='text-black' style={{fontSize:"11px", marginTop:"20em"}}>
          THANK YOU FOR YOUR BUSINESS
          <br />
          Thank you for your purchase from Avant Garde. Please let us know if we can do anything else for you!
          </p>
     

        </div>
        
     
      </div>
    </div>
  </div>

      </div>
    )
  }
}


// const Invoice = forwardRef((items, ref) => {
    
//     // const orderNumber = "DS0204";
//     console.log("order_data",items.id)


//     return (
//       <div ref={ref}>
//       <div className="row">
//     <div className="col-lg-12">
//       <div className="">
//         <div className="card-body">

        

//         <div className="invoice-title">

// <div className="d-flex bd-highlight ">
//   <div className="me-auto bd-highlight">
//   <img className='img-fluid' src={InvoiceLogo} alt="" width={300}/>
//   </div>
//   <div className="p-2 bd-highlight">
//   <h4 className="justify-content-end font-size-15" style={{color:"#000"}}>Invoice #{items.id} <span className="badge bg-success font-size-12 ms-2">Paid</span></h4>
//   <div className='float-end'>
//   <QRCode value={items.id} size={60} />
//   </div>
//   </div>
// </div>

          
//             <div className="avant-garde info" style={{color:"#000"}}>
//               <p className="fw-bolder mb-1">AVANT GARDE</p>
//               <p className='fw-bold mb-1'>First Floor, 82 A1, PIA Hosuing Society, Lahore, Pakistan</p>
//               <p className="fw-normal mb-1">Email: info@avantgardeoriginal.com</p>
//               <p className="fw-normal mb-1">Phone: 0092-3217593759</p>
//             </div>
//           </div>

//           <hr className="my-2"/>

//           <div className="row" >
//             <div className="col-sm-6 " style={{color:"#000"}}>
//                 <h5 className="font-size-16 fw-bold mb-3 mt-4"  style={{color:"#000"}}>Invoice To:</h5>
//                 <h5 className="font-size-15 mb-2"  style={{color:"#000"}}>Preston Miller</h5>
//                 <p className="mb-1">4068 Post Avenue Newfolden, MN 56738</p>
//                 <p className="mb-1">PrestonMiller@armyspy.com</p>
//                 <p>001-234-5678</p>
//             </div>
//             {/* end col */}
//             <div className="col-sm-6"  style={{color:"#000"}}>
//               <div className="float-end">
//               <h5 className="font-size-16 fw-bold mb-3 mt-4"  style={{color:"#000"}}>Shipping To:</h5>
//                 <h5 className="font-size-15 mb-2"  style={{color:"#000"}}>Preston Miller</h5>
//                 <p className="mb-1">4068 Post Avenue Newfolden, MN 56738</p>
//                 <p className="mb-1">PrestonMiller@armyspy.com</p>
//                 <p>001-234-5678</p>
           
//               </div>
//             </div>
//             {/* end col */}
//           </div>

//           <hr className="my-2"/>

//           {/* end row */}
//           <div className="">
//             {/* <h5 className="font-size-15">Order Summary</h5> */}
//             <div className="table-responsive">
//               <table className="table align-middle table-nowrap table-centered table-striped mb-0">
//                 <thead  style={{backgroundColor:"#000000"}}>
//                   <tr className='text-center'>
//                     <th style={{color:"white"}}>Product</th>
//                     <th style={{color:"white"}}>SKU</th>
//                     <th style={{color:"white"}}>Price</th>
//                     <th style={{color:"white"}}>Qty</th>
//                     <th className="text-end" style={{width: 120 , color:"white"}}>Total</th>
//                   </tr>
//                 </thead>
//                 <tbody>

//                      <tr>
//                     <td>
//                       <div className='d-flex align-items-center'>
//                       <img className='img-fluid rounded-3 me-4' src={artImage} alt="" width={50}/>
//                         <div className='row'>
//                         <h5 className="text-truncate font-size-14 mb-1 fs-6"  style={{color:"#000000"}}>Stainless Steel S010</h5>
//                         <p className="text-truncate mb-0" style={{fontSize:"12px"}}>Size: Medium</p>
//                         </div>
//                       </div>
//                     </td>
//                     <td style={{color:"#000000" , fontSize:"12px"}}>
//                     SS002-00M-99 99
//                     </td>
//                     <td style={{fontSize:"13px" , color:"#000000" }}>245.50</td>
//                     <td style={{color:"#000000" }}>2</td>
//                     <td className="text-end" style={{color:"#000000" }}>491.00</td>
//                   </tr>

//                   <tr>
//                     <td>
//                       <div className='d-flex align-items-center'>
//                       <img className='img-fluid rounded-3 me-4' src={artImage} alt="" width={50}/>
//                         <div className='row'>
//                         <h5 className="text-truncate font-size-14 mb-1 fs-6"  style={{color:"#000000"}}>Stainless Steel S010</h5>
//                         <p className="text-truncate mb-0" style={{fontSize:"12px"}}>Size: Medium</p>
//                         </div>
//                       </div>
//                     </td>
//                     <td style={{color:"#000000" , fontSize:"12px"}}>
//                     SS002-00M-99 99
//                     </td>
//                     <td style={{fontSize:"13px" , color:"#000000" }}>245.50</td>
//                     <td style={{color:"#000000" }}>2</td>
//                     <td className="text-end" style={{color:"#000000" }}>491.00</td>
//                   </tr>

//                   <tr>
//                     <th scope="row" colSpan={4} className="text-end"  style={{color:"#000000" }}>Sub Total</th>
//                     <td className="text-end"  style={{color:"#000000" }}>Rs. 732.50</td>
//                   </tr>
//                   {/* end tr */}
          
//                   {/* end tr */}
//                   <tr>
//                     <th scope="row" colSpan={4} className="border-0 text-end"  style={{color:"#000000" }}>
//                       Shipping Charge :</th>
//                     <td className="border-0 text-end"  style={{color:"#000000" }}>Rs. 20.00</td>
//                   </tr>
//                   {/* end tr */}
//                   <tr>
//                     <th scope="row" colSpan={4} className="border-0 text-end"  style={{color:"#000000" }}>
//                       Tax</th>
//                     <td className="border-0 text-end"  style={{color:"#000000" }}>Rs. 0</td>
//                   </tr>
//                   {/* end tr */}
//                   <tr>
//                     <th scope="row" colSpan={4} className="border-0 text-end"  style={{color:"#000000" }}>Total</th>
//                     <td className="border-0 text-end"><h6 className="m-0 fw-semibold"  style={{color:"#000000" }}>Rs. 739.00</h6></td>
//                   </tr>
//                   {/* end tr */}
//                 </tbody>{/* end tbody */}
//               </table>{/* end table */}
//             </div>{/* end table responsive */}
  
//           </div>

//           <p className='text-black mt-5' style={{fontSize:"11px"}}>
//           THANK YOU FOR YOUR BUSINESS
//           <br />
//           Thank you for your purchase from Avant Garde. Please let us know if we can do anything else for you!
//           </p>
     

//         </div>
        
     
//       </div>
//     </div>
//   </div>

//       </div>
//     )
// })

// export default Invoice;              