import axios from "axios";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import React, {useState , useEffect} from 'react';

const UpdateGrantPermissionModal = ({grantedPermissionID  , getAllGrantedPermission, memID, permID ,showGrantPermissionModal , setShowGrantPermissionModal}) => {
    const [allPermissions, setAllPermissions] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const [memberID , setMemberID] = useState(memID);
    const [permissionID , setPermissionID] = useState(permID);

    const [loading , setLoading] = useState(false);


    const getAllUsers = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_users`)
        .then((res)=>{
          setAllUsers(res.data.Users)
        })
        .catch((err)=>{
            return err
        })
    }



    const getAllPermission = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_permissions`)
        .then((res)=>{
            setAllPermissions(res.data.Permissions)
        })
        .catch((err)=>{
            return err
        })
    }



  
    const updatedGrantedPermission =  ()=>{

        setLoading(true)


            const grantPermissionObj ={
                user_id:memberID,
                permission_id:permissionID,
                added_by:"bilal11"
              }
              axios.post(`${process.env.REACT_APP_BASE_URL}update_granted_permission_by_id/${grantedPermissionID}`, grantPermissionObj)
              .then((res)=>{
                if(res.data.status === '200'){

                    setLoading(false)
                    toast.info("Permission Updated!")

                    // setMemberID('');
                    // setPermissionID('');

                    getAllGrantedPermission()
        
                }
          })
          .catch((error)=>{
            
            if(error){
                console.log(error)
                setLoading(false)
                toast.warn("Something went wrong! Please try again later..")
                // setMemberID('');
                // setPermissionID('');
    
            }
   
           
        })
  
  
    }


    useEffect(() => {
        getAllUsers()
        getAllPermission()

    }, [])
  return (
    <>
    <Modal
      size="lg"
      show = {showGrantPermissionModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
            Update Granted Permission
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <div className="row">
            <div className="col-lg-6 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Member
                        </label>

                        <select className={"form-select"} aria-label="Default select example"
                        value={memberID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          setMemberID(selectedValue)

                        //   const selectedText = e.target.options[e.target.selectedIndex].text;
                        //   setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value=""> ---------------- &nbsp; &nbsp; Select Member &nbsp; &nbsp;---------------- </option>
                          {
                            allUsers && allUsers.length > 0 ?

                            allUsers?.map((item , id)=>{
                              return(
                                <option key={id} value={item.id}>{item.username}</option>
                              )
                            })

                            :

                            <option value="">No User Found !</option>
                          }
                          
                        </select>
            </div>

                        
            <div className="col-lg-6 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Permission
                        </label>

                        <select className={"form-select"} aria-label="Default select exampleTwo"
                        value={permissionID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          setPermissionID(selectedValue)

                        //   const selectedText = e.target.options[e.target.selectedIndex].text;
                        //   setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value=""> ---------------- &nbsp; &nbsp; Select Permission &nbsp; &nbsp;---------------- </option>
                          {
                            allPermissions && allPermissions.length > 0 ?

                            allPermissions?.map((item , id)=>{
                              return(
                                <option key={id} value={item.permission_id}>{item.permission_name}</option>
                              )
                            })

                            :

                            <option value="">No User Found !</option>
                          }
                          
                        </select>
            </div>

            </div>


      </Modal.Body>
      <Modal.Footer>
      <button className='btn btn-outline-secondary' onClick={()=>setShowGrantPermissionModal(!showGrantPermissionModal)}>Close</button>
      { 
      loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
       <button type="submit" className="btn btn-primary" onClick={updatedGrantedPermission}>Submit</button>

      }
      </Modal.Footer>
    </Modal>

    </>
  )
}

export default UpdateGrantPermissionModal