import { useQuery } from 'react-query';
import ReadMoreReact from 'read-more-react';
import React, {useState, useEffect} from 'react';
import UpdateVendor from '../Modals/UpdateVendor';
import VendorSectionEndPoint from '../../../Api/VendorSection/VendorSectionEndPoint';

const VendorSheet = () => {
  const {mutate:deleteVendor , isLoading } = VendorSectionEndPoint.useDeleteVendor();

  const[getVendors , setVendors] = useState([]);
  const[vendorID , setVendorID] = useState('');
  const[showVendorModal , setShowVendorModal] = useState(false);


  const [vendorName , setVendorName] = useState('');
  const [vendorPhone , setVendorPhone] = useState('');
  const [vendorEmail , setVendorEmail] = useState('');
  const [date , setDate] = useState('');
  const [filterButton , setFilterButton] = useState(false);



    // Hook for pagination:
    const[pages , setPages] = useState(1);
    const[count , setCount] = useState('');
  
    const [filteredCount, setFilteredCount] = useState(0);
  
  
  
    const resultsPerPage = 20;
  // function for getting page next:
    const handleNextPage = () => {
      setPages((prevPage) => prevPage + 1);
    };
  // function for getting previous page:
    const handlePrevPage = () => {
      setPages((prevPage) => Math.max(prevPage - 1, 1));
    };

    const totalResults = count|| 0;
    const startResult = (pages - 1) * resultsPerPage + 1;
    const endResult = Math.min(pages * resultsPerPage, totalResults);

    // Getting all textile items function:
  const {refetch}  =  useQuery(["all_venodrs" , pages], _=> VendorSectionEndPoint.getAllVendors(pages), {
      onSuccess: (data) => {
        setVendors(data.data.Vendors);
        setCount(data.data.TotalCount);
      },
      onError: (err) => {
        return err;
      },
    })


    function removeVendorsFromSheet(index) {
      setVendors((prevState) => {
        const vendors = [...prevState];
        vendors.splice(index, 1);
        return vendors;
      });
    }

    function delVendor(id,index){
      deleteVendor({id,removeVendorsFromSheet,index})
    }

    
    function openModal(id){
      setShowVendorModal((p)=>!p)
      setVendorID(id)
       
    }


    function AllVendors({items , index}){
      return (
        <tr key={index}className="text-center">
          <td>{getVendors.length - index}</td>
          <td>{items.username}</td>
          <td>{items.added_by_who}</td>
          <td>{items.vendor_name}</td>
          <td>{items.vendor_phone_number}</td>
          <td>{items.vendor_email}</td>
          <td>
          <ReadMoreReact
               text={
                    items.vendor_address
                }
                min={20}
                ideal={35}
                max={50}
              readMoreText="Read more..."
            />  
          
          </td>
          <td>{items.Idate}</td>
    <td>
    <div className="dropdown">
      <button
        type="button"
        className="btn p-0 dropdown-toggle hide-arrow"
        data-bs-toggle="dropdown"
      >
        <i className="bx bx-dots-vertical-rounded" />
      </button>
      <div className="dropdown-menu">
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>openModal(items.vendor_id)}
        >
          <i className="bx bx-edit-alt me-1" /> Edit
        </button>
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>{
            delVendor(items.vendor_id,index)
          }}
        >
          <i className="bx bx-trash me-1" /> Delete
        </button>
      </div>
    </div>
  </td>
</tr>

      )

    }
    
    const filterVendorData =  getVendors.length > 0 && 
      vendorName !== "" && vendorPhone === "" &&  vendorEmail === "" && date === "" 
         ? getVendors.filter((items) => items.vendor_name === vendorName)
         :
         vendorName === "" && vendorPhone !== "" &&  vendorEmail === "" && date === "" 
         ? getVendors.filter((items) => items.vendor_phone_number === Number(vendorPhone))
         :
         vendorName === "" && vendorPhone === ""&& vendorEmail !== "" && date === "" 
         ? getVendors.filter((items) => items.vendor_email === vendorEmail)
         :
         vendorName === "" && vendorPhone === ""&& vendorEmail === "" && date !== "" 
         ? getVendors.filter((items) => items.Idate === date)
         :
         (vendorName !== "" && vendorPhone !== "") && vendorEmail === "" && date === "" 
         ? getVendors.filter((items) => (items.vendor_name === vendorName && items.vendor_phone_number ===  Number(vendorPhone)))
         :
         (vendorName !== "" && vendorEmail !== "") && vendorPhone === "" && date === "" 
         ? getVendors.filter((items) => (items.vendor_name === vendorName && items.vendor_email === vendorEmail))
         :
         (vendorName !== "" && date !== "") && vendorPhone === "" && vendorEmail === "" 
         ? getVendors.filter((items) => (items.vendor_name === vendorName && items.Idate === date))

         :
         (vendorPhone !== "" && vendorEmail !== "") && vendorName === "" && date === "" 
         ? getVendors.filter((items) => (items.vendor_phone_number ===  Number(vendorPhone) && items.vendor_email === vendorEmail ))
         :
         (vendorPhone !== "" && date !== "") && vendorName === "" && vendorEmail === "" 
         ? getVendors.filter((items) => (items.vendor_phone_number ===  Number(vendorPhone) && items.Idate === date))
         :
         (vendorEmail !== "" && date !== "") && vendorPhone === "" && vendorEmail === "" 
         ? getVendors.filter((items) => (items.vendor_email === vendorEmail && items.Idate === date))
         : getVendors

      
    useEffect(() => {
      setFilteredCount(filterVendorData.length);
    }, [filterVendorData])

    
  return (
    <>
     <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light">Vendor Sheet </span> 
            </h4>

            
            <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Vendor name
                          </label>
                          <input
                            type="text"
                            placeholder="Search with vendor name..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setVendorName(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Vendor phone
                          </label>
                          <input
                            type="number"
                            placeholder="Search with vendor phone..."
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setVendorPhone(e.target.value) }
                          />
                      </div>
                    
                      <div className="col-lg-3">
                        <label
                          className="form-label fw-bold"
                        >
                          Vendor Email
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="email"
                            placeholder="Enter Vendor email..."
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setVendorEmail(e.target.value) }
                          />

                        </div>


                      </div>
                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }

            <div className="card">
              <h5 className="card-header">Vendor Sheet</h5>
              <div className="table-responsive text-nowrap">
                {
                  filterVendorData && filterVendorData.length > 0 ?
                  <table className="table">
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>Username</th>
                      <th>Added By</th>
                      <th>Vendor Name</th>
                      <th>Vendor Phone</th>
                      <th>Eamil</th>
                      <th>Address</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                   
                     {
                        filterVendorData?.map((items , index)=>{
                          return(
                            <AllVendors items={items} index={index}/>
                          )
                        })
                     }

                  </tbody>
        
                </table>
                :
                <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
                }

              <div className="p-3">
              <button className="btn btn-outline-primary btn-sm" onClick={handlePrevPage} disabled={pages === 1}>
                <i className="fa-solid fa-arrow-left"></i>
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-outline-primary btn-sm" onClick={handleNextPage} disabled={totalResults <= endResult}>
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
                <p >Showing {startResult} - {endResult} of {filteredCount} results  -  total :&nbsp;&nbsp;{count}</p>
              </div>

           
              </div>

            </div>

          </div>

          {/*Update Sub Category Modal */}
          {
            showVendorModal === true && (

              <UpdateVendor 
              vendorID ={vendorID} 
              showVendorModal = {showVendorModal} 
              setShowVendorModal = {setShowVendorModal}
              refetchVendors = {refetch}
              />
            )
          }
          {/* *Update Sub Category Modal */}
        </div>
      </div>
    </>
  )
}

export default VendorSheet