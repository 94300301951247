import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState , useEffect} from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
import VendorSectionEndPoint from '../../../Api/VendorSection/VendorSectionEndPoint';
const VendorForm = () => {
  const {mutate:vendorSubmission , isLoading } = VendorSectionEndPoint.useSubmitVendors();
  
  // Items Hooks;
  const [vendorName , setVendorName] = useState('');
  const [vendorPhone , setVendorPhone] = useState('');
  const [vendorEmail , setVendorEmail] = useState('');
  const [vendorAddress , setVendorAddress] = useState('');

  const[userData , setUserData] = useState('');


  // loading and conditing hooks:
  const[input , setInput] = useState(false);


  function submitVendor(){
    if(vendorName === '' || vendorPhone === '' || vendorEmail === ''  || vendorAddress === '' ){
      setInput(true)
      toast.warn("Fill the information !")
      
    }
    else{
      const vendorObj = {
        role_id :userData.role_id,
        user_id:userData.id,
        added_by_who:userData.username,
        vendor_name: vendorName,
        vendor_phone_number:vendorPhone,
        vendor_email: vendorEmail,
        vendor_address:vendorAddress,
      }

      
      vendorSubmission((vendorObj) , {
        onMutate: () => {
        },
        onSettled: () => {

          setInput(false);

          setVendorName('');
          setVendorPhone('');
          setVendorEmail('');
          setVendorAddress('')
  
        },

      
      })
 
    }

  }

  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
  }, [])
  

  return (
    <>
         <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light"></span> 
              Vendor Form
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Vendor Form</h5>
                    {/* <small className="text-muted float-end">
                      Default label
                    </small> */}
                  </div>
                  <div className="card-body">

                    <div className="row">
                      <div className="col-lg-4">
                      <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-company"
                          >
                            Vendor name
                          </label>
                          <input
                            type="text"
                            id="basic-default-company"
                            placeholder="Enter Vendor name..."
                            className = {vendorName === ''&& input === true?"form-control border border-danger":"form-control"}
                            value={vendorName}
                            onChange={(e)=>setVendorName(e.target.value) }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-company"
                          >
                            Vendor phone
                          </label>
                          <input
                            type="number"
                            id="basic-default-company"
                            placeholder="Enter Vendor phone..."
                            className = {vendorPhone === ''&& input === true?"form-control border border-danger":"form-control"}
                            value={vendorPhone}
                            onChange={(e)=>setVendorPhone(e.target.value) }
                          />
                        </div>
                      </div>
                    
                      <div className="col-lg-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-email"
                        >
                          Vendor Email
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="email"
                            id="basic-default-email"
                            placeholder="Enter Vendor email..."
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            className = {vendorEmail === ''&& input === true?"form-control border border-danger":"form-control"}
                            value={vendorEmail}
                            onChange={(e)=>setVendorEmail(e.target.value) }
                          />

                        </div>

                      </div>

                      </div>


                      <div className="col-lg-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Vendor Address
                        </label>
                        <textarea
                          type="number"
                          id="basic-default-phone"
                          placeholder="Enter Vendor address..."
                          className = {vendorAddress === ''&& input === true?"form-control border border-danger":"form-control"}
                          value={vendorAddress}
                          onChange={(e)=>setVendorAddress(e.target.value) }
                          rows={5}
                        />
                      </div>
                      </div>
  
                    </div>
                    {
                        isLoading ?
                        <div className="spinner-border text-primary float-end" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                    <button type="submit" className="btn btn-primary float-end" onClick={submitVendor}>
                      Submit
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorForm