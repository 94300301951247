import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import GraphErr from '../Images/graphErr.svg';
const ExpenseGraph = () => {
    const [financeGraphData, setFinanceGraphData] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    
    const [checkBoxStatus , setCheckBoxStatus] = useState(false)
    const [checkBoxVal , setCheckBoxVal] = useState('')
  
  
    const [startDate ,setStartDate] = useState('');
    const [endDate , setEndDate] = useState('');
    const [enableSearchBtn , setEnableSearchBtn] = useState(false);
  
  
    const handleCheckInput = (val)=>{
      setSelectedCheckbox(val);
      graphFunc(val)
    }
  

  
    function graphFunc(val){
   
  
      var formdata = new FormData();
  formdata.append("type", val);
  formdata.append("start_date", startDate);
  formdata.append("end_date", endDate);
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  fetch("https://avantgarde.alphanitesofts.net/api/fetch_graphs_data", requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === '200'){
        setFinanceGraphData(result?.data)
      }
    })
  
  
    .catch(error => console.log('error', error));
  
    }
  
    
  function clearFilters(){
    setStartDate('');
    setEndDate('');
    setCheckBoxVal('');
    setEnableSearchBtn(false)
  }
  
  

//expense data:
const expenseDate = financeGraphData?.map((items)=> items.Idate)
const expenseAmount = financeGraphData?.map((items)=> items.expence_price)
const expenseName = financeGraphData?.map((items)=> items.expence_name)

    const expense = {

        series: [
          {
            name: 'Expense Amount - Expenses',
            data: expenseAmount,
            color: '#00bcd4'
          },
        ],
        options: {
          chart: {
            type: 'bar',
            height: 300,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories:financeGraphData?.map((data, index) => {
                return `${data.expence_date} - ${data.expence_name}`;
              }),
          },
          yaxis: {
            title: {
              text: 'Expenses',
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return 'Rs.' + val;
              },
            },
          },
        },
      }
  return (
    <>
        <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mb-5">
              <div className="card-header">
                <h4 className="fw-bold">
                  <span className="text-muted fw-light">Expense Graph</span>
                </h4>
              </div>

              <div className="d-flex">
              <div className="form-check ms-5">
                <input
                  className="form-check-input fs-5 border border-primary"
                  type="checkbox"
                  id="inlineCheckbox2"
                  defaultValue="option2"
                  onChange={()=> 
                    {
                    handleCheckInput('expence')
                    setCheckBoxVal('expence')

                    setCheckBoxStatus(true)
                }}
                  checked={selectedCheckbox === 'expence' && checkBoxStatus === true}
                />
                <label
                  className="form-check-label fs-5"
                  htmlFor="inlineCheckbox2"
                >
                  Expence
                </label>
              </div>
     
                &nbsp;&nbsp;&nbsp;
             
                {
                checkBoxStatus === true ?
                  <>
                          <div className="col-lg-3">
                  <div className="input-group input-group-sm">
                    <label
                      className="input-group-text border border-primary fw-bold"
                      htmlFor="inputGroupSelect01"
                    >
                      From*
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm border border-primary"
                      id="inputGroupSelect01"
                      value={startDate}
                      onChange={(e)=> setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                &nbsp;&nbsp;
                <div className="col-lg-3">
                  <div className="input-group input-group-sm">
                    <label
                      className="input-group-text border border-primary fw-bold"
                      htmlFor="inputGroupSelect02"
                    >
                      To*
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm border border-primary"
                      id="inputGroupSelect02"
                      value={endDate}
                      onChange={(e)=> {
                        setEndDate(e.target.value)
                        setEnableSearchBtn(true)
                      }}

                    />
                  </div>
                </div>
                &nbsp; &nbsp;
                {
                  enableSearchBtn === true ?
                  <>
                  <button className="btn btn-sm btn-outline-primary" onClick={()=>graphFunc(checkBoxVal)}> 
                  <i className="fa fa-magnifying-glass"/>
                  &nbsp;&nbsp;
                  search
                  </button>
                  &nbsp;&nbsp;
                  <button className="btn btn-sm btn-outline-primary" onClick={()=>clearFilters(checkBoxVal)}> 
                  <i className="fa fa-filter"/>
                  &nbsp;&nbsp;
                  clear filters
                  </button>
                  
                  
                  </>
                  :
                  null
                }
               
                  </>
                  :
                  null
                }
          

              </div>

              <div className="card-body">
              {
                selectedCheckbox === "expence" ?
                <ReactApexChart
                    options={expense.options}
                    series={expense.series}
                  type="bar"
                  height={300}
                />

                :
                <>
              <img className='img-fluid d-block mx-auto m-5' src={GraphErr} alt="" width={300}/>
             </>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExpenseGraph