import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useMutation , useQueryClient} from 'react-query';


function getAllPromoCodes(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_codes?page=${pages}`)
  
}

 function getPromosByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_code_by_id/${ID}`)
 }

 
function promocodeSubmission(formdata){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_promo_code`, formdata)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Promocode Submit!")
          return res.data
          
        }
        else {
            throw new toast.error("Can't submit!");
        }
    })
  .catch((error)=>{
    if(error){
      toast.warn("Something went wrong, please try again in a while")
    }
    })
}

function updatePromos({formdata,promoID}){
  
   return axios.post(`${process.env.REACT_APP_BASE_URL}update_code_by_id/${promoID}`, formdata)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Promocode Updated!")
  }
  else {
    throw new toast.error("Can't submit!");
  }
  })
  .catch((error)=>{
    if(error)
    {
      toast.warn("Something went wrong, please try again in a while")
    }
    
 
})

}


function deletePromos({id,removePromosFromSheet,index}){

    axios.post(`${process.env.REACT_APP_BASE_URL}delete_code_by_id/${id}`)
    .then((res)=>{
        if(res.data.status === '200'){
            toast.error("Promocode Removed")
            removePromosFromSheet(index)
            
      }
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}

const useSubmitPromoCode = ()=>{
    return useMutation(promocodeSubmission)
}

const useUpdatePromos = ()=>{
  return useMutation(updatePromos)
}

const useDeletePromocode = ()=>{
  return useMutation(deletePromos)
}


const EventSectionEndPoint ={
    getAllPromoCodes,
    useSubmitPromoCode,
    useUpdatePromos,
    useDeletePromocode,
    getPromosByID
}

export default EventSectionEndPoint;