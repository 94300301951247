import axios from "axios";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import React, {useState , useEffect} from 'react';

const UpdatePermissionModal = ({permissionID , permissionTo , getAllPermission ,showPermissionModal , setShowPermissionModal}) => {

    const [permissionName , setPermissionName] = useState('');
    const [loading , setLoading] = useState(false);

    
    // const getPermissionByID = ()=>{
    //     axios.post(`${process.env.REACT_APP_BASE_URL}fetch_permission_by_id/${permissionID}`)
    //     .then((res)=>{
    //         setPermissionName(res.data.Permissions.permission_name)
    //         console.log(res.data)
    //     })
    //     .catch((err)=>{
    //         return err
    //     })
    // }


    // useEffect(() => {
    //     getPermissionByID()
    // }, [])
    
  

    
    const updatePermission =  ()=>{

        setLoading(true)
        if(permissionName){
            const permissionObj ={
                permission_name:permissionName,
                added_by:"bilal11"
              }
              axios.post(`${process.env.REACT_APP_BASE_URL}update_permission_by_id/${permissionID}`, permissionObj)
              .then((res)=>{
                if(res.data.status === '200'){
                    setLoading(false)
                    toast.info("Permission Added!")
                    setPermissionName('')
                    getAllPermission()
                }
         
  
     
          })
          .catch((error)=>{
            
            if(error){
                setLoading(false)
                setPermissionName('')
                toast.warn("Something went wrong! Please try again later..")
            }
   
           
        })
  
      }
   
      else{
        
        toast.warn("Fill the information !")
        setLoading(false)
      }
    }
  return (
    <>
        <Modal
      size="md"
      show = {showPermissionModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
            Update Permission
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

              <div className="form-group mb-2">
                        <label className="fw-bold mb-2" htmlFor="exampleInputEmail3">Permission name*</label>
                        <input type="text" name="permissionName" defaultValue={permissionTo} className="form-control" id="exampleInputEmail3"  onChange={(e)=>setPermissionName(e.target.value)} placeholder="Enter Permission name"  />
                    </div>


      </Modal.Body>
      <Modal.Footer>
      <button className='btn btn-outline-secondary' onClick={()=>setShowPermissionModal(!showPermissionModal)}>Close</button>
      { 
      loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
       <button type="submit" className="btn btn-primary" onClick={updatePermission}>Submit</button>

      }
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default UpdatePermissionModal