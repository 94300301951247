import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState, useEffect} from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
import PromocodeSection from '../../../Api/PromocodeSection/PromocodeSection';

const PromoCodeForm = () => {
  const {mutate:promocodeSubmission , isLoading } = PromocodeSection.useSubmitPromoCode();

  const [input , setInput] = useState(false)
  const [promocode , setPromoCode] = useState('');
  const [promoCodeFor , setPromoCodeFor] = useState('');
  const [discountPromoCode , setDiscountPromoCode] = useState('');
  const [promocodeValidTill , setPromoCodeValidTill] = useState('');

  const [userData , setUserData] = useState('');
  


  
  function submitPromoCode(){
    if(promocode === '' || promoCodeFor === ''  || discountPromoCode === '' || promocodeValidTill === ''){
      setInput(true)
      toast.warn("Fill the information !")
      
    }
    else{
      var formdata = new FormData();
      formdata.append("code", promocode);
      formdata.append("promo_for", promoCodeFor);
      formdata.append("added_by",userData.username);
      formdata.append("user_id",userData.id);
      formdata.append("role_id",userData.role_id);
      formdata.append("discount",discountPromoCode);
      formdata.append("valid_till",promocodeValidTill);


      
      promocodeSubmission((formdata) , {
        onMutate: () => {
        },
        onSettled: () => {

          setInput(false);

          setPromoCode('');
          setPromoCodeFor('');
          setDiscountPromoCode('');
          setPromoCodeValidTill('')
        },

      
      })
 
    }

  }

  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
  }, [])



  return (
    <>
        <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div class="container-xxl flex-grow-1 container-p-y">
            <h4 class="fw-bold py-3 mb-4">
              <span class="text-muted fw-light">Promocode Form</span> 
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Promocode Form</h5>
                  {/* <small className="text-muted float-end">Default label</small> */}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Promo Code
                        </label>
                        <input
                          type="text"
                          value={promocode}
                          className={promocode === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname"
                          placeholder="Enter Promocode"
                          onChange={(e)=>setPromoCode(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Promocode For
                        </label>
                        <input
                          type="text"
                          value={promoCodeFor}
                          className={promoCodeFor === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-company"
                          placeholder="Enter Promocode For"
                          onChange={(e)=>setPromoCodeFor(e.target.value)}

                        />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company01"
                        >
                          Discount On Promo
                        </label>
                        <input
                          type="text"
                          value={discountPromoCode}
                          className={discountPromoCode === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-company01"
                          placeholder="Enter discount on Promocode"
                          onChange={(e)=>setDiscountPromoCode(e.target.value)}

                        />
                      </div>

                      
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company02"
                        >
                          Valid Till
                        </label>
                        <input
                          type="date"
                          value={promocodeValidTill}
                          className={promocodeValidTill === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-company02"
                          placeholder=""
                          onChange={(e)=>setPromoCodeValidTill(e.target.value)}

                        />
                      </div>
                    </div>

                    {
                        isLoading ? 
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                    <button type="submit" className="btn btn-primary" onClick={submitPromoCode}>
                      Submit
                    </button>
                }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PromoCodeForm