import Chart from 'react-apexcharts';
import {useQuery} from "react-query";
import React,{useState} from 'react';
import StatisticsSectionEndPoint from '../Api/StatisticsSection/StatisticsSectionEndPoint';


const FinancePieChart = () => {
    const [itemReceiving , setItemReceiving] = useState(0);
    const [articleReceiving , setArticleReceiving] = useState(0);
    const [staffSpending , setStaffSpending] = useState(0);
    const [otherExpences , setOtherExpences] = useState(0);
    const [grossIncome , setGrossIncome] = useState(0);
    const [paidCourier ,setPaidCourier] = useState(0);
    const [totalSpent , setTotalSpent] = useState(0);
    const [profit , setProfit] = useState(0);
    const [dcPendings , setDCPendings] = useState(0);



  useQuery("all_finance_amounts",  StatisticsSectionEndPoint.allFinanceAmounts,
  {
    onSuccess: (data) => {
        setItemReceiving(data.data?.item_receiving);
        setArticleReceiving(data.data?.article_receiving);
        setStaffSpending(data.data?.pay_staff);
        setOtherExpences(data.data?.expence);
        setGrossIncome(data.data?.gross_income)
        setPaidCourier(data.data?.courier_paid)
        setTotalSpent(data.data?.total_spent)
        setProfit(data.data?.profit)
        setDCPendings(data.data?.dc_pending)
    },
    onError: (err) => {
      return err;
    },
  }
)


    const chartOptions = {
      series: [itemReceiving, articleReceiving, staffSpending, otherExpences, grossIncome , paidCourier ,totalSpent,profit,dcPendings],
      
      chart: {
        type: 'donut',
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
              },
            },
          }
        },
      },
      labels: ['Item Receiving', 'Article Receiving', 'Staff Spendings', 'Other Expenses', 'Gross Income','Paid to Couriers','Total Spend','Profit','Courier Pendings'], // Change label names here
      colors: ['#8bc34a', '#42a5f5', '#26a69a', '#00e5ff','#66bb6a','#ffca28','#ff7043','#ab47bc','#c6ff00'],
    }
    
  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div class="container-xxl flex-grow-1 container-p-y">
            
            <div className="card mb-5">
                <div className="card-header">
                <h4 className="fw-bold">
                    <span className="text-muted fw-light">Finance Pie Chart</span>
                  </h4>
                </div>
                <div className="card-body">
                    <Chart options={chartOptions} series={chartOptions.series} type="donut" height={400} />
                </div>
      
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancePieChart;
