import axios from 'axios';
import React, {useState} from 'react';
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";

const GenerateCSVToWhatsapp = ({
    orderID ,
    openCSVModal,
    setOpenCSVModal,
    selectedWhatsappCSVContent,
    index
}) => {
    const [loading , setLoading] = useState(false);

    function submitOTP(){
        // setLoading(true)
          // const optObj = {
          //   to:+923354546704,
          //   file:selectedWhatsappCSVContent
        
          // }
          // const formdata = new FormData();
          // formdata.append("file", selectedWhatsappCSVContent, "[PROXY]");
          // formdata.append("to", +923354546704);

          // axios.post(`${process.env.REACT_APP_BASE_URL}sendMediaMessage`,formdata )
          // .then((res)=>{
          //   setLoading(false)
          //   console.log(res)
            // if(res.status === 200){
            //     toast.info("CVS submit!")
            //     setLoading(false)
              
            // }
            // else {
            //   setLoading(false)
            //   throw new toast.error("Can't submit items!");
            // }
          // })
        // .catch((error)=>{
        //   console.log(error)
        //   setLoading(false)
          // if(error){
          //   setLoading(false)
          //   toast.warn("Something went wrong, please try again in a while")
          // }
          // })
          const myHeaders = new Headers();
myHeaders.append("Authorization", "Basic QUM5MGY1YjBhM2Y4N2JiOWI2M2EwYTIyOWQyZTQyZTZjZTozNGE5ZjkyNGJlNjMzMDYwZTc4NTY5YmI0NDhhNDA4OA==");
          
            const formdata = new FormData();
            formdata.append("file",selectedWhatsappCSVContent,);
            formdata.append("to", +923354546704);

            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow"
            };

            fetch("https://avantgarde.alphanitesofts.net/api/sendMediaMessage", requestOptions)
              .then((response) => response.text())
              .then((result) => console.log(result))
              .catch((error) => console.error(error));
          
        }
 
  return (
    <>
<Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={openCSVModal}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
       Submit Marked Orders?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

  
        <p  style={{fontSize:"17px"}}>  Procced the action! by hitting submit ... <i className="fa-solid fa-circle-info"/></p>

       
      
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-outline-dark' onClick={()=> setOpenCSVModal(!openCSVModal)}>
        Close
        </button>
        {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
     
        :
        <button className='btn btn-outline-primary' onClick={submitOTP}>
            Submit
        </button>
}
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default GenerateCSVToWhatsapp