import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DispatchImage from '../../Images/dispatch.gif';
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";


const DispatchModal = ({orderAction,orderID,index,showDispatchModal,setShowDispatchModal,removeSpecificeOrder}) => {
  const { mutate: approveOrdersByStatus, isLoading } =  OrderIntakeSectionEndPoint.useApproveOrderStatus();

    function updateOrderStatus() {
        const orderStatusObj = {
          status: "dispatched",
        };
    
        approveOrdersByStatus(({orderStatusObj,orderAction ,orderID,index,removeSpecificeOrder}), {
          onMutate: () => {
    
          },
          onSettled: () => {
          },
        });
    }
  return (
    <>
      <Modal
        show={showDispatchModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>
          <i className="fa-solid fa-box fs-3"/> &nbsp; Order#
        {orderID}     
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black fs-4">
        <div className="row text-center">
                <div className="col-lg-12">
                Move this order to {orderAction} orders?  <img className="img-fluid fs-5"src={DispatchImage} alt="orderBox" width={40}/>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-dark"  onClick={()=> setShowDispatchModal(!showDispatchModal)}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={updateOrderStatus}>
         {isLoading ? "...":"Okay ?"} 
        </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DispatchModal