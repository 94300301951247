import axios from 'axios';
import {useQuery} from "react-query";
import Error from "../../Error/Error";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState, useEffect} from "react";
import StaffSectionEndPoint from '../../Api/StaffSection/StaffSection';
import CourierSectionEndPoint from '../../Api/CourierSection/CourierSectionEndPoint';



const PayCourierSheet = () => {
  const { mutate: deleteStaffMember } = StaffSectionEndPoint.useDeleteStaffMember();
  const { mutate: getOrderDataByCourier, data:courierData } = CourierSectionEndPoint.OrderDataByCourierID();
  const { mutate: courierAndOrderAmountSubmissions , isLoading} = CourierSectionEndPoint.CourierAndOrderAmount();



  const[allStaff , setAllStaff] = useState([]);
  const[staffID , setStaffID] = useState('');

  // Hook for pagination:
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState("");

  const [allCourier , setAllCourier] = useState([]);
  const [courierID , setCourierID] = useState('');

  
  const [selectedCourier, setSelectedCourier] = useState('');
  const [autoFetch, setAutoFetch] = useState(false);
  const [showMultiCheckButton, setShowMultiCheckButton] = useState(false);


const [multiSelectApproveOrderIDs, setMultiSelectApproveOrderIDs] = useState([]);
const [courierCharges , setCourierCharges] = useState('');
const [totalAmount , setTotalAmount] = useState('');
const [bankAmountTotal , setBankAmountTotal] = useState('');
const [stripeAmountTotal , setStripeAmountTotal] = useState('');
const [nonCodAmount , setNonCodAmount] = useState('');

const [invoiceNum , setInvoiceNum] = useState('');


const [allBanks, setAllBanks] = useState([]);
const [selectedBank , setSelectedBank] = useState('');


  const resultsPerPage = 20;
  // function for getting page next:
  const handleNextPage = () => {
    setPages((prevPage) => prevPage + 1);
  };
  // function for getting previous page:
  const handlePrevPage = () => {
    setPages((prevPage) => Math.max(prevPage - 1, 1));
  };

  const totalResults = count || 0;
  const startResult = (pages - 1) * resultsPerPage + 1;
  const endResult = Math.min(pages * resultsPerPage, totalResults);



  // Getting all couriers:
  useQuery("all_Courier",  CourierSectionEndPoint.getAllCourier,
        {
          enabled:!autoFetch,
          onSuccess: (data) => {
            setAllCourier(data.data.Couriers);
            setAutoFetch(true)
          },
          onError: (err) => {
            return err;
          },
        }
      )


    function getAllBanks(){
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
        .then((res)=>{
            setAllBanks(res.data.Banks)
        })
        .catch((err)=>{
            return err
        })
    }

      
  const handleSelectCourier = (e) => {
    setCourierID(e.target.value) 
    setSelectedCourier(e.target.value)
  }

  const handleSelectBank= (e) =>{
    setSelectedBank(e.target.value)
  }
  
  function gettingOrderDataByCourierID(){
    const courierIDObj = {
      courier_id :Number(courierID)
   
    };

    getOrderDataByCourier((courierIDObj), {
      onMutate: () => {

      },
      onSettled: () => {
      },
    });
  }

  

  function submitOrderCourierAmounts(){
    const orderAndCourierObj ={
      courier_id:courierID,
      order_id: multiSelectApproveOrderIDs,
      total_payable:courierCharges,
      total_receiveable:totalAmount,
      paid_via_bank:bankAmountTotal,
      paid_via_stripe:stripeAmountTotal,
      non_cod: nonCodAmount,
      pay_via:selectedBank,
      invoice_no : invoiceNum
    }
    
   courierAndOrderAmountSubmissions((orderAndCourierObj), {
      onMutate: () => {

      },
      onSettled: () => {
        // setMultiSelectApproveOrderIDs([])
        setShowMultiCheckButton(false);
        // setSelectedBank('');
        // setInvoiceNum('');
      },
    });

 

  }


  function removeStaffFromSheet(index) {
    setAllStaff((prevState) => {
      const staff = [...prevState];
      staff.splice(index, 1);
      return staff;
    });
  }
function delStaff(id, index) {
  deleteStaffMember({ id, removeStaffFromSheet, index });
}




  // Multi Select approve order status function:
  const handleMultiSelectApproveOrders = (itemId) => {
    const isSelected = multiSelectApproveOrderIDs.includes(itemId);


    if (isSelected) {
      setMultiSelectApproveOrderIDs((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    } else {
      setMultiSelectApproveOrderIDs((prevSelectedItems) => [
        ...prevSelectedItems,
        itemId,
      ]);
    }
  const selectedDCCourierValue = calculateSelectedOrderValues();
  setCourierCharges(selectedDCCourierValue);
  setTotalAmount(selectedDCCourierValue)
  setBankAmountTotal(selectedDCCourierValue)
  setStripeAmountTotal(selectedDCCourierValue)


  };



  const calculateSelectedOrderValues = () => {
   
      if (multiSelectApproveOrderIDs.length === 1) {
        const selectedItem = courierData.data.find(item => item.id === multiSelectApproveOrderIDs[0]);

        const resultDCCourier  = selectedItem ? selectedItem.dc_courier : 0;
        const resultTotalPrice = selectedItem  ? selectedItem.total : 0;


        setCourierCharges(resultDCCourier);
        setTotalAmount(resultTotalPrice);

      } else if (multiSelectApproveOrderIDs.length > 1) {
        let sumDCCourier  = 0;
        let sumTotalPrice = 0;
        let sumTotalBank = 0;
        let sumTotalStripe = 0;

        for (const itemId of multiSelectApproveOrderIDs) {
          const selectedItem = courierData.data.find(item => item.id === itemId);
          
          if (selectedItem ) {
            sumDCCourier  += parseInt(selectedItem.dc_courier, 10);
            sumTotalPrice += parseInt(selectedItem.total,10);
            sumTotalBank += parseInt(selectedItem.payment_method === "bank" ?  (selectedItem.total) : 0);
            sumTotalStripe += parseInt(selectedItem.payment_method === "stripe" ? (selectedItem.total) : 0 );
          }
        }
        setCourierCharges(sumDCCourier);
        setTotalAmount(sumTotalPrice);
        setBankAmountTotal(sumTotalBank);
        setStripeAmountTotal(sumTotalStripe);
        setNonCodAmount(sumTotalBank + sumTotalStripe)

    

      } else {
        setCourierCharges(0);
        setTotalAmount(0);
        setBankAmountTotal(0);
        setStripeAmountTotal(0);


      }

    return courierCharges;
    
  };


  

useEffect(() => {
  calculateSelectedOrderValues();
  getAllBanks();
}, [multiSelectApproveOrderIDs, courierData?.data])




function changeCourierCharges(id , courierCharges){
  const courierObj ={
    dc_courier:courierCharges,
  }
  axios.post(`${process.env.REACT_APP_BASE_URL}update_dc_courier_by_order_id/${id}`, courierObj)
  .then((res)=>{
    if(res.data.status === '200'){
        toast.info("Courier Charges Updated!");

    }
})
.catch((error)=>{

if(error){
    toast.warn("Something went wrong! Please try again later..")

}


})

}


function OrderDataByCourier({ items, index }) {

  const [showCourierInput , setShowCourierInput] = useState(false)
  return (
    <>
       
      <tr key={index} className="text-center">
        {showMultiCheckButton ? (
        <td>
              
              <input
                className="form-check-input border border-primary"
                type="checkbox"
                id={`defaultCheck${items.id}`}
                onChange={() =>{ 
                  handleMultiSelectApproveOrders(items.id)
                }}
                checked={multiSelectApproveOrderIDs.includes(items.id)}
                
              />
        </td>
          ) : 
          <td>
            {null}
          </td>
          }
      <td>{courierData.data?.length - index}</td>
      <td>{items.id}</td>
      <td>{items.total}</td>
      <td>{items.sub_total}</td>
      <td>{items.shipment_charges}</td>
      <td>{items.payment_method}</td>
      <td>{items.status}</td>
      <td onDoubleClick={() => setShowCourierInput(!showCourierInput)}>
        {
          showCourierInput ?
          <input
          className='form-control text-center'
                      name="dc_courier"
                      defaultValue={items.dc_courier}
                      type="number"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          changeCourierCharges(items.id, e.target.value);

                        }
                      }}
                      placeholder="courier charges"
                    />
                    :

                    (items.dc_courier)
        }

      </td>
      <td>{items.cn}</td>
      

      <td>
        {
          items.order_data?.map((order)=>{
            return(
              <>
              <td>Articles# {order.pre_post}</td>
              </>
         
            )
          })
        }
      </td>

      
      <td>
        {
          items.order_data?.map((order)=>{
            return(
              <>
              <td>Size: {order.size}</td>
              </>
         
            )
          })
        }
      </td>

      <td>
        {
          items.order_data?.map((order)=>{
            return(
              <>
              <td>Qty: {order.qty}</td>
              </>
         
            )
          })
        }
      </td>



      <td>{items.Idate}</td>
      <td>
        <div className="dropdown">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
          >
            <i className="bx bx-dots-vertical-rounded" />
          </button>
          <div className="dropdown-menu">
            <button
              type="button"
              className="dropdown-item"
              // onClick={() => {
              //   delStaff(items.staff_id, index);
              // }}
            >
              <i className="bx bx-trash me-1" /> Delete
            </button>
          </div>
        </div>
      </td>
    </tr>
    </>

  );
}

// const filterStaff =  allStaff.length > 0 && 
// staffName !== "" && staffPhone === "" && staffNic === "" && date === "" 
// ? allStaff.filter((items) => items.staff_name === staffName)
// :
// staffName === "" && staffPhone !== "" && date === "" && staffNic === ""
// ? allStaff.filter((items) => items.staff_phone === staffPhone)
// :
// staffName === "" && staffPhone === "" && date === "" && staffNic !== ""
// ? allStaff.filter((items) => items.cnic === staffNic)
// :
// staffName === "" && staffPhone === "" && date !== "" && staffNic === ""
// ? allStaff.filter((items) => items.Idate === date)
// :

// (staffName !== "" && staffPhone !== "") && date === "" && staffNic === ""
// ? allStaff.filter((items) => (items.staff_name === staffName && items.staff_phone === staffPhone))
// :
// (staffName !== "" && staffNic !== "") && staffPhone === "" && date === ""
// ? allStaff.filter((items) => (items.staff_name === staffName && items.cnic === staffNic))
// :
// (staffName !== "" && date !== "") && staffPhone === "" && staffNic === ""
// ? allStaff.filter((items) => (items.staff_name === staffName && items.Idate === date))
// :
// (staffPhone !== "" && staffNic !== "") && date === "" && staffName === ""
// ? allStaff.filter((items) => (items.staff_phone === staffPhone && items.cnic === staffNic))
// :
// (staffPhone !== "" && date !== "") && staffName === "" && staffNic === ""
// ? allStaff.filter((items) => (items.staff_phone === staffPhone && items.Idate === date))
// :
// (staffNic !== "" && date !== "") && staffPhone === "" && staffName === ""
// ? allStaff.filter((items) => (items.cnic === staffNic && items.Idate === date))

// : allStaff;

// useEffect(() => {
// setFilteredCount(filterStaff.length);
// }, [filterStaff])
  return (
    <>
     <div className="scroll-view-component scrollbar-secondary-component"> 
      <div className="content-wrapper">
        <div className="container-xxl  container-p-y">
          {/* <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">Pay Couriers</span>
          </h4> */}
          
          
                  {/* <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button> */}

                  {/* 
                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Staff name..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setStaffName(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Phone*
                          </label>
                          <input
                            type="number"
                            placeholder="Search with Staff Phone..."
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setStaffPhone(e.target.value)}
                          />
                      </div>
                    
                      <div className="col-lg-3">
                        <label
                          className="form-label fw-bold"
                        >
                          Identity No*
                        </label>
                        <input
                            type="text"
                            placeholder="Search with NIC..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setStaffNic(e.target.value) }
                          />

                      </div>
                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  } */}
             

          <div className="card">
            <div className="card-body">

            <div className="d-flex">
            <div className="col-lg-4">
                <label htmlFor="emailBasic" className="form-label fw-bold">Select Courier</label>
                <select className="form-select form-select-sm border border-primary" aria-label="Default select example"
                value={selectedCourier}
                onChange={handleSelectCourier}
                >
                <option value={0}>Choose....</option>
                {
                    allCourier && allCourier.length > 0 ?
                        allCourier?.map((courier, index)=>{
                            return(
                                <option key={index} value={courier?.courier_id}>{courier.courier_name}</option>
                            )
                        })
                    :
                    "No Courier Found!"
                }
          
                </select>
                </div>
                &nbsp;&nbsp;
                <div className="col-lg-3" style={{marginTop:"2em"}}>
                  <button className="btn btn-outline-primary btn-sm" 
                  onClick={gettingOrderDataByCourierID}
                  >
                  <i className="fa-solid fa-truck-fast"/>
                  </button>
                </div>
            </div>
            <div className="col-lg-4">
       <button
       type="button"
       className={"btn btn-outline-secondary btn-sm mb-3 mt-2"}
       onClick={() => {
         setShowMultiCheckButton(!showMultiCheckButton);
       }}
     >
       <span className="tf-icons bx bx-bell"></span>&nbsp; Select Orders
     </button>
                   </div>
         
                     <div className="table table-responsive text-nowrap">
            {courierData && courierData.data && courierData.data.length > 0 ? (

                  <table className="table table-responsive table-bordered table-sm">
                    <thead>
                      <tr className="text-center">
                        {/* {
                          showMultiCheckButton === true?  
                            <th>Check</th>
                          
                          :
                          ""
                        } */}
                        <th>Check</th>
                        <th>#</th>
                        <th>Order#</th>
                        <th>Order Total</th>
                        <th>Order SubTotal</th>
                        <th>Shippment Charges</th>
                        <th>Payment Method</th>
                        <th>Courier Status</th>
                        <th>Delivery Charges</th>
                        <th>CN</th>
                        <th>Articles#</th>
                        <th>Article Sizes</th>
                        <th>Article Quantities</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border table-sm table-responsive">
                      {courierData.data?.map((items, index) => {
                        return <OrderDataByCourier items={items} index={index} />;
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">
                   <Error/>

                  </div>
                )}

        
            </div>

            </div>
            {/* <div className="card-footer">
            <div className="p-3">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handlePrevPage}
                    disabled={pages === 1}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handleNextPage}
                    disabled={totalResults <= endResult}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                  <p>
                    Showing {startResult} - {endResult} of {filteredCount}
                    results - total :&nbsp;&nbsp;{count}
                  </p>
                </div>
            </div> */}

          </div>
          <div className="row mt-3">
  <div className="col-md-6 ms-auto">
    <div className="card mb-4">
      <h5 className="card-header">"Total Amount Ratio"</h5>
      <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="" className="fw-bold mb-1">Payables*</label>
                  <input type="number" className="form-control form-control-sm" id="floatingInput1" defaultValue={courierCharges === 0 ||"" || null? courierData?.total_payable :courierCharges } placeholder="Payables..." aria-describedby="floatingInputHelp" onChange={(e)=> setCourierCharges(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label htmlFor="" className="fw-bold mb-1">Receivable*</label>
                  <input type="number" className="form-control form-control-sm" id="floatingInput2" defaultValue={totalAmount === 0 || ""|| null ? courierData?.total_receiveable : totalAmount} placeholder="Receivable..." aria-describedby="floatingInputHelp" onChange={(e)=> setTotalAmount(e.target.value)}/>
              </div>
              <div className="col-lg-6">
                <label htmlFor="" className="fw-bold mb-1 mt-1">Amounts via strip*</label>
                  <input type="number" className="form-control form-control-sm" id="floatingInput1" defaultValue={stripeAmountTotal === 0 || "" || null ?courierData?.paid_via_stripe : stripeAmountTotal} placeholder="Amounts via strip..." aria-describedby="floatingInputHelp" onChange={(e)=> setStripeAmountTotal(e.target.value)}/>
              </div>
              <div className="col-lg-6">
                <label htmlFor="" className="fw-bold mb-1 mt-1">Amounts via bank*</label>
                  <input type="number" className="form-control form-control-sm" id="floatingInput2" defaultValue={bankAmountTotal === 0 || "" || null ? courierData?.paid_via_bank : bankAmountTotal } placeholder="Amounts via bank..." aria-describedby="floatingInputHelp" onChange={(e)=> setBankAmountTotal(e.target.value)}/>
              </div>

              <div className="col-lg-6">
                <label htmlFor="" className="fw-bold mb-2 mt-1">Non-COD amounts*</label>
                  <input type="number" className="form-control form-control-sm" id="floatingInput2" defaultValue={nonCodAmount !== 0 || "" || null? courierData?.non_cod : nonCodAmount} placeholder="Non cod - amount..." aria-describedby="floatingInputHelp" onChange={(e)=> setNonCodAmount(e.target.value)}/>
              </div>

              <div className="col-lg-6">
                <label htmlFor="emailBasic" className="form-label fw-bold">Select Bank</label>
                <select className="form-select form-select-sm border border-primary" aria-label="Default select example"
                value={selectedBank}
                onChange={handleSelectBank}
                >
                <option value={0}>Choose....</option>
                {
                    allBanks && allBanks.length > 0 ?
                    allBanks?.map((bank, index)=>{
                            return(
                                <option key={index} value={bank?.bank_id}>{bank.bank_name}</option>
                            )
                        })
                    :
                    "No Bank Found!"
                }
          
                </select>
                </div>

                <div className="col-lg-6">
                <label htmlFor="" className="fw-bold mb-1">Invoice No*</label>
                  <input type="text" className="form-control form-control-sm" id="floatingInput1" value={invoiceNum} placeholder="Enter Invoice no..." aria-describedby="floatingInputHelp" onChange={(e)=> setInvoiceNum(e.target.value)} />
              </div>
            </div>
      </div>
      <div className="card-footer bg-dark">
        {
          isLoading ? (
            <div className="spinner-border text-primary float-end btn-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          )
          :
          ( multiSelectApproveOrderIDs.length > 0 ?
            <button
            type="submit"
            class="btn btn-outline-light float-end btn-sm"
            onClick={submitOrderCourierAmounts}
          >
            Submit
          </button>
          :
          null
          )
        }
   
        
      </div>
    </div>
  </div>

</div>

        </div>



      </div>



    {/* <UpdateStaffModal staffID={staffID}/> */}


      </div>
    </>
  )
}

export default PayCourierSheet