import axios from 'axios';
import React,{useState} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PermissionForm = () => {
    const [permissionName , setPermissionName] = useState('');
    const [loading , setLoading] = useState(false);



    const submitPermission =  ()=>{

        setLoading(true)
        if(permissionName){
            const permissionObj ={
                permission_name:permissionName,
                added_by:"bilal11"
              }
              axios.post(`${process.env.REACT_APP_BASE_URL}post_permission`, permissionObj)
              .then((res)=>{
              setLoading(false)
              toast.info("Permission Added!")
              setPermissionName('')
  
     
          })
          .catch((error)=>{
            
            if(error){
                setLoading(false)
                setPermissionName('')
                toast.warn("Something went wrong! Please try again later..")
            }
   
           
        })
  
      }
   
      else{
        
        toast.warn("Fill the information !")
        setLoading(false)
      }
    }

  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">

            <h4 class="fw-bold mb-4"><span class="text-muted fw-light">Permissions</span></h4>


            <div className="row">
            <div className="col-lg-7">

            <div className="card">
              <div className="card-body">
            <h5 class="fw-bold mb-4">Permission Form</h5>
              <div className="form-group mb-2">
                        <label className="fw-bold mb-2" htmlFor="exampleInputEmail3">Permission name*</label>
                        <input type="text" name="permissionName" value={permissionName} className={"form-control"} id="exampleInputEmail3"  onChange={(e)=>setPermissionName(e.target.value)} placeholder="Enter Permission name"  />
                    </div>

                    {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
        <button type="submit" className="btn btn-primary float-end mt-3"
          onClick={submitPermission}>Submit</button>

    }
              </div>


             </div>

             </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionForm;
