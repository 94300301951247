import axios from 'axios';
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import React,{useState, useEffect} from 'react';
import UsersGlobalData from '../../GlobalCalls/UsersGlobalData';

const UpdateExpenseModal = ({expenseID , showExpenseModal , setShowExpenseModal , getAllExpenses}) => {
    const [getBanks , setGetBanks] = useState([]);
    
    const [loading , setLoading] = useState(false);

    const [bankID , setBankID] = useState('');
    const [selectBankName , setSelectBankName] = useState('');
    const [expenseName , setExpenseName] = useState('');
    const [expensePrice , setExpensePrice] = useState('');
    const [epxenseDate , setExpenseDate] = useState('');

    const [getExpenseByID , setExpenseByID] = useState('');

    const[userData , setUserData] = useState('');

    function fetchExpensesByID(){
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_expence_by_id/${expenseID}`)
        .then((res)=>{
            setExpenseByID(res.data.Expences)
        })
        .catch((err)=>{
          return err
        })
        
    }

    function submitExpense(){
        setLoading(true)
          const expenseObj = {
            role_id :userData.role_id,
            user_id:userData.id,
            added_by:userData.username,
              made_by:userData.firstname,
              bank_id:bankID,
              expence_name : expenseName,
              expence_price : expensePrice,
              expence_date: epxenseDate,
  
          }
          axios.post(`${process.env.REACT_APP_BASE_URL}update_expence_by_id/${expenseID}`, expenseObj)
          .then((res)=>{
            if(res.data.status === '200'){
                toast.info("Expenses Submit!")
                getAllExpenses()
                setExpenseName('');
                setExpensePrice('')
                setExpenseDate('');
                setBankID('');
                setSelectBankName('');
                setLoading(false);
              
            }
            else {
              setLoading(false)
              throw new toast.error("Can't submit items!");
            }
          })
        .catch((error)=>{
            console.log(error)
          if(error){
            setLoading(false)
            toast.warn("Something went wrong, please try again in a while")
          }
          })
  
      
      }



    function getAllBanks(){
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
        .then((res)=>{
          setGetBanks(res.data.Banks)
        })
        .catch((err)=>{
          return err
        })
  
      }

      useEffect(() => {
        getAllBanks();
        fetchExpensesByID()

          const getocal = async() =>{
            const data = await UsersGlobalData()
           setUserData(data)
          }
          getocal()
  
      }, [])

  return (
    <>
     <Modal
     show = {showExpenseModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         Update Expenses
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row">
      <div className="col-lg-4 mb-3">
            <label className="form-label" htmlFor="basic-default-fullname">Existing Bank</label>
            <input type="text" value={getExpenseByID.bank_name} className={"form-control"} id="basic-default-fullname" placeholder="Enter expense name"
            disabled
            />
          </div>

    <div className="col-lg-4 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Bank
                        </label>

                        <select className={"form-select"} aria-label="Default select example"
                        value={bankID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          const selectedText = e.target.options[e.target.selectedIndex].text;
                          setBankID(selectedValue)
                          setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value="">Bank select menu</option>
                          {
                            getBanks && getBanks.length > 0 ?

                            getBanks?.map((item , id)=>{
                              return(
                                <option key={id} value={item.bank_id}>{item.bank_name}</option>
                              )
                            })

                            :

                            <option value="">No Bank Found !</option>
                          }
                          
                        </select>
                        </div>

                        <div className="col-lg-4 mb-3">
            <label className="form-label" htmlFor="basic-default-fullname">Expense Name</label>
            <input type="text" defaultValue={getExpenseByID.expence_name} className={"form-control"} id="basic-default-fullname" placeholder="Enter expense name"
            onChange={(e)=> {setExpenseName(e.target.value)}}
            />
          </div>


          <div className="col-lg-6 mb-3">
            <label className="form-label" htmlFor="basic-default-company">Expense Price</label>
            <input type="number" defaultValue={getExpenseByID.expence_price} className={"form-control"} id="basic-default-company" placeholder="Enter expense price"
              onChange={(e)=> {setExpensePrice(e.target.value)}}
            />
          </div>

          <div className="col-lg-6 mb-3">
            <label className="form-label" htmlFor="basic-default-company">Expense Date</label>
            <input type="date" defaultValue={getExpenseByID.expence_date} className={"form-control"} id="basic-default-company" placeholder="ACME Inc." 
                onChange={(e)=> {setExpenseDate(e.target.value)}}
            />
          </div>
    </div>
      </Modal.Body>
      <Modal.Footer>
      <button className='btn btn-dark float-end mt-3'
        onClick={()=> {setShowExpenseModal(!showExpenseModal)
        }}
        >
        Close
        </button>
      {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
       
          <button type="submit" className="btn btn-primary float-end mt-3"
          onClick={submitExpense}
          >Submit</button>
    }
      </Modal.Footer>
    </Modal>

    </>
  )
}

export default UpdateExpenseModal