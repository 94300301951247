import axios from 'axios';
import React, {useState} from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-bootstrap/Modal';


const GenerateOTPApprovalModal = (
  {
  assignedOrder,
  assignedOrderMessage ,
  orderID ,
  showOTPModal , 
  setShowOTPModal,
  onSuccess,
  index

}
  
  ) => {
  const [randomNumber, setRandomNumber] = useState(null);
  const [loading , setLoading] = useState(false);
  const[showOTPInput ,setShowOTPInput] = useState(false);

  const[enterOTP , setEnterOTP] = useState();


  const generateRandomFourDigitNumber = () => {
    const min = 1000;
    const max = 9999;
    const randomNum = Math.floor(min + Math.random() * (max - min + 1)); 
    setRandomNumber(randomNum);
    submitOTP(randomNum)
  }

function submitOTP(randomNum){
setLoading(true)
  const optObj = {
    to:+923354546704,
    message:`Order# ${orderID} - ${assignedOrderMessage} - OTP:${randomNum}`

  }
  console.log(optObj)
  axios.post(`${process.env.REACT_APP_BASE_URL}sendMessage`,optObj )
  .then((res)=>{
    if(res.status === 200){
        toast.info("OTP send!")
        setLoading(false)
        setShowOTPInput(true)
      
    }
    else {
      setLoading(false)
      throw new toast.error("Can't submit items!");
    }
  })
.catch((error)=>{
  if(error){
    setLoading(false)
    toast.warn("Something went wrong, please try again in a while")
  }
  })
  
}


function authenticateOTP(){
  setLoading(true)
  if(randomNumber === Number(enterOTP)){
    toast.info("OTP valid !")
    setLoading(false)
    onSuccess(orderID,index)

  }
  else{
    toast.error("Invalid OTP!");
    setLoading(false)

  }

}
  return (
    <>
     <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      show={showOTPModal}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
        Approval for {assignedOrder} - # {orderID}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

       
       {
        showOTPInput === true ?
        <>
         <label className="form-label" htmlFor="basic-icon-default-companyThree">Enter OTP*</label>
        <input type="number" id="basic-icon-default-companyThree" value={enterOTP} className="form-control" placeholder="Enter OTP..." aria-label="ACME Inc." aria-describedby="basic-icon-default-company3" onChange={(e)=>setEnterOTP(e.target.value)}/>
        </>
        :
        <p style={{fontSize:"17px"}}> Can't procced this action! You've to submit for approval <i className="fa-solid fa-circle-info"/></p>

       }
       
      
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-outline-dark' onClick={()=> setShowOTPModal(!assignedOrder)}>
        Close
        </button>
        {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
        showOTPInput === true ? 
        <button className='btn btn-outline-primary' onClick={authenticateOTP}>
        Check OTP
    </button>
        :
        <button className='btn btn-outline-primary' onClick={generateRandomFourDigitNumber}>
            Submit
        </button>
}
      </Modal.Footer>
    </Modal>

    </>
  )
}

export default GenerateOTPApprovalModal