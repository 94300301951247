import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import UpdateStaffPayRollModal from '../Modals/UpdateStaffPayRollModal';
import StaffPayRollSectionEndPoint from '../../../Api/StaffPayRollSection/StaffPayRollSection';

const StaffPayRollSheet = () => {
      const { mutate: deletePayStaff } = StaffPayRollSectionEndPoint.useDeletePayStaff();

      const[staffPayID , setStaffPayID] = useState('')
      const[payStaffMem , setPayStaffMem] = useState([]);

      // Hook for pagination:
      const [pages, setPages] = useState(1);
      const [count, setCount] = useState("");

      const[staffName , setStaffName] = useState('');
      const[monthOfPay , setMonthOfPay] = useState('');
      const[status , setStatus] = useState('');
      const[date, setDate] = useState('');

      const [filterButton , setFilterButton] = useState(false);

      const [filteredCount, setFilteredCount] = useState(0);
    
      const resultsPerPage = 20;
      // function for getting page next:
      const handleNextPage = () => {
        setPages((prevPage) => prevPage + 1);
      };
      // function for getting previous page:
      const handlePrevPage = () => {
        setPages((prevPage) => Math.max(prevPage - 1, 1));
      };
    
      const totalResults = count || 0;
      const startResult = (pages - 1) * resultsPerPage + 1;
      const endResult = Math.min(pages * resultsPerPage, totalResults);

          // Getting all staff members function:
    useQuery(["all_pay_staff_members", pages],(_) => StaffPayRollSectionEndPoint.getAllPayStaffMembers(pages),
    {
      onSuccess: (data) => {
        setPayStaffMem(data.data.Pay_staff);
        setCount(data.data.TotalCount);
      },
      onError: (err) => {
        return err;
      },
    }
  )

  
  function removePayStaffFromSheet(index) {
    setPayStaffMem((prevState) => {
      const payStaff = [...prevState];
      payStaff.splice(index, 1);
      return payStaff;
    });
  }
function delPayStaff(id, index) {
  deletePayStaff({ id, removePayStaffFromSheet, index });
}

  function AllPayStaff({ items, index }) {
    return (
      <tr key={index} className="text-center">
        <td>{payStaffMem.length - index}</td>
        <td>{items.added_by}</td>
        <td>{items.username}</td>
        <td>{items.staff_name}</td>
        <td>{items.days_off}</td>
        <td>{items.leaves}</td>
        <td>{items.real_salary}</td>
        <td>{items.calculated_salary}</td>
        <td>{items.amount_paid}</td>
        <td>{items.amount_deducted}</td>
        <td>{items.amount_left}</td>
        <td>{items.month_of_pay}</td>
          <td>{items.paid === "false" ? (
            <span className="badge bg-label-danger me-1">Not Paid</span>
          ) : (
            <span className="badge bg-label-primary me-1">Paid</span>
          )}</td>
        <td>{items.Idate}</td>
        <td>
          <div className="dropdown">
            <button
              type="button"
              className="btn p-0 dropdown-toggle hide-arrow"
              data-bs-toggle="dropdown"
            >
              <i className="bx bx-dots-vertical-rounded" />
            </button>
            <div className="dropdown-menu">
              <button
                type="button"
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#updatePayStaffModal"
                onClick={() => setStaffPayID(items.pay_staff_id)}
              >
                <i className="bx bx-edit-alt me-1" /> Edit
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  delPayStaff(items.pay_staff_id, index);
                }}
              >
                <i className="bx bx-trash me-1" /> Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  const filterStaff =  payStaffMem.length > 0 && 
staffName !== "" && monthOfPay === "" && status === "" && date === "" 
? payStaffMem.filter((items) => items.staff_name === staffName)
:
staffName === "" && monthOfPay !== "" && date === "" && status === ""
? payStaffMem.filter((items) => items.month_of_pay === monthOfPay)
:
staffName === "" && monthOfPay === "" && date === "" && status !== ""
? payStaffMem.filter((items) => items.paid === status)
:
staffName === "" && monthOfPay === "" && date !== "" && status === ""
? payStaffMem.filter((items) => items.Idate === date)
:

(staffName !== "" && monthOfPay !== "") && date === "" && status === ""
? payStaffMem.filter((items) => (items.staff_name === staffName && items.month_of_pay === monthOfPay))
:
(staffName !== "" && status !== "") && monthOfPay === "" && date === ""
? payStaffMem.filter((items) => (items.staff_name === staffName && items.paid === status))
:
(staffName !== "" && date !== "") && monthOfPay === "" && status === ""
? payStaffMem.filter((items) => (items.staff_name === staffName && items.Idate === date))
:
(monthOfPay !== "" && status !== "") && date === "" && staffName === ""
? payStaffMem.filter((items) => (items.month_of_pay === monthOfPay && items.paid === status))
:
(monthOfPay !== "" && date !== "") && staffName === "" && status === ""
? payStaffMem.filter((items) => (items.month_of_pay === monthOfPay && items.Idate === date))
:
(status !== "" && date !== "") && monthOfPay === "" && staffName === ""
? payStaffMem.filter((items) => (items.paid === status && items.Idate === date))

: payStaffMem;

useEffect(() => {
  setFilteredCount(filterStaff.length);
}, [filterStaff])
  return (
    <>
    <div className="scroll-view-component scrollbar-secondary-component"> 
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">Pay Staff Sheet</span>
          </h4>

          
          
          <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Staff Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Staff Name..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setStaffName(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Month of Pay*
                          </label>
                          <input
                            type="date"
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setMonthOfPay(e.target.value)}
                          />
                      </div>
                    
                      <div className="col-lg-3">
                        <label
                          className="form-label fw-bold"
                        >
                          Paid (Status)*
                        </label>
                        <select
                            id="inputGroupSelect01"
                            className = {"form-select form-select-sm border border-primary"}
                            onChange={(e)=>setStatus(e.target.value) }
                          >
                            <option value="">Choose...</option>
                            <option value="false">Not Paid</option>
                            <option value="true">Paid</option>

                          </select>


                      </div>
                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }
          <div className="card">
            <h5 className="card-header">Pay Staff Sheet</h5>
            <div className="table-responsive text-nowrap">
            {filterStaff && filterStaff.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>Added By</th>
                        <th>Username</th>
                        <th>Staff Name</th>
                        <th>Days OFF</th>
                        <th>Leaves</th>
                        <th>Actual Salary</th>
                        <th>Calculated Salary</th>
                        <th>Amount Paid</th>
                        <th>Amount Deducted</th>
                        <th>Amount Left</th>
                        <th>Month of pay</th>
                        <th>Is Paid</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 ">
                      {filterStaff?.map((items, index) => {
                        return <AllPayStaff items={items} index={index} />;
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
                )}

                  <div className="p-3">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handlePrevPage}
                    disabled={pages === 1}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handleNextPage}
                    disabled={totalResults <= endResult}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                  <p>
                    Showing {startResult} - {endResult} of {filteredCount}
                    results - total :&nbsp;&nbsp;{count}
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>



    <UpdateStaffPayRollModal staffPayID={staffPayID}/>


      </div>
    </>
  )
}

export default StaffPayRollSheet