import axios from 'axios'
import {useQuery} from 'react-query';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState, useRef, useEffect} from 'react';
import StaffPayRollSectionEndPoint from '../../../Api/StaffPayRollSection/StaffPayRollSection';


const StaffPayRollForm = () => {
  const {mutate:staffPayRollSubmission , isLoading } = StaffPayRollSectionEndPoint.useStaffPayRoll();


     // loading and conditing hooks:
      const[input , setInput] = useState(false);

      const[allStaffMembers , setAllStaffMembers] = useState([]);
      const fileInputRef = useRef(null);
      const fileInputRefTwo = useRef(null);
      const fileInputRefThree = useRef(null);
      const fileInputRefFour = useRef(null);
      const fileInputRefFive = useRef(null);
      

      const[staffID , setStaffID] = useState('');
      const[staffName ,setStaffName] = useState('');
      const[staffActualSalary , setStaffActualSalary] = useState('');
      const[daysOff , setDaysOff] = useState('');
      const[staffLeaves , setStaffLeaves] = useState('');
      const[paidAmount , setPaidAmount] = useState('');
      const[staffPayRollDate , setStaffPayRollDate] = useState('');

      const[getBanks , setGetBanks] = useState([]);

      const[bankID , setBankID] = useState('');
      const[selectBankName , setSelectBankName] = useState('');






        // Getting all staff function:
        useQuery("all_staff_members", StaffPayRollSectionEndPoint.getAllStaffMembers, {
          onSuccess: (data) => {
            setAllStaffMembers(data.data.Staff);
          },
          onError: (err) => {
            return err;
          },
        })

        function getAllBanks(){
          axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
          .then((res)=>{
            setGetBanks(res.data.Banks)
          })
          .catch((err)=>{
            return err
          })
    
        }



        const getStaffNameAndID = (selectedOption)=>{
          setStaffID(selectedOption.staff_id);     
          setStaffName(selectedOption.staff_name);
          setStaffActualSalary(selectedOption.staff_salary)
        }
        
        const dailyEstimatedAmount = ((staffActualSalary/30)*daysOff)
        const calculatedAmountAfterDaysOff = (staffActualSalary - dailyEstimatedAmount)
        
        const amountAfterPaidAdvance = (calculatedAmountAfterDaysOff - paidAmount);
        const amountAfterDeduction = (staffActualSalary - calculatedAmountAfterDaysOff);

        function submitStafPayRoll(){

          if(staffName === '' || staffActualSalary === '' || daysOff === '' || staffLeaves === '' || paidAmount === '' || staffPayRollDate === '' || selectBankName === ''){
            setInput(true)
            toast.warn("Fill the information !")
          }

          else{ 
            const staffPayRollObj ={
              staff_id: staffID,
              staff_name:staffName,
              days_off: daysOff,
              leaves: staffLeaves,
              real_salary: staffActualSalary,
              calculated_salary:calculatedAmountAfterDaysOff.toFixed(2),
              amount_paid:paidAmount,
              amount_deducted:amountAfterDeduction.toFixed(2),
              amount_left:amountAfterPaidAdvance.toFixed(2),
              month_of_pay:staffPayRollDate,
              added_by:"admin",
              role_id:1,
              user_id:"1",
              bank_id:bankID,
              ledger_description : `PayStaff salary of ${staffName} ${bankID}`
  
            }
            staffPayRollSubmission((staffPayRollObj) , {
              onMutate: () => {
              },
              onSettled: () => {
                setInput(false);

                setDaysOff('');
                setStaffLeaves('');
                setStaffPayRollDate('');
                setPaidAmount('');
                setStaffActualSalary('');
                setSelectBankName('');
                setBankID('')
  
                fileInputRef.current.value = 'Choose...';
                fileInputRefTwo.current.value = '';
                fileInputRefThree.current.value = '';
                fileInputRefFour.current.value = '';
                fileInputRefFive.current.value = '';
  
              },
      
            
            })
            
          }

  
        }
      
        useEffect(() => {
          getAllBanks()
        }, [])
        


  return (
    <>
        <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div class="container-xxl flex-grow-1 container-p-y">
            <h4 class="fw-bold">
              <span class="text-muted fw-light">Staff Payroll Form</span> 
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Staff Payroll Form</h5>
                  {/* <small className="text-muted float-end">Default label</small> */}
                  </div>
                  <div className="card-body">
                    <div className="row">

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Staff Member Name
                        </label>
                        <select className={staffName === ''&& input === true?"form-select border border-danger":"form-select"} aria-label="Default select example"
                         ref={fileInputRef}
                       onChange={(e) => {
                              const selectedOption = allStaffMembers.find((item) => item.staff_name === e.target.value)
                              if (selectedOption) {
                                getStaffNameAndID(selectedOption);
                              }
                            }}

                        >
                        <option>Choose...</option>
                        {
                          allStaffMembers && allStaffMembers.length > 0 ?
                          allStaffMembers?.map((items)=>{
                            return(
                              <option key={items.staff_id} value={items.staff_name}>{items.staff_name}</option>
                            )
                          })
                          :
                          <option value="no_staff">No Staff</option>

                        }
                      </select>
                      </div>

                                   
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname01"
                        >
                          Days Off
                        </label>
                        <input
                          type="number"
                          value={daysOff}
                          className={daysOff === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname01"
                          placeholder="Enter Days Off..."
                          onChange={(e)=> setDaysOff(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message01"
                        >
                          Leaves Taken
                        </label>
                        <input
                          type="number"
                          className={staffLeaves === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname05"
                          placeholder="Enter Leaves taken..."
                          value={staffLeaves}
                          onChange={(e)=> setStaffLeaves(e.target.value)}
                        />
                      </div>


                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname02"
                        >
                          Actual Salary
                        </label>
                        <input
                          ref={fileInputRefTwo}
                          type="number"
                          value={staffActualSalary}
                          className={staffActualSalary === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname02"
                          placeholder="Enter Actual Salary..."

                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname03"
                        >
                          Calculated Salary
                        </label>
                        <input
                          ref={fileInputRefThree}
                          type="number"
                          className={calculatedAmountAfterDaysOff === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname03"
                          placeholder="Enter Calculated Salary..."
                          value ={calculatedAmountAfterDaysOff.toFixed(2)} 
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname033"
                        >
                          Paid Amount
                        </label>
                        <input
                          type="number"
                          value = {paidAmount}
                          className={paidAmount === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname033"
                          placeholder="Enter Paid Amount..."
                          onChange={(e)=>setPaidAmount(e.target.value)}
                        />
                      </div>

                      
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname034"
                        >
                           Amount Deducted
                        </label>
                        <input
                          ref={fileInputRefFour}
                          type="number"
                          className={amountAfterDeduction === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname034"
                          placeholder="Enter Amount Deduction..."
                          value = {amountAfterDeduction.toFixed(2)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                          Amount Left
                        </label>
                        <input
                          ref={fileInputRefFive}
                          type="text"
                          className={amountAfterPaidAdvance === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname044"
                          placeholder="Enter Amount Left..."
                          value={amountAfterPaidAdvance.toFixed(2)}
                        />
                      </div>

                        
                      {/* <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Paid
                        </label>
                        <select className="form-select" aria-label="Default select example">
                        <option>Choose...</option>
                        <option value="true">Paid</option>
                        <option value="false">Not Paid</option>
                      </select>
                      </div> */}

                    

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                          Month of Pay
                        </label>
                        <input
                          type="date"
                          value={staffPayRollDate}
                          className={staffPayRollDate === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname044"
                          onChange={(e)=> setStaffPayRollDate(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Bank
                        </label>

                        <select className={selectBankName === '' && input === true ?"form-select border border-danger" :"form-select"} aria-label="Default select example"
                        value={bankID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          const selectedText = e.target.options[e.target.selectedIndex].text;
                          setBankID(selectedValue)
                          setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value="">Bank select menu</option>
                          {
                            getBanks && getBanks.length > 0 ?

                            getBanks?.map((item , id)=>{
                              return(
                                <option key={id} value={item.bank_id}>{item.bank_name}</option>
                              )
                            })

                            :

                            <option value="">No Bank Found !</option>
                          }
                          
                        </select>
                        </div>

           

                    </div>
                      {
                        isLoading ?
                        <div className="spinner-border text-primary mt-3 float-end" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                      <button type="submit" className="btn btn-primary mt-3 float-end" onClick={submitStafPayRoll}>
                      Submit
                    </button>
                      }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StaffPayRollForm