import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import React,{useState,useEffect } from "react";
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";

const AddBankOrderModal = ({orderID,index,removeSpecificeOrder ,showUpdateBankModal ,setUpdateBankModal}) => {
  const { mutate: approveBankwithOrder, isLoading } =  OrderIntakeSectionEndPoint.useApproveBankWithOrder();

  const[bankID , setBankID] = useState('');
  const[selectBankName , setSelectBankName] = useState('');

  const[getBanks , setGetBanks] = useState([]);
  function getAllBanks(){
    axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
    .then((res)=>{
      setGetBanks(res.data.Banks)
    })
    .catch((err)=>{
      return err
    })

  }

  useEffect(() => {
    getAllBanks()
  }, [])

  function updateBankofOrder() {
    const bankIDObj = {
      paid_in: bankID,
    };

    approveBankwithOrder(({bankIDObj, orderID,index,removeSpecificeOrder}), {
      onMutate: () => {

      },
      onSettled: () => {
      },
    });
}
  return (
    <>
    <Modal
        show={showUpdateBankModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>
          <i className="fa-solid fa-box fs-3"/> &nbsp; Order#
        {orderID}     
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" text-black fs-4">
     <p className='text-center'> Select Bank to update order  <i className="fa fa-bank fs-4"alt="orderBox" /></p>  
       <div className="col-lg-12 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Bank
                        </label>

                        <select className={"form-select"} aria-label="Default select example"
                        value={bankID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          const selectedText = e.target.options[e.target.selectedIndex].text;
                          setBankID(selectedValue)
                          setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value="">Bank select menu</option>
                          {
                            getBanks && getBanks.length > 0 ?

                            getBanks?.map((item , id)=>{
                              return(
                                <option key={id} value={item.bank_id}>{item.bank_name}</option>
                              )
                            })

                            :

                            <option value="">No Bank Found !</option>
                          }
                          
                        </select>
                        </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-dark"  onClick={()=> setUpdateBankModal(!showUpdateBankModal)}>
            Close
          </button>
        <button className="btn btn-outline-primary" onClick={updateBankofOrder}>
         {isLoading ? "...":"Okay ?"} 
        </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddBankOrderModal