import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState , useEffect} from "react";

const GrantPermissionForm = () => {

    const [allPermissions, setAllPermissions] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const [memberID , setMemberID] = useState('');
    const [permissionID , setPermissionID] = useState('');

    const [loading , setLoading] = useState(false);


    const getAllUsers = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_users`)
        .then((res)=>{
          setAllUsers(res.data.Users)
        })
        .catch((err)=>{
            return err
        })
    }



    const getAllPermission = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_permissions`)
        .then((res)=>{
            setAllPermissions(res.data.Permissions)
        })
        .catch((err)=>{
            return err
        })
    }



  
    const submitGrantedPermission =  ()=>{

        setLoading(true)
        if(memberID && permissionID){

            const permissionObj ={
                user_id:memberID,
                permission_id:permissionID,
                added_by:"bilal11"
              }
              axios.post(`${process.env.REACT_APP_BASE_URL}post_granted_permission`, permissionObj)
              .then((res)=>{
                if(res.data.status === '200'){

                    setLoading(false)
                    toast.info("Permission Granted!")

                    setMemberID('');
                    setPermissionID('');
        
                }
          })
          .catch((error)=>{
            
            if(error){
                setLoading(false)
                toast.warn("Something went wrong! Please try again later..")
                setMemberID('');
                setPermissionID('');
    
            }
   
           
        })
  
      }
   
      else{
        toast.warn("Fill the information !")
        setLoading(false)
      }
    }


    useEffect(() => {
        getAllUsers()
        getAllPermission()

    }, [])
    
  return (
    <>
       <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 class="fw-bold mb-4"><span class="text-muted fw-light">Grant Permissions</span></h4>

            <div className="row">
            <div className="col-lg-12">

            <div className="card">
              <div className="card-body">

            <h5 class="fw-bold mb-5">Grant Permission Form</h5>

            <div className="row">
            <div className="col-lg-6 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Member
                        </label>

                        <select className={"form-select"} aria-label="Default select example"
                        value={memberID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          setMemberID(selectedValue)

                        //   const selectedText = e.target.options[e.target.selectedIndex].text;
                        //   setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value=""> -------------------- &nbsp; &nbsp; Select Member &nbsp; &nbsp;-------------------- </option>
                          {
                            allUsers && allUsers.length > 0 ?

                            allUsers?.map((item , id)=>{
                              return(
                                <option key={id} value={item.id}>{item.username}</option>
                              )
                            })

                            :

                            <option value="">No User Found !</option>
                          }
                          
                        </select>
            </div>

                        
            <div className="col-lg-6 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Permission
                        </label>

                        <select className={"form-select"} aria-label="Default select exampleTwo"
                        value={permissionID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          setPermissionID(selectedValue)

                        //   const selectedText = e.target.options[e.target.selectedIndex].text;
                        //   setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value=""> -------------------- &nbsp; &nbsp; Select Permission &nbsp; &nbsp;-------------------- </option>
                          {
                            allPermissions && allPermissions.length > 0 ?

                            allPermissions?.map((item , id)=>{
                              return(
                                <option key={id} value={item.permission_id}>{item.permission_name}</option>
                              )
                            })

                            :

                            <option value="">No User Found !</option>
                          }
                          
                        </select>
            </div>

            </div>

                    {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
        <button type="submit" className="btn btn-primary float-end mt-3"
          onClick={submitGrantedPermission}>Submit</button>

    }
              </div>


             </div>

             </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default GrantPermissionForm