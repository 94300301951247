import axios from 'axios';
import { toast } from "react-toastify";
import {useMutation} from 'react-query';
import "react-toastify/dist/ReactToastify.css";

function getAllStaffMembers(){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_staffs_nr`)
 }

 function getAllPayStaffMembers(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_pay_staff?page=${pages}`)
 }

 function getPayStaffMemberByID(id){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_pay_staff_by_id/${id}`)
 }

 
function staffPayRollSubmission(staffPayRollObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_pay_staff`, staffPayRollObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Payrolls Submit!")
        return res.data
      }
      else {
        throw new toast.error("Can't submit!");
      }

    })
  .catch((error)=>{
    if(error){
      toast.warn("Something went wrong, please try again in a while")
    }
   
    })
}

function updatePayRollStaff({formdata, staffPayRollID}){
  
   axios.post(`${process.env.REACT_APP_BASE_URL}update_pay_staff_by_id/${staffPayRollID}`, formdata)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("PayRoll Staff Updated!")
  }
  else {
    throw new toast.error("Can't submit!");
  }
  })
  .catch((error)=>{
    if(error)
    {
      toast.warn("Something went wrong, please try again in a while")
    }
    
 
})

}

function deletePayStaff({ id, removePayStaffFromSheet, index }){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_pay_staff_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("PayStaff Removed")
      removePayStaffFromSheet(index)

      }
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}



const useStaffPayRoll = ()=>{
    return useMutation(staffPayRollSubmission)
}

const useUpdatePayRollStaff = ()=>{
  return useMutation(updatePayRollStaff , {

  })
}

const useDeletePayStaff = ()=>{
  return useMutation( deletePayStaff)
}
const StaffPayRollSectionEndPoint ={
    getAllPayStaffMembers,
    getPayStaffMemberByID,
    useStaffPayRoll,
    useUpdatePayRollStaff,
    useDeletePayStaff,
    getAllStaffMembers,
}

export default StaffPayRollSectionEndPoint;