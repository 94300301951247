import {useQuery} from 'react-query';
import React,{useState, useEffect} from "react";
import UpdateTextileItems from "../Modals/UpdateTextileItems";
import TextileSectionEndPoint from '../../../Api/TextileSection/TextileSectionEndPoint';


const TextileItemsSheet = () => {
  const {mutate:deleteTextileItem  } = TextileSectionEndPoint.useDeleteTextileItem();
  const [getTextileItems , setTextileItems] = useState([]);
  const [showUpdateTextileModal, setShowTextileModal] = useState(false);
  const [textileID , setTextileID] = useState('');

  const [username , setUsername] = useState('');
  const [itemName , setItemName] = useState('');
  const [date , setDate] = useState('')
  const [filterButton , setFilterButton] = useState(false);


  // Hook for pagination:
  const[pages , setPages] = useState(1);
  const[count , setCount] = useState('');
    
  const [filteredCount, setFilteredCount] = useState(0);
    
    
    
      const resultsPerPage = 20;
    // function for getting page next:
      const handleNextPage = () => {
        setPages((prevPage) => prevPage + 1);
      };
    // function for getting previous page:
      const handlePrevPage = () => {
        setPages((prevPage) => Math.max(prevPage - 1, 1));
      };
  
      const totalResults = count|| 0;
      const startResult = (pages - 1) * resultsPerPage + 1;
      const endResult = Math.min(pages * resultsPerPage, totalResults);

    // Getting all textile items function:
    const { refetch } =  useQuery(["all_textile_items", pages], _=>TextileSectionEndPoint.getAllTextileItems(pages), {
      onSuccess: (data) => {
        setTextileItems(data.data.Items);
        setCount(data.data.TotalCount);
      },
      onError: (err) => {
        return err;
      },
    })




    function removeTextileItemFromSheet(index) {
      setTextileItems((prevState) => {
        const textileItems = [...prevState];
        textileItems.splice(index, 1);
        return textileItems;
      });
    }

    function delTextileItem(id,index){
      deleteTextileItem({id,removeTextileItemFromSheet,index})
    }

    function openModal(id){
      setShowTextileModal((p)=>!p)
      setTextileID(id)
       
    }



    function TextileItems({items , index}){
      return (
        <>
        <tr key={index}className="text-center">
          <td>{getTextileItems.length - index}</td>
          <td>{items.username}</td>
          <td>{items.added_by}</td>
          <td>{items.item_name}</td>
          <td>{items.unit_type}</td>
          <td>{items.price_per_unit}</td>
          <td>{items.Idate}</td>
    <td>
    <div className="dropdown">
      <button
        type="button"
        className="btn p-0 dropdown-toggle hide-arrow"
        data-bs-toggle="dropdown"
      >
        <i className="bx bx-dots-vertical-rounded" />
      </button>
      <div className="dropdown-menu">
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>{
            openModal(items.item_id)
          }}
        >
          <i className="bx bx-edit-alt me-1" /> Edit
        </button>
        <button
          type="button"
          className="dropdown-item"
          onClick={()=>{
            delTextileItem(items.item_id,index)
          }}
        >
          <i className="bx bx-trash me-1" /> Delete
        </button>
      </div>
    </div>
  </td>
</tr>
      

</>

      )

    }

    const filterTextileItemData =  getTextileItems.length > 0 && 
         username !== "" && itemName === ""&& date === "" 
         ? getTextileItems.filter((items) => items.username === username)
         :
         username === "" && itemName !== ""&& date === "" 
         ? getTextileItems.filter((items) => items.item_name === itemName)
         :
         username === "" && itemName === ""&& date !== "" 
         ? getTextileItems.filter((items) => items.Idate === date)
         :
         (username !== "" && itemName !== "") && date === "" 
         ? getTextileItems.filter((items) => (items.username === username && items.item_name === itemName))
         :
         (username !== "" && date !== "") && itemName === "" 
         ? getTextileItems.filter((items) => (items.username === username && items.Idate === date))
         :
         (itemName !== "" && date !== "") && username === "" 
         ? getTextileItems.filter((items) => (items.item_name === itemName && items.Idate === date))
         : getTextileItems

      useEffect(() => {
    setFilteredCount(filterTextileItemData.length);
  }, [filterTextileItemData])


  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper mb-5">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-1">
              <span className="text-muted fw-light"> Textile Item Sheet </span>
            </h4>
            
                <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                          filterButton ? 
                    (
                      <div className="row mt-2 mb-2">

                        
                        <div className="col-lg-4">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Username*</label>
                      <input type="text"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with username..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=>{
                        setUsername(e.target.value)
                      }}
                      />
                        </div>

                        <div className="col-lg-4">
                        <label for="defaultFormControlInput1" className="form-label fw-bold">Item Name*</label>
                      <input type="text" className="form-control form-control-sm border border-primary" id="defaultFormControlInput1" placeholder="Search with Item name..." aria-describedby="defaultFormControlHelp"
                      onChange={(e)=>{ 
                        setItemName(e.target.value)
                      }}
                      />
                        </div>

                         
                        <div className="col-lg-4">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="Search with order id..." aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }

            <div className="card">
              <h5 className="card-header">Item Sheet</h5>
              <div className="table-responsive text-nowrap">
                { filterTextileItemData && filterTextileItemData.length > 0 ?
                      <table className="table">
                      <thead>
                        <tr className="text-center">
                          <th>#</th>
                          <th>Username</th>
                          <th>Added By</th>
                          <th>Item Name</th>
                          <th>Unit Type</th>
                          <th>Unit Price</th>
                          <th>Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                          {
                            filterTextileItemData?.map((items , index)=>{
                                return(
                                  <TextileItems items={items} index={index}/>
                                )
                            })
                          }
                      

                      </tbody>
                    </table>
                    :

                    <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
              }
                
                <div className="p-3">
              <button className="btn btn-outline-primary btn-sm" onClick={handlePrevPage} disabled={pages === 1}>
                <i className="fa-solid fa-arrow-left"></i>
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-outline-primary btn-sm" onClick={handleNextPage} disabled={totalResults <= endResult}>
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
                <p >Showing {startResult} - {endResult} of {filteredCount} results  -  total :&nbsp;&nbsp;{count}</p>
              </div>

              </div>
            </div>
          </div>
          {

      showUpdateTextileModal === true && (
            <UpdateTextileItems 
            textileItemID = {textileID}
            showUpdateTextileModal = {showUpdateTextileModal}
            setShowTextileModal = {openModal}
            refetchTextileItem = {refetch}
            />
      )
      }

        </div>
      </div>
    </>
  );
};

export default TextileItemsSheet;
