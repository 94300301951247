import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useMutation , useQueryClient} from 'react-query';

function getAllSubCategories(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_sub_categorys?page=${pages}`)
 }

 function getSubCategoryByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}fetch_sub_category_by_id/${ID}`,)
 }
 
function subCategoriesSubmission(subCategoryObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_sub_category`, subCategoryObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Sub Category Submit!")
      }
      else {
        throw new toast.error("Can't submit!");
      }
    })
  .catch((error)=>{
        if(error){
            toast.warn("Something went wrong, please try again in a while")
        }
    
    })
}

function updateSubCategorySubmission({subCategoryObj,subCateID}){
  
  axios.post(`${process.env.REACT_APP_BASE_URL}update_sub_category_by_id/${subCateID}`, subCategoryObj)
  .then((res)=>{
    if(res.data.status === '200'){
        toast.info("Sub Category Update!")
    }
    else {
      throw new toast.error("Can't submit!");
    }
  })
.catch((error)=>{
      if(error){
          toast.warn("Something went wrong, please try again in a while")
      }
  
  })

}

function deleteSubCategory({id,removeSubCategoryFromSheet,index}){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_sub_category_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("Sub_Category Removed!")
      removeSubCategoryFromSheet(index)
      }
     
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}


const useSubCategorySubmission = ()=>{
    return useMutation(subCategoriesSubmission)
}

const useUpdateSubCategory = ()=>{
  // const queryClient = useQueryClient()
  return useMutation(updateSubCategorySubmission , {
  //   onSuccess: ()=>{
  //  queryClient.invalidateQueries({ queryKey: ['all_textile_items'] })
  //   }
  })
}

const useDeleteSubCategory = ()=>{
  return useMutation( deleteSubCategory)
}
const ClothingSubCategorySectionEndPoint ={
    useSubCategorySubmission,
    useUpdateSubCategory,
    getAllSubCategories,
    useDeleteSubCategory,
    getSubCategoryByID
}

export default ClothingSubCategorySectionEndPoint;