import React from 'react';
import Modal from 'react-bootstrap/Modal';
import orderBox from '../../Images/orderDisputeSign.gif';
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";


const DisputeOrderModal = ({disputeAction,orderID,index,removeSpecificeOrder ,showDisputeModal ,setShowDisputeModal}) => {
  const { mutate: disputedOrdersByStatus, isLoading } =  OrderIntakeSectionEndPoint.useDisputedOrderStatus();

  function updateOrderStatus() {
    const orderStatusObj = {
      status: "disputed",
    };

    disputedOrdersByStatus(({disputeAction,orderStatusObj, orderID,index,removeSpecificeOrder}), {
      onMutate: () => {

      },
      onSettled: () => {
      },
    });
}


  return (
<>
<Modal
        show={showDisputeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>
          <i className="fa-solid fa-box fs-3"/> &nbsp; Order#
        {orderID}     
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center text-black fs-4">
        Want to dispute this order?  <img className="img-fluid fs-5"src={orderBox} alt="orderBox" width={40}/>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-dark"  onClick={()=> setShowDisputeModal(!showDisputeModal)}>
            Close
          </button>
        <button className="btn btn-outline-primary" onClick={updateOrderStatus}>
         {isLoading ? "...":"Okay ?"} 
        </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default DisputeOrderModal