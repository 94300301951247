import axios from "axios";
import Select from 'react-select';
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import makeAnimated from 'react-select/animated';
import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";

const UpdateArticleReceivingModal = ({ articleReceivingID , showArticleReceivingModal , setShowArticleReceivingModal , getAllArticleReceiving}) => {
    const animatedComponents = makeAnimated();

    // console.log(articleReceivingData)

    const [allItems , setAllItems] = useState([]);
    const [itemUsed, setItemUsed] = useState([]);
    const [selectedItemsData, setSelectedItemsData] = useState([]);

    const[getBanks , setGetBanks] = useState([]);


    const[allVendors , setAllVendors] = useState([])
    const[vendorID , setVendorID] = useState('');

    const [articles , setAllArticles] = useState([]);
    const [articleID , setArticleID] = useState('');
    const [receArticleByID , setReceArticleByID] = useState('')

    const [selected, setSelected] = useState([]);
    const [inputFields, setInputFields] = useState([]);


    const[bankID , setBankID] = useState('');
    const[selectBankName , setSelectBankName] = useState('');

    const[totalQuantity , setTotalQuantity] = useState('');
    const[totalPrice , setTotalPrice] = useState('');
    const[paidAmount , setPaidAmount] = useState('');
    const[materialCost , setMaterialCost] = useState('');
    const[subTotalBill , setSubTotalBill] = useState('');

    const[totalBill , setTotalBill] = useState('');

    const[input , setInput] = useState(false);
    const[Loading , setLoading] = useState(false);

    const[userData , setUserData] = useState('');

  const options = [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ]


  // Handling Sizes input with respective qunatity input fields:

  const handleSelectChange = (selected) => {
    setSelected(selected);


    // Create an input field for each selected value
    const newInputFields = selected.map((value) => ({
      value,
      inputValue: "",
    }));
    setInputFields(newInputFields);
  };

  const handleInputChange = (value, inputValue) => {
    // Update the inputValue for the corresponding selected value
    const updatedInputFields = inputFields.map((field) =>
      field.value === value ? { ...field, inputValue } : field
    );
    setInputFields(updatedInputFields);
  };

  const selectedSizeValue = selected.map((item) => item.value);
  const selectedSizeQunatity = inputFields.map((item) => item.inputValue);


  const sizeAndQuantity = selectedSizeValue.map((size , index) =>({
      qty: parseInt(selectedSizeQunatity[index],10) || 0,
      size
  }))




// Getting all items:

  function getAllItems(){
    axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_items`)
    .then((res)=>{
        setAllItems(res.data.Items)
    })
    .catch((err)=>{
        return err;
    })
  }

//   Getting all articles:
function getAllArticles(){
    axios.post(`${process.env.REACT_APP_BASE_URL}get_all_articles`)
    .then((res)=>{
        setAllArticles(res.data.data)
    })
    .catch((err)=>{
        return err;
    })
}

// Getting all vendors:

function getAllVendors(){
    axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_vendors`)
    .then((res)=>{
        setAllVendors(res.data.Vendors)
    })
    .catch((err)=>{
        return err;
    })
}

// Getting All Banks:

function getAllBanks(){
    axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
    .then((res)=>{
      setGetBanks(res.data.Banks)
    })
    .catch((err)=>{
      return err
    })

  }

//   Getting Rece-Articles by Rece-Article-ID:

 function getAllReceArticles(){

     axios.post(`${process.env.REACT_APP_BASE_URL}fetch_article_receiving_by_id/${articleReceivingID}`)
    .then((res)=>{
        setReceArticleByID(res.data.Article_receiving)
    })
    .catch((err)=>{
      return err
    })

  }



//   Receiving Items itemOptions:
 const itemOptions = allItems?.map((item) => ({
     
     value: item.item_id,
     label: item.item_name,
     itemQty : item.in_stock,
     itemPrice : item.price_per_unit,


  }));

   // Function to handle item selection
   const handleItemSelect = (selectedItems) => {
    const updatedSelectedItemsData = selectedItems.map((selectedItem) => {
        const existingData = selectedItemsData.find(data => data.value === selectedItem.value);
        return {
            item_id: selectedItem.value,
        //   label: selectedItem.label,
          qty: existingData ? existingData.qty : selectedItem.itemQty,
          price: existingData ? existingData.price : selectedItem.itemPrice,
        }
      });
  
      setSelectedItemsData(updatedSelectedItemsData);
  
      const updatedItemUsed = selectedItems.map((selectedItem) => ({
        item_id: selectedItem.value,
        qty: selectedItem.itemQty,
        price: selectedItem.itemPrice,
      }));
  
      setItemUsed(updatedItemUsed);
  };


  const handleQtyChange = (value, selectedItem) => {
    // const updatedSelectedItemsData = selectedItemsData.map((data) =>
    //   data.value === selectedItem.value ? { ...data, qty: value } : data
    // );
    // setSelectedItemsData(updatedSelectedItemsData);
    setSelectedItemsData((prevData) => {
        return prevData.map((data) =>
          data.value === selectedItem.value ? { ...data, qty: value } : data
        );
      });
  };

  const handlePriceChange = (value, selectedItem) => {
    // const updatedSelectedItemsData = selectedItemsData.map((data) =>
    //   data.value === selectedItem.value ? { ...data, price: value } : data
    // );
    // setSelectedItemsData(updatedSelectedItemsData);
    setSelectedItemsData((prevData) => {
        return prevData.map((data) =>
          data.value === selectedItem.value ? { ...data, price: value } : data
        );
      });
  };


  useEffect(() => {
    getAllItems();
    getAllArticles();
    getAllVendors();
    getAllBanks();
    getAllReceArticles();
      const getocal = async() =>{
        const data = await UsersGlobalData()
       setUserData(data)
      }
      getocal()

  }, [])

  function submitArticle() {
    setLoading(true)


      const articleObj ={

     
        article_id: articleID,
        total_qty:totalQuantity,
        total_price:totalPrice,
        role_id :userData.role_id,
        user_id:userData.id,
        added_by:userData.username,
        bank_id:bankID,
        ledger_description:`Update article receiving ${bankID}`,
        vendor_id:vendorID,
        paid_amount:paidAmount,
        material_cost:materialCost,
        sub_total:subTotalBill,
        total_bill:totalBill,
        item_used:selectedItemsData,
        size_wise_qty:sizeAndQuantity
    }



    axios.post(`${process.env.REACT_APP_BASE_URL}update_article_receiving_by_id/${articleReceivingID}`,articleObj)
    .then((res)=>{
      if(res.data.status === '200'){  
        toast.info("Article receiving updated!")
        setInput(false)
        setLoading(false)
        getAllArticleReceiving()

    }
    
    else {
      throw new toast.error("Can't submit items!");
    }
  })
  .catch((error)=>{
    if(error)
      {
        setLoading(false)
        toast.warn("Something went wrong, please try again in a while")
      }
   
  })



  setInput(false)

  setSelectedItemsData('')
  setItemUsed('')
  setItemUsed([])
  setSelected([]);
  setSelectedItemsData([])
  setInputFields([]);
  setVendorID('');
  setArticleID('');
  setTotalBill('');
  setSubTotalBill('');
  setMaterialCost('');
  setPaidAmount('');
  setTotalQuantity('');
  setBankID('');
  setTotalPrice('')
  

 
  }
  return (
    <>
     <Modal
      show={showArticleReceivingModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         Update Article Receiving
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row">

      <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company111"
  >
  Existing Items
  </label>
  <input
    type="text"
    className={"form-control"}
    id="basic-default-company111"
    placeholder="Enter total quantity..."

    value={receArticleByID?.item_used?.map((item)=> `${item.item_name} - ${item.price}`).join(', ')}
    disabled
  />
    </div>

<div className="col-lg-4 mb-3">
  <label
      className="form-label fw-bold"
      htmlFor="basic-default-message"
    >
     Select Items
    </label>
    <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        options={itemOptions}
        onChange={handleItemSelect}
        isMulti
        />
    </div>

    {selectedItemsData.map((selectedItem) => (
    <>
        <div className="col-lg-4">
        <label
        className="form-label fw-bold"
        htmlFor={`basic-default-company${selectedItem.value}`}
        >{`Qty for Selected Items `}</label>
        <input
        type="number"
        className="form-control"
        defaultValue={selectedItem.qty}
        onChange={(e) => handleQtyChange(e.target.value, selectedItem)}
        />
        </div>
    <div className="col-lg-4">
    <label
    className="form-label fw-bold"
    htmlFor={`basic-default-company${selectedItem.value}`}
    >{`Price  Selected Items `}</label>
        <input
        type="number"
        className="form-control"
        defaultValue={selectedItem.price}
        onChange={(e) => handlePriceChange(e.target.value, selectedItem)}
        />
    </div>
    
    </>
    ))}

<div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company112"
  >
  Existing Article
  </label>
  <input
    type="text"
    className={"form-control"}
    id="basic-default-company112"
    placeholder="Enter total quantity..."

    value={receArticleByID?.article_title}
    disabled
  />
    </div>

<div className="col-lg-4 mb-3">
  <label
      className="form-label fw-bold"
      htmlFor="basic-default-message"
    >
    Select Article
    </label>

    <select className={"form-select"} aria-label="Default select example"
    value={articleID}
    onChange={(e)=>
      {
        setArticleID(e.target.value)
      }
    }
    >
      <option value="">Select Article</option>
      {
        articles && articles.length > 0 ?

        articles?.map((item , id)=>{
          return(
            <option key={id} value={item.id}>{item.title}</option>
          )
        })

        :

        <option value="">No Articles Found !</option>
      }
      
    </select>
    </div>

    <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company112"
  >
  Existing Vendor
  </label>
  <input
    type="text"
    className={"form-control"}
    id="basic-default-company112"
    placeholder="Enter total quantity..."

    value={receArticleByID?.vendor_name}
    disabled
  />
    </div>

    
<div className="col-lg-4 mb-3">
  <label
      className="form-label fw-bold"
      htmlFor="basic-default-message"
    >
    Select Vendors
    </label>

    <select className={vendorID === "" && input === true ?"form-select border border-danger" :"form-select"} aria-label="Default select example"
    value={vendorID}
    onChange={(e)=>
      {
        setVendorID(e.target.value)
      }
    }
    >
      <option value="">Select Vendor</option>
      {
        allVendors && allVendors.length > 0 ?

        allVendors?.map((item , id)=>{
          return(
            <option key={id} value={item.vendor_id}>{item.vendor_name}</option>
          )
        })

        :

        <option value="">No Vendors Found !</option>
      }
      
    </select>
    </div>

    <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company111"
  >
  Existing Sizes & Quantity
  </label>
  <input
    type="text"
    className={"form-control"}
    id="basic-default-company111"
    placeholder="Enter total quantity..."

    value={receArticleByID?.size_wise_qty?.map((item)=> `${item.size} - ${item.qty}`).join(', ') }
    disabled
  />
    </div>

     
  <div className="col-lg-4">
    <div className="mb-3">
      <label
        className="form-label"
        htmlFor="basic-default-fullname33"
      >
        Select Sizes
      </label>
      <MultiSelect
        
        options={options}
        value={selected}
        onChange={handleSelectChange}
        labelledBy="Select"
      />
    </div>
  </div>
  {inputFields.map((field, index) => (
    <div key={index} className="col-lg-4 mb-3">
      <label
        className="form-label"
        htmlFor="basic-default-company77"
      >
        {`Qunatity for ${field.value.label}`}
      </label>
      <input
        type="number"
        className={field.inputValue === ''&& input === true?"form-control border border-danger":"form-control"}
        placeholder="Enter Quantity"
        value={field.inputValue}
        onChange={(e) =>
          handleInputChange(field.value, e.target.value)
        }
      />
    </div>
  ))}



<div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company1"
  >
   Total Qunatity
  </label>
  <input
    type="number"
    className={"form-control"}
    id="basic-default-company0"
    placeholder="Enter total quantity..."
    defaultValue={receArticleByID? receArticleByID.total_qty :totalQuantity}
    onChange={(e)=> setTotalQuantity(e.target.value)}
  />
    </div>
            
 <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company2"
  >
    Total Price
  </label>
  <input
    type="number"
    className={"form-control"}
    id="basic-default-company1"
    placeholder="Enter total price..."
    defaultValue={receArticleByID ? receArticleByID.total_price: totalPrice}
    onChange={(e)=> setTotalPrice(e.target.value)}
  />
    </div>

    <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company3"
  >
    Paid amount
  </label>
  <input
    type="number"
    className={"form-control"}
    id="basic-default-company2"
    placeholder="Enter paid amount..."
    defaultValue={receArticleByID ? receArticleByID.paid_amount:paidAmount}
    onChange={(e)=> setPaidAmount(e.target.value)}
  />
    </div>

    
    <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company4"
  >
    Material Cost
  </label>
  <input
    type="number"
    className={"form-control"}
    id="basic-default-company3"
    placeholder="Enter Material cost..."
    defaultValue={receArticleByID? receArticleByID.material_cost : materialCost}
    onChange={(e)=> setMaterialCost(e.target.value)}
  />
    </div>

    <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company53"
  >
    Sub_Total Bill
  </label>
  <input
    type="number"
    className={"form-control"}
    id="basic-default-company44"
    placeholder="Enter sub total bill..."
    defaultValue={receArticleByID ? receArticleByID.sub_total :subTotalBill}
    onChange={(e)=> setSubTotalBill(e.target.value)}
  />
    </div>

    <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company54"
  >
    Total Bill
  </label>
  <input
    type="number"
    className={"form-control"}
    id="basic-default-company45"
    placeholder="Enter sub total bill..."
    defaultValue={receArticleByID ? receArticleByID.total_bill :totalBill}
    onChange={(e)=> setTotalBill(e.target.value)}
  />
    </div>

    <div className="col-lg-4 mb-3">
  <label
    className="form-label"
    htmlFor="basic-default-company113"
  >
  Existing Bank
  </label>
  <input
    type="text"
    className={"form-control"}
    id="basic-default-company113"
    placeholder="Enter total quantity..."

    value={receArticleByID?.bank_name}
    disabled
  />
    </div>
    

<div className="col-lg-4 mb-3">
  <label
      className="form-label fw-bold"
      htmlFor="basic-default-message"
    >
     Select Bank
    </label>

    <select className={selectBankName === '' && input === true ?"form-select border border-danger" :"form-select"} aria-label="Default select example"
    value={bankID}
    onChange={(e)=>
      {
      const selectedValue = e.target.value; 
      const selectedText = e.target.options[e.target.selectedIndex].text;
      setBankID(selectedValue)
      setSelectBankName(selectedText)
      }
    }
    >
      <option value="">Bank select menu</option>
      {
        getBanks && getBanks.length > 0 ?

        getBanks?.map((item , id)=>{
          return(
            <option key={id} value={item.bank_id}>{item.bank_name}</option>
          )
        })

        :

        <option value="">No Bank Found !</option>
      }
      
    </select>
    </div>


</div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-dark'
        onClick={()=> {setShowArticleReceivingModal(!showArticleReceivingModal)
        }}
        >
        Close
        </button>
        {
                        Loading === true ?
                        <div className="spinner-border text-primary mt-3 float-end" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
        <button className='btn btn-primary' onClick={submitArticle}>
            Submit
        </button>
}
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default UpdateArticleReceivingModal