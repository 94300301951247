import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState , useEffect} from 'react';
import UsersGlobalData from '../GlobalCalls/UsersGlobalData';


const BankForm = () => {
    const [bankName , setBankName] = useState('');
    const [bankTitle , setBankTitle] = useState('');
    const [accountNumber , setAccountNumber] = useState('');
    const [balance , setBalance] = useState('');
    const [loading , setLoading] = useState(false);


    const[userData , setUserData] = useState('');

    function submitBankAcc(){
      setLoading(true)
        const bankAccObj = {
            role_id : userData.role_id,
            user_id:userData.id,
            added_by:userData.username,
            bank_name : bankName,
            account_title : bankTitle,
            account_no: accountNumber,
            balance: balance

        }
        axios.post(`${process.env.REACT_APP_BASE_URL}post_bank`, bankAccObj)
        .then((res)=>{
          if(res.data.status === '200'){
              toast.info("Bank Details Submit!")
              setBankName('');
              setBankTitle('')
              setAccountNumber('');
              setBalance('');
              setLoading(false)
            
          }
          else {
            setLoading(false)
            throw new toast.error("Can't submit items!");
          }
        })
      .catch((error)=>{
        if(error){
          setLoading(false)
          toast.warn("Something went wrong, please try again in a while")
        }
        })

    
    }


    useEffect(()=>{
      const getocal = async() =>{
        const data = await UsersGlobalData()
        setUserData(data)
      }
      getocal()
  
    },[])



  return (
    <>
     <div className="scroll-view-component scrollbar-secondary-component">
    <div className="content-wrapper">
<div class="container-xxl flex-grow-1 container-p-y">
<h4 class="fw-bold "><span class="text-muted fw-light">Bank Form</span></h4>
  <div className="row">

  <div className="col-xl">
    <div className="card mb-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Bank Form</h5>
      </div>
      <div className="card-body">
        <div className="row">

            <div className="col-lg-4">
            <div className="mb-3">
            <label className="form-label" htmlFor="basic-icon-default-fullname">Bank Name</label>
            <div className="input-group input-group-merge">
              <span id="basic-icon-default-fullname2" className="input-group-text"><i className="bx bx-rename" /></span>
              <input type="text" className="form-control" value={bankName} id="basic-icon-default-fullname" placeholder="Enetr bank name..." aria-label="John Doe" aria-describedby="basic-icon-default-fullname2" onChange={(e)=>setBankName(e.target.value)}/>
            </div>
          </div>
            </div>

            <div className="col-lg-4">
            <label className="form-label" htmlFor="basic-icon-default-company">Bank Title</label>
            <div className="input-group input-group-merge">
              <span id="basic-icon-default-company2" className="input-group-text"><i className="bx bx-captions" /></span>
              <input type="text" id="basic-icon-default-company" value={bankTitle} className="form-control" placeholder="Enter bank account title..." aria-label="ACME Inc." aria-describedby="basic-icon-default-company2" onChange={(e)=>setBankTitle(e.target.value)}/>
            </div>
            </div>

            <div className="col-lg-4">
            <label className="form-label" htmlFor="basic-icon-default-company">Balance</label>
            <div className="input-group input-group-merge">
              <span id="basic-icon-default-company3" className="input-group-text"><i className="bx bx-money" /></span>
              <input type="number" id="basic-icon-default-company" value={balance} className="form-control" placeholder="Enter bank account title..." aria-label="ACME Inc." aria-describedby="basic-icon-default-company3" onChange={(e)=>setBalance(e.target.value)}/>
            </div>
            </div>

        </div>
    
       
          <div className="mb-3">
            <label className="form-label" htmlFor="basic-icon-default-email">Bank Account Number</label>
            <div className="input-group input-group-merge">
              <span className="input-group-text"><i className="bx bxs-user-account" /></span>
              <input type="text" id="basic-icon-default-email" value={accountNumber} className="form-control" placeholder="Enter bank account number..." aria-label="john.doe" aria-describedby="basic-icon-default-email2" onChange={(e)=>setAccountNumber(e.target.value)}/>
              <span id="basic-icon-default-email2" className="input-group-text">e.g XYZ202912NIL</span>
            </div>
          </div>

        {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
        
        <button type="submit" className="btn btn-primary mt-3 float-end" onClick={submitBankAcc}>Submit</button>
        }
      
      </div>
    </div>
  </div>
</div>


            </div>
    
    </div>
    </div>
    </>
  )
}

export default BankForm