import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import React, { useState , useEffect } from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
import VendorSectionEndPoint from "../../../Api/VendorSection/VendorSectionEndPoint";

const UpdateVendor = ({ vendorID , showVendorModal , setShowVendorModal , refetchVendors }) => {
  const { mutate: updateVendorSubmission, isLoading } = VendorSectionEndPoint.useUpdateVendor();

  const [vendID, setVendorID] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [autoFetch , setAutoFetch] = useState(false);
  const[userData , setUserData] = useState('');


  // Getting Specific textile items:
  useQuery(["getVendorByID", vendorID],(_) => VendorSectionEndPoint.getVendorByID(vendorID),
    {
      enabled:!autoFetch,
      onSuccess: (data) => {
        setVendorID(data.data.Vendor.vendor_id);
        setVendorName(data.data.Vendor.vendor_name);
        setVendorPhone(data.data.Vendor.vendor_phone_number);
        setVendorEmail(data.data.Vendor.vendor_email);
        setVendorAddress(data.data.Vendor.vendor_address);
        setAutoFetch(true)
      },
      onError: (err) => {
        return err;
      },
    }
  );

  function updateVendor() {
    const vendorObj = {
      role_id :userData.role_id,
      user_id:userData.id,
      added_by:userData.username,
      vendor_name: vendorName,
      vendor_phone_number: vendorPhone,
      vendor_email: vendorEmail,
      vendor_address: vendorAddress,
    };

    updateVendorSubmission({ vendorObj, vendID },
      {
        onMutate: () => {},
        onSettled: () => {
          setVendorName("");
          setVendorPhone("");
          setVendorEmail("");
          setVendorAddress("");
        },
      }
    );
  }

  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
    
  }, [])
  

  return (
    <>
      <Modal show={showVendorModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Vendor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label" htmlFor="basic-default-company">
                  Vendor name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="basic-default-company"
                  placeholder="Enter Vendor name..."
                  value={vendorName}
                  onChange={(e) => setVendorName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label" htmlFor="basic-default-company">
                  Vendor phone
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="basic-default-company"
                  placeholder="Enter Vendor phone..."
                  value={vendorPhone}
                  onChange={(e) => setVendorPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label" htmlFor="basic-default-email">
                  Vendor Email
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type="email"
                    id="basic-default-email"
                    className="form-control"
                    placeholder="Enter Vendor email..."
                    aria-label="john.doe"
                    aria-describedby="basic-default-email2"
                    value={vendorEmail}
                    onChange={(e) => setVendorEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label" htmlFor="basic-default-phone">
                  Vendor Address
                </label>
                <textarea
                  type="number"
                  id="basic-default-phone"
                  className="form-control phone-mask"
                  placeholder="Enter Vendor address..."
                  value={vendorAddress}
                  onChange={(e) => setVendorAddress(e.target.value)}
                  rows={5}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark"
          
          onClick={()=> {
            setShowVendorModal()
            refetchVendors()
            }}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={updateVendor}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateVendor;
