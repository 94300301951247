import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{useState, useEffect} from 'react';
import UsersGlobalData from '../../GlobalCalls/UsersGlobalData';

const DepositForm = () => {
    const[getBanks , setGetBanks] = useState([]);
    
    const[input , setInput] = useState(false);
    const [loading , setLoading] = useState(false);

    const[bankID , setBankID] = useState('');
    const[selectBankName , setSelectBankName] = useState('');
    const[amount , setAmount] = useState('');
    const[description , setDescription] = useState('');

    const[userData , setUserData] = useState('');

    function submitDeposit(){
  
        setLoading(true)
        if(selectBankName && amount && description){
               const despositObj = {
              role_id :userData.role_id,
              user_id:userData.id,
              added_by:userData.username,
              bank_id:bankID,
              amount : amount,
              transaction_type :"deposit",
              ledger_description: `${description} - ${bankID}`
  
          }
          axios.post(`${process.env.REACT_APP_BASE_URL}post_bank_deposit`, despositObj)
          .then((res)=>{
            if(res.data.status === '200'){
                toast.info("Deposit Submit!")
                setBankID('');
                setAmount('');
                setDescription('');
                setSelectBankName('');
                setLoading(false);
              
            }
            else {
              setLoading(false)
              throw new toast.error("Can't submit items!");
            }
          })
        .catch((error)=>{
          if(error){
            console.log(error)
            setLoading(false)
            toast.warn("Something went wrong, please try again in a while")
          }
          })
  
      
      
            
        }
        else{
            setInput(true)
            toast.warn("Fill the information")
            setLoading(false)


        }
       }



    function getAllBanks(){
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
        .then((res)=>{
          setGetBanks(res.data.Banks)
        })
        .catch((err)=>{
          return err
        })
  
      }

      useEffect(() => {
        getAllBanks()

          const getocal = async() =>{
            const data = await UsersGlobalData()
           setUserData(data)
          }
          getocal()
  
      }, [])
  return (
    <>
        <div className="scroll-view-component scrollbar-secondary-component">
    <div className="content-wrapper">
<div className="container-xxl flex-grow-1 container-p-y">

<h4 className="fw-bold"><span className="text-muted fw-light">Deposit Form</span> </h4>
<div className="card">
<div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Deposit Form</h5>
      </div>
<div className="card-body">
    <div className="row">
    <div className="col-lg-6 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Bank
                        </label>

                        <select className={selectBankName === '' && input === true ?"form-select border border-danger" :"form-select"} aria-label="Default select example"
                        value={bankID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          const selectedText = e.target.options[e.target.selectedIndex].text;
                          setBankID(selectedValue)
                          setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value="">Bank select menu</option>
                          {
                            getBanks && getBanks.length > 0 ?

                            getBanks?.map((item , id)=>{
                              return(
                                <option key={id} value={item.bank_id}>{item.bank_name}</option>
                              )
                            })

                            :

                            <option value="">No Bank Found !</option>
                          }
                          
                        </select>
                        </div>


          <div className="col-lg-6 mb-3">
            <label className="form-label" htmlFor="basic-default-company">Amount</label>
            <input type="number" value={amount} className={amount === ''&& input === true?"form-control border border-danger":"form-control"} id="basic-default-companyy" placeholder="Enter amount..." 
              onChange={(e)=> {setAmount(e.target.value)}}
            />
          </div>

          <div className="col-lg-12 mb-3">
            <label className="form-label" htmlFor="basic-default-fullname">Description</label>
            <input type="text" value={description} className={description === ''&& input === true?"form-control border border-danger":"form-control"} id="basic-default-fullname" placeholder="Enter description..." 
            onChange={(e)=> {setDescription(e.target.value)}}
            />
          </div>

    </div>
    {
          loading ?
          <div className="spinner-border text-primary mt-3 float-end" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        
        :
       
          <button type="submit" className="btn btn-primary float-end mt-3"
          onClick={submitDeposit}
          >Submit</button>
    }
      </div>
      </div>
    </div>
    </div>
    </div>
    </>
  )
}

export default DepositForm