import React from 'react';
import Modal from 'react-bootstrap/Modal';
import OrderApproveSign from '../../Images/OrderApproveSign.gif';
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";


const ApproveOrderModal = ({orderAction,orderID,index,removeSpecificeOrder,showAproveModal , setShowApproveModal}) => {


  const { mutate: approveOrdersByStatus, isLoading } =  OrderIntakeSectionEndPoint.useApproveOrderStatus();

  function updateOrderStatus() {
    const orderStatusObj = {
      status:orderAction
    };


    approveOrdersByStatus(({orderStatusObj,orderAction,orderID,index,removeSpecificeOrder}), {
      onMutate: () => {

      },
      onSettled: () => {
      },
    });
}

  return (
    <>

        <Modal
        show={showAproveModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>
          <i className="fa-solid fa-box fs-3"/> &nbsp; Order#
        {orderID}     
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center text-black fs-4">

         Move this order to {orderAction} orders?  <img className="img-fluid fs-5"src={OrderApproveSign} alt="orderBox" width={40}/>
       
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-dark"  onClick={()=> setShowApproveModal(!showAproveModal)}>
            Close
          </button>
        <button className="btn btn-outline-primary" onClick={updateOrderStatus}>
         {isLoading ? "...":"Okay ?"} 
        </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default ApproveOrderModal