import axios from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import ArticleSectionEndPoint from "../../../Api/ArticleSection/ArticleSectionEndpoint";

import Modal from 'react-bootstrap/Modal';


import "quill/dist/quill.snow.css";
import { useQuill } from "react-quilljs";
import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter';

import { DndContext, closestCenter } from "@dnd-kit/core";
import SortableArticleImages from "./SortableArticleImages";
import {arrayMove,horizontalListSortingStrategy,SortableContext} from "@dnd-kit/sortable";



Quill.register('modules/blotFormatter', BlotFormatter);
const UpdateArticleModal = ({refetch ,  articleID ,showArticleModal  ,setShowArticleModal }) => {
  const [artID, setArtID] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleDescription, setArticleDescription] = useState("");
  const [articlePrice, setArticlePrice] = useState("");
  const [articlesImg, setArticlesImages] = useState([]);
  const [articlesImgPreview, setArticlesImagesPreview] = useState([]);
  const [sizeChartImage, setSizeChartImage] = useState("");

  const [articlePrefix, setArticlePrefix] = useState("");
  const [articlePostfix, setArticlePostfix] = useState("");
  const [subPrepost , setSubPrepost] = useState('');

  const [articleColor, setArticleColor] = useState("");

  const [articleInstructions, setArticleInstructions] = useState("");
  const [articleMaterial, setArticleMaterial] = useState("");
  const [articleOnSale, setArticleOnSale] = useState("");
  const [articleSalePrice, setArticleSalePrice] = useState("");

  const [articleSizeSelect, setArticleSizeSelect] = useState([]);
  const [articleQuantityInputs, setArticleQuantityInputs] = useState([]);
  const [articleSizeQunatity, setArticleSizeQunatity] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [preCategories, setPreCategories] = useState("");
  const [subCategoryID, setSubCategoryID] = useState("");
  const [getArticleOnce, seGetArticleOnce] = useState(false);

 

  const [loading, setLoading] = useState(false);

  const { quill, quillRef} = useQuill({
    modules: { blotFormatter: {} }
  });

  
  // Getting specific articles:
 const {isSuccess} =  useQuery(["getArticlesID", articleID],(_) => ArticleSectionEndPoint.getArticleByID(articleID),
  // getArticleOnce === false ?
    {
      enabled: !getArticleOnce,
      onSuccess: (data) => {
        setArtID(data.data.data.id);
        setArticleTitle(data.data.data.title);
        setArticleDescription(data.data.data.description);
        setArticlePrice(data.data.data.price);
        setArticlePrefix(data.data.data.article_prefix);
        setArticlePostfix(data.data.data.article_post_fix);
        setPreCategories(data.data.data.cat_name);
        setArticleSizeQunatity(data.data.data.sub_datas);
        setArticleColor(data.data.data.color);
        setArticleInstructions(data.data.data.instruction);
        setArticleMaterial(data.data.data.material);
        setArticleOnSale(data.data.data.on_sale);
        setSubCategoryID(data.data.data.sub_categorys_id);
        seGetArticleOnce(true)
        
      },
      onError: (err) => {
        return err;
      },
    }
  );  


  const handleArticleImageSelect = (selectedFiles) => {
    const selectedImages = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      selectedImages.push({
        id: i,
        url: URL.createObjectURL(selectedFiles[i]),
      });
    }
    setArticlesImagesPreview(selectedImages);
    const files = Array.from(selectedFiles);
    setArticlesImages(files)
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
     if (!active || !over ) {
    // Handle the case where active or over is null or their id is undefined
    return;
  }

  if (active.id === over.id) {
    // No need to proceed if the dragged item is dropped on itself
    return;
  }

  setArticlesImagesPreview((articlesImg) => {
      const updatedImages = [...articlesImg];
      
      const activeIndex  = articlesImg.findIndex((file) => file.id === active.id); 
      const newIndex = articlesImg.findIndex((file) => file.id === over.id);
      // return arrayMove(articlesImg, oldIndex, newIndex);

        if (activeIndex === -1 || newIndex === -1) {
          // Handle the case where activeIndex or overIndex is not found
          return articlesImg;
        }

       // Remove the dragged item from its current position
    const draggedItem = updatedImages.splice(activeIndex, 1)[0];

    // Insert the dragged item at the new index
    updatedImages.splice(newIndex, 0, draggedItem);

        return updatedImages;
    });


  };

  const handleImageDelete = (id) => {
    setArticlesImagesPreview((prevImages) => {
      const updatedImages = prevImages.filter((image) => image.id !== id);
      return updatedImages;
    });
  };


  // Example options for sizes:
  const options = [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ];

  // Initialize the selected state with the fetched sizes:


  useEffect(() => {
    if (articleSizeQunatity) {
      setArticleSizeSelect(
        articleSizeQunatity.map((item) => {
          const option = options.find((opt) => opt.label === item.size);
          return option ? option : null;
        })
      );
    }
  }, [articleSizeQunatity])

  // Initialize the inputFields state with the fetched quantities
  useEffect(() => {
    if (articleSizeQunatity) {
      setArticleQuantityInputs(
        articleSizeQunatity.map((item) => ({
          ...item,
          p_id: item.p_id // set p_id dynamically from preexisting data
        }))
      );
    }
  }, [articleSizeQunatity]);

  const handleSelectSizesChange = (selected) => {
    setArticleSizeSelect(selected);
  };

  const selectedSizeValue = articleSizeSelect.map((item) => item.value);
  const selectedSizeQunatity = articleQuantityInputs ? articleQuantityInputs.map((item) => item.quantity) : "";

  const pre_postVal = articleQuantityInputs ? articleQuantityInputs.map((item) => item.p_id) : "";



  const sizeAndQuantity = selectedSizeValue.map((size, index) => ({
    quantity: parseInt(selectedSizeQunatity[index], 10) || 0,
    size,
    in_progress_stock:0,
    p_id : pre_postVal[index]
  }));

  // Articles Category & Sub_Category functionality:

  const handleSelectCategories = (e) => {
    getSubCategories(e);
    setSelectedCategory(e.target.value);
  };

  function getCategories() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}get_categorys_name_and_id`)
      .then((res) => {
        setCategories(res.data.Categorys);
      })
      .catch((error) => {
        return error;
      });
  }

  function getSubCategories(e) {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}fetch_sub_categorys_by_cid/${e.target.value}`
      )
      .then((res) => {
        setSubCategories(res.data.sub_categories);
      })
      .catch((error) => {
        return error;
      });
  }

  // Showing prefetched categories in category dropdown:

  useEffect(() => {
    getCategories();
    if (preCategories) {
      setSelectedCategory(preCategories);
    }
  }, [preCategories]);



  useEffect(() => {
    if (isSuccess && quill) {
      
      // const delta = quill.clipboard.convert(articleDescription);

      // const rtlCharacter = '\u200F'; 
      // delta.ops.unshift({ insert: rtlCharacter });


      // quill.setContents(delta);

      
      // quill.container.classList.add('rtl-text');

      quill.on("text-change", (delta, oldDelta, source) => {
        // quill.setContents(JSON.parse(articleDescription));
        const htmlContent = quill.root.innerHTML;
           setArticleDescription(htmlContent);
        // setArticleDescription(quill.getText()); 
        // setArticleDescription(quill.getContents())
        // setArticleDescription(quill.getContents()); 
        // setArticleDescription(quill.root.innerHTML); // Get innerHTML using quill
        // setArticleDescription(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
    });
    }
  }, [quill, articleDescription, isSuccess]);

  function updateArticle() {

    setLoading(true)
    
    let formdata = new FormData();
    sizeChartImage &&
    formdata.append("size_chart_img",sizeChartImage)
    articlesImg &&
    articlesImg.map((image)=>{
      formdata.append("image[]", image);

    })

    formdata.append("description", articleDescription);
    formdata.append("article_prefix" , articlePrefix);
    formdata.append("sub_category", subCategoryID);
    formdata.append("article_post_fix",articlePostfix);
    formdata.append("pre_post",articlePrefix+articlePostfix);
    formdata.append("color", articleColor);
    formdata.append("title", articleTitle);
    formdata.append("price", articlePrice);
    formdata.append("instruction", articleInstructions);
    formdata.append("material", articleMaterial);
    formdata.append("on_sale", articleOnSale);

    articleSalePrice &&
    formdata.append("sale_price", articleSalePrice);


    formdata.append("is_live", true);
    formdata.append("sub_datas", JSON.stringify(sizeAndQuantity));

      axios.post(`${process.env.REACT_APP_BASE_URL}update_article_by_id/${artID}`,formdata)
      .then((res)=>{
        if(res.data.status === '200'){
          toast.info("Article Updated!")
          setLoading(false)


    setTimeout(() => {
      setArtID('')
      setArticleTitle('')
      setArticleDescription('')
      setArticlePrice('')
      setArticlePrefix('')
      setArticlePostfix('')
      setPreCategories('')
      setArticleSizeQunatity('')
      setArticleColor('')
      setArticleInstructions('')
      setArticleMaterial('')
      setArticleOnSale('')
      setSubCategoryID('');
      setArticleSizeSelect([]);
      setArticleQuantityInputs([]);
      setCategories([]);
      setSubCategories([]);
      setArticlesImages([])
      setArticlesImagesPreview([])

    }, 2000);

    }
    })
    .catch((error)=>{
      if(error)
      {
        setLoading(false)
        toast.warn("Something went wrong, please try again in a while")
      }
    })
  }

  return (
    <>
    <Modal
        show={showArticleModal}
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered
      >
       <Modal.Header>
          <Modal.Title>
          Update Article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Article Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-company011"
                    placeholder="Enter Title..."
                    value={articleTitle}
                    onChange={(e) => setArticleTitle(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label for="formFileMultiple" class="form-label">
                    Select Size Chart Image
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFileMultiple1"
                    onChange={(e) => setSizeChartImage(e.target.files[0])}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Article Prefix
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-company1"
                    placeholder="Enter Prefix..."
                    value={articlePrefix}
                    onChange={(e) => setArticlePrefix(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Article Postfix
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-company2"
                    placeholder="Enter Postfix..."
                    value={articlePostfix}
                    onChange={(e) => setArticlePostfix(e.target.value)}
                  />
                </div>
{/* 
                <div className="col-lg-4 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company44"
                      >
                        Article Sub Pre_post
                      </label>
                      <input
                        type="text"
                        className={"form-control"}
                        id="basic-default-company44"
                        placeholder="Enter Sub Prepost..."
                        value={subPrepost}
                        onChange={(e)=> setSubPrepost(e.target.value)}
                      />
                        </div> */}


                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Color
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-company3"
                    placeholder="Enter Article color..."
                    value={articleColor}
                    onChange={(e) => setArticleColor(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="basic-default-company4"
                    placeholder="Enter Article price..."
                    value={articlePrice}
                    onChange={(e) => setArticlePrice(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Instructions
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-company013"
                    placeholder="Enter Instructions..."
                    value={articleInstructions}
                    onChange={(e) => setArticleInstructions(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Material
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-default-company014"
                    placeholder="Enter Material..."
                    value={articleMaterial === "null" ?"no material":articleMaterial}
                    onChange={(e) => setArticleMaterial(e.target.value)}
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="basic-default-company">
                    Article On Sale/Not
                  </label>
                  <select
                    id="inputGroupSelect01"
                    className="form-select"
                    value={articleOnSale}
                    onChange={(e) => setArticleOnSale(e.target.value)}
                  >
                    <option>Choose...</option>
                    <option value="true">On Sale</option>
                    <option value="false">Not On Sale</option>
                  </select>
                </div>

                {articleOnSale === "true" ? (
                  <div className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-company"
                    >
                      Sale Price
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-default-company0166"
                      placeholder="Enter Sale Price..."
                      value={articleSalePrice}
                      onChange={(e) => setArticleSalePrice(e.target.value)}
                    />
                  </div>
                ) : null}

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="inputGroupSelect01">
                    Categories
                  </label>
                  <select
                    id="inputGroupSelect01"
                    className="form-select"
                    value={selectedCategory}
                    onChange={handleSelectCategories}
                  >
                    <option>Choose...</option>
                    {categories && categories.length > 0 ? (
                      categories.map((items, index) => {
                        return (
                          <option key={index} value={items.id}>
                            {items.cat_name}
                          </option>
                        );
                      })
                    ) : (
                      <option value="nothing">No Category Found</option>
                    )}

                    {/* Add an option with the prefetched data */}
                    {preCategories && (
                      <option value={preCategories} disabled>
                        {preCategories}
                      </option>
                    )}
                  </select>
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label" htmlFor="inputGroupSelect02">
                    Sub Categories
                  </label>
                  <select
                    id="inputGroupSelect02"
                    className={"form-select"}
                    value={subCategoryID}
                    onChange={(e) => setSubCategoryID(e.target.value)}
                  >
                    <option>Choose...</option>
                    {subCategories && subCategories.length > 0 ? (
                      subCategories.map((items, index) => {
                        return (
                          <option key={index} value={items.id}>
                            {items.sub_cat_name}
                          </option>
                        );
                      })
                    ) : (
                      <option value="nothing">No Sub_Category Found</option>
                    )}
                  </select>
                </div>

                <div className="col-lg-4">
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-default-fullname"
                    >
                      Select Sizes
                    </label>
                    <MultiSelect
                      options={options}
                      value={articleSizeSelect}
                      onChange={handleSelectSizesChange}
                      labelledBy="Select"
                    />
                  </div>
                </div>
                {articleSizeSelect.map((selectedSize) => (
                  <>
                  <div key={selectedSize.value} className="col-lg-4 mb-3">
                    <label
                      className="form-label"
                      htmlFor={`basic-default-company-${selectedSize.value}`}
                    >
                      {`Quantity for ${selectedSize.label}`}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={`Enter Quantity`}
                      value={
                        Array.isArray(articleQuantityInputs)
                          ? articleQuantityInputs.find( (field) => field.size === selectedSize.label)?.quantity || ""
                          : ""
                      }
                      onChange={(e) => {
                        const updatedQuantity = e.target.value;
                        const selectedSizeLabel = selectedSize.label;
                        let updatedInputFields = articleQuantityInputs
                          ? [...articleQuantityInputs]
                          : [];

                        // Check if the selected size is already in the state
                        const existingSizeIndex = updatedInputFields.findIndex((field) => field.size === selectedSizeLabel);

                        if (existingSizeIndex >= 0) {
                          // If it exists, update the quantity
                          updatedInputFields[existingSizeIndex] = {
                            ...updatedInputFields[existingSizeIndex],
                            quantity: updatedQuantity,
                          };
                        } else {
                          // If it doesn't exist, add the new size with quantity
                          updatedInputFields = [
                            ...updatedInputFields,
                            {
                              quantity: updatedQuantity,
                              size: selectedSizeLabel,
                              p_id: sizeAndQuantity.find(item => item.size === selectedSizeLabel)?.p_id || ""
                            },
                          ];
                        }

                        setArticleQuantityInputs(updatedInputFields);
                      }}
                    />
                        </div>

                        <div className="col-lg-4 mb-3">
                     <label className="form-label" htmlFor={`p-id-${selectedSize.value}`}>
                          {`Pre_post for ${selectedSize.label}`}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`Enter pre_post`}
                          value={
                            Array.isArray(articleQuantityInputs)
                              ? articleQuantityInputs.find((field) => field.size === selectedSize.label)?.p_id || ""
                              : ""
                          }
                          onChange={(e) => {
                            const updatedPId = e.target.value;
                            const selectedSizeLabel = selectedSize.label;
                            let updatedInputFields = articleQuantityInputs
                              ? [...articleQuantityInputs]
                              : [];

                            // Check if the selected size is already in the state
                            const existingSizeIndex = updatedInputFields.findIndex(
                              (field) => field.size === selectedSizeLabel
                            );

                            if (existingSizeIndex >= 0) {
                              // If it exists, update the p_id
                              updatedInputFields[existingSizeIndex] = {
                                ...updatedInputFields[existingSizeIndex],
                                p_id: updatedPId,
                              };
                            } else {
                              // If it doesn't exist, add the new size with p_id
                              updatedInputFields = [
                                ...updatedInputFields,
                                {
                                  p_id: updatedPId,
                                  size: selectedSizeLabel,
                                  quantity: sizeAndQuantity.find(item => item.size === selectedSizeLabel)?.quantity || 0, // set quantity dynamically
                                },
                              ];
                            }

                            setArticleQuantityInputs(updatedInputFields);
                          }}
                        />
                        </div>
                        </>
                ))}
                


                <div className="col-lg-12" style={{ marginBottom: "6em" }}>
                  <label className="form-label" htmlFor="basic-default-company">
                    Article Description
                  </label>
                  <div
                    ref={quillRef}
                    type="text"
                    className="form-control"
                    id="basic-default-company012"
                    placeholder={`${78}`}
                    // value={
                    //   articleDescription 
                    //   // === "null" || null
                    //   //   ? "no discription"
                    //   //   : articleDescription
                    // }
                    // dangerouslySetInnerHTML={{ __html: articleDescription }}
                    // onChange={(e) => setArticleDescription(e.target.value)}

                    // rows={4}
                  />
                </div>

                <div className="col-lg-12 mb-3 mt-3">
                  <label for="formFileMultiple" class="form-label">
                    Select Multiple Article Images
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFileMultiple"
                    multiple
                    onChange={(e) => handleArticleImageSelect(e.target.files)}
                  />
                </div>

                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  style={{marginBottom:"10em"}}
                >
                  <SortableContext
                    items={articlesImgPreview}
                    strategy={horizontalListSortingStrategy}
                  >
                    <div className="d-flex p-2">
                      {articlesImgPreview.map((file, index) => (
                        <SortableArticleImages
                          key={file.id}
                          file={file}
                          index={index}
                          handleImageDelete={handleImageDelete}
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
              </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setLgShow(false)}>
            Close
          </Button> */}

          {loading === true ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>

                <button
                  type="submit"
                  className="btn btn-dark"
                  onClick={() => {
                    setShowArticleModal(!showArticleModal)
                    refetch()
                    // setAutoFetch(true)
                  }}
                >
                  Close
                </button>
                &nbsp;
                    <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={updateArticle}
                >
                  Submit
                </button>
                
                </>
              
              )}

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateArticleModal;
