import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import UpdatePayCourierModal from "../Modal/UpdatePayCourierModal";

const PaidCourierSheet = () => {
  const [payCourierID , setPayCourierID] = useState('');
  const [allPayCourier, setAllPayCourier] = useState([]);
  const [showUpdatePayCourierModal , setUpdatePayCourierModal] = useState(false);


  function getAllPayCourier() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}fetch_all_pay_couriers`)
      .then((res) => {
        setAllPayCourier(res.data.data);
      })
      .catch((error) => {
        return error;
      });
  }

  function removePayCourierDetails(index) {
    setAllPayCourier((prevState) => {
      const payCourier = [...prevState];
      payCourier.splice(index, 1);
      return payCourier;
    });
  }

  function deletePayCourierDetails(id,index){
    axios.post(`${process.env.REACT_APP_BASE_URL}delete_pay_courier_by_id/${id}`)
    .then((res)=>{
      if(res.data.status === '200'){
        toast.error("Pay Courier Removed!")
        removePayCourierDetails(index)
  
        }
    })
    .catch((error)=>{
        if(error){
          toast.warn("Something went wrong!1")
        }
   
  })
  }

  function openUpdatePayCourierModal(id,index){
    setUpdatePayCourierModal((p)=>!p)
    setPayCourierID( id )

  }

  useEffect(() => {
    getAllPayCourier();
  }, []);

  
  function AllPayCourier({ items, index }) {
    return (
      <>
        <tr key={index} className="text-center">
          {/* {showMultiCheckButton ? (
              <td>
                    
                    <input
                      className="form-check-input border border-primary"
                      type="checkbox"
                      id={`defaultCheck${items.id}`}
                      onChange={() =>{ 
                        handleMultiSelectApproveOrders(items.id)
                      }}
                      checked={multiSelectApproveOrderIDs.includes(items.id)}
                      
                    />
              </td>
                ) : 
                <td>
                  {null}
                </td>
                } */}
          <td>{allPayCourier?.length - index}</td>
          <td>
            {items.order_id?.map((order) => {
              return (
                <span className="m-1">
                  {order === "" ? "No orders" : order}
                </span>
              );
            })}
          </td>
          <td>{items.courier_name}</td>
          <td>{items.total_receiveable}</td>

          <td>{items.total_payable}</td>

          <td>{items.paid_via_stripe}</td>

          <td>{items.paid_via_bank}</td>
          <td>{items.non_cod}</td>
          <td>{items.bank_name}</td>
          <td>{items.Idate}</td>

          <td >
         
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={()=>openUpdatePayCourierModal(items.id, index)}
                >
                  <i className="bx bx-edit-alt" />
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    deletePayCourierDetails(items.id, index);
                  }}
                >
                  <i className="bx bx-trash " /> 
                </button>
        
          </td>
        </tr>
        
        {
            showUpdatePayCourierModal  ?
            <UpdatePayCourierModal
            payCourierID = {payCourierID}
            getAllPayCourier = {getAllPayCourier}
            showUpdatePayCourierModal={showUpdatePayCourierModal}
            setUpdatePayCourierModal = {setUpdatePayCourierModal}

            />
            :
            null


        }

      </>
    );
  }
  return (
    <>
    <div className="scroll-view-component scrollbar-secondary-component"> 
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">Pay All Courier </span> 
          </h4>
          <div className="card">
            <h5 className="card-header">Pay All Courier</h5>
            <div className="table-responsive text-nowrap">

              <table className="table text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Orders #</th>
                    <th>Courier</th>
                    <th>Payables</th>
                    <th>Receivable</th>
                    <th>Stripe amount</th>
                    <th>Bank amount</th>
                    <th>Non COD</th>
                    <th>Bank</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="table-border  table-responsive">
                  {allPayCourier?.map((items, index) => {
                    return <AllPayCourier items={items} index={index} />;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default PaidCourierSheet;
