import React from 'react';
import Modal from 'react-bootstrap/Modal';
import OrderApproveSign from '../../../Images/OrderApproveSign.gif';
import MultipleOrderSubmissionSection from '../../../Api/OrderSection/MultipleOrderSubmitSection';

const ApproveMultipleOrderModal = ({
    orderAction,index,removeSpecificeOrder,removeMultiOrders,
    showMultiSelectApproveModal,setShowMultiSelectApproveModal, 
    multiSelectApproveOrder , setMultiSelectApproveOrders,
    autoFetch , setAutoFetch , showMultiCheckButton, setShowMultiCheckButton,
    
}) => {

const { mutate: approveMultipleOrdersByStatus, isLoading } =  MultipleOrderSubmissionSection.useApproveMultipleOrderStatus();

  function updateOrderStatus() {


    approveMultipleOrdersByStatus(({multiSelectApproveOrder,orderAction,index,removeSpecificeOrder, removeMultiOrders}), {
      onMutate: () => {

      },
      onSettled: () => {
        setAutoFetch(!autoFetch)
        setShowMultiCheckButton(!showMultiCheckButton);
        setMultiSelectApproveOrders('')
      },
    });
}

  return (
    <>
      
      <Modal
        show={showMultiSelectApproveModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>
          <i className="fa-solid fa-box fs-3"/> #Orders   
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center text-black fs-4">
        Want to approve all these selected orders to {orderAction} section?  <img className="img-fluid fs-5"src={OrderApproveSign} alt="orderBox" width={40}/>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-dark"  onClick={()=> setShowMultiSelectApproveModal(!showMultiSelectApproveModal)}>
            Close
          </button>
        <button className="btn btn-outline-primary" onClick={updateOrderStatus}>
         {isLoading ? "...":"Okay ?"} 
        </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default ApproveMultipleOrderModal