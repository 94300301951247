import React from 'react'

const Error = () => {
  return (
    <>
  <div className="container-xxl container-p-y">
  <div className="misc-wrapper">
    <div className="mt-3">
      <img src="../assets/img/illustrations/page-misc-error-light.png" alt="page-misc-error-light" width={250} className="img-fluid d-block mx-auto" data-app-dark-img="illustrations/page-misc-error-dark.png" data-app-light-img="illustrations/page-misc-error-light.png" />
    </div>
    <h3 className="text-center fw-bold mt-4">No Order Found!</h3>
  </div>
</div>

    </>
  )
}

export default Error