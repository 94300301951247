import React  from 'react'

const UsersGlobalData =async () => {

   
        try{
          let user = await localStorage.getItem('ag_user');
          let parsed = JSON.parse(user);
          if(parsed !== null){
            return parsed;
          }
        }catch{
            return null;
        }


      
}

export default UsersGlobalData