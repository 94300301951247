import Forms from "../Forms/Forms";
import Sheets from "../Sheets/Sheets";
import Navbar from "../Layout/Navbar";
import Content from "../Layout/Content";
import Sidebar from "../Layout/Sidebar";
import React, { useState } from "react";

import RegisterMemberForm from "../Users/Form/RegisterMemberForm";
import MembersSheet from "../Users/Sheets/MembersSheet";

import PermissionForm from "../Permissions/Permission/PermissionForm";
import PermissionSheet from '../Permissions/Permission/PermissionSheet';

import GrantPermissionForm from "../Permissions/GrantPermission/GrantPermissionForm";
import GrantPermissionSheet from "../Permissions/GrantPermission/GrantPermissionSheet";

import TextileItemsForm from "../Inventory/TextileItems/Forms/TextileItemsForm";
import TextileItemsSheet from "../Inventory/TextileItems/Sheets/TextileItemsSheet";

import ClothCategoryForm from "../Inventory/ClothingCategories/Forms/ClothCategoryForm";
import ClothCategorySheet from "../Inventory/ClothingCategories/Sheets/ClothCategorySheet";
import ClothSubCategoryForm from "../Inventory/ClothingCategories/Forms/ClothSubCategoryForm";
import ClothSubCategorySheet from "../Inventory/ClothingCategories/Sheets/ClothSubCategorySheet";

import VendorForm from "../Inventory/Vendors/Forms/VendorForm";
import VendorSheet from "../Inventory/Vendors/Sheets/VendorSheet";

import ItemReceivingForm from "../Inventory/ItemsReceiving/Forms/ItemReceivingForm";
import ItemReceivingSheet from "../Inventory/ItemsReceiving/Sheets/ItemReceivingSheet";

import AddArticleForm from "../Inventory/Article/Forms/AddArticleForm";
import ArticleSheet from '../Inventory/Article/Sheets/ArticleSheet';

import ArticleReceivingForm from "../Inventory/ArticleReceiving/Forms/ArticleReceivingForm";
import ArticleReceivingSheet from "../Inventory/ArticleReceiving/Sheets/ArticleReceivingSheet";


import EventForm from '../Events/Forms/AddEventForm';
import EventSheet from "../Events/Sheets/EventSheet";

import PromoCodeForm from "../Events/PromoCodes/Forms/PromoCodeForm";
import PromoCodeSheet from "../Events/PromoCodes/Sheets/PromoCodeSheet";


import StaffForm from "../Staff/Forms/StaffForm";
import StaffSheet from "../Staff/Sheets/StaffSheet";

import StaffPayRollForm from "../Staff/StaffPayRolls/Forms/StaffPayRollForm";
import StaffPayRollSheet from "../Staff/StaffPayRolls/Sheets/StaffPayRollSheet";

import BankForm from '../Bank/BankForm';
import BankSheet from '../Bank/BankSheet';

import DepositForm from '../DepositsAndDeductions/Forms/DepositForm';
import DeductionForm from '../DepositsAndDeductions/Forms/DeductionForm';

import ManualOrderForm from "../Orders/ManualOrders/Form/ManualOrderForm";

import AllOrders from "../Orders/AllOrders/AllOrders";
import OrderIntake from "../Orders/OrderIntake/OrderIntake";
import VerifiedOrders from "../Orders/VerifiedOrders/VerifiedOrders";
import AssignedOrders from '../Orders/AssignedOrders/AssignedOrders';
import PendingOrders  from "../Orders/PendingOrders/PendingOrders"; 
import DispatchedOrders from '../Orders/DispatchedOrders/DispatchedOrders';
import DeliveredOrders from "../Orders/DeliveredOrders/DeliveredOrders";
import DisputedOrders from "../Orders/DisputedOrders/DisputedOrders";

import ExpenseForm from "../Expenses/Forms/ExpenseForm";
import ExpenseSheet from '../Expenses/Sheets/ExpenseSheet';


import PayCourierSheet from "../Courier/Sheets/PayCourierSheet";
import PaidCourierSheet from '../Courier/Sheets/PaidCourierSheet';

import FinancePieChart from '../Statistics/FinancePieChart';
import ItemsAndArticleGraph from '../Statistics/ItemsAndArticleGraph';

import PayStaffGraph from "../Statistics/PayStaffGraph";
import ExpenseGraph from "../Statistics/ExpenseGraph";

import DelieveredOrderGraph from "../Statistics/DeliveredOrderGraph";
import PayCourierGraph from "../Statistics/PayCourierGraph";

import { BrowserRouter as Router, Routes, Route   } from "react-router-dom";
const Dashboard = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
    <Router>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Sidebar isVisible={sidebarVisible} toggleSidebar={toggleSidebar} />
          <div className="layout-page">
            <Navbar toggleSidebar={toggleSidebar} />
            <Routes>
            <Route path="/" element={<Content />}/>   
            <Route path="/Forms" element={<Forms />}/>   
            <Route path="/Sheets" element={<Sheets />}/>
            
            <Route path="/PermissionForm" element={<PermissionForm />}/>
            <Route path="/PermissionSheet" element={<PermissionSheet />}/>

            <Route path="/GrantPermissionForm" element={<GrantPermissionForm />}/>
            <Route path="/GrantPermissionSheet" element={<GrantPermissionSheet />}/>


            <Route path="/RegisterMemberForm" element={<RegisterMemberForm />}/>
            <Route path="/MembersSheet" element={<MembersSheet />}/>
    
            
            <Route path="/TextileItemsForm" element={<TextileItemsForm />}/>
            <Route path="/TextileItemsSheet" element={<TextileItemsSheet />}/>
           
            <Route path="/ClothCategoryForm" element={<ClothCategoryForm />}/>
            <Route path="/ClothCategorySheet" element={<ClothCategorySheet />}/>
            <Route path="/ClothSubCategoryForm" element={<ClothSubCategoryForm />}/>
            <Route path="/ClothSubCategorySheet" element={<ClothSubCategorySheet />}/>

            <Route path="/VendorForm" element={<VendorForm />}/>
            <Route path="/VendorSheet" element={<VendorSheet />}/>

            <Route path="/ItemReceivingForm" element={<ItemReceivingForm/>}/>
            <Route path="/ItemReceivingSheet" element={<ItemReceivingSheet/>}/>

            <Route path="/AddArticleForm" element={<AddArticleForm/>}/>
            <Route path="/ArticleSheet" element={<ArticleSheet/>}/>

            <Route path="/ArticleReceivingForm" element={<ArticleReceivingForm/>}/>
            <Route path="/ArticleReceivingSheet" element={<ArticleReceivingSheet/>}/>


            <Route path="/EventForm" element={<EventForm/>}/>
            <Route path="/EventSheet" element={<EventSheet/>}/>

            <Route path="/PromoCodeForm" element={<PromoCodeForm/>}/>
            <Route path="/PromoCodeSheet" element={<PromoCodeSheet/>}/>

            <Route path="/PromoCodeForm" element={<PromoCodeForm/>}/>
            <Route path="/PromoCodeForm" element={<PromoCodeForm/>}/>


            <Route path="/BankForm" element={<BankForm/>}/>
            <Route path="/BankSheet" element={<BankSheet/>}/>

            <Route path="/DepositForm" element={<DepositForm/>}/>
            <Route path="/DeductionForm" element={<DeductionForm/>}/>    
            

            <Route path="/StaffForm" element={<StaffForm/>}/>
            <Route path="/StaffSheet" element={<StaffSheet/>}/>

            <Route path="/StaffPayRollForm" element={<StaffPayRollForm/>}/>
            <Route path="/StaffPayRollSheet" element={<StaffPayRollSheet/>}/>

            

            <Route path="/ManualOrderForm" element={<ManualOrderForm/>}/>

            <Route path="/AllOrders" element={<AllOrders/>}/>

            <Route path="/OrderIntake" element={<OrderIntake/>}/>
            <Route path="/VerifiedOrders" element={<VerifiedOrders/>}/>
            <Route path="/AssignedOrders" element={<AssignedOrders/>}/>
            <Route path="/PendingOrders" element={<PendingOrders/>}/>

            <Route path="/DispatchedOrders" element={<DispatchedOrders/>}/>
            <Route path="/DeliveredOrders" element={<DeliveredOrders/>}/>

            <Route path="/DisputedOrders" element={<DisputedOrders/>}/>


            <Route path="/ExpenseForm" element={<ExpenseForm/>}/>
            <Route path="/ExpenseSheet" element={<ExpenseSheet/>}/>
            

            <Route path="/PayCourierSheet" element={<PayCourierSheet/>}/>
            <Route path="/PaidCourierSheet" element={<PaidCourierSheet/>}/>

            <Route path="/FinancePieChart" element={<FinancePieChart/>}/>
            <Route path="/ItemsAndArticleGraph" element={<ItemsAndArticleGraph/>}/>
            

            <Route path="/PayStaffGraph" element={<PayStaffGraph/>}/>
            <Route path="/ExpenseGraph" element={<ExpenseGraph/>}/>

            <Route path="/DelieveredOrderGraph" element={<DelieveredOrderGraph/>}/>
            <Route path="/PayCourierGraph" element={<PayCourierGraph/>}/>

            </Routes>  
            
          </div>
        </div>
      </div>
      </Router>
    </>
  );
};

export default Dashboard;
