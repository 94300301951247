import { toast } from "react-toastify";
import React,{useState , useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import StaffSectionEndPoint from '../../Api/StaffSection/StaffSection';

const StaffForm = () => {
  const {mutate:staffSubmission , isLoading } = StaffSectionEndPoint.useStaffSubmit();


  const fileInputRef = useRef(null);

  const[staffMemName ,setStaffMemName] = useState('');
  const[staffPhone , setStaffPhone] = useState('');
  const[staffNIC , setStaffNIC] = useState('');
  const[staffEmail , setStaffEmail] = useState('');
  const[staffSalary , setStaffSalary] = useState('');
  const[staffImage , setStaffImage] = useState('');
  const[staffDaysPerWeek , setStaffDaysWeek] = useState('');


    // loading and conditing hooks:
    const[input , setInput] = useState(false);


    function submitStaff(){
        if(staffMemName === '' || staffPhone === ''  || staffNIC === '' || staffEmail === '' || staffSalary === '' || staffDaysPerWeek === ''){
          setInput(true)
          toast.warn("Fill the information !")
          
        }
        else{
          var formdata = new FormData();
          formdata.append("staff_name",staffMemName);
          formdata.append("staff_salary", staffSalary);
          formdata.append("staff_phone", staffPhone);
          formdata.append("staff_email", staffEmail);
          formdata.append("days_per_week", staffDaysPerWeek);
          formdata.append("staff_image",staffImage);
          formdata.append("cnic", staffNIC);
    
          
          staffSubmission((formdata) , {
            onMutate: () => {
            },
            onSettled: () => {
    
              setInput(false);
    
              setStaffMemName('');
              setStaffPhone('');
              setStaffNIC('');
              setStaffEmail('');
              setStaffSalary('');
              setStaffDaysWeek('');

              fileInputRef.current.value = '';
            },
    
          
          })
     
        }
    
      }



  return (
    <>
        <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div class="container-xxl flex-grow-1 container-p-y">
            <h4 class="fw-bold py-3 mb-4">
              <span class="text-muted fw-light">Staff Form</span> 
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Staff Form</h5>
                  {/* <small className="text-muted float-end">Default label</small> */}
                  </div>
                  <div className="card-body">
                    <div className="row">

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Staff Member Name
                        </label>
                        <input
                          type="text"
                          className={staffMemName === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname"
                          placeholder="Enter Member Name"
                          value={staffMemName}
                          onChange={(e)=> setStaffMemName(e.target.value)}
                        />
                      </div>

                                   
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname01"
                        >
                          Staff Member Phone
                        </label>
                        <input
                          type="number"
                          className={staffPhone === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname01"
                          placeholder="Enter Staff Name"
                          value={staffPhone}
                          onChange={(e)=> setStaffPhone(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message01"
                        >
                          Staff Member CNIC
                        </label>
                        <input
                          type="number"
                          className={staffNIC === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname05"
                          placeholder="Enter Member CNIC"
                          value={staffNIC}
                          onChange={(e)=> setStaffNIC(e.target.value)}
                        />
                      </div>


                      <div className="col-lg-12 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname02"
                        >
                          Staff Member Email
                        </label>
                        <input
                          type="email"
                          className={staffEmail === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname02"
                          placeholder="Enter Member Email"
                          value={staffEmail}
                          onChange={(e)=> setStaffEmail(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname03"
                        >
                          Staff Member Salary
                        </label>
                        <input
                          type="number"
                          className={staffSalary === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname03"
                          placeholder="Enter Salary"
                          value={staffSalary}
                          onChange={(e)=> setStaffSalary(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Member Image
                        </label>
                        <input
                          ref={fileInputRef}
                          type="file"
                          className={staffImage === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-company"
                          onChange={(e)=> setStaffImage(e.target.files[0])}
                        />
                      </div>

                    
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                          Days Per Week
                        </label>
                        <input
                          type="text"
                          className={staffDaysPerWeek === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-fullname04"
                          placeholder="Enter Days Per Week"
                          value={staffDaysPerWeek}
                          onChange={(e)=> setStaffDaysWeek(e.target.value)}
                        />
                      </div>

           

                    </div>
                    {
                        isLoading ? 
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      :
                    <button type="submit" className="btn btn-primary" onClick={submitStaff}>
                      Submit
                    </button>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StaffForm