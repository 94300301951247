import axios from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState , useEffect} from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
import TextileReceiveSectionEndPoint from "../../../Api/TextileReceiveSection/TextileReceiveSectionEndPoint";

const ItemReceivingForm = () => {
  const { mutate: textileReceiveItemsSubmission, isLoading } =  TextileReceiveSectionEndPoint.useSubmitTextileReceiveItems();

  // Items Hooks;
  const[itemID ,setItemID] = useState('')
  const[itemName , setItemName] = useState('');
  const[itemPrice , setItemPrice] = useState('');
  const[unitName , setUnitName] = useState('');
  const[receivedFrom , setReceivedFrom] = useState('');
  const[quantity , setQuantity] = useState('');
  const[totalBill , setTotalBill] = useState('');
  const[billPaid , setBillPaid] = useState('');
  const[tax , setTax] = useState('');
  const[gst , setGst] = useState('');
  const[otherChargesName , setOtherChargesName] = useState('');
  const[otherCharges , setOtherCharges] = useState('');
  const[shippingCharges , setShippingCharges] = useState('');


  const[getBanks , setGetBanks] = useState([]);
  const[bankID , setBankID] = useState('');
  const[selectBankName , setSelectBankName] = useState('');


  // loading and conditing hooks:
  const[input, setInput] = useState(false);
  const[getTextileItems , setTextileItems] = useState([]);

  const[userData , setUserData] = useState('');


  
    // Getting all textile items function:
    useQuery(("all_textile_items_for_dropdown"), TextileReceiveSectionEndPoint.getAllTextileItems, {
      onSuccess: (data) => {
        setTextileItems(data.data.items);
      },
      onError: (err) => {
        return err;
      },
    })

    function getAllBanks(){
      axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
      .then((res)=>{
        setGetBanks(res.data.Banks)
      })
      .catch((err)=>{
        return err
      })

    }

  function submitReceiveItems() {

    if (itemName === "" || itemPrice ==="" || receivedFrom ==="" || unitName === "" || quantity ==="" || totalBill ==="" || 
    billPaid ==="" ||  otherChargesName ==="" || otherCharges === "" || shippingCharges === "" ||  selectBankName === "") {
      setInput(true);
      toast.warn("Fill the information !");
    } else {
      const receiveItemObj = {
        role_id :userData.role_id,
        user_id:userData.id,
        added_by:userData.username,
        item_id:itemID,
        item_name: itemName,
        item_price: itemPrice,
        received_by: receivedFrom,
        unit_name:unitName,
        quantity: quantity,
        total_bill: totalBill,
        paid:billPaid,
        tax:tax === "" ? "":tax,
        gst:gst=== "" ? "":gst,
        other_charges:otherCharges,
        other_charges_name:otherChargesName,
        shipping_charges:shippingCharges,
        ledger_description:`item receiving - ${itemName} - ${bankID}`,
        bank_id : bankID,
      };

      textileReceiveItemsSubmission(receiveItemObj, {
        // onMutate: () => {},
        onSettled: () => {
          setInput(false);

          setItemName('');
          setItemID('')
          setItemPrice('');
          setUnitName('');
          setReceivedFrom('');
          setQuantity('');
          setTotalBill('');
          setBillPaid('');
          setTax('');
          setGst('');
          setOtherChargesName('');
          setOtherCharges('');
          setShippingCharges('');
          setSelectBankName('');
          setBankID('');
        },
      });
    }
  }




  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
    getAllBanks()
  }, [])
  
  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light"></span>
               Items Receiving Form
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Items Receiving Form</h5>
                    {/* <small className="text-muted float-end">
                      Default label
                    </small> */}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Item Name
                        </label>
                        <select
                            id="inputGroupSelect01"
                            className = {itemName === ''&& input === true?"form-select border border-danger":"form-select"}
                            value={itemID}
                            onChange={(e)=>
                             {
                              const selectedValue = e.target.value; 
                              const selectedText = e.target.options[e.target.selectedIndex].text;
                              setItemID(selectedValue)
                              setItemName(selectedText)
                             }
                            }
                          >
                            <option value="">Choose...</option>
                            {
                              getTextileItems && getTextileItems.length > 0 ?
                              getTextileItems?.map((items,index)=>{
                                return(
                                  <option key={index} value={items.id}>{items.item_name}</option>

                                ) 
                              })
                              :
                              <option >No Category Found...</option>
                            }

                          </select>
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Item Price
                        </label>
                        <input
                          type="number"
                          className={itemPrice === ''&& input === true?"form-control border border-danger":"form-control"}
                          id="basic-default-company"
                          placeholder="Enter Item price..."
                          value={itemPrice}
                          onChange={(e)=> setItemPrice(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-email"
                        >
                          Unit Name
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            id="basic-default-email"
                            className={unitName === ''&& input === true?"form-control border border-danger":"form-control"}
                            placeholder="Enter Unit name..."
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            value={unitName}
                            onChange={(e)=> setUnitName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Received By
                        </label>
                        <input
                          type="text"
                          id="basic-default-phone"
                          className={receivedFrom === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Item Received from..."
                          value={receivedFrom}
                          onChange={(e)=> setReceivedFrom(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                          Quantity    
                        </label>
                        <input
                          type="number"
                          id="basic-default-message"
                          className={quantity === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Enter Quantity..."
                          value={quantity}
                          onChange={(e)=> setQuantity(e.target.value)}
                        />
                      </div>
                      
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                          Total Bill    
                        </label>
                        <input
                          type="number"
                          id="basic-default-message"
                          className={totalBill === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Enter Total Bill..."
                          value={totalBill}
                          onChange={(e)=> setTotalBill(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Bill Paid(Status)   
                        </label>
                          <select
                            id="inputGroupSelect01"
                            className = {billPaid === ''&& input === true?"form-select border border-danger":"form-select"}
                            value={billPaid}
                            onChange={(e)=>setBillPaid(e.target.value) }
                          >
                            <option value="">Choose...</option>
                            <option value="true">Paid</option>
                            <option value="false">Not Paid</option>

                          </select>
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Tax    
                        </label>
                        <input
                          type="number"
                          id="basic-default-message"
                          className={"form-control"}
                          placeholder="Enter Tax..."
                          value={tax}
                          onChange={(e)=> setTax(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Gst    
                        </label>
                        <input
                          type="number"
                          id="basic-default-message"
                          className={"form-control"}
                          placeholder="Enter Gst..."
                          value={gst}
                          onChange={(e)=> setGst(e.target.value)}
                        />
                      </div>

                      
                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Other Charges Name    
                        </label>
                        <input
                          type="text"
                          id="basic-default-message"
                          className={otherChargesName === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Enter other charges name..."
                          value={otherChargesName}
                          onChange={(e)=> setOtherChargesName(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Other charges amount
                        </label>
                        <input
                          type="number"
                          id="basic-default-message"
                          className={otherCharges === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Enter other charges amount..."
                          value={otherCharges}
                          onChange={(e)=> setOtherCharges(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                         Shipping Charges    
                        </label>
                        <input
                          type="number"
                          id="basic-default-message"
                          className={shippingCharges === ''&& input === true?"form-control border border-danger":"form-control"}
                          placeholder="Enter Shipping charges..."
                          value={shippingCharges}
                          onChange={(e)=> setShippingCharges(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 mb-3">
                      <label
                          className="form-label fw-bold"
                          htmlFor="basic-default-message"
                        >
                         Select Bank
                        </label>

                        <select className={selectBankName === '' && input === true ?"form-select border border-danger" :"form-select"} aria-label="Default select example"
                        value={bankID}
                        onChange={(e)=>
                          {
                          const selectedValue = e.target.value; 
                          const selectedText = e.target.options[e.target.selectedIndex].text;
                          setBankID(selectedValue)
                          setSelectBankName(selectedText)
                          }
                        }
                        >
                          <option value="">Bank select menu</option>
                          {
                            getBanks && getBanks.length > 0 ?

                            getBanks?.map((item , id)=>{
                              return(
                                <option key={id} value={item.bank_id}>{item.bank_name}</option>
                              )
                            })

                            :

                            <option value="">No Bank Found !</option>
                          }
                          
                        </select>
                        </div>

                    </div>

                    {isLoading ? (
                      <div
                        className="spinner-border text-primary mt-3 float-end"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary mt-3 float-end"
                        onClick={submitReceiveItems}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemReceivingForm;
