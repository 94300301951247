import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import OrderIntakeSectionEndPoint from "../../Api/OrderSection/OrderIntakeSection";
import CourierSectionEndPoint from "../../Api/CourierSection/CourierSectionEndPoint";

const DispatchToReturnOrderModal = ({
  orderID,
  orderData,
  orderAction,
  index,
  removeSpecificeOrder,
  showReturnDispatchedModal,
  setShowReturnOrderModal,
}) => {
  const { mutate: approveOrdersByStatus, isLoading } =
    OrderIntakeSectionEndPoint.useApproveOrderStatus();

  const [allCourier, setAllCourier] = useState([]);
  const [courierID, setCourierID] = useState("");
  const [courierName, setCourierName] = useState("");
  const [packageWeight, setPackageWeight] = useState("");
  const [amountCollection, setAmountCollection] = useState("");
  const [courierDescrip, setCourierDescrip] = useState("");

  const [selectedCourier, setSelectedCourier] = useState("");
  const [autoFetch, setAutoFetch] = useState(false);

  const [loading, setLoading] = useState(false);

  const [articleSelectData, setArticleSelectData] = useState([]);

  // Getting all orders by status:
  useQuery("all_Courier", CourierSectionEndPoint.getAllCourier, {
    enabled: !autoFetch,
    onSuccess: (data) => {
      setAllCourier(data.data.Couriers);
      setAutoFetch(true);
    },
    onError: (err) => {
      return err;
    },
  });

  const handleSelectCourier = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    setCourierID(selectedValue.id);
    setCourierName(selectedValue.cName);
    setSelectedCourier(e.target.value);
  };

  async function assignCourier() {
    if (courierName === "Call courier") {
      setLoading(true);

      const saveBookingUrl =
        "http://cod.callcourier.com.pk/api/CallCourier/SaveBooking";
      const loginId = "test-0001";
      const consigneeName = orderData.contact_data.name;
      const consigneeRefNo = orderID;
      const consigneeCellNo = "03004344328";
      const address = orderData.billing_data.address;
      const origin = orderData.billing_data.city;
      const destCityId = 18;
      const serviceTypeId = 7;
      const pcs = "01";
      const weight = packageWeight;
      const description = courierDescrip;
      const selOrigin = "Domestic";
      const codAmount = amountCollection;
      const specialHandling = false;
      const myBoxId = "1 My Box ID";
      const holiday = false;
      const remarks = courierDescrip;
      const shipperName = "AVANT GARDE";
      const shipperCellNo = "+923217593759";
      const shipperArea = "1";
      const shipperCity = "1";
      const shipperAddress = "82 A1, PIA Housing Society, Lahore, Pakistan";
      const shipperLandLineNo = "+923217593759";
      const shipperEmail = "info@avantgardeoriginal.com";

      try {
        const response = await fetch(
          `${saveBookingUrl}?loginId=${loginId}&ConsigneeName=${consigneeName}&ConsigneeRefNo=${consigneeRefNo}&ConsigneeCellNo=${consigneeCellNo}&Address=${address}&Origin=${origin}&DestCityId=${destCityId}&ServiceTypeId=${serviceTypeId}&Pcs=${pcs}&Weight=${weight}&Description=${description}&SelOrigin=${selOrigin}&CodAmount=${codAmount}&SpecialHandling=${specialHandling}&MyBoxId=${myBoxId}&Holiday=${holiday}&remarks=${remarks}&ShipperName=${shipperName}&ShipperCellNo=${shipperCellNo}&ShipperArea=${shipperArea}&ShipperCity=${shipperCity}&ShipperAddress=${shipperAddress}&ShipperLandLineNo=${shipperLandLineNo}&ShipperEmail=${shipperEmail}`
        );

        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.Response === "true") {
          setLoading(false);
          updateOrderStatus(data.CNNO);
          setPackageWeight("");
          setAmountCollection("");
        }
      } catch (error) {
        return error;
      }
    } else if (courierName === "Leopard courier") {
      setLoading(true);
      const apiUrl =
        "http://new.leopardscod.com/webservice/bookPacket/format/json/";
      const corsProxyUrl = "https://corsproxy.io/?";
      var raw = {
        api_key: "9AB795FFDDC27C8D29E9E9035A7EA170",
        api_password: "VERYFASTAVANT123",

        booked_packet_weight: packageWeight,
        booked_packet_vol_weight_w: 0,
        booked_packet_vol_weight_h: 0,
        booked_packet_vol_weight_l: 0,
        booked_packet_no_piece: "01",
        booked_packet_collect_amount: amountCollection,
        booked_packet_order_id: orderData.id,

        origin_city: 789,
        destination_city: 789,

        shipment_id: "",
        shipment_name_eng: "AVANT GARDE",
        shipment_email: "info@avantgardeoriginal.com",
        shipment_phone: +923217593759,
        shipment_address: "82 A1, PIA Housing Society, Lahore, Pakistan",

        consignment_name_eng: "orderData.contact_data.name",
        consignment_email: orderData.contact_data.email,
        consignment_phone: orderData.contact_data.phone,
        consignment_phone_two: "",
        consignment_phone_three: "",
        consignment_address: orderData.shipping_data.address,
        special_instructions: courierDescrip,

        shipment_type: "",
        custom_data: "",
        return_address: "82 A1, PIA Housing Society, Lahore, Pakistan",
        return_city: 789,
        is_vpc: 0,
      };

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(raw),
        redirect: "follow",
      };

      fetch(corsProxyUrl + apiUrl, requestOptions)
        .then((response) => {
          if (!response.ok) {
            setLoading(false);
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          if (result.status === 1) {
            setLoading(false);
            updateOrderStatus(result.track_number);
          } else {
            console.log(result);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          if (error) {
            toast.warn("Something went wrong, please try again in a while");
          }
        });
    } else if (courierName === "Rider") {
      const apiUrl = "http://api.withrider.com/rider/v2/SaveBooking";
      const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";

      // Assuming the following query parameters
      const queryParams = {
        loginId: 392,
        ConsigneeName: "SAK",
        ConsigneeRefNo: "TEST ORD SAK 005 - COMAPI V2",
        ConsigneeCellNo: "03015050775",
        Address: "House No. 555, Street 2",
        OriginCityId: 1,
        OriginCityName: "KHI",
        DestCityId: 1,
        DestCityName: "LHE",
        DestAreaId: 5,
        ServiceTypeId: 1,
        DeliveryTypeId: 2,
        Pcs: 1,
        Weight: 5.5,
        Description: "Handle with care.....",
        CodAmount: 1400.14,
        remarks: "NA",
        ShipperAddress: "JJ Warehouse",
        vendorName: "NEW VENDOR TEST",
        vendorAddress: "Vendor Address",
        vendorPhone: "0303030303",
        paymentModeId: 2,
        apikey:
          "kSvCVG44D$GU5nYX9N M2OKBkHrUp35NEUR1aa@fKHRaw8oevVirUtkoOrl!E1ObM",
      };

      var requestOptionRider = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryParams),
        redirect: "follow",
      };

      fetch(corsProxyUrl + apiUrl, requestOptionRider)
        .then((response) => {
          if (!response.ok) {
            console.log(response);
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result.statuscode === 200) {
            updateOrderStatus(result.CNUM);
          } else {
            console.log(result);
          }
        })
        .catch((error) => {
          if (error) {
            toast.warn("Something went wrong, please try again in a while");
          }
        });
    }
  }

  function updateOrderStatus(CN) {
    const orderStatusObj = {
      user_id: 1,
      role_id: 1,
      courier_id: parseInt(courierID),
      order_id: orderID,
      cn: CN,
      added_by: "admin",
      status: "assigned",
    };

    approveOrdersByStatus(
      { orderStatusObj, orderAction, index, removeSpecificeOrder },
      {
        onMutate: () => {},
        onSettled: () => {
          setSelectedCourier("");
          setCourierDescrip("");
        },
      }
    );
  }



  const handleArticleSelectData = (order)=>{
       const isSelected = articleSelectData.some((selectedOrder) => selectedOrder.article_id === order.article_id);

       if (!isSelected) {
        setArticleSelectData((prevSelectedOrders) => [...prevSelectedOrders, order]);
       } else {
         const updatedOrders = articleSelectData.filter((selectedOrder) => selectedOrder.article_id !== order.article_id);
         setArticleSelectData(updatedOrders);
       }
  }
  return (
    <>
      <Modal
        show={showReturnDispatchedModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            <i className="fa-solid fa-box fs-3" /> &nbsp; Order#
            {orderID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black fs-4">
          <div className="row">
            <h6>#Articles</h6>

            {orderData.order_data && orderData.order_data.length > 0
              ? orderData.order_data.map((order, index) => {
                  return (
                    <div className="row m-1" key={index}>
                      <div className="form-check">
                        <label
                          className="form-check-label fw-bold fs-6"
                          htmlFor={`defaultCheck${index + 1}`}
                        >
                          
                          "Select Article"
                        </label>
                        <input
                          className="form-check-input fs-6 border border-primary mt-2"
                          type="checkbox"
                          id={`defaultCheck${index + 1}`}
                          onClick={() => {
                            handleArticleSelectData(order);
                          }}
                          checked={articleSelectData.some((selectedOrder) => selectedOrder.article_id === order.article_id)}
                        />
                      </div>
                      <div className="col-lg-10 border border-1 shadow-sm rounded ">
                        <div className="row">
                          <span style={{ fontSize: "12px" }}>
                            #{order.article_id}
                          </span>
                          <div
                            className="col-lg-5 d-flex"
                            style={{ fontSize: "13px" }}
                          >
                            {order.image && order.image !== null ? (
                              <img
                                key={order.image[0]}
                                className="img-fluid card-img-left me-3"
                                src={`${process.env.REACT_APP_IMAGE_URL}${order.image[0]}`}
                                alt="Card_image"
                                width={35}
                              />
                            ) : (
                              <div className="text-black ms-1">
                                <i
                                  className="fa-solid fa-image me-3"
                                  style={{ fontSize: "2.5em" }}
                                />
                              </div>
                            )}
                            <div className="text-black fw-bold mt-2">
                              Qty: &nbsp;
                              <span className="badge rounded-pill bg-label-warning">
                                {order.qty}
                              </span>
                            </div>
                            &nbsp;&nbsp;
                            {order.on_sale === "true" ? (
                              <div className="text-black fw-bold mt-2">
                                Sale:&nbsp;
                                <span className="badge bg-label-info">Yes</span>
                              </div>
                            ) : (
                              <div className="text-black fw-bold mt-2">
                                Sale:&nbsp;
                                <span className="badge bg-label-danger">
                                  No
                                </span>
                              </div>
                            )}
                          </div>

                          <div
                            className="col-lg-7"
                            style={{ fontSize: "12px" }}
                          >
                            <span className="text-black fw-bold">
                              Actual Price:
                            </span>
                            &nbsp;&nbsp;
                            <span className="text-black me-2">
                              <span>{order.actual_single_price}</span>
                            </span>
                            <span className="text-black fw-bold ">
                              Single Price:
                            </span>
                            &nbsp;&nbsp;
                            <span className="text-black me-2">
                              <span>{order.single_price}</span>
                            </span>
                            <div>
                              <span className="text-black fw-bold">
                                Total Price:
                              </span>
                              &nbsp;&nbsp;
                              <span className="text-black">
                                {order.total_price}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* <div className="ms-1"> */}
                        <span
                          className="badge bg-label-dark"
                          style={{ fontSize: "8px" }}
                        >
                          {order.size}
                        </span>
                        {/* </div> */}
                      </div>
                    </div>
                  );
                })
              : "No Data Found!"}

            <div className="col-lg-4">
              <label className="form-label" htmlFor="basic-default-phone">
                Return Paid Status
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option value="">------------Choose----------</option>
                <option value="Paid">Paid</option>
                <option value="Not Paid">Not Paid</option>
              </select>
            </div>

            <div className="col-lg-4">
              <label className="form-label" htmlFor="basic-default-company">
                Return Charges
              </label>
              <input
                type="number"
                className="form-control"
                id="basic-default-company"
                placeholder="Enter return charges..."
              />
            </div>

            <div className="col-lg-4">
              <label className="form-label" htmlFor="basic-default-phone">
                Select Return Status
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option value="">------------Choose----------</option>
                <option value="Return R-1">Return R-1</option>
                <option value="Return R-2">Return R-2</option>
                <option value="Return R-3">Return R-3</option>
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark"
            onClick={() => setShowReturnOrderModal(!showReturnDispatchedModal)}
          >
            Close
          </button>
          {loading === true ? (
            <>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </>
          ) : (
            <button className="btn btn-outline-primary" onClick={assignCourier}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DispatchToReturnOrderModal;
