import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState,useEffect} from 'react';
import UpdateArticleReceivingModal from '../Modals/UpdateArticleReceivingModal';

const ArticleReceivingSheet = () => {
    const [articleReceiving, setAllArticleReceiving] = useState([])

    const getAllArticleReceiving = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_article_receiving`)
        .then((res)=>{
            setAllArticleReceiving(res.data.Article_receivings)
        })
        .catch((err)=>{
            return err
        })
    }

    
    function removeArticleReceiving(index) {
        setAllArticleReceiving((prevState) => {
          const articleReceiving = [...prevState];
          articleReceiving.splice(index, 1);
          return articleReceiving;
        });
      }

      function deleteArticleReceiving(id,index){
        axios.post(`${process.env.REACT_APP_BASE_URL}delete_article_receiving_by_id/${id}`)
        .then((res)=>{
          if(res.data.status === '200'){
            toast.error("Article Receiving Removed")
            removeArticleReceiving(index)
      
            }
        })
        .catch((error)=>{
            if(error){
              toast.warn("Something went wrong! Please try again")
            }
       
      })
      }

    useEffect(() => {
        getAllArticleReceiving()
    }, [])


    function ArticleReceivingSheet({items , index}){
        
      const [showArticleReceivingModal, setShowArticleReceivingModal] = useState(false);
  
  
          return(
              <>
                 <tr>
                  <td>{items.article_receivings_id}</td>
                  <td>{items.added_by}</td>
                  <td>{items.article_title}</td>
                  <td>{items.vendor_name}</td>
                      <td>
                        {
                        items.item_used.map((item)=>{
                          return(
                            <div className='d-flex justify-content-center align-self-center'>
                              {item.item_name}
                            </div>

                          )
                      })
                  }
                      </td>
                  <td>{items.total_qty}</td>
                  <td>{items.total_price}</td>
                  <td>{items.bank_name}</td>
                  <td>{items.paid_amount}</td>
                  <td>{items.material_cost}</td>
                  <td>{items.sub_total}</td>
                  <td>{items.total_bill}</td>
                  <td>{items.Idate}</td>
  
                <td>
                  <div className="dropdown">
                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                      <i className="bx bx-dots-vertical-rounded" />
                    </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item" onClick={()=>{
                        setShowArticleReceivingModal(true)
                        // setBankID(items.bank_id)
                      }}><i className="bx bx-edit-alt me-1" /> Edit</button>
                      <button className="dropdown-item" onClick={()=>deleteArticleReceiving(items.article_receivings_id,index)}><i className="bx bx-trash me-1" /> Delete</button>
                    </div>
                  </div>
                </td>
              </tr>
            {
              showArticleReceivingModal === true ?
              <UpdateArticleReceivingModal
              
              articleReceivingID = {items.article_receivings_id}
              articleReceivingData = {items}
              showArticleReceivingModal={showArticleReceivingModal}
              setShowArticleReceivingModal = {setShowArticleReceivingModal}
              getAllArticleReceiving = {getAllArticleReceiving}

              />
              :
              ""
            }
  
              </>
          )
      }
  return (
    <>
     <div className="scroll-view-component scrollbar-secondary-component">       
       <div className="content-wrapper">
  <div className="container-xxl flex-grow-1 container-p-y">
    <h4 className="fw-bold "><span className="text-muted fw-light">Article Receiving Sheet</span></h4>
    <div className="card">
      <h5 className="card-header">Article Receiving Sheet</h5>
      <div className="table-responsive text-nowrap">
        <table className="table">
          <thead>
            <tr className='text-center'>
              <th>#</th>
              <th>Added By</th>
              <th>Article</th>
              <th>Vendor</th>
              <th>Items Used</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Bank</th>
              <th>Paid Amount</th>
              <th>Material Cost</th>
              <th>Sub Total</th>
              <th>Total</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0 table-responsive text-center">
          {
                    articleReceiving?.map((items,index)=>{
                        return(
                            <ArticleReceivingSheet items={items} index={index} />  
                        )
                    })
                }
          </tbody>
        </table>
      </div>
    </div>

  </div>

</div>
</div>


    </>
  )
}

export default ArticleReceivingSheet