import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useMutation , useQueryClient} from 'react-query';


function getAllTextileItems(){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}get_item_name_and_id`)
  
}

function getAllArticles(pages){
  return  axios.post(`${process.env.REACT_APP_BASE_URL}get_all_articles?page=${pages}`)
      
 }

 function getArticleByID(ID){
  return axios.post(`${process.env.REACT_APP_BASE_URL}get_articles_by_id/${ID}`,)
 }

 
function textileReceiveItemsSubmission(receiveItemObj){
  return axios.post(`${process.env.REACT_APP_BASE_URL}post_item_receivings`, receiveItemObj)
    .then((res)=>{
      if(res.data.status === '200'){
          toast.info("Received Items Submit!")
        return res.data
      }
      else {
        throw new toast.error("Can't submit items!");
      }
    })
  .catch((error)=>{
    if(error.response.data.status === '401'){
      toast.warn(error.response.data.message)
    }
    else{
      toast.warn("Something went wrong, please try again in a while")
    }
    })
}

function updateTextileReceivedItem({receiveItemObj, itemReceivingID}){
  
   axios.post(`${process.env.REACT_APP_BASE_URL}update_item_receiving_by_id/${itemReceivingID}`, receiveItemObj)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.info("Item Updated!")
  }
  else {
    throw new toast.error("Can't submit items!");
  }
  })
  .catch((error)=>{
    console.log(error)

    if(error)
    {
      console.log(error)
      toast.warn("Something went wrong, please try again in a while")
    }
    
 
})

}

function deleteArticleItem({id,removeArticleFromSheet,index}){
  axios.post(`${process.env.REACT_APP_BASE_URL}delete_article_by_id/${id}`)
  .then((res)=>{
    if(res.data.status === '200'){
      toast.error("Article Removed")
      removeArticleFromSheet(index)

      }
  })
  .catch((error)=>{
      if(error){
        toast.warn("Something went wrong")
      }
 
})
}



const useSubmitTextileReceiveItems = ()=>{
    return useMutation(textileReceiveItemsSubmission)
}

const useUpdateTextileReceivedItems = ()=>{
  // const queryClient = useQueryClient()
  return useMutation(updateTextileReceivedItem , {
  //   onSuccess: ()=>{
  //  queryClient.invalidateQueries({ queryKey: ['all_textile_items'] })
  //   }
  })
}

const useDeleteArticleItem = ()=>{
  return useMutation( deleteArticleItem)
}
const ArticleSectionEndPoint ={
    getAllTextileItems,
    useSubmitTextileReceiveItems,
    useUpdateTextileReceivedItems,
    useDeleteArticleItem,
    getAllArticles,
    getArticleByID
}

export default ArticleSectionEndPoint;