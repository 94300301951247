import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState , useEffect} from "react";
import UsersGlobalData from "../../../GlobalCalls/UsersGlobalData";
import ClothingCategorySectionEndPoint from '../../../Api/ClothingSection/ClothingCategorySectionEndPoint';

const ClothCategoryForm = () => {
  const {mutate:categoriesSubmission , isLoading } = ClothingCategorySectionEndPoint.useCategorySubmission();

    // Items Hooks;
    const [categoryName , setCategoryName] = useState('');
    // const [categoryStatus , setCategoryStatus] = useState('');

    const[userData , setUserData] = useState('');

  
    // loading and conditing hooks:
    const[input , setInput] = useState(false);

    function submitCategory(){
      if(categoryName === ''){
        setInput(true)
        toast.warn("Fill the information !")
        
      }
      else{
        const categoryObj = {
          role_id :userData.role_id,
          user_id:userData.id,
          added_by:userData.username,
          cat_name: categoryName,
        }
  
        
        categoriesSubmission((categoryObj) , {
          onMutate: () => {
          },
          onSettled: () => {
  
            setInput(false);
            setCategoryName('');

          },
  
        
        })
   
      }
  
    }

    useEffect(() => {
      const getocal = async() =>{
        const data = await UsersGlobalData()
       setUserData(data)
      }
      getocal()
    }, [])

  return (
    <>
          <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">
              <span className="text-muted fw-light"></span> 
              Category Form
            </h4>
            <div className="row">
              <div className="col-lg-7">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Category Form</h5>
                    {/* <small className="text-muted float-end">
                      Default label
                    </small> */}
                  </div>
                  <div className="card-body">

                    <div className="row">

                    <div className="col-lg-12">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-company"
                          >
                            Category name
                          </label>
                          <input
                            type="text"
                            value={categoryName}
                            className={categoryName === ''&& input === true?"form-control border border-danger":"form-control"}
                            id="basic-default-company"
                            placeholder="Enter Category name..."
                            onChange={(e)=>setCategoryName(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* <div className="col-lg-6">
                        <div className="mb-3">
                          <label
                           className="form-label"
                            htmlFor="inputGroupSelect01"
                          >
                             Category Status
                          </label>
                          <select
                            className="form-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>Choose...</option>
                            <option value={1}>ACTIVE</option>
                            <option value={2}>IN-ACTIVE</option>
                          </select>
                        </div>
                      </div> */}
            
  
                    </div>
                    {
                       isLoading ?
                       <div className="spinner-border text-primary float-end" role="status">
                       <span className="visually-hidden">Loading...</span>
                     </div>
                     :
                     <button type="submit" className="btn btn-primary float-end" onClick={submitCategory}>
                     Submit
                   </button>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClothCategoryForm