import {useQuery} from 'react-query';
import Modal from "react-bootstrap/Modal";
import React, {useState , useEffect} from 'react';
import UsersGlobalData from '../../../GlobalCalls/UsersGlobalData';
import PromocodeSection from "../../../Api/PromocodeSection/PromocodeSection";


const UpdatePromoCodeModal = ({promoCodeID , showPromoModal , setShowPromoModal , refetchPromos}) => {

  const {mutate:updatePromos  } = PromocodeSection.useUpdatePromos();


  const [promoID , setPromoCodeID] = useState('')
  const [promocode , setPromoCode] = useState('');
  const [promoCodeFor , setPromoCodeFor] = useState('');
  const [discountPromoCode , setDiscountPromoCode] = useState('');
  const [promocodeValidTill , setPromoCodeValidTill] = useState('');
  const [promoCodeLive, setPromoCodeLive] = useState('');
  const [addedBy , setAddedBy] = useState('');
  const [autoFetch , setAutoFetch] = useState(false);

  const[userData , setUserData] = useState('');
  

    // Getting Specific promos:
    useQuery(["getPromosByID", promoCodeID], _=> PromocodeSection.getPromosByID(promoCodeID), {
      enabled:!autoFetch,
      onSuccess: (data) => {
        setPromoCodeID(data.data.Promo_code.promo_code_id)
        setPromoCode(data.data.Promo_code.code)
        setPromoCodeFor(data.data.Promo_code.promo_for);
        setDiscountPromoCode(data.data.Promo_code.discount);
        setPromoCodeValidTill(data.data.Promo_code.valid_till);
        setAddedBy(data.data.Promo_code.added_by)
        setPromoCodeLive(data.data.Promo_code.live)
        setAutoFetch(true)

      },
      onError: (err) => {
        return err;
      }  
    })

    function updatePromoCode(){

        var formdata = new FormData();
        
        
        formdata.append("user_id",userData.id);
        formdata.append("role_id", userData.role_id);

        formdata.append("code", promocode);
        formdata.append("promo_for", promoCodeFor);
        formdata.append("added_by", userData.username);
        formdata.append("discount",discountPromoCode);
        formdata.append("valid_till",promocodeValidTill);
        formdata.append("added_by",addedBy);
        formdata.append("live",promoCodeLive);

        
  
        
        updatePromos(({formdata,promoID}) , {
          onMutate: () => {
          },
          onSettled: () => {
  
  
            setPromoCode('');
            setPromoCodeFor('');
            setDiscountPromoCode('');
            setPromoCodeValidTill('');
            setPromoCodeLive('');

          },
  
        
        })
   
  
    }

    useEffect(() => {
      const getocal = async() =>{
        const data = await UsersGlobalData()
       setUserData(data)
      }
      getocal()
    }, [])

  return (
    <>
      <Modal show={showPromoModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Vendor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Promo Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="basic-default-fullname"
                          placeholder="Enter Promocode"
                          value={promocode}
                          onChange={(e)=>setPromoCode(e.target.value)}

                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Promocode For
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="basic-default-company"
                          placeholder="Enter Promocode For"
                          value={promoCodeFor}
                          onChange={(e)=>setPromoCodeFor(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company01"
                        >
                          Discount On Promo
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="basic-default-company01"
                          placeholder="Enter discount on Promocode"
                          value={discountPromoCode}
                          onChange={(e)=>setDiscountPromoCode(e.target.value)}
                        />
                      </div>

                      
                      <div className="col-lg-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company02"
                        >
                          Valid Till
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="basic-default-company02"
                          value={promocodeValidTill}
                          onChange={(e)=>setPromoCodeValidTill(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-12  mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-email"
                        >
                          Promocode Status
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={promoCodeLive}
                          onChange={(e)=> setPromoCodeLive(e.target.value)}
                        >
                          <option >Choose...</option>
                          <option value="true">Active</option>
                          <option value="false">Not Active</option>
                        </select>
                      </div>
                    

                    </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark"
          
          onClick={()=> {
            setShowPromoModal()
            refetchPromos()
            }}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={()=> updatePromoCode()}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdatePromoCodeModal