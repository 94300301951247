import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, {useState,useEffect} from 'react';
import UpdateBankDetails from './Modal/UpdateBankDetails';

const BankSheet = () => {
    const [allBanks, setAllBanks] = useState([])

    const getAllBanks = ()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}fetch_all_banks`)
        .then((res)=>{
            setAllBanks(res.data.Banks)
        })
        .catch((err)=>{
            return err
        })
    }

    
    function removeBankDetails(index) {
        setAllBanks((prevState) => {
          const bankDetails = [...prevState];
          bankDetails.splice(index, 1);
          return bankDetails;
        });
      }

      function deleteBankDetails(id,index){
        axios.post(`${process.env.REACT_APP_BASE_URL}delete_bank_by_id/${id}`)
        .then((res)=>{
          if(res.data.status === '200'){
            toast.error("Details Removed")
            removeBankDetails(index)
      
            }
        })
        .catch((error)=>{
            if(error){
              toast.warn("Something went wrong")
            }
       
      })
      }

    useEffect(() => {
        getAllBanks()
    }, [])


    function AllBankDetails({items , index}){
      
      const [bankID , setBankID] = useState('');
      const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);


        return(
            <>
               <tr>
                <td>{items.bank_id}</td>
                <td>{items.added_by}</td>
                <td>{items.username}</td>
                <td>{items.bank_name}</td>
                <td>{items.account_title}</td>
                <td>{items.account_no}</td>
                <td>{items.balance}</td>
                <td>{items.Idate}</td>

              <td>
                <div className="dropdown">
                  <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded" />
                  </button>
                  <div className="dropdown-menu">
                    <button className="dropdown-item" onClick={()=>{
                      setShowBankDetailsModal(true)
                      setBankID(items.bank_id)
                    }}><i className="bx bx-edit-alt me-1" /> Edit</button>
                    <button className="dropdown-item" onClick={()=>deleteBankDetails(items.bank_id,index)}><i className="bx bx-trash me-1" /> Delete</button>
                  </div>
                </div>
              </td>
            </tr>
          {
            showBankDetailsModal === true ?
            <UpdateBankDetails
            bankID = {bankID}
            showBankDetailsModal={showBankDetailsModal}
            setShowBankDetailsModal = {setShowBankDetailsModal}
            />
            :
            ""
          }

            </>
        )
    }
    

  return (
    <>
    <div className="scroll-view-component scrollbar-secondary-component">
<div className="content-wrapper">
  <div className="container-xxl flex-grow-1 container-p-y">
    <h4 className="fw-bold"><span className="text-muted fw-light">Bank Sheet</span> </h4>
    <div className="card">
      <h5 className="card-header">Bank Sheet</h5>
      <div className="table-responsive text-nowrap">
        <table className="table">
          <thead>
            <tr className='text-center'>
              <th>#</th>
              <th>Added By</th>
              <th>Username</th>
              <th>Bank Name</th>
              <th>Account Title</th>
              <th>Account No</th>
              <th>Balance</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0 table-responsive text-center">
                {
                  allBanks && allBanks.length > 0 ?
                    allBanks?.map((items,index)=>{
                        return(
                            <AllBankDetails items={items} index={index} />  
                        )
                    })
                    :
                    <p className='fw-bold text-center'>No Data Found</p>
                }
          </tbody>
        </table>
      </div>
    </div>

  </div>

</div>
</div>
    </>
  )
}

export default BankSheet