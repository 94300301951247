import {useQuery} from 'react-query';
import React, {useState,useRef,useEffect} from 'react';
import UsersGlobalData from '../../GlobalCalls/UsersGlobalData';
import EventSectionEndPoint from "../../Api/EventSection/EventSectionEndPoint";


const UpdateEventModal = ({eventID}) => {
  
  const {mutate:updateEvents , isLoading } = EventSectionEndPoint.useUpdateEvent();

  const fileInput = useRef(null);

  const[eveID , setEveID] = useState('');

  const[eveName , setEveName] = useState('');

  const[showEveImage , setShowEveImage] = useState('');
  const[eveImage , setEveImage] = useState('');
  const[eveDescription , setEveDescription] = useState('');

  const[userData , setUserData] = useState('')

      
  // Getting Specific events:
  useQuery(["getEventByID", eventID], _=> EventSectionEndPoint.getAllEventsByID(eventID), {
    onSuccess: (data) => {
      setEveID(data.data.Event.event_id)
      setEveName(data.data.Event.event_name)
      setShowEveImage(data.data.Event.event_image)
      setEveDescription(data.data.Event.description)
      // setUsername(data.data.Event.username)
      // setAddedBy(data.data.Event.added_by)
    },
    onError: (err) => {
      return err;
    },
    refetchOnWindowFocus: false,

  })

  function updateEvent(){

    var formdata = new FormData();
    formdata.append("event_name", eveName);
    formdata.append("event_image", eveImage);
    formdata.append("event_live", "false");
    formdata.append("user_id",userData.id);
    formdata.append("role_id",userData.role_id);
    formdata.append("description", eveDescription);
    formdata.append("added_by", userData.username);

    updateEvents(({formdata ,eveID }) , {
      onMutate: () => {
      },
      onSettled: () => {
        setEveName('')
        setEveDescription('');
        fileInput.current.value = '';
      }

    
    })

  }

  useEffect(() => {
    const getocal = async() =>{
      const data = await UsersGlobalData()
     setUserData(data)
    }
    getocal()
  }, [])

  return (
    <>
     <div
        className="modal fade"
        id="updateEventModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel2">
                Update Event
              </h5>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              /> */}
            </div>
            <div className="modal-body">
            <div className="row">
                      <div className="col-lg-12 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Event Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="basic-default-fullname"
                          placeholder="Enter Event Name"
                          value={eveName}
                          onChange={(e)=>setEveName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Event Image
                        </label>
                        <input
                          type="file"
                          ref={fileInput}
                          className="form-control"
                          id="basic-default-company"
                          onChange={(e)=>setEveImage(e.target.files[0])}
                        />
                        <span className='text-warning' onClick={()=>{window.open(`${process.env.REACT_APP_IMAGE_URL}${showEveImage}`)}} target="_blank" style={{cursor:"pointer"}}>View Image</span>
                      </div>
                    
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-message"
                        >
                          Event Description
                        </label>
                        <textarea
                          value={eveDescription}
                          onChange={(e)=> setEveDescription(e.target.value)}
                          id="basic-default-message"
                          className="form-control"
                          placeholder="Event Description..."
                          defaultValue={""}
                          rows={5}
                        />
                      </div>
                    </div>

 
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {
                       isLoading ?
                       <div className="spinner-border text-primary" role="status">
                       <span className="visually-hidden">Loading...</span>
                     </div>
                     :
              <button type="button" className="btn btn-outline-primary" onClick={()=> updateEvent()}>
                Update
              </button>
            }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateEventModal