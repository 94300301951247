import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import UpdateStaffModal from '../Modals/UpdateStaffModal';
import StaffSectionEndPoint from '../../Api/StaffSection/StaffSection';

const StaffSheet = () => {
   const { mutate: deleteStaffMember } = StaffSectionEndPoint.useDeleteStaffMember();


    const[allStaff , setAllStaff] = useState([]);
    const[staffID , setStaffID] = useState('');

    // Hook for pagination:
    const [pages, setPages] = useState(1);
    const [count, setCount] = useState("");


    const[staffName, setStaffName] = useState('');
    const[staffPhone , setStaffPhone] = useState('');
    const[staffNic , setStaffNic] = useState('');
    const[date , setDate] = useState('');

    const [filterButton , setFilterButton] = useState(false);

  
    const [filteredCount, setFilteredCount] = useState(0);
  
    const resultsPerPage = 20;
    // function for getting page next:
    const handleNextPage = () => {
      setPages((prevPage) => prevPage + 1);
    };
    // function for getting previous page:
    const handlePrevPage = () => {
      setPages((prevPage) => Math.max(prevPage - 1, 1));
    };
  
    const totalResults = count || 0;
    const startResult = (pages - 1) * resultsPerPage + 1;
    const endResult = Math.min(pages * resultsPerPage, totalResults);
  
    // Getting all staff members function:
    useQuery(["all_staff_members", pages],(_) => StaffSectionEndPoint.getAllStaffMembers(pages),
      {
        onSuccess: (data) => {
          setAllStaff(data.data.Staff);
          setCount(data.data.TotalCount);
        },
        onError: (err) => {
          return err;
        },
      }
    )

    function removeStaffFromSheet(index) {
      setAllStaff((prevState) => {
        const staff = [...prevState];
        staff.splice(index, 1);
        return staff;
      });
    }
  function delStaff(id, index) {
    deleteStaffMember({ id, removeStaffFromSheet, index });
  }

  function AllStaff({ items, index }) {
    return (
      <tr key={index} className="text-center">
        <td>{allStaff.length - index}</td>
        <td>{items.staff_name}</td>
        <td>{items.staff_salary}</td>
        <td>{items.staff_phone}</td>
        <td>{items.staff_email}</td>
        <td>{items.days_per_week}</td>
        <td>
          <img
            data-bs-toggle="tooltip"
            data-popup="tooltip-custom"
            data-bs-placement="top"
            className="avatar avatar-lg"
            src={`${process.env.REACT_APP_IMAGE_URL}${items.staff_image}`}
            alt="staff_images"
            width={100}
            style={{ borderRadius: "10%" }}
          />
        </td>


        <td>{items.cnic}</td>

        <td>{items.Idate}</td>
        <td>
          <div className="dropdown">
            <button
              type="button"
              className="btn p-0 dropdown-toggle hide-arrow"
              data-bs-toggle="dropdown"
            >
              <i className="bx bx-dots-vertical-rounded" />
            </button>
            <div className="dropdown-menu">
              <button
                type="button"
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#updateStaffModal"
                onClick={() => setStaffID(items.staff_id)}
              >
                <i className="bx bx-edit-alt me-1" /> Edit
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  delStaff(items.staff_id, index);
                }}
              >
                <i className="bx bx-trash me-1" /> Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  }

const filterStaff =  allStaff.length > 0 && 
staffName !== "" && staffPhone === "" && staffNic === "" && date === "" 
? allStaff.filter((items) => items.staff_name === staffName)
:
staffName === "" && staffPhone !== "" && date === "" && staffNic === ""
? allStaff.filter((items) => items.staff_phone === staffPhone)
:
staffName === "" && staffPhone === "" && date === "" && staffNic !== ""
? allStaff.filter((items) => items.cnic === staffNic)
:
staffName === "" && staffPhone === "" && date !== "" && staffNic === ""
? allStaff.filter((items) => items.Idate === date)
:

(staffName !== "" && staffPhone !== "") && date === "" && staffNic === ""
? allStaff.filter((items) => (items.staff_name === staffName && items.staff_phone === staffPhone))
:
(staffName !== "" && staffNic !== "") && staffPhone === "" && date === ""
? allStaff.filter((items) => (items.staff_name === staffName && items.cnic === staffNic))
:
(staffName !== "" && date !== "") && staffPhone === "" && staffNic === ""
? allStaff.filter((items) => (items.staff_name === staffName && items.Idate === date))
:
(staffPhone !== "" && staffNic !== "") && date === "" && staffName === ""
? allStaff.filter((items) => (items.staff_phone === staffPhone && items.cnic === staffNic))
:
(staffPhone !== "" && date !== "") && staffName === "" && staffNic === ""
? allStaff.filter((items) => (items.staff_phone === staffPhone && items.Idate === date))
:
(staffNic !== "" && date !== "") && staffPhone === "" && staffName === ""
? allStaff.filter((items) => (items.cnic === staffNic && items.Idate === date))

: allStaff;

useEffect(() => {
  setFilteredCount(filterStaff.length);
}, [filterStaff])
  return (
    <>
      <div className="scroll-view-component scrollbar-secondary-component"> 
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">Staff Sheet</span>
          </h4>
          
          
          <button
                  type="button"
                  className={filterButton  ?"btn btn-dark mb-2 me-3" :"btn btn-outline-dark mb-2 me-3"}
                  onClick={() => {
                    setFilterButton(!filterButton)
                  }}
                  
                >
                  <span className="tf-icons bx bx-filter-alt"></span>&nbsp;Enable Filters
                </button>

                {
                  filterButton ? 
                    (
                      <div className="row mt-2 mb-4">

                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Search with Staff name..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setStaffName(e.target.value) }
                          />
                      </div>
                      <div className="col-lg-3">
                          <label
                            className="form-label fw-bold"
                          >
                            Phone*
                          </label>
                          <input
                            type="number"
                            placeholder="Search with Staff Phone..."
                            className ={"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setStaffPhone(e.target.value)}
                          />
                      </div>
                    
                      <div className="col-lg-3">
                        <label
                          className="form-label fw-bold"
                        >
                          Identity No*
                        </label>
                        <input
                            type="text"
                            placeholder="Search with NIC..."
                            className = {"form-control form-control-sm border border-primary"}
                            onChange={(e)=>setStaffNic(e.target.value) }
                          />

                      </div>
                         
                        <div className="col-lg-3">
                        <label for="defaultFormControlInput6" className="form-label fw-bold">Date*</label>
                      <input type="date"  className="form-control form-control-sm border border-primary" id="defaultFormControlInput6" placeholder="" aria-describedby="defaultFormControlHelp"
                      
                      onChange={(e)=> {
                        setDate(e.target.value)
                      }}
                      />
                        </div>

           

                      </div>
                    )
                    :
                    null
                    
                  }
          <div className="card">
            <h5 className="card-header">Staff Sheet</h5>
            <div className="table-responsive text-nowrap">
            {filterStaff && filterStaff.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>Name</th>
                        <th>Salary</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Days Per Week</th>
                        <th>Image</th>
                        <th>Cnic</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 ">
                      {filterStaff?.map((items, index) => {
                        return <AllStaff items={items} index={index} />;
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">
                    <h2>No Record Found</h2>
                  </div>
                )}

                <div className="p-3">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handlePrevPage}
                    disabled={pages === 1}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handleNextPage}
                    disabled={totalResults <= endResult}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                  <p>
                    Showing {startResult} - {endResult} of {filteredCount}
                    results - total :&nbsp;&nbsp;{count}
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>



    <UpdateStaffModal staffID={staffID}/>


      </div>
    </>
  )
}

export default StaffSheet